import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components

import ContactListTable2 from './components/ContactListTable2';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services

// Custom components
import { ProductsToolbar } from './components';

// Component styles
import styles from './styles';

class ProductList extends Component {
    render() {
        const { classes } = this.props;

        return (
            <DashboardLayout title="员工管理">
                <div className={classes.root}>
                    {/* <ProductsToolbar /> */}
                    <div className={classes.content}>
                        <ContactListTable2 />
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

ProductList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductList);
