import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Divider, Typography } from '@material-ui/core';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
});

class Footer extends Component {
    render() {
        const { classes, className } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <Divider />
                <Typography
                    className={classes.company}
                    variant="body1"
                >
                    &copy; 山东紫光比威网络技术有限公司
                </Typography>
                <Typography variant="caption">
                    专注利用手机 实现快捷可靠的签到管理！ 跟传统打卡机Say
                    Goooooooodbye！
                </Typography>
            </div>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
