export default {
    name: {
        presence: { allowEmpty: false, message: '员工姓名不能为空' }
    },
    telephone: {
        presence: { allowEmpty: false, message: '手机号码不能为空' },
        // length: {
        //     is: 11,
        //     message: '手机号码格式不对'
        // },
        format: {
            pattern: '[0-9]+',
            flags: 'i',
            message: '只能包含数字'
        }
    },
    // pname1: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname2: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname3: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
};
