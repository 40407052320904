import XLSX, { utils, writeFile } from 'xlsx';

function createWs(data, fields, titles) {
    const ws = XLSX.utils.json_to_sheet(data, {
        header: fields
    });
    const range = XLSX.utils.decode_range(ws['!ref']);

    for (let c = range.s.c; c <= range.e.c; c++) {
        const header = XLSX.utils.encode_col(c) + '1';
        ws[header].v = titles[ws[header].v];
    }

    return ws;
}

const handleExportCsv = (columnList, initialData, name) => {
    let titles = {};
    columnList.forEach(column => {
        if (column.field !== '') {
            titles[column.field] = column.title;
        }
    });
    let fields = [];
    columnList.forEach(column => {
        if (column.field !== '') {
            fields.push(column.field);
        }
    });
    let data = [];
    initialData.map(rowData => {
        let one_field = {};
        fields.forEach(field => (one_field[field] = rowData[field]));
        data.push(one_field);
    });

    const ws = createWs(data, fields, titles);
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, `${name}`);

    writeFile(wb, `${name}.xlsx`);
};

export default handleExportCsv;
