export default {
    firmname: {
        presence: { allowEmpty: false, message: '单位名称不能为空' }
    },
    address: {
        presence: { allowEmpty: false, message: '单位地址不能为空' }
    },
    tel: {
        presence: { allowEmpty: false, message: '电话号码不能为空' },
        format: {
            pattern: '[0-9]+',
            flags: 'i',
            message: '只能包含数字'
        }
    },
    // pname1: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname2: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname3: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
};
