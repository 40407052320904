import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import {
    Button,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';

// Material icons
import {
    ArrowForwardIos as ArrowForwardIosIcon,
    Payment as PaymentIcon,
    PeopleOutlined as PeopleIcon,
    Code as CodeIcon,
    Store as StoreIcon
} from '@material-ui/icons';

// Component styles
import styles from './styles';

const icons = {
    order: {
        icon: <PaymentIcon />,
        color: 'blue'
    },
    user: {
        icon: <PeopleIcon />,
        color: 'red'
    },
    product: {
        icon: <StoreIcon />,
        color: 'green'
    },
    feature: {
        icon: <CodeIcon />,
        color: 'purple'
    }
};

class NotificationList extends Component {
    seeSelectedNotification=(value)=>{
        const route={tabs:1, conditon:1, id:value}
        localStorage.setItem('uc_route',route)
        // this.props.onSelect()
    }

    render() {
        const { className, classes, notifications, onSelect } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                {notifications.length > 0 ? (
                    <Fragment>
                        <div className={classes.header}>
                            <Typography variant="h6">
                            {notifications.length}条新的系统消息
                            </Typography>
                        </div>
                        <div className={classes.content}>
                            <List component="div">
                                {notifications.map(notification => (
                                    <Link
                                        key={notification.id}
                                        to="#"
                                    >
                                        <ListItem
                                            className={classes.listItem}
                                            component="div"
                                            onClick={this.seeSelectedNotification(notification.id)}
                                        >
                                            {/* <ListItemIcon
                                                className={classes.listItemIcon}
                                                style={{
                                                    color:
                                                        icons[notification.type]
                                                            .color
                                                }}
                                            >
                                                {icons[notification.type].icon}
                                            </ListItemIcon> */}
                                            <ListItemText
                                                classes={{
                                                    secondary:
                                                        classes.listItemTextSecondary
                                                }}
                                                primary={notification.title}
                                                // secondary={notification.when}
                                                secondary={notification.content.length>30?notification.content.substring(0,29)+'...':notification.content}
                                            />
                                            <ArrowForwardIosIcon
                                                className={classes.arrowForward}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </Link>
                                ))}
                            </List>
                            <div className={classes.footer}>
                                <Button
                                    color="primary"
                                    component={Link}
                                    size="small"
                                    // to="#"
                                    onClick={this.seeSelectedNotification(0)}
                                    variant="contained"
                                >
                                    查看全部
                                </Button>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div className={classes.empty}>
                        {/* <div className={classes.emptyImageWrapper}>
                            <img
                                alt="Empty list"
                                className={classes.emptyImage}
                                src="/images/empty.png"
                            />
                        </div> */}
                        <Typography variant="h6">
                            {/* There's nothing here... */}
                            没有新的系统通知
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

NotificationList.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    onSelect: PropTypes.func
};

NotificationList.defaultProps = {
    notifications: [],
    onSelect: () => {}
};

export default withStyles(styles)(NotificationList);
