import { format } from 'date-fns';
export function compare_all_time(form_value, num, mode) {
    if(mode==0){
        let eb1=form_value.stime1.getTime()
        let se1=form_value.offtime.getTime()
        let eb2=form_value.stime2.getTime()
        let se2=form_value.offtime2.getTime()
        if (num === 1) {
            if ((eb1>=eb2 && eb1<=se2)||(eb2>=eb1 && eb2<=se1)){
                return -102;
            } else{
                return 1
            }
        } else if (num === 2) {        
            let eb1=form_value.stime1.getTime()
            let se1=form_value.offtime.getTime()
            let eb2=form_value.stime2.getTime()
            let se2=form_value.offtime2.getTime()
            let eb3=form_value.stime3.getTime()
            let se3=form_value.offtime3.getTime()

            if ((eb1>=eb2 && eb1<=se2)||(eb2>=eb1 && eb2<=se1)){
                return -102;
            } else if ((eb1>=eb3 && eb1<=se3)||(eb3>=eb1 && eb3<=se1)){
                return -103;
            } else if ((eb2>=eb3 && eb2<=se3)||(eb3>=eb2 && eb3<=se2)){
                return -203;
            } else{
                return 1
            }
        }
    }else{
        let eb1=form_value.stime1.getTime()+(1-form_value.flag11)*24*60*60*1000
        let ee1=form_value.etime1.getTime()+(1-form_value.flag14)*24*60*60*1000
        let eb2=form_value.stime2.getTime()+(1-form_value.flag21)*24*60*60*1000
        let ee2=form_value.etime2.getTime()+(1-form_value.flag24)*24*60*60*1000
        if (num === 1) {
            if ((eb1>=eb2 && eb1<ee2)||(eb2>=eb1 && eb2<ee1)){
                return -102;
            } else if((Math.abs(ee2-eb1)>24*60*60*1000)||(Math.abs(ee1-eb2)>24*60*60*1000)) {
                return -112;
            } else{
                return 1
            }
        } else if (num === 2) {        
            let eb1=form_value.stime1.getTime()+(1-form_value.flag11)*24*60*60*1000
            let ee1=form_value.etime1.getTime()+(1-form_value.flag14)*24*60*60*1000
            let eb2=form_value.stime2.getTime()+(1-form_value.flag21)*24*60*60*1000
            let ee2=form_value.etime2.getTime()+(1-form_value.flag24)*24*60*60*1000
            let eb3=form_value.stime3.getTime()+(1-form_value.flag31)*24*60*60*1000
            let ee3=form_value.etime3.getTime()+(1-form_value.flag34)*24*60*60*1000

            if ((eb1>=eb2 && eb1<ee2)||(eb2>=eb1 && eb2<ee1)){
                return -102;
            } else if ((eb1>=eb3 && eb1<ee3)||(eb3>=eb1 && eb3<ee1)){
                return -103;
            } else if ((eb2>=eb3 && eb2<ee3)||(eb3>=eb2 && eb3<ee2)){
                return -203;
            }else if((Math.abs(ee2-eb1)>24*60*60*1000)||(Math.abs(ee1-eb2)>24*60*60*1000)) {
                return -112;
            }else if((Math.abs(ee2-eb3)>24*60*60*1000)||(Math.abs(ee3-eb2)>24*60*60*1000)) {
                return -213;
            }else if((Math.abs(ee3-eb1)>24*60*60*1000)||(Math.abs(ee3-eb2)>24*60*60*1000)) {
                return -113;
            }else{
                return 1
            }
        }
    }

    // for (let i = 0; i < test.length; i++) {
    //     console.log(format(test[i][0], 'HH:mm'), format(test[i][1], 'HH:mm'));
    // }
}


export function compare_all_timeV2(form_value, mode) {
    if(mode==0){
        let num=form_value.mode0info.mode0num
        let eb1=form_value.mode0info.stime1.getTime()
        let se1=form_value.mode0info.offtime1.getTime()
        let eb2=form_value.mode0info.stime2.getTime()
        let se2=form_value.mode0info.offtime2.getTime()
        if (num === 1) {
            if ((eb1>=eb2 && eb1<=se2)||(eb2>=eb1 && eb2<=se1)){
                return -102;
            } else{
                return 1
            }
        } else if (num === 2) {        
            let eb1=form_value.mode0info.stime1.getTime()
            let se1=form_value.mode0info.offtime1.getTime()
            let eb2=form_value.mode0info.stime2.getTime()
            let se2=form_value.mode0info.offtime2.getTime()
            let eb3=form_value.mode0info.stime3.getTime()
            let se3=form_value.mode0info.offtime3.getTime()

            if ((eb1>=eb2 && eb1<=se2)||(eb2>=eb1 && eb2<=se1)){
                return -102;
            } else if ((eb1>=eb3 && eb1<=se3)||(eb3>=eb1 && eb3<=se1)){
                return -103;
            } else if ((eb2>=eb3 && eb2<=se3)||(eb3>=eb2 && eb3<=se2)){
                return -203;
            } else{
                return 1
            }
        }
    }else if(mode==1){
        let num=form_value.mode1info.mode1num
        let eb1=form_value.mode1info.stime1.getTime()+(1-form_value.mode1info.flag11)*24*60*60*1000
        let ee1=form_value.mode1info.etime1.getTime()+(1-form_value.mode1info.flag14)*24*60*60*1000
        let eb2=form_value.mode1info.stime2.getTime()+(1-form_value.mode1info.flag21)*24*60*60*1000
        let ee2=form_value.mode1info.etime2.getTime()+(1-form_value.mode1info.flag24)*24*60*60*1000
        if (num === 1) {
            if ((eb1>=eb2 && eb1<ee2)||(eb2>=eb1 && eb2<ee1)){
                return -102;
            } else if((Math.abs(ee2-eb1)>24*60*60*1000)||(Math.abs(ee1-eb2)>24*60*60*1000)) {
                return -112;
            } else{
                return 1
            }
        } else if (num === 2) {        
            let eb1=form_value.mode1info.stime1.getTime()+(1-form_value.mode1info.flag11)*24*60*60*1000
            let ee1=form_value.mode1info.etime1.getTime()+(1-form_value.mode1info.flag14)*24*60*60*1000
            let eb2=form_value.mode1info.stime2.getTime()+(1-form_value.mode1info.flag21)*24*60*60*1000
            let ee2=form_value.mode1info.etime2.getTime()+(1-form_value.mode1info.flag24)*24*60*60*1000
            let eb3=form_value.mode1info.stime3.getTime()+(1-form_value.mode1info.flag31)*24*60*60*1000
            let ee3=form_value.mode1info.etime3.getTime()+(1-form_value.mode1info.flag34)*24*60*60*1000

            if ((eb1>=eb2 && eb1<ee2)||(eb2>=eb1 && eb2<ee1)){
                return -102;
            } else if ((eb1>=eb3 && eb1<ee3)||(eb3>=eb1 && eb3<ee1)){
                return -103;
            } else if ((eb2>=eb3 && eb2<ee3)||(eb3>=eb2 && eb3<ee2)){
                return -203;
            }else if((Math.abs(ee2-eb1)>24*60*60*1000)||(Math.abs(ee1-eb2)>24*60*60*1000)) {
                return -112;
            }else if((Math.abs(ee2-eb3)>24*60*60*1000)||(Math.abs(ee3-eb2)>24*60*60*1000)) {
                return -213;
            }else if((Math.abs(ee3-eb1)>24*60*60*1000)||(Math.abs(ee3-eb2)>24*60*60*1000)) {
                return -113;
            }else{
                return 1
            }
        }
    }
}

export function compare_each_one(form_value, num, mode) {
    if(mode==0){
        let eb1=form_value.stime1.getTime()
        let sb1=form_value.ontime.getTime()
        let se1=form_value.offtime.getTime()
        if (num === 0) {
            if (eb1>sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            } else{
                return 1
            }
        } else if (num === 1) {
            let eb2=form_value.stime2.getTime()
            let sb2=form_value.ontime2.getTime()
            let se2=form_value.offtime2.getTime()
            if (eb1>sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (eb2>sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            } else{
                return 1;
            }
        } else if (num === 2) {
            let eb2=form_value.stime2.getTime()
            let sb2=form_value.ontime2.getTime()
            let se2=form_value.offtime2.getTime()
            let eb3=form_value.stime3.getTime()
            let sb3=form_value.ontime3.getTime()
            let se3=form_value.offtime3.getTime()
            if (eb1>sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            } else if (eb2>sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            } else if (eb3>sb3) {
                // 第一时段的 stime 设置不正确
                return -31;
            } else if (sb3>se3){
                // 第一时段的 ontime 设置不正确
                return -32;
            } else{
                return 1;
            }
        }
    } else{
        let eb1=form_value.stime1.getTime()+(1-form_value.flag11)*24*60*60*1000
        let sb1=form_value.ontime.getTime()+(1-form_value.flag12)*24*60*60*1000
        let se1=form_value.offtime.getTime()+(1-form_value.flag13)*24*60*60*1000
        let ee1=form_value.etime1.getTime()+(1-form_value.flag14)*24*60*60*1000
        if (num === 0) {
            if (eb1>sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (se1>ee1){
                // 第一时段的 offtime 设置不正确
                return -13;
            }else if((ee1-eb1)>24*60*60*1000){
                return -14;
            }else{
                return 1
            }
        } else if (num === 1) {
            let eb2=form_value.stime2.getTime()+(1-form_value.flag21)*24*60*60*1000
            let sb2=form_value.ontime2.getTime()+(1-form_value.flag22)*24*60*60*1000
            let se2=form_value.offtime2.getTime()+(1-form_value.flag23)*24*60*60*1000
            let ee2=form_value.etime2.getTime()+(1-form_value.flag24)*24*60*60*1000
            if (eb1>sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (se1>ee1){
                // 第一时段的 offtime 设置不正确
                return -13;
            }else if((ee1-eb1)>24*60*60*1000){
                return -14;
            }else if (eb2>sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            }else if (se2>ee2){
                // 第一时段的 offtime 设置不正确
                return -23;
            }else if((ee2-eb2)>24*60*60*1000){
                return -24;
            }else{
                return 1;
            }
        } else if (num === 2) {
            let eb2=form_value.stime2.getTime()+(1-form_value.flag21)*24*60*60*1000
            let sb2=form_value.ontime2.getTime()+(1-form_value.flag22)*24*60*60*1000
            let se2=form_value.offtime2.getTime()+(1-form_value.flag23)*24*60*60*1000
            let ee2=form_value.etime2.getTime()+(1-form_value.flag24)*24*60*60*1000
            let eb3=form_value.stime3.getTime()+(1-form_value.flag31)*24*60*60*1000
            let sb3=form_value.ontime3.getTime()+(1-form_value.flag32)*24*60*60*1000
            let se3=form_value.offtime3.getTime()+(1-form_value.flag33)*24*60*60*1000
            let ee3=form_value.etime3.getTime()+(1-form_value.flag34)*24*60*60*1000
            if (eb1>sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (se1>ee1){
                // 第一时段的 offtime 设置不正确
                return -13;
            }else if((ee1-eb1)>24*60*60*1000){
                return -14;
            }else if (eb2>sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            }else if (se2>ee2){
                // 第一时段的 offtime 设置不正确
                return -23;
            }else if((ee2-eb2)>24*60*60*1000){
                return -24;
            }else if (eb3>sb3) {
                // 第一时段的 stime 设置不正确
                return -31;
            } else if (sb3>se3){
                // 第一时段的 ontime 设置不正确
                return -32;
            }else if (se3>ee3){
                // 第一时段的 offtime 设置不正确
                return -33;
            }else if((ee3-eb3)>24*60*60*1000){
                return -34;
            }else{
                return 1;
            }
        }
    }
}

export function compare_each_oneV2(form_value, mode) {
    if(mode==0){
        let num=form_value.mode0info.mode0num
        let eb1=form_value.mode0info.stime1.getTime()
        let sb1=form_value.mode0info.ontime1.getTime()
        let se1=form_value.mode0info.offtime1.getTime()
        if (num === 0) {
            if (eb1>=sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>=se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            } else{
                return 1
            }
        } else if (num === 1) {
            let eb2=form_value.mode0info.stime2.getTime()
            let sb2=form_value.mode0info.ontime2.getTime()
            let se2=form_value.mode0info.offtime2.getTime()
            if (eb1>=sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>=se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (eb2>=sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>=se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            } else{
                return 1;
            }
        } else if (num === 2) {
            let eb2=form_value.mode0info.stime2.getTime()
            let sb2=form_value.mode0info.ontime2.getTime()
            let se2=form_value.mode0info.offtime2.getTime()
            let eb3=form_value.mode0info.stime3.getTime()
            let sb3=form_value.mode0info.ontime3.getTime()
            let se3=form_value.mode0info.offtime3.getTime()
            if (eb1>=sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>=se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            } else if (eb2>=sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>=se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            } else if (eb3>=sb3) {
                // 第一时段的 stime 设置不正确
                return -31;
            } else if (sb3>=se3){
                // 第一时段的 ontime 设置不正确
                return -32;
            } else{
                return 1;
            }
        }
    } else if (mode==1){
        let num=form_value.mode1info.mode1num
        let eb1=form_value.mode1info.stime1.getTime()+(1-form_value.mode1info.flag11)*24*60*60*1000
        let sb1=form_value.mode1info.ontime1.getTime()+(1-form_value.mode1info.flag12)*24*60*60*1000
        let se1=form_value.mode1info.offtime1.getTime()+(1-form_value.mode1info.flag13)*24*60*60*1000
        let ee1=form_value.mode1info.etime1.getTime()+(1-form_value.mode1info.flag14)*24*60*60*1000
        if (num === 0) {
            if (eb1>=sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>=se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (se1>=ee1){
                // 第一时段的 offtime 设置不正确
                return -13;
            }else if((ee1-eb1)>24*60*60*1000){
                return -14;
            }else{
                return 1
            }
        } else if (num === 1) {
            let eb2=form_value.mode1info.stime2.getTime()+(1-form_value.mode1info.flag21)*24*60*60*1000
            let sb2=form_value.mode1info.ontime2.getTime()+(1-form_value.mode1info.flag22)*24*60*60*1000
            let se2=form_value.mode1info.offtime2.getTime()+(1-form_value.mode1info.flag23)*24*60*60*1000
            let ee2=form_value.mode1info.etime2.getTime()+(1-form_value.mode1info.flag24)*24*60*60*1000
            if (eb1>=sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>=se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (se1>=ee1){
                // 第一时段的 offtime 设置不正确
                return -13;
            }else if((ee1-eb1)>24*60*60*1000){
                return -14;
            }else if (eb2>=sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>=se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            }else if (se2>=ee2){
                // 第一时段的 offtime 设置不正确
                return -23;
            }else if((ee2-eb2)>24*60*60*1000){
                return -24;
            }else{
                return 1;
            }
        } else if (num === 2) {
            let eb2=form_value.mode1info.stime2.getTime()+(1-form_value.mode1info.flag21)*24*60*60*1000
            let sb2=form_value.mode1info.ontime2.getTime()+(1-form_value.mode1info.flag22)*24*60*60*1000
            let se2=form_value.mode1info.offtime2.getTime()+(1-form_value.mode1info.flag23)*24*60*60*1000
            let ee2=form_value.mode1info.etime2.getTime()+(1-form_value.mode1info.flag24)*24*60*60*1000
            let eb3=form_value.mode1info.stime3.getTime()+(1-form_value.mode1info.flag31)*24*60*60*1000
            let sb3=form_value.mode1info.ontime3.getTime()+(1-form_value.mode1info.flag32)*24*60*60*1000
            let se3=form_value.mode1info.offtime3.getTime()+(1-form_value.mode1info.flag33)*24*60*60*1000
            let ee3=form_value.mode1info.etime3.getTime()+(1-form_value.mode1info.flag34)*24*60*60*1000
            if (eb1>=sb1) {
                // 第一时段的 stime 设置不正确
                return -11;
            } else if (sb1>=se1){
                // 第一时段的 ontime 设置不正确
                return -12;
            }else if (se1>=ee1){
                // 第一时段的 offtime 设置不正确
                return -13;
            }else if((ee1-eb1)>24*60*60*1000){
                return -14;
            }else if (eb2>=sb2) {
                // 第一时段的 stime 设置不正确
                return -21;
            } else if (sb2>=se2){
                // 第一时段的 ontime 设置不正确
                return -22;
            }else if (se2>=ee2){
                // 第一时段的 offtime 设置不正确
                return -23;
            }else if((ee2-eb2)>24*60*60*1000){
                return -24;
            }else if (eb3>=sb3) {
                // 第一时段的 stime 设置不正确
                return -31;
            } else if (sb3>=se3){
                // 第一时段的 ontime 设置不正确
                return -32;
            }else if (se3>=ee3){
                // 第一时段的 offtime 设置不正确
                return -33;
            }else if((ee3-eb3)>24*60*60*1000){
                return -34;
            }else{
                return 1;
            }
        }
    }
}

export function compare_result(code) {
    switch(code){
        case -11:
            return '第一时段设置不正确，开始签到时间不能晚于上班考勤时间'
        case -12:
            return '第一时段设置不正确，上班考勤时间不能晚于下班考勤时间'
        case -13:
            return '第一时段设置不正确，结束签到时间不能早于下班考勤时间'
        case -14:
            return '第一时段设置不正确，考勤时段的时间跨度不能超过24小时'
        case -21:
            return '第二时段设置不正确，开始签到时间不能晚于上班考勤时间'
        case -22:
            return '第二时段设置不正确，上班考勤时间不能晚于下班考勤时间'
        case -23:
            return '第二时段设置不正确，结束签到时间不能早于下班考勤时间'
        case -24:
            return '第二时段设置不正确，考勤时段的时间跨度不能超过24小时'
        case -31:
            return '第三时段设置不正确，开始签到时间不能晚于上班考勤时间'
        case -32:
            return '第三时段设置不正确，上班考勤时间不能晚于下班考勤时间'
        case -33:
            return '第三时段设置不正确，结束签到时间不能早于下班考勤时间'
        case -34:
            return '第三时段设置不正确，考勤时段的时间跨度不能超过24小时'
        case -102:
            return '第一时段和第二时段的时间设置有交叉重叠，请检查'
        case -103:
            return '第一时段和第三时段的时间设置有交叉重叠，请检查'
        case -203:
            return '第二时段和第三时段的时间设置有交叉重叠，请检查'
        case -112:
            return '第一时段和第二时段的时间跨度不能超过24小时，请检查'
        case -113:
            return '第一时段和第三时段的时间跨度不能超过24小时，请检查'
        case -213:
            return '第二时段和第三时段的时间跨度不能超过24小时，请检查'
        default:
            return ''
    }
}
