import React, { Component, Fragment, createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, styled } from '@material-ui/styles';

import { Button, DialogTitle, DialogContent, DialogActions, Dialog, RadioGroup, Radio, FormControlLabel, Chip,Backdrop,CircularProgress,
    Box, Toolbar, IconButton, AppBar, Typography, Grid, Paper, Card, CardMedia, CardActionArea, CardContent, CardHeader, Divider, Avatar, LinearProgress } from '@material-ui/core';

import { PermIdentity as UserIcon, Clear } from '@material-ui/icons';

import vipImg from 'static/img/vip.png'
import nonVipImg from 'static/img/nonvip.png'
import styles from './styles';
import bgImg from 'static/img/bg1.png'
import dftLogo from 'static/img/defaultlogo.png'
import top4Img from 'static/img/t4.png'
import { forEach, zip } from 'lodash';
import DialogPaper from '../DialogPaper';

class VipDialogRaw extends Component {
  
  state={open:this.props.open, step:0, accountInfo:this.props.dlgProps.accountInfo, productInfo:this.props.dlgProps.productInfo};
      
  handleCancel = (value) => {
    this.setState({step:0, accountInfo:value.accountInfo})
    this.props.onClose(value);
  };

  handleRefresh = (value) => {
    this.setState({
      accountInfo: value.accountInfo,step:value.step
    });
    // console.log(this.state)
  };
        
  render(){
    const{open}=this.props
    const{productInfo}=this.props.dlgProps
    const{step,accountInfo}=this.state
    
    const MyDialog = styled(Dialog)({
      '& .MuiDialog-paper': { width: 720, height: 580,  borderRadius:18,
        backgroundImage:`url(${bgImg})`, backgroundSize: "cover", backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
      }
    });
    const FreeCardHeader = styled(CardHeader)({
      '& .MuiTypography-h5': { color: '#222222', fontWeight: 700}
    });
    const VipCardHeader = styled(CardHeader)({
      '& .MuiTypography-h5': { color: 'white', fontWeight: 700}
    });

    return (
      
      <Fragment>
        <MyDialog
          maxWidth='md'
          open={open}
        >
          <DialogPaper onClose={this.handleCancel} 
            // onRefresh={this.handleRefresh}
            step={step}  
            paperValue={{accountInfo:accountInfo, productInfo:productInfo}} 
          />
          {/* <DialogActions style={{borderRadius: 18, padding:20}}>
            <Box style={{width:'100%'}}>
              <Grid container spacing={1} >
                <Grid item xs={1} style={{textAlign:'-webkit-right'}}>
                  <Avatar src={(accountInfo.isVip && accountInfo.logo.length>1)?accountInfo.logo:dftLogo} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h6" component="div" style={{ color: 'white' }}>
                    {accountInfo.name}
                  </Typography>
                  <img src={(accountInfo.isVip?vipImg:nonVipImg)} style={{maxHeight:12}}/> 
                  <Typography variant="body2" component="span" style={{ paddingLeft:10, color: 'white' }}>
                    {(accountInfo.isVip?'会员截止期：'+accountInfo.validDate.substring(0,10):'会员未开通' )}
                  </Typography>                      
                </Grid>          
              </Grid>
            </Box>      
            <IconButton size='small' onClick={this.handleCancel}><Clear fontSize='small' style={{ color: 'white' }}/></IconButton>
          </DialogActions>
          <DialogContent style={{padding:0}}>
            <DialogPaper step={step} onClose={this.handleCancel} onRefresh={this.handleRefresh} 
              paperValue={productInfo} 
            />
          </DialogContent >  */}
        </MyDialog>
      </Fragment>
    );
  }
}

VipDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // onRefresh: PropTypes.func.isRequired,
  dlgProps:PropTypes.object.isRequired
};

export default withStyles(styles)(VipDialogRaw);
