import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import ProductCTAImageDots from 'static/onepirate/productCTAImageDots.png';
import lianxi from 'static/onepirate/lianxi.png';
import jiantou from 'static/onepirate/jiantou.png';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(10),
        marginBottom: 0,
        display: 'flex'
    },
    cardWrapper: {
        zIndex: 1
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.warning.main,
        padding: theme.spacing(8, 3)
    },
    cardContent: {
        maxWidth: 400
    },
    textField: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    button: {
        width: '100%'
    },
    imagesWrapper: {
        position: 'relative'
    },
    imageDots: {
        position: 'absolute',
        top: -67,
        left: -67,
        right: 0,
        bottom: 0,
        width: '100%',
        background: ProductCTAImageDots
    },
    image: {
        position: 'absolute',
        top: -28,
        left: -28,
        right: 0,
        bottom: 0,
        width: '100%',
        maxWidth: 600
    }
});

class ProductCTA extends React.Component {
    state = {
        open: false
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Container className={classes.root} component="section">
                <Grid container>
                    <Grid className={classes.cardWrapper} item md={6} xs={12}>
                        <div className={classes.card}>
                            <div
                                className={classes.cardContent}
                                onSubmit={this.handleSubmit}
                            >
                                <Typography
                                    component="h2"
                                    gutterBottom
                                    variant="h2"
                                >
                                    联系我们
                                </Typography>
                                <Typography variant="h5" />
                                {/* <TextField
                                    className={classes.textField}
                                    noBorder
                                    placeholder="如需联系您请留下手机号码或邮箱"
                                    variant="outlined"
                                /> */}
                                <img
                                    alt="jiantou"
                                    // className={classes.image}
                                    src={jiantou}
                                />
                                <Typography variant="h5">
                                    邮箱: dingdong@ddqiandao.com
                                </Typography>
                                <br />
                                <Typography variant="h5">
                                    QQ客服: 860799988
                                </Typography>
                                <br />
                                <Typography variant="h5">
                                    客服电话:0531-86079998
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid className={classes.imagesWrapper} item md={6} xs={12}>
                        <Hidden smDown>
                            <div className={classes.imageDots} />
                            <img
                                alt="call to action"
                                className={classes.image}
                                src={lianxi}
                            />
                        </Hidden>
                    </Grid>
                </Grid>
                {/* <Snackbar
                    message="We will send you our best offers, once a week."
                    onClose={this.handleClose}
                    open={this.state.open}
                /> */}
            </Container>
        );
    }
}

ProductCTA.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCTA);
