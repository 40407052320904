import React from 'react';
import { withRouter } from 'react-router-dom';

import MaterialTable ,{ MTableToolbar } from 'material-table';
import { format } from 'date-fns'

import { Portlet, PortletHeader, PortletLabel, PortletContent, SnackbarsMessage, TableIcons } from 'components';
import {CircularProgress, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider, Typography, 
  List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, IconButton, Avatar} from '@material-ui/core';
import {AddCircleOutline, RemoveCircleOutline, Router, Edit, Clear} from '@material-ui/icons';
import LoadingOverlay from 'react-loading-overlay';

import classNames from 'classnames';
import utils from 'utility';

import { compare_all_timeV2, compare_each_oneV2, compare_result } from 'utils/time_compare';

import { withStyles, styled } from '@material-ui/styles';

// import ProductsToolbar from '../ProductsToolbar/index'
import ProductsToolbar2 from '../ProductsToolbar2/index'
import ChangeDepDialog from '../ChangeDepDialog';
// import ChangeTimeDialog from '../ChangeTimeDialog';
// import ChangeTimeDialog1 from '../ChangeTimeDialog1';
import ChangeTimeDialog2 from '../ChangeTimeDialog2';

import validate from 'validate.js';

// Form validation schema
import schema from './schema';
import Http from 'services/http';

import styles from './styles';

import export_to_excel from 'utils/export_to_excel'
// import UserForm1 from '../UserForm1';
import UserForm2 from '../UserForm2';
// import { Tooltip } from 'chart.js';
// import { Grid } from 'react-virtualized';

const roles=[{id:0, name:'普通员工', desp:'普通员工只能登录员工端APP'},
  {id:1, name:'部门管理员', desp:'部门管理员可以登录网页或者管理端APP查看部门数据'},
  {id:2, name:'单位管理员', desp:'单位管理员可以登录网页或者管理端APP查看单位数据'}
]

const m0info={ 
  mode0num:0,           
  pname1: '第一时段',
  stime1: new Date('December 17, 1995 8:00:00'),
  ontime1: new Date('December 17, 1995 8:30:00'),
  offtime1: new Date('December 17, 1995 17:30:00'),
  pname2: '第二时段',
  stime2: new Date('December 17, 1995 12:30:00'),
  ontime2: new Date('December 17, 1995 13:00:00'),
  offtime2: new Date('December 17, 1995 17:30:00'),
  pname3: '第三时段',
  stime3: new Date('December 17, 1995 16:30:00'),
  ontime3: new Date('December 17, 1995 17:00:00'),
  offtime3: new Date('December 17, 1995 23:00:00')
}

const m1info={
  mode1num:0,          
  pname1: '第一时段',
  flag11:1,
  stime1: new Date('December 17, 1995 8:00:00'),
  flag12:1,
  ontime1: new Date('December 17, 1995 8:30:00'),
  flag13:1,
  offtime1: new Date('December 17, 1995 17:30:00'),
  flag14:1,
  etime1: new Date('December 17, 1995 19:00:00'),
  pname2: '第二时段',
  flag21:1,
  stime2: new Date('December 17, 1995 12:30:00'),
  flag22:1,
  ontime2: new Date('December 17, 1995 13:00:00'),
  flag23:1,
  offtime2: new Date('December 17, 1995 17:30:00'),
  flag24:1,
  etime2: new Date('December 17, 1995 19:00:00'),
  pname3: '第三时段',
  flag31:1,
  stime3: new Date('December 17, 1995 19:30:00'),
  flag32:1,
  ontime3: new Date('December 17, 1995 20:00:00'),
  flag33:0,
  offtime3: new Date('December 17, 1995 3:00:00'),
  flag34:0,
  etime3: new Date('December 17, 1995 5:00:00')
}



class Index extends React.Component {
  constructor(props) {
    super(props);
    // 序号	姓名	工号	电话	职务
    this.state = {
      cpyname:'',
      users:[],
      cur_router: null,
      change_dialog_state: false,
      staff_detail_state: false,
      cur_user_id: null,
      cur_user_name:'',
      routers: [],
      loading: false,
      userid_list: null,
      cpy_group: [],
      errors:{
        name: null,
        telephone: null,
      },
      touched: {
        name: false,
        telephone: false
      },
      form_value:{
        status:'N',
        name:'',
        telephone: '',
        department: 0,
        role: 0,
        mode:0,
        mode0info:{
          mode0num:0,           
          pname1: '第一时段',
          stime1: new Date('December 17, 1995 8:00:00'),
          ontime1: new Date('December 17, 1995 8:30:00'),
          offtime1: new Date('December 17, 1995 17:30:00'),
          pname2: '第二时段',
          stime2: new Date('December 17, 1995 12:30:00'),
          ontime2: new Date('December 17, 1995 13:00:00'),
          offtime2: new Date('December 17, 1995 17:30:00'),
          pname3: '第三时段',
          stime3: new Date('December 17, 1995 16:30:00'),
          ontime3: new Date('December 17, 1995 17:00:00'),
          offtime3: new Date('December 17, 1995 23:00:00')
        },
        mode1info:{
          mode1num:0,          
          pname1: '第一时段',
          flag11:1,
          stime1: new Date('December 17, 1995 8:00:00'),
          flag12:1,
          ontime1: new Date('December 17, 1995 8:30:00'),
          flag13:1,
          offtime1: new Date('December 17, 1995 17:30:00'),
          flag14:1,
          etime1: new Date('December 17, 1995 19:00:00'),
          pname2: '第二时段',
          flag21:1,
          stime2: new Date('December 17, 1995 12:30:00'),
          flag22:1,
          ontime2: new Date('December 17, 1995 13:00:00'),
          flag23:1,
          offtime2: new Date('December 17, 1995 17:30:00'),
          flag24:1,
          etime2: new Date('December 17, 1995 19:00:00'),
          pname3: '第三时段',
          flag31:1,
          stime3: new Date('December 17, 1995 19:30:00'),
          flag32:1,
          ontime3: new Date('December 17, 1995 20:00:00'),
          flag33:0,
          offtime3: new Date('December 17, 1995 3:00:00'),
          flag34:0,
          etime3: new Date('December 17, 1995 5:00:00')
        },
        chongzhi: false,
        // 未知
        userid: 1362,
        Stype: 0,
        managepass: '',
        // 工号
        sn:'',
        // 职位
        position:'',
        // 备注
        memo:'',
        isphone:1
      },
      
      submitError: null,

      time_dialog_open: false,
      dep_dialog_open: false,

      selectUsers: [],
      selected_option: 'all',
      snackbarsVisible:false,
      snackbarsMsg: '',
      isValid:false,
      submit_loading: false,

      selected_group: 'all',

      curpage:0,
      pagesize:5,
      pagearray:[5,10,20],

      anchorEl: null,
      anchorOpen: false,

      accountInfo:{},
      productInfo:{},
      accountInfoStatus:false,
      productInfoStatus:false

    };
  }

  async componentDidMount() {
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    const cur_user = JSON.parse(localStorage.getItem('cur_user'));
    if(null!=cur_user && undefined!=cur_user){
        this.setState({
            cur_user: cur_user
        });
        
        this.handleAccountInfo(cur_user.UserID)
        this.handleProductInfo(cur_user.UserID)
    } else {
      this.props.history.push('/sign-in');
    }
    const {form_value} = this.state
    if (cur_cpy) {
      this.setState({ loading: true, cpyname:cur_cpy.CompanyName});
      
      try{
        const user_list = await Http.user_list(
          {
            CompanySN: cur_cpy.CompanySN,
          },
          true
        );
        const routers = await Http.get_routers({
          CompanyID: cur_cpy.CompanySN,
        },true)
        const cpy_group = await Http.get_group({
          cpySn: cur_cpy.CompanySN,
          _: new Date().getTime()
        },true)
        // const lookup = {}
        // cpy_group.data.forEach(dep=>{
        //   lookup[dep['GroupID']] = dep['GroupName']
        // })
        //console.log(user_list.data)
        form_value['department'] = cpy_group.data[0].GroupID
        
        let pagearray=[5,10,20]
        if(user_list.data.length<11){
            pagearray.pop(20);
        }
        if(user_list.data.length<6){
            pagearray.pop(10)
        }
        
        this.setState({
          users: user_list.data,
          routers: routers.data,
          loading: false,
          total_users: user_list.data.length,
          cpy_group: cpy_group.data,
          pagearray:pagearray,
          form_value
          // lookup
        });
      }catch(e){
        console.log(e)
      }
    } else {
      this.props.history.push('/sign-in');
    }
  }

  handleProductInfo= async (info)=>{
      try{
          const { data, errcode, errmsg } = await Http.getProductInfo({managerid:info});
          if (errcode !== 0) {
              console.log(errmsg)
              this.setState({productInfoStatus: false})
          } else {
              this.setState({productInfoStatus: true, productInfo:data}) 
          }
      }catch(error){
          return;
      }
  }

  handleAccountInfo= async (info)=>{
      try {
          const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
          if (errcode !== 0) {
              console.log(errmsg)
              this.setState({accountInfoStatus: false})
          } else {
              // console.log(data)
              this.setState({accountInfoStatus: true, accountInfo:data})         
          }
      }catch (error) {
          return;
      }
  }

  change_detail = async (record) =>{
    const {form_value} = this.state
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    this.setState({
      cur_user_id: record.UserID
    })
    
    try {
      const res = await Http.get_user_infoV2({
        userid: this.state.cur_user.UserID,
        target_userid: record.UserID,
        companysn: cur_cpy.CompanySN,
        _: new Date().getTime()
      })
      
      const {data, errcode, errmsg} = res
      if(errcode===0){
        form_value.userid=data.UserID
        form_value.name = data.Name
        form_value.telephone = data.Phone
        form_value.department = String(data.GroupID)
        if (data.IUser ==='1' ) {
          form_value.role = 0
        } else if(data.IIUser === '1') {
          form_value.role = 1
        } else if(data.IIIUser === '1'){
          form_value.role = 2
        }
        form_value.managepass = data.Pass
        form_value.sn = data.GongHao
        form_value.position = data.ZhiWei
        form_value.memo = data.Remark
        form_value.chongzhi = false    
        form_value.status = data.workState
        form_value.isphone=data.isPhone
  
        if(data.mode==0){
          form_value.mode=0
          if(data.mode0info.name1.length>0){
            form_value.mode0info.mode0num=0
            form_value.mode0info.pname1 = data.mode0info.name1;
            form_value.mode0info.ontime1 = new Date( 'December 17, 1995 ' + data.mode0info.begin1 + ':00' );
            form_value.mode0info.offtime1 = new Date( 'December 17, 1995 ' + data.mode0info.end1 + ':00' );
            form_value.mode0info.stime1 = new Date( 'December 17, 1995 ' + data.mode0info.eff1 + ':00' );
          }
          if(data.mode0info.name2.length>0){
              form_value.mode0info.mode0num+=1;
              form_value.mode0info.pname2 = data.mode0info.name2;
              form_value.mode0info.ontime2 = new Date( 'December 17, 1995 ' + data.mode0info.begin2 + ':00' );
              form_value.mode0info.offtime2 = new Date( 'December 17, 1995 ' + data.mode0info.end2 + ':00' );
              form_value.mode0info.stime2 = new Date( 'December 17, 1995 ' + data.mode0info.eff2 + ':00' );
          }
          if(data.mode0info.name3.length>0){
              form_value.mode0info.mode0num+=1;
              form_value.mode0info.pname3 = data.mode0info.name3;
              form_value.mode0info.ontime3 = new Date( 'December 17, 1995 ' + data.mode0info.begin3 + ':00' );
              form_value.mode0info.offtime3 = new Date( 'December 17, 1995 ' + data.mode0info.end3 + ':00' );
              form_value.mode0info.stime3 = new Date( 'December 17, 1995 ' + data.mode0info.eff3 + ':00' );
          }

        }else if(data.mode==1){
          form_value.mode=1
          if(data.mode1info.name1.length>0){
              form_value.mode1info.mode1num=0
              form_value.mode1info.pname1 = data.mode1info.name1;
              form_value.mode1info.flag11=data.mode1info.flag11
              form_value.mode1info.stime1 = new Date( 'December 17, 1995 ' + data.mode1info.eff1 + ':00' );
              form_value.mode1info.flag12=data.mode1info.flag12
              form_value.mode1info.ontime1 = new Date( 'December 17, 1995 ' + data.mode1info.begin1 + ':00' );
              form_value.mode1info.flag13=data.mode1info.flag13
              form_value.mode1info.offtime1 = new Date( 'December 17, 1995 ' + data.mode1info.end1 + ':00' );
              form_value.mode1info.flag14=data.mode1info.flag14
              form_value.mode1info.etime1 = new Date( 'December 17, 1995 ' + data.mode1info.noeff1 + ':00' );
          }
          if(data.mode1info.name2.length>0){
              form_value.mode1info.mode1num+=1;
              form_value.mode1info.pname2 = data.mode1info.name2;
              form_value.mode1info.flag21=data.mode1info.flag21
              form_value.mode1info.stime2 = new Date( 'December 17, 1995 ' + data.mode1info.eff2 + ':00' );
              form_value.mode1info.flag22=data.mode1info.flag22
              form_value.mode1info.ontime2 = new Date( 'December 17, 1995 ' + data.mode1info.begin2 + ':00' );
              form_value.mode1info.flag23=data.mode1info.flag23
              form_value.mode1info.offtime2 = new Date( 'December 17, 1995 ' + data.mode1info.end2 + ':00' );
              form_value.mode1info.flag24=data.mode1info.flag24
              form_value.mode1info.etime2 = new Date( 'December 17, 1995 ' + data.mode1info.noeff2 + ':00' );
          }
          if(data.mode1info.name3.length>0){
              form_value.mode1info.mode1num+=1;
              form_value.mode1info.pname3 = data.mode1info.name3;
              form_value.mode1info.flag31=data.mode1info.flag31
              form_value.mode1info.stime3 = new Date( 'December 17, 1995 ' + data.mode1info.eff3 + ':00' );
              form_value.mode1info.flag32=data.mode1info.flag32
              form_value.mode1info.ontime3 = new Date( 'December 17, 1995 ' + data.mode1info.begin3 + ':00' );
              form_value.mode1info.flag33=data.mode1info.flag33
              form_value.mode1info.offtime3 = new Date( 'December 17, 1995 ' + data.mode1info.end3 + ':00' );
              form_value.mode1info.flag34=data.mode1info.flag34
              form_value.mode1info.etime3 = new Date( 'December 17, 1995 ' + data.mode1info.noeff3 + ':00' );
          }
        }       
      
        this.setState({
          form_value,
          staff_detail_state: true
        })
      } else {
        this.showMsg(errmsg)
      }
    } catch (err) {
      console.log(err)
    }
  }

  change_router = async user => {
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    const cur_user_router = await Http.user_router_list(
      {
        userid: user.UserID,
        CompanyID: cur_cpy.CompanyID
      },
      true
    )
    this.setState({
      change_dialog_state: true,
      cur_user_id : user.UserID,
      cur_user_name:user.UserName,
      cur_router: cur_user_router.data
    });
  };

  add_router = async router => {
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    const res = await Http.user_rel_wifi({
      wifiid: router.wifiid,
      userid: this.state.cur_user_id,
      CompanyID: cur_cpy.CompanyID
    },true)
    if (res.errcode === 0){
      const cur_user_router = await Http.user_router_list(
        {
          userid: this.state.cur_user_id,
          CompanyID: cur_cpy.CompanyID
        },
        true
      )
      this.setState({
        cur_router: cur_user_router.data
      });
      // console.log('修改成功')
    } else {
      console.log(res.errmsg)
    }
  };

  delete_router = async router => {
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    const res = await Http.user_del_wifi({
      wifiid: router.wifiid,
      userid: this.state.cur_user_id,
      CompanyID: cur_cpy.CompanyID
    },true)
    if (res.errcode === 0){
      const cur_user_router = await Http.user_router_list(
        {
          userid: this.state.cur_user_id,
          CompanyID: cur_cpy.CompanyID
        },
        true
      )
      this.setState({
        cur_router: cur_user_router.data
      });
      // console.log('修改成功')
    } else {
      console.log(res.errmsg)
    }
  };

  handleClose = () => {
    const{form_value}=this.state
    form_value.mode0info={...m0info}
    form_value.mode1info={...m1info}
    this.setState({
      change_dialog_state: false,
      staff_detail_state: false,
      errors: {
          name: null,
          telephone: null
      },
      num:0,
      isValid:false,
      touched: {
          name: false,
          telephone: false
      },
      form_value           
    });
  };

  handleSnackBarsClose = () => {
    this.setState({
      snackbarsVisible:false,
    });
  };

  handleUserSave = async () =>{

    // this.validateForm()
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    const { form_value } = this.state;
    const { mode0info, mode1info, mode } = this.state.form_value;
    let code=compare_each_oneV2(form_value, mode)
    if (code<1) {
        this.showMsg(compare_result(code));
        return;
    }
    code=compare_all_timeV2(form_value, mode)
    if (code<1) {
        this.showMsg(compare_result(code));
        return;
    }
        
    if (form_value.role !== 0 && form_value.managepass === '') {
      this.showMsg('请输入管理员工密码');
      return;
    }

    try{      
      this.setState({
        submit_loading: true,
        isValid: false
      })
      let num=-1
      let m0ts={
        name1:"",
        name2:"",
        name3:"",
        begin1:"",
        begin2:"",
        begin3:"",
        end1:"",
        end2:"",
        end3:"",
        eff1 :"",
        eff2:"",
        eff3:""
      }
      let m1ts={
        name1:"",
        name2:"",
        name3:"",
        begin1:"",
        begin2:"",
        begin3:"",
        end1:"",
        end2:"",
        end3:"",
        eff1 :"",
        eff2:"",
        eff3:"",
        noeff1 :"",
        noeff2:"",
        noeff3:"",
        flag11:1,
        flag12:1,
        flag13:1,
        flag14:1,
        flag21:1,
        flag22:1,
        flag23:1,
        flag24:1,
        flag31:1,
        flag32:1,
        flag33:1,
        flag34:1
      }
      switch(mode){
        case 0:
          num=mode0info.mode0num;
          
          if(num==0){
            m0ts.name1=mode0info.pname1
            m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
            m0ts.end1=format(mode0info.offtime1, 'HH:mm')
            m0ts.eff1=format(mode0info.stime1, 'HH:mm')
          }else if(num==1){
            m0ts.name1=mode0info.pname1
            m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
            m0ts.end1=format(mode0info.offtime1, 'HH:mm')
            m0ts.eff1=format(mode0info.stime1, 'HH:mm')
            
            m0ts.name2=mode0info.pname2
            m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
            m0ts.end2=format(mode0info.offtime2, 'HH:mm')
            m0ts.eff2=format(mode0info.stime2, 'HH:mm')
          }else{
            m0ts.name1=mode0info.pname1
            m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
            m0ts.end1=format(mode0info.offtime1, 'HH:mm')
            m0ts.eff1=format(mode0info.stime1, 'HH:mm')
            
            m0ts.name2=mode0info.pname2
            m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
            m0ts.end2=format(mode0info.offtime2, 'HH:mm')
            m0ts.eff2=format(mode0info.stime2, 'HH:mm')
            
            m0ts.name3=mode0info.pname3
            m0ts.begin3=format(mode0info.ontime3, 'HH:mm')
            m0ts.end3=format(mode0info.offtime3, 'HH:mm')
            m0ts.eff3=format(mode0info.stime3, 'HH:mm')
          }
          break;
        case 1:                
          num=mode1info.mode1num;                
          if(num==0){
            m1ts.name1=mode1info.pname1
            m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
            m1ts.end1=format(mode1info.offtime1, 'HH:mm')
            m1ts.eff1=format(mode1info.stime1, 'HH:mm')
            m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
            m1ts.flag11=mode1info.flag11
            m1ts.flag12=mode1info.flag12
            m1ts.flag13=mode1info.flag13
            m1ts.flag14=mode1info.flag14
          }else if(num==1){
            m1ts.name1=mode1info.pname1
            m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
            m1ts.end1=format(mode1info.offtime1, 'HH:mm')
            m1ts.eff1=format(mode1info.stime1, 'HH:mm')
            m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
            m1ts.flag11=mode1info.flag11
            m1ts.flag12=mode1info.flag12
            m1ts.flag13=mode1info.flag13
            m1ts.flag14=mode1info.flag14
            m1ts.name2=mode1info.pname2
            m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
            m1ts.end2=format(mode1info.offtime2, 'HH:mm')
            m1ts.eff2=format(mode1info.stime2, 'HH:mm')
            m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
            m1ts.flag21=mode1info.flag21
            m1ts.flag22=mode1info.flag22
            m1ts.flag23=mode1info.flag23
            m1ts.flag24=mode1info.flag24
          }else{
            m1ts.name1=mode1info.pname1
            m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
            m1ts.end1=format(mode1info.offtime1, 'HH:mm')
            m1ts.eff1=format(mode1info.stime1, 'HH:mm')
            m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
            m1ts.flag11=mode1info.flag11
            m1ts.flag12=mode1info.flag12
            m1ts.flag13=mode1info.flag13
            m1ts.flag14=mode1info.flag14
            m1ts.name2=mode1info.pname2
            m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
            m1ts.end2=format(mode1info.offtime2, 'HH:mm')
            m1ts.eff2=format(mode1info.stime2, 'HH:mm')
            m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
            m1ts.flag21=mode1info.flag21
            m1ts.flag22=mode1info.flag22
            m1ts.flag23=mode1info.flag23
            m1ts.flag24=mode1info.flag24
            m1ts.name3=mode1info.pname3
            m1ts.begin3=format(mode1info.ontime3, 'HH:mm')
            m1ts.end3=format(mode1info.offtime3, 'HH:mm')
            m1ts.eff3=format(mode1info.stime3, 'HH:mm')
            m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
            m1ts.flag31=mode1info.flag31
            m1ts.flag32=mode1info.flag32
            m1ts.flag33=mode1info.flag33
            m1ts.flag34=mode1info.flag34
          }
          break;
      }
      
      let postData = {};
      if(mode===0){
        postData = {
          target_userid: form_value.userid,
          userid:this.state.cur_user.UserID,
          companysn: cur_cpy.CompanySN,
          name: form_value.name,
          telephone: form_value.telephone,
          department: form_value.department,
          status: form_value.status,
          role: form_value.role,
          managepass: (form_value.managepass.length===32)?form_value.managepass:utils.md5(form_value.managepass),
          Stype: form_value.Stype,
          chongzhi: form_value.chongzhi?1:0,
          sn: form_value.sn,
          position: '',
          memo: form_value.memo,
          isPhone:form_value.isphone,
          
          mode:form_value.mode,
          
          mode0info:{...m0ts},
          mode1info:{},
          mode2info:{},
          mode3info:{}
        };

      } else if (mode==1) {
        postData = {
          target_userid: form_value.userid,
          userid:this.state.cur_user.UserID,
          companysn: cur_cpy.CompanySN,
          name: form_value.name,
          telephone: form_value.telephone,
          department: form_value.department,
          status: form_value.status,
          role: form_value.role,
          //
          managepass: utils.md5(form_value.managepass),
          chongzhi: form_value.chongzhi?1:0,
          sn: form_value.sn,
          position: '',
          memo: form_value.memo,
          isPhone:form_value.isphone,
          Stype: form_value.Stype,
          mode:form_value.mode,
          mode0info:{},
          mode1info:{...m1ts},
          mode2info:{},
          mode3info:{}

        };
        
      } 
      const res = await Http.edituserV2(postData)
      const {errcode, errmsg} = res
      if(errcode===0){
        this.showMsg(errmsg)
        this.setState({
          isValid:false,
          submit_loading: false
        })
        this.componentDidMount()
        this.handleClose()
      }else{
        this.showMsg(errmsg)
        this.setState({
          isValid:true,
          submit_loading:false
        })
      }
    }catch(err){
      this.showMsg('服务器开小差了，请重试')
      this.setState({
        isValid:true,
        submit_loading:false
      })
      console.log(err)
    }
  }

  handleSave = () => {
    this.setState({
      change_dialog_state: false
    });
  };

  validateForm = () => {
    const {form_value} = this.state
    let errors = validate(form_value, schema)
    let isValid = errors ? false : true;
    errors = errors || {};
    this.setState({
      isValid,
      errors
    })
  }

  // 表单
  handleFieldChange = (value, field) => {
    const { form_value, touched } = this.state;
    let {mode0info, mode1info}=this.state.form_value
    let fields='stime1stime2stime3etime1etime2etime3ontime1ontime2ontime3offtime1offtime2offtime3flag11flag12flag13flag14flag21flag22flag23flag24flag31flag32flag33flag34'
    if(fields.includes(field)){
        if(form_value.mode==0){
            mode0info[field]=value
            form_value.mode0info={...mode0info}
        }
        if(form_value.mode==1){
            mode1info[field]=value
            form_value.mode1info={...mode1info}
        }
    }else{
        form_value[field] = value;
    }
    touched[field] = true
    this.setState({ form_value, touched }, this.validateForm);
  };

  handleCheck = () => {
    const { form_value } = this.state;
    form_value.chongzhi = !form_value.chongzhi
    this.setState({
      form_value,
      // isValid: true
    })
  }

  timeValid=(value)=>{
      this.setState({
          isValid:value
      })
  }

  changeNum = type => {
    const { form_value } = this.state;
    switch(form_value.mode){
      case 0:
        if (form_value.mode0info.mode0num >= 0 && form_value.mode0info.mode0num < 3) {
            if (type === 'add' && form_value.mode0info.mode0num !== 2) { form_value.mode0info.mode0num += 1; } else if (type === 'minus' && form_value.mode0info.mode0num !== 0) { form_value.mode0info.mode0num -= 1; }
        }
        this.setState({form_value}, this.validateForm);
        break;
      case 1:
        if (form_value.mode1info.mode1num >= 0 && form_value.mode1info.mode1num < 3) {
            if (type === 'add' && form_value.mode1info.mode1num !== 2) { form_value.mode1info.mode1num += 1; } else if (type === 'minus' && form_value.mode1info.mode1num !== 0) { form_value.mode1info.mode1num -= 1; }
        }
        this.setState({form_value}, this.validateForm);
        break;
    }   
  };

  // 批量选择员工
  handleSelection = async rows => {
    const users = await rows.map(user => {
      return user.UserID;
    });
    this.setState({
      selectUsers: users
    })
  };
  
  handleRowsChange = async rowsPerPage => {
    this.setState({ pagesize: rowsPerPage })
  }

  handlePageChange= async Page => {
      this.setState({ curpage: Page })
  }

  handleDialogClose = () =>{
    this.componentDidMount()
    this.setState({
      time_dialog_open: false,
      dep_dialog_open: false,
      selectUsers:[]
    })
  }

  handleDialogOpen = async type =>{
    if(this.state.selectUsers.length === 0){
      this.showMsg('请先选择需修改的员工')
    }else{
      if(type === 'dep'){
        this.setState({
          dep_dialog_open: true,
        })
      } else {
        this.setState({
          time_dialog_open: true
        })
      }
    }    
  }

  showMsg = msg => {
    this.setState({
        snackbarsMsg: msg,
        snackbarsVisible: true
    });
    setTimeout(() => {
        this.setState({
            snackbarsMsg: '',
            snackbarsVisible: false
        });
    }, 2000);
  };

  handleSelectChange = async (event) => {
    const router_id = event.target.value
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    const {selected_option, selectUsers}=this.state

    if(router_id==selected_option){return}

    this.setState({
      loading: true,
      selected_option: router_id,
      selectUsers:[]
    })
    await this.componentDidMount()
    if(router_id ==='all') {
      this.setState({
        loading: false
      })
    }else {
      let {users} = this.state
      try{
        const res = await Http.get_userid_by_equid(
          {
            companyid: cur_cpy.CompanySN,
            equid:router_id,
          },true
        )
        if (res.errcode === 0){
          users = users.filter(({UserID})=>{
            const flag = res.data.users.find(user=>user.UserID ===UserID)
            if (flag !== undefined){
              return true
            } else{
              return false
            }
          })
          this.setState({
            userid_list: res.data.users,
            users: users,
            loading: false
          })
        }
      } catch(e){
        console.log(e)
      }
    }
  }

  handleSelectGroupChange= async (event)=>{
    const group_id = event.target.value;
    const {selected_group, selected_option, selectUsers}=this.state
    if(group_id==selected_group){
      return
    }
    this.setState({
      loading: true,
      selected_group: group_id,
      selectUsers:[]
    })
    await this.componentDidMount()
    if(group_id ==='all') {
      this.setState({
        loading: false
      })
    }else {
      let usrs = this.state.users
      usrs = usrs.filter(user=>user.DeptId === parseInt(group_id))
      this.setState({
        users: usrs,
        loading: false
      })
    }
  }
  
  reload = () => {
    this.componentDidMount()
  }
  
  refresh = async() => {
    await this.componentDidMount()
    this.setState({selectUsers:[]})
  }
  
  delete_user_open = anchorEl => {
    this.setState({
        anchorEl: anchorEl,
        anchorOpen: true
    });
  };

  delUser = async () => {
    const {cur_user_id}= this.state
    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
    this.setState({loading:true})
    
    try {
      const res = await Http.userDelMulty({
        uids: cur_user_id,
        companyid: cur_cpy.CompanySN
      },true)
      const {errcode, errmsg, data}= res
      if(errcode ===0) {
        this.showMsg('删除成功')
        this.handleClose()
        this.componentDidMount()
      }else{
        this.showMsg('删除失败')
        this.handleClose()
      }
      this.setState({loading:false})
    }catch (err) {
      this.showMsg('删除失败')
      console.log(err)
    }
  }

  render() {

    const columns = [
      { title: '姓名', field: 'UserName', sorting: false },
      { title: '工号', field: 'GongHao' },
      { title: '电话', field: 'UserPhone' , sorting: false},
      {
        title: '部门', sorting: false,
        field: 'DeptName',
        // DeptName
        // lookup: this.state.lookup
      },
      {
        title: '考勤设备',
        field: '',
        editable: 'never',
        filtering: false ,
        sorting:false,
        render: record =>(
          <Button
              color="primary"
              onClick={() => this.change_router(record)}
              size="small"
              style={{ textTransform: 'none' }}
              variant="contained"
          >
            签到设备
          </Button>)
      },
      {
        title: '员工详情',
        field: '',
        editable: 'never',
        filtering: false ,
        sorting:false,
        render: record => (
          <Button
              color="primary"
              onClick={()=>this.change_detail(record)}
              size="small"
              style={{ textTransform: 'none' }}
              variant="contained"
          >
            员工详情
          </Button>
        )
      }
    ]
    const { classes, className, ...rest} = this.props;
    const rootClassName = classNames(classes.root, className);
    const { dep_dialog_open, cpy_group, selectUsers, time_dialog_open, anchorOpen, routers, errors, touched, accountInfoStatus,
        selected_option, selected_group, users, loading,curpage, pagesize, pagearray,snackbarsVisible, cpyname, productInfoStatus,
        cur_user_name, cur_router,staff_detail_state,form_value,submit_loading,isValid, productInfo, accountInfo, cur_user
    } = this.state;
    const name_error = touched.name && (errors.name?true:false) && (errors.name==null?false:true);
    const telephone_error = touched.telephone && (errors.telephone?true:false) && (errors.telephone==null?false:true);
    
    return (
      <div>
        {/* <ProductsToolbar className={classes.toolbar} reload={this.reload} /> */}
        <LoadingOverlay active={ !accountInfoStatus && !productInfoStatus } spinner text="正在加载" >
          <ProductsToolbar2 className={classes.toolbar} reload={this.reload} accountInfo={accountInfo} productInfo={productInfo} />
        </LoadingOverlay>
        <ChangeDepDialog 
            refresh={this.refresh} 
            open={dep_dialog_open} 
            showmsg={this.showMsg} 
            deps ={ cpy_group} 
            users={ selectUsers} 
            handleclose ={this.handleDialogClose} 
        />
        <ChangeTimeDialog2
            open={time_dialog_open} 
            showmsg={this.showMsg} 
            users={selectUsers} 
            handleclose={this.handleDialogClose}
            accountinfo={accountInfo}
        />
        <SnackbarsMessage
            handleClose={this.handleSnackBarsClose}
            msg={this.state.snackbarsMsg}
            visible={snackbarsVisible}
        />
        <MaterialTable 
            columns={columns}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div
                      className={classes.row}
                  >
                <TextField
                    className={classes.formControl}
                    label="当前设备"
                    margin="dense"
                    onChange={this.handleSelectChange}
                    select
                    SelectProps={{ native: true }}
                    value={selected_option}
                    variant="outlined"
                >
                  <option value="all">全部签到设备</option>
                  {routers.map(router=><option key={router.EquID} value={router.EquID}>{router.EquName}</option>)}

                </TextField>
                <TextField
                    className={classes.formControl}
                    label="当前部门"
                    margin="dense"
                    onChange={this.handleSelectGroupChange}
                    select
                    SelectProps={{ native: true }}
                    value={selected_group}
                    variant="outlined"
                >
                  <option value="all">全部部门</option>
                  {cpy_group.map(group=><option key={group.GroupID} value={group.GroupID}>{group.GroupName}</option>)}

                </TextField>
                  </div>
                  <div className={classes.row} style={{ marginLeft: '10px', marginBottom: '10px' }} >
                    <Button
                        color="primary"
                        onClick={()=>this.handleDialogOpen('time')}
                        size="small"
                        variant="outlined"
                    >
                      <Edit />
                                        批量修改-签到时间
                    </Button>
                    <Button
                        color="primary"
                        onClick={()=>this.handleDialogOpen('dep')}
                        size="small"
                        style={{ marginLeft: '10px' }}
                        variant="outlined"
                    >
                      <Edit />
                                        批量修改-部门
                    </Button>
                  </div>
                </div>
              ),
            }}
            data={users}
            icons={TableIcons}
            isLoading = {loading}
            localization={{
              pagination: {
                labelRowsSelect: '行每页',
                labelDisplayedRows: users.length>0?' {from}-{to} 共 {count} 条':'0-0 共 0 条',
                firstTooltip: '第一页',
                previousTooltip: '前一页',
                nextTooltip: '后一页',
                lastTooltip: '到最后'
              },
              toolbar: {
                searchTooltip: '模糊查找',
                searchPlaceholder: '模糊查找',
                exportName: '导出表格',
                nRowsSelected: '已选择{0}名员工',
                exportTitle: '导出表格'
              },
              header: {
                actions: '操作'
              },
              body: {
                addTooltip: '新增记录',
                emptyDataSourceMessage: '暂时没有数据',
                filterRow: {
                  filterTooltip: 'Filter'
                },
                editRow: {
                  deleteText: '确定要删除吗？',
                  cancelTooltip: '取消',
                  saveTooltip: '确定'
                }
              }
            }}
            onSelectionChange={this.handleSelection}
            onChangeRowsPerPage={this.handleRowsChange}
            onChangePage={this.handlePageChange}
            options={{
              exportButton: true,
              minBodyHeight:600,
              selection: true,
              // pageSize:10,
              // paging:false,
              exportCsv:export_to_excel,
              // exportFileName: 'untitled.csv'
              // filtering: true
              sorting:true,
              paging:true,
              pageSizeOptions:pagearray,
              pageSize:pagesize,
              paginationType:'normal',
              initialPage:curpage,
            }}
            title="员工管理"
        />
        <Dialog
            aria-labelledby="max-width-dialog-title"
            fullWidth
            maxWidth={'sm'}
            onClose={this.handleClose}
            open={this.state.change_dialog_state}
        >
          <DialogActions>
              <Box style={{width:'100%'}}>
                  <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >签到设备管理</Typography>
              </Box>     
              <IconButton size='small' onClick={this.handleClose}><Clear fontSize='small' style={{ color: 'primary' }}/></IconButton>
          </DialogActions>
          <DialogContent>
            <Portlet>
              <PortletHeader>
                <PortletLabel
                    subtitle="签到设备绑定/解绑"
                    title={cur_user_name}
                />
              </PortletHeader>
              <PortletContent>
                <Typography variant='body1'>已绑定的签到设备{cur_router?'('+cur_router.okWifi.length+'个)':'(0个)'}</Typography>
                <List dense={false}>
                  {cur_router?cur_router.okWifi
                    .map(router => (
                      <ListItem key={router.wifiid}>
                        <ListItemAvatar>
                          <Avatar style={{color: 'green'}}>
                            <Router />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            color="green"
                            primary={router.ssid}
                            secondary={'可签到'}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                              aria-label="Delete"
                              edge="end"
                              onClick={() => this.delete_router(router)}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )):null}
                  <Divider />
                  <Typography variant='body1' style={{marginTop:10}}>
                    已解绑的签到设备{cur_router?'('+cur_router.noWifi.length+'个)':'(0个)'}
                  </Typography>
                  {cur_router?cur_router.noWifi.map(router => (
                      <ListItem key={router.wifiid}>
                        <ListItemAvatar>
                          <Avatar style={{ color: 'red' }}>
                            <Router />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={router.ssid}
                            secondary={<span style={{ color: 'red' }}>不可签到</span>}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                              aria-label="Delete"
                              edge="end"
                              onClick={() => this.add_router(router)}
                          >
                            <AddCircleOutline />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )):null}
                </List>
              </PortletContent>
            </Portlet>
          <DialogActions>
            <Button color="primary" variant='outlined' onClick={this.handleClose} >
              返回
            </Button>
          </DialogActions>
          </DialogContent>
        </Dialog>

        <Dialog
            aria-labelledby="max-width-dialog-title"
            fullWidth
            maxWidth="md"
            onClose={this.handleClose}
            open={staff_detail_state}
        >
          <DialogTitle id="max-width-dialog-title">
              <Typography component='div' variant="h4" style={{marginTop:10}} >
                  <Typography component='span' variant="h4" >员工管理</Typography>
              </Typography>
          </DialogTitle>
          <DialogContent>
            <UserForm2 
              cpy_group={cpy_group} 
              form_value={form_value} 
              handlecheck={this.handleCheck}
              nameerror={name_error} 
              teleerror={telephone_error} 
              errors={errors} 
              isnew={false} 
              handlefieldchange={this.handleFieldChange} 
              changenum={this.changeNum} 
              deluser={this.delUser} 
              accountInfo={accountInfo}
              // timevalid={this.timeValid} num={num} 
            />
            <DialogActions>
              <div style={{ position: 'relative' }}>
                  {submit_loading && (
                      <CircularProgress size={24}
                          style={{
                              position: 'absolute',
                              marginLeft: -12,
                              marginTop: -12,
                              top: '50%',
                              left: '50%'
                          }}
                      />
                  )}
                  <Button variant='outlined'
                      color="primary"
                      disabled={!isValid || (accountInfo.isVip<=0 && form_value.mode>0)}
                      onClick={this.handleUserSave}
                  >
                      保存并退出
                  </Button>
              </div>
              <Button variant='outlined'
                  color="primary"
                  onClick={this.handleClose}
              >
                取消
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Index));
