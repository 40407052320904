import React from 'react';
import {Avatar, Button, Link, CssBaseline, Typography, Container, Stepper, Step, StepLabel, TextField, List,
    ListItem, ListItemSecondaryAction, ListItemAvatar, Divider, CircularProgress, IconButton, Tooltip
 }from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

import validate from 'validate.js';
import { format, isValid } from 'date-fns';
// Form validation schema
import schema from './schema';
// import router_schema from './router_schema';
import _ from 'underscore';
import { EmailShareButton, EmailIcon } from 'react-share';
import {CompanyForm, UserForm} from './components';

import utils from 'utility';
import user_schema from './user_schema';
import Http from 'services/http';

import { SnackbarsMessage, Portlet, PortletHeader, PortletLabel, PortletContent } from 'components';

import { compare_all_timeV2, compare_each_oneV2, compare_result } from 'utils/time_compare';
import {BookmarkBorderOutlined, Delete as DeleteIcon } from '@material-ui/icons';

import LoadingOverlay from 'react-loading-overlay';

import Share from 'social-share-react';
import logoImg from 'static/img/logo.png'
// import { Tooltip } from 'chart.js';

const default_form_value = {
    firmname: '',
    tel: '',
    address: '',
    email: '',
    mode:0,
    mode0info:{ 
        mode0num:0,           
        pname1: '第一时段',
        stime1: new Date('December 17, 1995 8:00:00'),
        ontime1: new Date('December 17, 1995 8:30:00'),
        offtime1: new Date('December 17, 1995 17:30:00'),
        pname2: '第二时段',
        stime2: new Date('December 17, 1995 12:30:00'),
        ontime2: new Date('December 17, 1995 13:00:00'),
        offtime2: new Date('December 17, 1995 17:30:00'),
        pname3: '第三时段',
        stime3: new Date('December 17, 1995 16:30:00'),
        ontime3: new Date('December 17, 1995 17:00:00'),
        offtime3: new Date('December 17, 1995 23:00:00')
      },
      
      mode1info:{
        mode1num:0,          
        pname1: '第一时段',
        flag11:1,
        stime1: new Date('December 17, 1995 8:00:00'),
        flag12:1,
        ontime1: new Date('December 17, 1995 8:30:00'),
        flag13:1,
        offtime1: new Date('December 17, 1995 17:30:00'),
        flag14:1,
        etime1: new Date('December 17, 1995 19:00:00'),
        pname2: '第二时段',
        flag21:1,
        stime2: new Date('December 17, 1995 12:30:00'),
        flag22:1,
        ontime2: new Date('December 17, 1995 13:00:00'),
        flag23:1,
        offtime2: new Date('December 17, 1995 17:30:00'),
        flag24:1,
        etime2: new Date('December 17, 1995 19:00:00'),
        pname3: '第三时段',
        flag31:1,
        stime3: new Date('December 17, 1995 19:30:00'),
        flag32:1,
        ontime3: new Date('December 17, 1995 20:00:00'),
        flag33:0,
        offtime3: new Date('December 17, 1995 3:00:00'),
        flag34:0,
        etime3: new Date('December 17, 1995 5:00:00')
      },

    equipname: '',
    firmlogo:
        'https://app.ddqiandao.com/Upload/20170824/a60bb445-1665-4bc2-9e84-163a562a75ab.jpg',
    Stype: '',
    memo: '高新科技'
};

const default_user_form_value = {
    status:'N',
    name:'',
    telephone: '',
    department: 0,
    role: 0,
    mode:0,
    mode0info:{ 
        mode0num:0,           
        pname1: '第一时段',
        stime1: new Date('December 17, 1995 8:00:00'),
        ontime1: new Date('December 17, 1995 8:30:00'),
        offtime1: new Date('December 17, 1995 17:30:00'),
        pname2: '第二时段',
        stime2: new Date('December 17, 1995 12:30:00'),
        ontime2: new Date('December 17, 1995 13:00:00'),
        offtime2: new Date('December 17, 1995 17:30:00'),
        pname3: '第三时段',
        stime3: new Date('December 17, 1995 16:30:00'),
        ontime3: new Date('December 17, 1995 17:00:00'),
        offtime3: new Date('December 17, 1995 23:00:00')
      },
      
      mode1info:{
        mode1num:0,          
        pname1: '第一时段',
        flag11:1,
        stime1: new Date('December 17, 1995 8:00:00'),
        flag12:1,
        ontime1: new Date('December 17, 1995 8:30:00'),
        flag13:1,
        offtime1: new Date('December 17, 1995 17:30:00'),
        flag14:1,
        etime1: new Date('December 17, 1995 19:00:00'),
        pname2: '第二时段',
        flag21:1,
        stime2: new Date('December 17, 1995 12:30:00'),
        flag22:1,
        ontime2: new Date('December 17, 1995 13:00:00'),
        flag23:1,
        offtime2: new Date('December 17, 1995 17:30:00'),
        flag24:1,
        etime2: new Date('December 17, 1995 19:00:00'),
        pname3: '第三时段',
        flag31:1,
        stime3: new Date('December 17, 1995 19:30:00'),
        flag32:1,
        ontime3: new Date('December 17, 1995 20:00:00'),
        flag33:0,
        offtime3: new Date('December 17, 1995 3:00:00'),
        flag34:0,
        etime3: new Date('December 17, 1995 5:00:00')
      },
    chongzhi: false,
    // 未知
    userid: 1362,
    Stype: 0,
    managepass: '',
    // 工号
    sn:'',
    // 职位
    position:'',
    // 备注
    memo:'',
    isphone:1
};
const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    
    group: {
        textTransform: 'none'
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    label: {
        backgroundColor: 'white'
    }
});

class Index extends React.Component {
    state = {
        selectCompany: 0,
        open: false,
        depart_open: false,

        form_value: {
            firmname: '',
            tel: '',
            address: '',
            email: '',
            mode:0,
            mode0info:{ 
                mode0num:0,           
                pname1: '第一时段',
                stime1: new Date('December 17, 1995 8:00:00'),
                ontime1: new Date('December 17, 1995 8:30:00'),
                offtime1: new Date('December 17, 1995 17:30:00'),
                pname2: '第二时段',
                stime2: new Date('December 17, 1995 12:30:00'),
                ontime2: new Date('December 17, 1995 13:00:00'),
                offtime2: new Date('December 17, 1995 17:30:00'),
                pname3: '第三时段',
                stime3: new Date('December 17, 1995 16:30:00'),
                ontime3: new Date('December 17, 1995 17:00:00'),
                offtime3: new Date('December 17, 1995 23:00:00')
              },
              
              mode1info:{
                mode1num:0,          
                pname1: '第一时段',
                flag11:1,
                stime1: new Date('December 17, 1995 8:00:00'),
                flag12:1,
                ontime1: new Date('December 17, 1995 8:30:00'),
                flag13:1,
                offtime1: new Date('December 17, 1995 17:30:00'),
                flag14:1,
                etime1: new Date('December 17, 1995 19:00:00'),
                pname2: '第二时段',
                flag21:1,
                stime2: new Date('December 17, 1995 12:30:00'),
                flag22:1,
                ontime2: new Date('December 17, 1995 13:00:00'),
                flag23:1,
                offtime2: new Date('December 17, 1995 17:30:00'),
                flag24:1,
                etime2: new Date('December 17, 1995 19:00:00'),
                pname3: '第三时段',
                flag31:1,
                stime3: new Date('December 17, 1995 19:30:00'),
                flag32:1,
                ontime3: new Date('December 17, 1995 20:00:00'),
                flag33:0,
                offtime3: new Date('December 17, 1995 3:00:00'),
                flag34:0,
                etime3: new Date('December 17, 1995 5:00:00')
              },

            equipname: '',
            firmlogo:
                'https://app.ddqiandao.com/Upload/20170824/a60bb445-1665-4bc2-9e84-163a562a75ab.jpg',
            Stype: '',
            memo: '高新科技'
        },

        errors: {
            firmname: null,
            tel: null,
            address: null
        },

        touched: {
            firmname: false,
            tel: false,
            address: false
        },
        isValid: true,
        isLoading: false,

        activestep: 0,

        dep_name: '',
        // user
        user_form_value: {
            status:'N',
            name:'',
            telephone: '',
            department: 0,
            role: 0,
            mode:0,
            mode0info:{ 
                mode0num:0,           
                pname1: '第一时段',
                stime1: new Date('December 17, 1995 8:00:00'),
                ontime1: new Date('December 17, 1995 8:30:00'),
                offtime1: new Date('December 17, 1995 17:30:00'),
                pname2: '第二时段',
                stime2: new Date('December 17, 1995 12:30:00'),
                ontime2: new Date('December 17, 1995 13:00:00'),
                offtime2: new Date('December 17, 1995 17:30:00'),
                pname3: '第三时段',
                stime3: new Date('December 17, 1995 16:30:00'),
                ontime3: new Date('December 17, 1995 17:00:00'),
                offtime3: new Date('December 17, 1995 23:00:00')
              },
              
              mode1info:{
                mode1num:0,          
                pname1: '第一时段',
                flag11:1,
                stime1: new Date('December 17, 1995 8:00:00'),
                flag12:1,
                ontime1: new Date('December 17, 1995 8:30:00'),
                flag13:1,
                offtime1: new Date('December 17, 1995 17:30:00'),
                flag14:1,
                etime1: new Date('December 17, 1995 19:00:00'),
                pname2: '第二时段',
                flag21:1,
                stime2: new Date('December 17, 1995 12:30:00'),
                flag22:1,
                ontime2: new Date('December 17, 1995 13:00:00'),
                flag23:1,
                offtime2: new Date('December 17, 1995 17:30:00'),
                flag24:1,
                etime2: new Date('December 17, 1995 19:00:00'),
                pname3: '第三时段',
                flag31:1,
                stime3: new Date('December 17, 1995 19:30:00'),
                flag32:1,
                ontime3: new Date('December 17, 1995 20:00:00'),
                flag33:0,
                offtime3: new Date('December 17, 1995 3:00:00'),
                flag34:0,
                etime3: new Date('December 17, 1995 5:00:00')
              },
            chongzhi: false,
            // 未知
            userid: 1362,
            Stype: 0,
            managepass: '',
            // 工号
            sn:'',
            // 职位
            position:'',
            // 备注
            memo:'',
            isphone:1
        },
        cpy_group: [],
        user_errors: {
            firmname: null,
            tel: null,
            address: null
        },

        user_touched: {
            firmname: false,
            tel: false,
            address: false
        },
        user_isValid: true,
        user_isLoading: false,

        snackbarsMsg: '',
        snackbarsVisible: false,

        departments: [],
        department_name: '',

        router_form: {
            title: '',
            remark: ''
        },
        router_errors: {
            title: null,
            remark: null
        },
        router_touched: {
            title: false,
            remark: false
        },
        submitError: null,

        
        accountInfo:{},
        accountInfoStatus:false,

        label: '新部门',
        select_group: 0,
        select_group_name: '',
        cur_cpy: {},
        mail_str:
            'Mailto:?Subject=叮咚考勤注册通知&Body=员工登录时，需正确填写员工姓名、手机号和单位号信息，以免造成无法签到或签到数据有误。员工端APP可通过各大手机下载市场进行下载安装。',
        share_str: `叮咚考勤注册通知`
    };

    validateForm = () => {
        const { form_value } = this.state;

        let errors = validate(form_value, schema);
        const isValid = errors ? true : false;
        errors = errors || {};
        this.setState({
            errors,
            isValid
        });
    };
    
    async componentDidMount() {
        try {
            let resData = await Http.session_check();

            if (resData && resData.errcode !== 0) {
                localStorage.clear();
                this.props.history.push('/sign-in');
            }else if(JSON.parse(localStorage.getItem('cur_user'))){
                if(JSON.parse(localStorage.getItem('cur_cpy'))){
                    /**
                    this.setState({
                        num: 0,
                        activestep: 1
                    });
                    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
                    const group_data = await Http.get_group(
                        {
                            cpySn: cur_cpy.CompanySN,
                            _: new Date().getTime()
                        },
                        true
                    );
                    this.setState({
                        share_str: `叮咚考勤注册通知:单位号:${cur_cpy.CompanySN}`,
                        mail_str: `Mailto:?Subject=叮咚考勤注册通知&Body=单位号:${cur_cpy.CompanySN},员工登录时，需正确填写员工姓名、手机号和单位号信息，以免造成无法签到或签到数据有误。员工端APP可通过各大手机下载市场进行下载安装。`,
                        cpySn: cur_cpy.CompanySN,
                        departments: group_data.data
                    }); */
                    this.props.history.push('/signdata');
                }else{
                    const cur_user = JSON.parse(localStorage.getItem('cur_user'));                    
                    this.handleAccountInfo(cur_user.UserID)
                }
            }else{
                this.props.history.push('/sign-in');
            }
        } catch (err) {
            localStorage.clear();
            this.props.history.push('/sign-in');
        }
    }    
  
    handleAccountInfo= async (info)=>{
        try {
            const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
            if (errcode !== 0) {
                this.setState({accountInfoStatus: false})
            } else {
                this.setState({accountInfoStatus: true, accountInfo:data})         
            }
        }catch (error) {
            return;
        }
    }

    handleFieldChange = (value, field) => {
        const { form_value, touched } = this.state;
        let {mode0info, mode1info}=this.state.form_value
        let fields='stime1stime2stime3etime1etime2etime3ontime1ontime2ontime3offtime1offtime2offtime3flag11flag12flag13flag14flag21flag22flag23flag24flag31flag32flag33flag34'
        if(fields.includes(field)){
            if(form_value.mode==0){
                mode0info[field]=value
                form_value.mode0info={...mode0info}
            }
            if(form_value.mode==1){
                mode1info[field]=value
                form_value.mode1info={...mode1info}
            }
        }else{
            form_value[field] = value;
        }
        touched[field] = true;
        this.setState({ form_value }, this.validateForm);
    };

    handleListItemClick = id => {
        this.setState({
            selectCompany: id
        });
    };

    newFirm = async () => {
        const { form_value } = this.state;
        const { mode0info, mode1info, mode } = this.state.form_value;
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));

        let code=compare_each_oneV2(form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        code=compare_all_timeV2(form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }

        
        this.setState({ isLoading: true, isValid: true });        
        let num=-1
        let m0ts={
          name1:"",
          name2:"",
          name3:"",
          begin1:"",
          begin2:"",
          begin3:"",
          end1:"",
          end2:"",
          end3:"",
          eff1 :"",
          eff2:"",
          eff3:""
        }
        let m1ts={
          name1:"",
          name2:"",
          name3:"",
          begin1:"",
          begin2:"",
          begin3:"",
          end1:"",
          end2:"",
          end3:"",
          eff1 :"",
          eff2:"",
          eff3:"",
          noeff1 :"",
          noeff2:"",
          noeff3:"",
          flag11:1,
          flag12:1,
          flag13:1,
          flag14:1,
          flag21:1,
          flag22:1,
          flag23:1,
          flag24:1,
          flag31:1,
          flag32:1,
          flag33:1,
          flag34:1
        }
        switch(mode){
          case 0:
            num=mode0info.mode0num;
            
            if(num==0){
              m0ts.name1=mode0info.pname1
              m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
              m0ts.end1=format(mode0info.offtime1, 'HH:mm')
              m0ts.eff1=format(mode0info.stime1, 'HH:mm')
            }else if(num==1){
              m0ts.name1=mode0info.pname1
              m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
              m0ts.end1=format(mode0info.offtime1, 'HH:mm')
              m0ts.eff1=format(mode0info.stime1, 'HH:mm')
              
              m0ts.name2=mode0info.pname2
              m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
              m0ts.end2=format(mode0info.offtime2, 'HH:mm')
              m0ts.eff2=format(mode0info.stime2, 'HH:mm')
            }else{
              m0ts.name1=mode0info.pname1
              m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
              m0ts.end1=format(mode0info.offtime1, 'HH:mm')
              m0ts.eff1=format(mode0info.stime1, 'HH:mm')
              
              m0ts.name2=mode0info.pname2
              m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
              m0ts.end2=format(mode0info.offtime2, 'HH:mm')
              m0ts.eff2=format(mode0info.stime2, 'HH:mm')
              
              m0ts.name3=mode0info.pname3
              m0ts.begin3=format(mode0info.ontime3, 'HH:mm')
              m0ts.end3=format(mode0info.offtime3, 'HH:mm')
              m0ts.eff3=format(mode0info.stime3, 'HH:mm')
            }
            break;
          case 1:                
            num=mode1info.mode1num;                
            if(num==0){
              m1ts.name1=mode1info.pname1
              m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
              m1ts.end1=format(mode1info.offtime1, 'HH:mm')
              m1ts.eff1=format(mode1info.stime1, 'HH:mm')
              m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
              m1ts.flag11=mode1info.flag11
              m1ts.flag12=mode1info.flag12
              m1ts.flag13=mode1info.flag13
              m1ts.flag14=mode1info.flag14
            }else if(num==1){
              m1ts.name1=mode1info.pname1
              m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
              m1ts.end1=format(mode1info.offtime1, 'HH:mm')
              m1ts.eff1=format(mode1info.stime1, 'HH:mm')
              m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
              m1ts.flag11=mode1info.flag11
              m1ts.flag12=mode1info.flag12
              m1ts.flag13=mode1info.flag13
              m1ts.flag14=mode1info.flag14
              m1ts.name2=mode1info.pname2
              m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
              m1ts.end2=format(mode1info.offtime2, 'HH:mm')
              m1ts.eff2=format(mode1info.stime2, 'HH:mm')
              m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
              m1ts.flag21=mode1info.flag21
              m1ts.flag22=mode1info.flag22
              m1ts.flag23=mode1info.flag23
              m1ts.flag24=mode1info.flag24
            }else{
              m1ts.name1=mode1info.pname1
              m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
              m1ts.end1=format(mode1info.offtime1, 'HH:mm')
              m1ts.eff1=format(mode1info.stime1, 'HH:mm')
              m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
              m1ts.flag11=mode1info.flag11
              m1ts.flag12=mode1info.flag12
              m1ts.flag13=mode1info.flag13
              m1ts.flag14=mode1info.flag14
              m1ts.name2=mode1info.pname2
              m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
              m1ts.end2=format(mode1info.offtime2, 'HH:mm')
              m1ts.eff2=format(mode1info.stime2, 'HH:mm')
              m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
              m1ts.flag21=mode1info.flag21
              m1ts.flag22=mode1info.flag22
              m1ts.flag23=mode1info.flag23
              m1ts.flag24=mode1info.flag24
              m1ts.name3=mode1info.pname3
              m1ts.begin3=format(mode1info.ontime3, 'HH:mm')
              m1ts.end3=format(mode1info.offtime3, 'HH:mm')
              m1ts.eff3=format(mode1info.stime3, 'HH:mm')
              m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
              m1ts.flag31=mode1info.flag31
              m1ts.flag32=mode1info.flag32
              m1ts.flag33=mode1info.flag33
              m1ts.flag34=mode1info.flag34
            }
            break;
        }

        let postData = {};
        if (mode === 0) {
            postData = {
                userid: cur_user.UserID,
                firmname: form_value.firmname,
                address: form_value.address,
                address2: '',
                tel: form_value.tel,
                email: form_value.email,
                typeSP: form_value.Stype,
                equipname: '',
                equipaddress: '',
                firmlogo: '',
                memo: form_value.memo,
                mode:form_value.mode,
                mode0info:{...m0ts},
                mode1info:{},
                mode2info:{},
                mode3info:{}
                
            };
        } else if (mode === 1) {
            postData = {
                userid: cur_user.UserID,
                firmname: form_value.firmname,
                address: form_value.address,
                address2: '',
                tel: form_value.tel,
                email: form_value.email,
                typeSP: form_value.Stype,
                equipname: '',
                equipaddress: '',
                firmlogo: '',
                memo: form_value.memo,
                mode:form_value.mode,
                mode0info:{},
                mode1info:{...m1ts},
                mode2info:{},
                mode3info:{}
                
            };
        } 

        try {
            const res = await Http.addFirmV2(postData);
            const { errmsg, errcode } = res;
            if (errcode === 0) {
                let cpyData = await Http.cpy({ type: 'cpyList' }, true);
                localStorage.setItem(
                    'cur_cpy',
                    JSON.stringify(cpyData.data[0])
                );
                localStorage.setItem('cpy_list', JSON.stringify(cpyData.data));
                this.setState({
                    activestep: 1
                });
                this.showMsg('新增单位成功');
                const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
                const group_data = await Http.get_group(
                    {
                        cpySn: cur_cpy.CompanySN,
                        _: new Date().getTime()
                    },
                    true
                );
                this.setState({
                    share_str: `叮咚考勤注册通知:单位号:${cur_cpy.CompanySN}`,
                    mail_str: `Mailto:?Subject=叮咚考勤注册通知&Body=单位号:${cur_cpy.CompanySN},员工登录时，需正确填写员工姓名、手机号和单位号信息，以免造成无法签到或签到数据有误。员工端APP可通过各大手机下载市场进行下载安装。`,
                    cpySn: cur_cpy.CompanySN,
                    departments: group_data.data
                });
                this.setState({ isLoading: false, isValid: false });
            } else {
                this.setState({ isLoading: false, isValid: false });
                this.showMsg(errmsg);
                // setTimeout(() => {
                //     this.props.history.push('/');
                // }, 1000);
            }
        } catch (err) {
            this.showMsg('服务器开小差了,请稍后再试');
            this.setState({ isLoading: false, isValid: false });
            console.log(err);
        }
    };

    changeNum = (type) => {
        const {form_value} = this.state;
        switch(form_value.mode){
            case 0:
                if (form_value.mode0info.mode0num >= 0 && form_value.mode0info.mode0num < 3) {
                    if (type === 'add' && form_value.mode0info.mode0num !== 2) { form_value.mode0info.mode0num += 1; } else if (type === 'minus' && form_value.mode0info.mode0num !== 0) { form_value.mode0info.mode0num -= 1; }
                }
                break;
            case 1:
                if (form_value.mode1info.mode1num >= 0 && form_value.mode1info.mode1num < 3) {
                    if (type === 'add' && form_value.mode1info.mode1num !== 2) { form_value.mode1info.mode1num += 1; } else if (type === 'minus' && form_value.mode1info.mode1num !== 0) { form_value.mode1info.mode1num -= 1; }
                }
                break;
        }
        this.setState({form_value}, this.validateForm);      
    };

    changeUserNum = (type) => {
        const {user_form_value} = this.state;
        switch(user_form_value.mode){
            case 0:
                if (user_form_value.mode0info.mode0num >= 0 && user_form_value.mode0info.mode0num < 3) {
                    if (type === 'add' && user_form_value.mode0info.mode0num !== 2) { user_form_value.mode0info.mode0num += 1; } else if (type === 'minus' && user_form_value.mode0info.mode0num !== 0) { user_form_value.mode0info.mode0num -= 1; }
                }
                break;
            case 1:
                if (user_form_value.mode1info.mode1num >= 0 && user_form_value.mode1info.mode1num < 3) {
                    if (type === 'add' && user_form_value.mode1info.mode1num !== 2) { user_form_value.mode1info.mode1num += 1; } else if (type === 'minus' && user_form_value.mode1info.mode1num !== 0) { user_form_value.mode1info.mode1num -= 1; }
                }
                break;
        }
        this.setState({user_form_value}, this.validateUserForm);       
    };

    handleDepChange = value => {
        this.setState({ dep_name: value });
    };

    newDep = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        this.setState({ isLoading: true, isValid: true });
        if (this.state.activestep === 1) {
            const { user_form_value } = this.state;
            try {
                const res = await Http.defaultTimeV2({
                    companysn: cur_cpy.CompanySN
                });
                const { data, errcode, errmsg } = res;
                if (errcode === 0) {
                    if (data.mode === 0) {
                        default_user_form_value.mode=0
                        if(data.Mode0Info.pname1.length>0){
                            default_user_form_value.mode0info.mode0num=0
                            default_user_form_value.mode0info.pname1 = data.Mode0Info.pname1;
                            default_user_form_value.mode0info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime1 + ':00' );
                            default_user_form_value.mode0info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime1 + ':00' );
                            default_user_form_value.mode0info.stime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime1 + ':00' );
                        }
                        if(data.Mode0Info.pname2.length>0){
                            default_user_form_value.mode0info.mode0num+=1;
                            default_user_form_value.mode0info.pname2 = data.Mode0Info.pname2;
                            default_user_form_value.mode0info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime2 + ':00' );
                            default_user_form_value.mode0info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime2 + ':00' );
                            default_user_form_value.mode0info.stime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime2 + ':00' );
                        }
                        if(data.Mode0Info.pname3.length>0){
                            default_user_form_value.mode0info.mode0num+=1;
                            default_user_form_value.mode0info.pname3 = data.Mode0Info.pname3;
                            default_user_form_value.mode0info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime3 + ':00' );
                            default_user_form_value.mode0info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime3 + ':00' );
                            default_user_form_value.mode0info.stime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime3 + ':00' );
                        }
    
                        this.setState({                        
                            //form_value, default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                            user_form_value:{...default_user_form_value, mode0info:{...default_user_form_value.mode0info}, mode1info:{...default_user_form_value.mode1info}}, //default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                        });
                    } else if(data.mode==1){
                        default_user_form_value.mode=1
                        if(data.Mode1Info.pname1.length>0){
                            default_user_form_value.mode1info.mode1num=0
                            default_user_form_value.mode1info.pname1 = data.Mode1Info.pname1;
                            default_user_form_value.mode1info.flag11=data.Mode1Info.flag11
                            default_user_form_value.mode1info.stime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime1 + ':00' );
                            default_user_form_value.mode1info.flag12=data.Mode1Info.flag12
                            default_user_form_value.mode1info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime1 + ':00' );
                            default_user_form_value.mode1info.flag13=data.Mode1Info.flag13
                            default_user_form_value.mode1info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime1 + ':00' );
                            default_user_form_value.mode1info.flag14=data.Mode1Info.flag14
                            default_user_form_value.mode1info.etime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime1 + ':00' );
                        }
                        if(data.Mode1Info.pname2.length>0){
                            default_user_form_value.mode1info.mode1num+=1;
                            default_user_form_value.mode1info.pname2 = data.Mode1Info.pname2;
                            default_user_form_value.mode1info.flag21=data.Mode1Info.flag21
                            default_user_form_value.mode1info.stime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime2 + ':00' );
                            default_user_form_value.mode1info.flag22=data.Mode1Info.flag22
                            default_user_form_value.mode1info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime2 + ':00' );
                            default_user_form_value.mode1info.flag23=data.Mode1Info.flag23
                            default_user_form_value.mode1info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime2 + ':00' );
                            default_user_form_value.mode1info.flag24=data.Mode1Info.flag24
                            default_user_form_value.mode1info.etime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime2 + ':00' );
                        }
                        if(data.Mode1Info.pname3.length>0){
                            default_user_form_value.mode1info.mode1num+=1;
                            default_user_form_value.mode1info.pname3 = data.Mode1Info.pname3;
                            default_user_form_value.mode1info.flag31=data.Mode1Info.flag31
                            default_user_form_value.mode1info.stime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime3 + ':00' );
                            default_user_form_value.mode1info.flag32=data.Mode1Info.flag32
                            default_user_form_value.mode1info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime3 + ':00' );
                            default_user_form_value.mode1info.flag33=data.Mode1Info.flag33
                            default_user_form_value.mode1info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime3 + ':00' );
                            default_user_form_value.mode1info.flag34=data.Mode1Info.flag34
                            default_user_form_value.mode1info.etime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime3 + ':00' );
                        }
    
                        this.setState({
                            //form_value, default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                            user_form_value:{...default_user_form_value, mode0info:{...default_user_form_value.mode0info}, mode1info:{...default_user_form_value.mode1info}}
                        });
                    }
                }else{
                    this.showMsg(errmsg)
                }
            } catch (err) {
                this.setState({
                    staff_add: true
                });
                console.log(err);
            }
        }
        try {
            const cpy_group = await Http.get_group(
                {
                    cpySn: cur_cpy.CompanySN,
                    _: new Date().getTime()
                },
                true
            );
            setTimeout(() => {
                this.setState({ isLoading: false, isValid: false });
            }, 1500);
            const { errcode } = cpy_group;
            if (errcode === 0) {
                this.setState({
                    cpy_group: cpy_group.data
                });
                this.setState({
                    activestep: 2
                });
            } else {
                this.showMsg('服务器开小差了');
                this.props.history.push('/sign-in');
            }
        } catch (err) {
            console.log(err);
        }
    };

    validateUserForm = () => {
        const { user_form_value } = this.state;

        let errors = validate(user_form_value, user_schema);
        const isValid = errors ? true : false;
        errors = errors || {};

        this.setState({
            user_errors: errors,
            user_isValid: isValid
        });
    };

    handleUserFieldChange = (value, field) => {
        const { user_form_value, user_touched } = this.state;
        let {mode0info, mode1info}=this.state.user_form_value
        let fields='stime1stime2stime3etime1etime2etime3ontime1ontime2ontime3offtime1offtime2offtime3flag11flag12flag13flag14flag21flag22flag23flag24flag31flag32flag33flag34'
        if(fields.includes(field)){
            if(user_form_value.mode==0){
                mode0info[field]=value
                user_form_value.mode0info={...mode0info}
            }
            if(user_form_value.mode==1){
                mode1info[field]=value
                user_form_value.mode1info={...mode1info}
            }
        }else{
            user_form_value[field] = value;
        }
        user_touched[field] = true;
        this.setState({ user_form_value }, this.validateUserForm);
    };

    addNewUser = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));        
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        const { user_form_value } = this.state;
        const { mode0info, mode1info, mode } = this.state.user_form_value;

        let code=compare_each_oneV2(user_form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        code=compare_all_timeV2(user_form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        
        try {
            const cpy_group = await Http.get_group(
                {
                    cpySn: cur_cpy.CompanySN,
                    _: new Date().getTime()
                },
                true
            );
            const {errmsg, errcode } = cpy_group;
            if (errcode === 0) {
                this.setState({
                    cpy_group: cpy_group.data
                });
            } else {
                this.showMsg(errmsg);
            }
        } catch (err) {
            console.log(err);
            this.showMsg('服务器开小差了');
        }        
        
        if (user_form_value.role !== 0 && user_form_value.managepass === '') {
            this.showMsg('请输新建管理员工密码');
            return;
        }
        
        this.setState({
            user_isLoading: true,
            user_isValid: false
        });
        
        let num=-1
        let m0ts={
            name1:"",
            name2:"",
            name3:"",
            begin1:"",
            begin2:"",
            begin3:"",
            end1:"",
            end2:"",
            end3:"",
            eff1 :"",
            eff2:"",
            eff3:""
        }
        let m1ts={
            name1:"",
            name2:"",
            name3:"",
            begin1:"",
            begin2:"",
            begin3:"",
            end1:"",
            end2:"",
            end3:"",
            eff1 :"",
            eff2:"",
            eff3:"",
            noeff1 :"",
            noeff2:"",
            noeff3:"",
            flag11:1,
            flag12:1,
            flag13:1,
            flag14:1,
            flag21:1,
            flag22:1,
            flag23:1,
            flag24:1,
            flag31:1,
            flag32:1,
            flag33:1,
            flag34:1
        }
        switch(mode){
            case 0:
                num=mode0info.mode0num;
                
                if(num==0){
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                }else if(num==1){
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                    
                    m0ts.name2=mode0info.pname2
                    m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
                    m0ts.end2=format(mode0info.offtime2, 'HH:mm')
                    m0ts.eff2=format(mode0info.stime2, 'HH:mm')
                }else{
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                    
                    m0ts.name2=mode0info.pname2
                    m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
                    m0ts.end2=format(mode0info.offtime2, 'HH:mm')
                    m0ts.eff2=format(mode0info.stime2, 'HH:mm')
                    
                    m0ts.name3=mode0info.pname3
                    m0ts.begin3=format(mode0info.ontime3, 'HH:mm')
                    m0ts.end3=format(mode0info.offtime3, 'HH:mm')
                    m0ts.eff3=format(mode0info.stime3, 'HH:mm')
                }
                break;
            case 1:                
                num=mode1info.mode1num;                
                if(num==0){
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                }else if(num==1){
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                    m1ts.name2=mode1info.pname2
                    m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
                    m1ts.end2=format(mode1info.offtime2, 'HH:mm')
                    m1ts.eff2=format(mode1info.stime2, 'HH:mm')
                    m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
                    m1ts.flag21=mode1info.flag21
                    m1ts.flag22=mode1info.flag22
                    m1ts.flag23=mode1info.flag23
                    m1ts.flag24=mode1info.flag24
                }else{
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                    m1ts.name2=mode1info.pname2
                    m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
                    m1ts.end2=format(mode1info.offtime2, 'HH:mm')
                    m1ts.eff2=format(mode1info.stime2, 'HH:mm')
                    m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
                    m1ts.flag21=mode1info.flag21
                    m1ts.flag22=mode1info.flag22
                    m1ts.flag23=mode1info.flag23
                    m1ts.flag24=mode1info.flag24
                    m1ts.name3=mode1info.pname3
                    m1ts.begin3=format(mode1info.ontime3, 'HH:mm')
                    m1ts.end3=format(mode1info.offtime3, 'HH:mm')
                    m1ts.eff3=format(mode1info.stime3, 'HH:mm')
                    m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
                    m1ts.flag31=mode1info.flag31
                    m1ts.flag32=mode1info.flag32
                    m1ts.flag33=mode1info.flag33
                    m1ts.flag34=mode1info.flag34
                }
                break;
        }

        let postData = {};
        if (mode === 0) {
            postData = {
                userid:cur_user.UserID,
                companysn: cur_cpy.CompanySN,
                name: user_form_value.name,
                telephone: user_form_value.telephone,
                department: user_form_value.department,
                status: user_form_value.status,
                role: user_form_value.role,
                //
                managepass:
                user_form_value.managepass.length === 32
                        ? user_form_value.managepass
                        : utils.md5(user_form_value.managepass),

                Stype: user_form_value.Stype,
            
                mode:user_form_value.mode,
                mode0info:{...m0ts},
                mode1info:{},
                mode2info:{},
                mode3info:{},
                //
                sn: user_form_value.sn,
                position: '',
                memo: '',
                isPhone:user_form_value.isphone
            };
        } else if (mode === 1) {
            postData = {
                userid:cur_user.UserID,
                companysn: cur_cpy.CompanySN,
                name: user_form_value.name,
                telephone: user_form_value.telephone,
                department: user_form_value.department,
                status: user_form_value.status,
                role: user_form_value.role,
                //
                managepass: utils.md5(user_form_value.managepass),

                Stype: user_form_value.Stype,
                mode:user_form_value.mode,
                mode0info:{},
                mode1info:{...m1ts},
                mode2info:{},
                mode3info:{},

                //
                sn: user_form_value.sn,

                position: '',
                memo: '',
                isPhone:user_form_value.isphone
            };
        } 
        this.setState({ user_isLoading: true, user_isValid: true });

        try {
            const res = await Http.addUserV2(postData);

            const { errcode, errmsg } = res;
            if (errcode !== 0) {
                this.showMsg(errmsg);
                setTimeout(() => {
                    this.setState({
                        user_isLoading: false,
                        user_isValid: false
                    });
                }, 500);
            } else {
                this.showMsg('新增员工成功');
                setTimeout(() => {
                    this.setState({
                        user_isLoading: false,
                        user_isValid: true,
                        user_form_value:{...default_user_form_value, mode0info:{...default_user_form_value.mode0info}, mode1info:{...default_user_form_value.mode1info}}
                    });
                }, 500);
            }
        } catch (err) {
            this.setState({ user_isLoading: false, user_isValid: false });
            console.log(err);
        }
    };

    handleSClose = () => {
        this.setState({
            snackbarsMsg: '',
            snackbarsVisible: false
        });
    };

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };

    timeValid=(v)=>{
        this.setState({isValid:v})
    }

    handleStart = async () => {
        this.setState({ isLoading: true, isValid: true });
        //console.log(this.state.activestep);

        setTimeout(() => {
            if (this.state.activestep === 4) {
                this.props.history.push('/signdata');
            } else if (this.state.activestep === 3) {
                const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
                this.setState({
                    cur_cpy,
                    activestep: this.state.activestep + 1
                });
            } else {
                this.setState({
                    isLoading: false,
                    isValid: false,
                    activestep: this.state.activestep + 1
                });
            }
        }, 400);
    };

    // 部门
    delete_department = async groupid => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        //console.log(this.state.departments);
        if (this.state.departments.length === 1) {
            this.showMsg('不能删除最后一个部门');
        }else{
            try {
                const res = await Http.delBu({
                    groupid: groupid,
                    companysn: cur_cpy.CompanySN
                });
                const { errcode, errmsg } = res;
                if (errcode == 0) {
                    this.setState({
                        department_name: ''
                    });

                    try {
                        const res = await Http.get_group(
                            {
                                cpySn: cur_cpy.CompanySN,
                                _: new Date().getTime()
                            },
                            true
                        );
                        this.setState({
                            departments: res.data
                        });
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    this.showMsg(errmsg);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    add_department = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));

        try {
            const res = await Http.addBu(
                {
                    groupname: this.state.department_name,
                    companyid: cur_cpy.CompanySN
                },
                true
            );
            const { errcode, msg } = res;
            if (errcode == 0) {
                this.setState({
                    department_name: ''
                });

                try {
                    const res = await Http.get_group(
                        {
                            cpySn: cur_cpy.CompanySN,
                            _: new Date().getTime()
                        },
                        true
                    );
                    this.setState({
                        departments: res.data
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    changeDepartmentName = name => {
        this.setState({
            department_name: name
        });
    };

    router_validate = _.debounce(() => {
        const { router_form } = this.state;
        const errors = validate(router_form, {
            title: {
                presence: { allowEmpty: false, message: '请填写路由器SSID' }
            },
            remark: {
                presence: { allowEmpty: false, message: '请填写路由器备注' }
            }
        });
        const submitError = errors ? false : true;

        this.setState({
            router_errors: errors || {},
            submitError
        });
    }, 100);

    handleRouterChange = (value, field) => {
        const { router_form, router_touched } = this.state;
        router_touched[field] = true;
        router_form[field] = value;
        const submitError = null;
        this.setState(
            {
                router_form,
                router_touched,
                submitError
            },
            this.router_validate
        );
    };

    addRouterSubmit = async () => {
        const { router_form } = this.state;
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        if (this.state.submitError) {
            try {
                const res = await Http.add_equ(
                    {
                        ssid: router_form.title,
                        remark: router_form.remark,
                        companysn: cur_cpy.CompanySN
                    },
                    true
                );
                const { errcode } = res;
                if (errcode === 0) {
                    this.showMsg('新建成功');
                    const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
                    //console.log(cur_cpy);
                    this.setState({
                        router_form: {
                            title: '',
                            remark: ''
                        },
                        cur_cpy
                    });
                    // this.props.history.push('/signdata');
                } else {
                    this.showMsg('新建失败,请稍后再试');
                }
            } catch (err) {
                this.showMsg('新建失败,请稍后再试');
                console.log(err);
            }
        } else {
            this.showMsg('您还未填写');
        }
    };

    handleEdit = group => {
        //console.log('handle edit');
        this.setState({
            select_group: group.GroupID,
            select_group_name: group.GroupName
        });
    };

    editDepartmentName = value => {
        this.setState({
            select_group_name: value
        });
    };

    saveEditDepartmentName = async () => {
        // groupname: zz
        // groupid: 38034
        if (this.state.select_group_name.trim() === '') {
            this.showMsg('请输入部门名称');
        } else {
            try {
                const res = await Http.editBu(
                    {
                        groupname: this.state.select_group_name,
                        groupid: this.state.select_group
                    },
                    true
                );
                const { errcode } = res;
                if (errcode === 0) {
                    this.refreshGroup();
                    this.editCancel();
                    this.showMsg('修改成功');
                } else {
                    this.showMsg('服务器开小差了，请稍后尝试');
                }
            } catch (err) {
                this.showMsg('服务器开小差了，请稍后尝试');
                console.log(err);
            }
        }
    };

    editCancel = () => {
        this.setState({ select_group: 0, select_group_name: '' });
    };

    refreshGroup = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        try {
            const res = await Http.get_group(
                {
                    cpySn: cur_cpy.CompanySN,
                    _: new Date().getTime()
                },
                true
            );
            this.setState({
                departments: res.data
            });
        } catch (err) {
            console.log(err);
        }
    };

    tohome=event=>{
        this.props.history.push('/homepage');
    }

    render() {
        const { classes } = this.props;

        const { errors, touched, num, activestep, form_value, isLoading, isValid, departments, select_group, select_group_name, label, 
            department_name, cpy_group, user_form_value, user_touched, user_errors, user_isLoading, user_isValid, router_form, router_touched, 
            router_errors, submitError, cur_cpy, share_str, snackbarsMsg, snackbarsVisible, accountInfo, accountInfoStatus
        } = this.state;
        
        const disableFirmname = touched.firmname && (errors.firmname?true:false)&&(errors.firmname==null?false:true);
        const disableAddress = touched.address && (errors.address?true:false)&&(errors.address==null?false:true); 
        const disableTel = touched.tel && (errors.tel?true:false)&&(errors.tel==null?false:true); 


        const disableEmail = touched.email && (errors.email?true:false)&&(errors.email==null?false:true);
        const disableMemo = touched.memo && (errors.memo?true:false)&&(errors.memo==null?false:true);
        // user
        const nameError = user_touched.name && (user_errors.name?true:false)&&(user_errors.name==null?false:true);
        const teleError = user_touched.telephone && (user_errors.telephone?true:false)&&(user_errors.telephone==null?false:true);

        const title_error =router_touched.title && (router_errors.title?true:false)&&(router_errors.title==null?false:true);
        const remark_error =router_touched.remark && (router_errors.remark?true:false)&&(router_errors.remark==null?false:true);
        
        return (
            <Container component="main" maxWidth="md">
                <SnackbarsMessage andleClose={this.handleSClose} msg={ snackbarsMsg} visible={ snackbarsVisible} />
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} onClick={this.tohome}>
                        <img alt="logo" src={logoImg} style={{cursor: 'pointer'}} />
                    </Avatar>
                    <Typography component="h1" variant="h5"><Link href='/#/homepage'>叮咚签到</Link></Typography>
                    {/* <Button onClick={this.test}>测试</Button> */}
                    <div style={{ width: '100%' }}>
                        <Stepper activeStep={ activestep} alternativeLabel >
                            <Step key={0}>
                                <StepLabel>{'新建单位'}</StepLabel>
                            </Step>
                            <Step key={1}>
                                <StepLabel>{'新建部门'}</StepLabel>
                            </Step>
                            <Step key={2}>
                                <StepLabel>{'新增员工'}</StepLabel>
                            </Step>
                            <Step key={3}>
                                <StepLabel>{'新增设备'}</StepLabel>
                            </Step>
                            <Step key={4}>
                                <StepLabel>{'通知员工'}</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                    <LoadingOverlay active={ !accountInfoStatus } spinner text="正在加载" >
                    <div hidden={activestep !== 0}>
                        <CompanyForm
                            changenum={this.changeNum}
                            disableaddress={disableAddress}
                            disableemail={disableEmail}
                            disablefirmname={disableFirmname}
                            disablememo={disableMemo}
                            disabletel={disableTel}
                            errors={errors}
                            form_value={form_value}
                            handlefieldchange={this.handleFieldChange}
                            touched={touched}
                            // num={num}
                            // timevalid={this.timeValid}
                            accountinfo={accountInfo}
                        />
                        <div style={{ position: 'relative' }}>
                            {isLoading && (
                                <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                            )}
                            <Button className={classes.submit} color="primary" disabled={isValid} fullWidth onClick={this.newFirm} type="submit" variant="contained" >
                                新建单位
                            </Button>
                            {/* <Button
                                className={classes.submit}
                                color="primary"
                                fullWidth
                                onClick={this.handleStart}
                                type="submit"
                                style={{ marginTop: '10px' }}
                                variant="outlined"
                            >
                                跳过
                            </Button> */}
                        </div>
                    </div>
                    <div hidden={activestep !== 1}>
                        <Portlet style={{ width: '600px' }}>
                            <PortletHeader>
                                <PortletLabel title="当前单位"
                                    subtitle={ <span> 单位部门管理{' '} <span style={{ color: 'blue' }}> 点击部门名称可以编辑部门 </span> </span> }
                                />
                            </PortletHeader>
                            <PortletContent>
                                <List dense={false}>
                                    {departments.map(department => (
                                        <ListItem key={department.GroupID}>
                                            <ListItemAvatar>
                                                <Avatar style={{ color: 'green' }} >
                                                    <BookmarkBorderOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            {select_group ===
                                            department.GroupID ? (
                                                <div>
                                                    <TextField margin="dense" required value={ select_group_name } variant="outlined" 
                                                        onChange={e => this.editDepartmentName( e.target.value ) }
                                                    />
                                                    <Button onClick={ this.saveEditDepartmentName } variant="outlined" 
                                                        style={{ marginLeft: '10px', marginTop: '10px' }}
                                                    >
                                                        保存
                                                    </Button>
                                                    <Button variant="outlined" onClick={ this.editCancel }
                                                        style={{ marginLeft: '10px', marginTop: '10px' }}
                                                    >
                                                        取消
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Button className={classes.group} onClick={() =>this.handleEdit(department)}>
                                                    {department.GroupName}
                                                </Button>
                                            )}
                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="Delete" edge="end" onClick={()=>this.delete_department(department.GroupID)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                    <Divider />
                                </List>

                                <div className={classes.field} style={{ width: '100%' }} >
                                    <TextField label={label} value={ department_name} required variant="outlined"
                                        margin="dense" className={classes.textField} 
                                        InputLabelProps={{ classes: { root: classes.label } }}
                                        onChange={e => this.changeDepartmentName( e.target.value ) }
                                        // style={{ width: '70%' }}
                                    />
                                    <Button className={classes.textField} disabled={department_name===''?true:false}
                                        edge="end" onClick={this.add_department} variant="outlined"
                                        style={{ width: '20%', float: 'right', marginTop: '10px' }}
                                    >
                                        新增部门
                                    </Button>
                                </div>
                            </PortletContent>
                        </Portlet>
                        {/* <TextField
                            className={classes.textField}
                            // helperText="部门名称"
                            label="部门名称"
                            margin="normal"
                            onChange={event =>
                                this.handleDepChange(event.target.value)
                            }
                            required
                            value={this.state.dep_name}
                            variant="outlined"
                        />
                        {this.state.dep_name === '' && (
                            <Typography
                                className={classes.fieldError}
                                variant="body2"
                            >
                                请输入部门名称
                            </Typography>
                        )} */}
                        <div style={{ position: 'relative' }}>
                            {isLoading && (
                                <CircularProgress size={24} style={{position: 'absolute',marginLeft: -12,marginTop: -12,top: '50%',left: '50%'}}/>
                            )}
                            <Button className={classes.submit} color="primary" disabled={ isLoading } fullWidth onClick={this.newDep} type="submit" variant="outlined" >
                                下一步
                            </Button>
                        </div>
                    </div>
                    <div hidden={activestep !== 2}>
                        <UserForm
                            changenum={this.changeUserNum}
                            cpy_group={cpy_group}
                            errors={user_errors}
                            form_value={user_form_value}
                            handlefieldchange={this.handleUserFieldChange}
                            nameerror={nameError}
                            teleerror={teleError}
                            // timevalid={this.timeValid}
                            // num={num}
                            accountinfo={accountInfo}
                        />
                        <div style={{ position: 'relative' }}>
                            {isLoading && (
                                <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                            )}
                            <Button className={classes.submit} color="primary" disabled={user_isValid} fullWidth type="submit" onClick={this.addNewUser} variant="contained" >
                                新增员工
                            </Button>
                            <Button className={classes.submit} color="primary" fullWidth onClick={this.handleStart} type="submit" style={{marginTop:'10px'}} variant="outlined" >
                                下一步
                            </Button>
                        </div>
                    </div>
                    <div hidden={ activestep !== 3}>
                        <Portlet style={{ width: '500px' }}>
                            <PortletHeader>
                                <PortletLabel subtitle="" title="添加签到设备" />
                            </PortletHeader>
                            <PortletContent>
                                <div className={classes.field}>
                                    <Tooltip title='请填写手机连接的签到wifi名称'>
                                    <TextField label="请填写签到wifi名称" required variant="outlined"
                                        margin="dense" className={classes.textField} value={router_form.title} 
                                        onChange={event => this.handleRouterChange( event.target.value, 'title' ) }
                                        InputLabelProps={{ classes: { root: classes.label } }}
                                        error={title_error}
                                        helperText={title_error?router_errors.title[0].replace( 'Title', '' ):''}
                                    /></Tooltip>
                                </div>
                                <div className={classes.field}>
                                    <Tooltip title='填写签到wifi设备的备注，例如：安装地点，办公室名称'>
                                    <TextField label="请填写签到设备备注" required multiline rows="2" variant="outlined"
                                        margin="dense" className={classes.textField} value={router_form.remark}
                                        onChange={event => this.handleRouterChange( event.target.value, 'remark' ) }
                                        InputLabelProps={{ classes: { root: classes.label } }}
                                        error={remark_error}
                                        helperText={remark_error?router_errors.remark[0].replace( 'Remark', '' ):''}
                                    /></Tooltip>
                                </div>
                            </PortletContent>
                        </Portlet>
                        <div>
                            <div style={{ position: 'relative' }}>
                                { isLoading && (
                                    <CircularProgress size={24} style={{position: 'absolute',marginLeft: -12,marginTop: -12,top: '50%',left: '50%'}}/>
                                )}
                                <Button color="primary" disabled={!submitError} fullWidth type="submit"
                                    onClick={this.addRouterSubmit} style={{marginTop:'10px'}} variant="contained"
                                >
                                    新增
                                </Button>
                                <Button className={classes.submit} color="primary" fullWidth type="submit" 
                                    onClick={this.handleStart} style={{marginTop:'10px'}} variant="outlined"
                                >
                                    下一步
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div hidden={ activestep !== 4}> <br /> <br /> <br />
                        <Typography style={{ textAlign: 'center' }} variant="h2" >
                            恭喜您完成了新增单位的操作！！
                        </Typography>
                        <br />
                        <Typography variant="h3">
                            叮咚签到欢迎{ cur_cpy.CompanyName}(单位号: <span style={{ color: 'blue' }}> { cur_cpy.CompanySN} </span> )的入驻
                        </Typography>
                        <br />
                        <Typography variant="h3">
                            您可以通知员工下载叮咚签到的员工端APP，员工完成登录后就可以
                        </Typography>
                        <br />
                        <Typography variant="h3">
                            使用叮咚签到进行考勤签到了。
                        </Typography>
                        <br />
                        <Typography variant="h3">
                            员工使用姓名+电话+单位号( <span style={{ color: 'blue' }}> { cur_cpy.CompanySN} </span> )进行登录。
                        </Typography>
                        <br />
                        {/* <Typography
                            style={{ textAlign: 'right' }}
                            variant="subtitle1"
                        >
                            qq通知
                        </Typography> */}
                        {/* <Share
                                url="http://www.ddqiandao.com"
                                title={this.state.share_str}
                                sites={['email']}
                            >
                                <a key="email" href={this.state.mail_str}></a>
                            </Share> */}
                        <div style={{ float: 'right' }}>
                            <div style={{ width: '140px', marginLeft: '5px' }}>
                                <EmailShareButton subject="叮咚考勤注册通知" style={{ width: '32px', height: '32px', display: 'inline-block' }}
                                    url="https://www.ddqiandao.com" body="员工登录时，需正确填写员工姓名、手机号和单位号信息，以免造成无法签到或签到数据有误。员工端APP可通过各大手机下载市场进行下载安装。"
                                >
                                    <EmailIcon size={32} iconBgStyle={{ fill: '#56b6e7' }} /* logoFillColor="blue"  */ round />
                                    
                                </EmailShareButton>

                                <Typography variant="subtitle1" style={{ float: 'right', marginTop: '5px' }} >
                                    邮件通知员工
                                </Typography>
                            </div>
                            <div style={{ width: '140px' }}>
                                <Share url="https://www.ddqiandao.com" title={ share_str} sites={['qq']} />
                                <Typography variant="subtitle1" style={{ float: 'right', marginTop: '-35px', marginRight: '2px' }} >
                                    QQ通知员工
                                </Typography>
                            </div>
                        </div>
                        <Button className={classes.submit} color="primary" fullWidth type="submit"
                            onClick={this.handleStart} style={{ marginTop: '50px' }} variant="outlined"
                        >
                            进入管理
                        </Button>
                    </div>
                    </LoadingOverlay>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles)(Index);
