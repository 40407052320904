import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomizedExpansionPanels from './CustomizedExpansionPanels';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden'
    },
    container: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(30),
        display: 'flex',
        position: 'relative'
    }
});

function ProductValues(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item md={12} xs={12}>
                        <CustomizedExpansionPanels />
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

ProductValues.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductValues);
