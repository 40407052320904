export default theme => ({
    root: {},
    form: {},
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    field: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    label: {
        backgroundColor: 'white'
    }
});
