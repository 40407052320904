import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';

import { TextField, FormControl, Typography, Checkbox,Radio,RadioGroup, FormControlLabel, FormLabel, Tooltip, Grid, Popover, Button, Divider, CircularProgress } from '@material-ui/core';
import { SnackbarsMessage, Portlet, PortletHeader, PortletLabel, PortletContent, //TimeSelect
    TimeSelect00,TimeSelect1 
} from 'components';

import styles from './styles';
import classNames from 'classnames';

const roles=[{id:0, name:'普通员工', desp:'普通员工只能登录员工端APP'},
    {id:1, name:'部门管理员', desp:'部门管理员可以登录网页或者管理端APP查看部门数据'},
    {id:2, name:'单位管理员', desp:'单位管理员可以登录网页或者管理端APP查看单位数据'}
]

class Index extends React.Component {
    state = {
        snackbarsMsg: '',
        snackbarsVisible: false,
    };    
    
    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };

    render() {
        const { classes, className, form_value, handlefieldchange, cpy_group, changenum, errors, nameerror, 
            teleerror, accountinfo//handlecheck, timevalid, num
        } = this.props;

        const rootClassName = classNames(classes.root, className);
        const { snackbarsMsg, snackbarsVisible } = this.state;

        return (
            <Portlet>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <PortletContent>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Tooltip title='员工端登录时需要使用员工姓名和手机号码'>
                                <Typography component='div' variant="body1">
                                    <TextField margin="dense" label="姓名" name="name"  variant="outlined" required
                                        style={{width:'100%'}} value={form_value.name} placeholder='请输入员工姓名'
                                        onFocus={event => handlefieldchange( event.target.value, 'name' ) }
                                        onChange={event => handlefieldchange( event.target.value, 'name' ) }
                                        error={nameerror} 
                                        helperText={nameerror?errors.name[0].replace('Name', ''):''}
                                    />
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                            <Tooltip title={roles[form_value.role].desp}>
                                <Typography component='div' variant="body1">
                                    <TextField margin="dense" label="角色" name="position"  variant="outlined" required
                                        style={{width:'100%'}} disabled={form_value.status=='M'?true:false}
                                        onChange={event => handlefieldchange( parseInt(event.target.value), 'role' ) }
                                        value={form_value.role} 
                                        select SelectProps={{ native: true }}
                                    >
                                        {roles.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                        ))}
                                    </TextField>
                                </Typography>
                            </Tooltip>
                        </Grid>
                        
                        {form_value.role === 0 ? null:(
                            <Grid item xs={4}>
                                <Tooltip title='部门管理员或单位管理员登录时需要使用的登录密码'>
                                    <Typography component='div' variant="body1">
                                        <TextField margin="dense" label="管理员密码" name="managepass"  variant="outlined" required type='password'
                                        disabled={form_value.status=='M'?true:false} placeholder='请输入密码'
                                        onChange={event => handlefieldchange( event.target.value, 'managepass' ) }
                                        value={form_value.managepass}
                                        />
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                    <Divider style={{marginTop:10, marginBottom:10}} />

                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Tooltip title='员工端登录时需要使用员工姓名和手机号码'>
                                <Typography component='div' variant="body1">
                                    <TextField margin="dense" label="手机号" name="telephone"  variant="outlined" required
                                        style={{width:'100%'}} value={form_value.telephone} placeholder='请输入员工手机号码'
                                        onFocus={event => handlefieldchange( event.target.value, 'telephone' ) }
                                        onChange={event => handlefieldchange( event.target.value, 'telephone' ) }
                                        error={teleerror} 
                                        helperText={teleerror?errors.telephone[0].replace('Telephone', ''):''}
                                    />
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                            <Tooltip title='选择员工所在的部门'>
                                <Typography component='div' variant="body1">
                                    <TextField required label="部门" margin="dense" variant="outlined" select 
                                        SelectProps={{ native: true }} style={{width:'100%'}} value={form_value.department}
                                        onChange={ event => handlefieldchange(event.target.value, 'department')}
                                    >
                                        {cpy_group.map(option => (
                                        <option
                                            key={option.GroupID}
                                            value={parseInt(option.GroupID)}
                                        >
                                            {option.GroupName}
                                        </option>
                                        ))}
                                    </TextField>
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component='div' variant="body1">
                                <TextField label="工号" margin="dense" variant="outlined"
                                    value={form_value.sn} style={{width:'100%'}} placeholder='请输入工号'
                                    onChange={event => handlefieldchange( event.target.value, 'sn' ) }
                                />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Tooltip title='选中则可以在员工端通讯录中显示手机号码，未选中则不显示。'>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{fontSize:10}}>是否显示号码</FormLabel>
                                    <FormControlLabel label="在员工端通讯录显示手机号码" 
                                        control={
                                            <Checkbox checked={form_value.isphone==1} color="primary" 
                                                inputProps={{ 'aria-label': 'primary checkbox', }}
                                                onChange={event => handlefieldchange(event.target.checked?1:0, 'isphone') }
                                            />
                                        }                                        
                                    />
                                </FormControl>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Divider style={{marginTop:10,marginBottom:20}} />
                    <div className={classes.field}>
                        {form_value.mode==0?
                            <TimeSelect00
                                changenum={changenum}
                                form_value={form_value}
                                handlefieldchange={handlefieldchange}
                                accountinfo={accountinfo}
                            />:
                            <TimeSelect1
                                changenum={changenum}
                                form_value={form_value}
                                handlefieldchange={handlefieldchange}
                                accountinfo={accountinfo}
                            />}
                        </div>
                </PortletContent>
            </Portlet>
        );
    }
}
Index.propTypes = {
    classes: PropTypes.object.isRequired,
    cpy_group: PropTypes.array.isRequired,
    form_value: PropTypes.object.isRequired,
    handlefieldchange: PropTypes.func.isRequired,
    accountinfo: PropTypes.object.isRequired
    // num: PropTypes.number.isRequired
};
export default withStyles(styles)(Index);
