
import React from 'react';

export default class TiandituMap extends React.Component {

    constructor(args) {
        super(args);
    }

    /**
     * 设置默认的props属性
     */
    static get defaultProps() {
        return {
            style: {
                height: '400px'
            }
        }
    }

    componentDidMount() {
        this.initTiandituMap();
    }

    initTiandituMap() {

        // 创建Map实例
        var map = new window.T.Map(this.refs.map)

        this.map = map;
        let center=this.props.center;     
        let maptype=this.props.mapType;
        let zoom = this.props.zoom;
        let text=this.props.title+(this.props.text.length>0?'<br>'+this.props.text:'')

        let wgs=(maptype==0?this.mars2earth(this.bd2mars(center)):(maptype==1?center:this.mars2earth(center)))

        let point=new window.T.LngLat(wgs.lng,wgs.lat)

        map.centerAndZoom(point, this.props.zoom);

        var icon = new window.T.Icon({
                iconUrl: "https://h5.gdong.net/dingdong/img/point.png",
                iconSize: new window.T.Point(25, 41),
                iconAnchor: new window.T.Point(13, 40)
            });

        var marker = new window.T.Marker(point,{icon:icon});
            
        map.addOverLay(marker);

        var label = new window.T.Label({
                text: text,
                position: point,
                offset: new window.T.Point(0, 0)
            });
        var isShow=1;
        map.addOverLay(label);
        addMarkerClick();
        addLabelClick();

        function addLabelClick() {
            //移除标注的点击事件，防止多次注册
            label.removeEventListener("click", showHideLabel);
            //注册标注的点击事件
            label.addEventListener("click", showHideLabel);
        } 

        function addMarkerClick() {
            //移除标注的点击事件，防止多次注册
             marker.removeEventListener("click", showHideLabel);
            //注册标注的点击事件
            marker.addEventListener("click", showHideLabel);
        } 

        function showHideLabel(e) {
            if(isShow===1){
                isShow=0;
                label.hide();
                return
            }
            if(isShow===0){
                isShow=1;
                label.show();
                return
            }

        }

    }



    bd2mars=(baidu_point)=>{   
        let PI = 3.14159265358979324;
        let xpi=PI*3000.0/180.0;     
        let x=baidu_point.lng-0.0065;
        let y=baidu_point.lat-0.006;
        let z=Math.sqrt(x*x+y*y)- 0.00002 * Math.sin(y * xpi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xpi);
        let mlng=z * Math.cos(theta);
        let mlat=z * Math.sin(theta);
        return {
            lat: mlat,
            lng: mlng
        }
    }

    mars2earth=(mars_point)=>{
        let d = this.delta(mars_point.lat, mars_point.lng)
        return {
            lat: mars_point.lat - d.lat,
            lng: mars_point.lng - d.lon
        }
    }

    mars2bd=(point)=>{
        let x_pi=3.14159265358979324 * 3000.0 / 180.0;
        let bd_point = {lng:'',lat:''};
        let x= point.lng;
        let y= point.lat;
        let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
        let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
        bd_point.lng = z * Math.cos(theta) + 0.0065;
        bd_point.lat = z * Math.sin(theta) + 0.006;
        return bd_point;
    }

    earth2bd=(point)=>{
        let pi = 3.14159265358979324;
        let a = 6378245.0;
        let ee = 0.00669342162296594323;
        let mars_point={lat:'',lng:''}
        let dLat = this.transformLat(point.lng - 105.0, point.lat - 35.0);
        let dLng = this.transformLng(point.lng - 105.0, point.lat - 35.0);   
        let radLat = point.lat / 180.0 * pi;
        let magic = Math.sin(radLat);
        magic = 1 - ee* magic * magic;
        let sqrtMagic = Math.sqrt(magic);
        mars_point.lat = point.lat + (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
        mars_point.lng = point.lng + (dLng * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
        return this.mars2bd(mars_point)
    }

    delta=(lat, lon)=>{    
        let PI = 3.14159265358979324;
        let a = 6378245.0 
        let ee = 0.00669342162296594323 
        let dLat = this.transformLat(lon - 105.0, lat - 35.0)
        let dLon = this.transformLng(lon - 105.0, lat - 35.0)
        let radLat = lat / 180.0 * PI
        let magic = Math.sin(radLat)
        magic = 1 - ee * magic * magic
        let sqrtMagic = Math.sqrt(magic)
        dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * PI)
        dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * PI)
        return {
            'lat': dLat,
            'lon': dLon
        }
    }

    transformLng=(x,y)=>{
        let PI = 3.14159265358979324;
        let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(x * PI) + 40.0 * Math.sin(x / 3.0 * PI)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(x / 12.0 * PI) + 300.0 * Math.sin(x / 30.0 * PI)) * 2.0 / 3.0;
        return ret;
    }

    transformLat=(x,y)=>{
        let PI = 3.14159265358979324;
        let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(y * PI) + 40.0 * Math.sin(y / 3.0 * PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(y / 12.0 * PI) + 320 * Math.sin(y * PI / 30.0)) * 2.0 / 3.0;
        return ret;
    }


    render() {
        var style = {
            height: '100%',
            position: 'relative'
        };
        for (var key in this.props.style) {
            style[key] = this.props.style[key];
        }
        return (
            <div style={style} key={this.props.keys}>
                <div ref='map' className={this.props.className} style={{height: '100%'}}>
                 加载地图中...
                </div>
            </div>
        );
    }
}
