import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import BannerImage from 'static/onepirate/banner.jpg';
const backgroundImage = BannerImage;

const styles = theme => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center'
    },
    button: {
        minWidth: 200
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10)
        }
    },
    more: {
        marginTop: theme.spacing(2)
    }
});

function ProductHero(props) {
    const { classes } = props;

    return (
        <ProductHeroLayout backgroundClassName={classes.background}>
            {/* Increase the network loading priority of the background image. */}
            <img style={{ display: 'none' }} src={backgroundImage} alt="" />
            <Typography
                align="center"
                color="inherit"
                // marked="center"
                variant="h4"
            >
                叮咚,签到啦！一个专注于考勤签到的手机应用
            </Typography>
            <Typography
                align="center"
                className={classes.h5}
                color="inherit"
                variant="h5"
            >
                叮咚签到,智能签到,快乐签到
            </Typography>
            {/* <Button
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                component="a"
                href="/sign-up"
            >
                注册
            </Button>
            <Typography
                variant="body2"
                color="inherit"
                className={classes.more}
            >
                叮咚签到，智能签到，快乐签到！
            </Typography> */}
        </ProductHeroLayout>
    );
}

ProductHero.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductHero);
