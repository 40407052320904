import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import Dashboard from './views/Dashboard';
import ProductList from './views/ProductList';
import UserList from './views/UserList';
import Typography from './views/Typography';
import Icons from './views/Icons';
import Account from './views/Account';
import Settings from './views/Settings';
import SignUp from './views/SignUp';
import SignIn from './views/SignIn';
// import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';
// import Pricing from './views/Pricing';
import RouterList from './views/RouterList';
import NewUser from './views/NewUser';
// import Report from './views/Report';
import HomePage from './views/onepirate/Home';
import FindPass from './views/FindPass';
import HelpPage from './views/onepirate/HelpPage';
import UserCenter from './views/UserCenter';
import AliPayOK from './views/AliPayOK';
import QA from './views/onepirate/QA';
import Http from 'services/http';
import history from 'history.js';
export default class Routes extends Component {
    componentDidMount() {
        history.listen(() => {
            try {
                Http.log({ txt: history.location.hash }, true);
            } catch (err) {
                console.log(err);
            }
        });
    }
    render() {
        return (
            <Switch>
                <Redirect exact from="/" to="/homepage" />
                <Route component={HelpPage} exact path="/help" />
                <Route component={QA} exact path="/qapage" />
                <Route component={SignUp} exact path="/sign-up" />
                <Route component={SignIn} exact path="/sign-in" />
                <Route component={FindPass} exact path="/pass" />
                <Route component={Dashboard} exact path="/dashboard" />
                <Route component={HomePage} exact path="/homepage" />
                <Route component={NewUser} exact path="/newuser" />
                <Route component={UserList} exact path="/signdata" />
                <Route component={ProductList} exact path="/users" />
                <Route component={RouterList} exact path="/routers" />
                <Route component={Typography} exact path="/typography" />
                <Route component={Icons} exact path="/icons" />
                <Route component={Account} exact path="/account" />
                <Route component={Settings} exact path="/settings" />
                <Route component={UserCenter} exact path="/center" />
                {/* <Route component={Report} exact path="/reports" /> */}
                {/* <Route
                    component={UnderDevelopment}
                    exact
                    path="/under-development"
                /> */}
                {/* <Route component={Pricing} exact path="/pricing" /> */}
                <Route component={NotFound} exact path="/not-found" />
                <Route component={AliPayOK} exact path="/payok" />
                <Redirect to="/not-found" />
            </Switch>
        );
    }
}
