import React, { Component } from 'react';
import Http from 'services/http';
import CircularProgress from '@material-ui/core/CircularProgress';
// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletToolbar,
    PortletContent,
    PortletFooter
} from 'components';

// Chart configuration
import { options } from './chart';

// Component styles
import styles from './styles';
let def_line_data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: '签到率',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};
class SalesChart extends Component {
    state = { data: {}, loading: false };
    async componentDidMount() {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        this.setState({
            loading: true
        });
        try {
            const res = await Http.comerateweek({
                companysn: cur_cpy.CompanySN
            });
            const { errcode, data } = res;
            let labels = [];
            let week_data = [];
            if (errcode === 0) {
                data.forEach(doc => {
                    labels.push(doc.day);
                });
                data.forEach(doc => {
                    week_data.push(doc.rate);
                });
                def_line_data.datasets[0].data = week_data;
                def_line_data = { ...def_line_data, labels };
                this.setState({
                    data: def_line_data,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                console.log(res.errmsg);
            }
        } catch (err) {
            this.setState({
                loading: false
            });
            console.log(err);
        }
    }
    render() {
        const { classes, className, ...rest } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <Portlet className={rootClassName}>
                <PortletHeader noDivider>
                    <PortletLabel title="签到时间分布" />
                    <PortletToolbar>
                        <Button
                            className={classes.dropdownButton}
                            size="small"
                            variant="text"
                        >
                            本周 <ArrowDropDownIcon />
                        </Button>
                    </PortletToolbar>
                </PortletHeader>
                <PortletContent>
                    <div className={classes.chartWrapper}>
                        {this.state.loading ? (
                            <CircularProgress className={classes.progress} />
                        ) : (
                            <Line data={this.state.data} options={options} />
                        )}
                    </div>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    <Button color="primary" size="small" variant="text">
                        导出(会员) <ArrowRightIcon />
                    </Button>
                </PortletFooter>
            </Portlet>
        );
    }
}

SalesChart.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SalesChart);
