import React, { Component, Fragment, createRef, useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, styled } from '@material-ui/styles';
import Http from 'services/http';

import { Button, DialogTitle, DialogContent, DialogActions, Dialog, RadioGroup, Radio, FormControlLabel, Chip,Backdrop,CircularProgress, Skeleton,
    Box, Toolbar, IconButton, AppBar, Typography, Grid, Paper, Card, CardMedia, CardActionArea, CardContent, CardHeader, Divider, Avatar, LinearProgress } from '@material-ui/core';

import{SnackbarsMessage} from 'components'

import { PermIdentity as UserIcon, Clear } from '@material-ui/icons';
import vipImg from 'static/img/vip.png'
import nonVipImg from 'static/img/nonvip.png'
import styles from './styles';
import bgImg from 'static/img/bg1.png'
import dftLogo from 'static/img/defaultlogo.png'
import top4Img from 'static/img/t4.png'
import zfbImg from 'static/img/zhifubao.png'
import wxImg from 'static/img/weixin.png'
import { forEach, zip } from 'lodash';

const zfbPay=7, wxPay=8

  
class DialogPaper extends Component {
  state={
    step:this.props.step,
    accountInfo:this.props.paperValue.accountInfo,
    loading:false, 
    selectedPay:-1, 
    selectedIndex:-1,
    qrcode:'',
    qrcodekey:0,
    payresult:0,
    orderInfo:{pid:0,price:0,payment:7},
    cur_user:null,
    orderidinfo:'',
    snackbarsMsg:'',
    snackbarsVisible:false
  };

  getOrderPayInfo= async (pid, payment)=>{
    try{
      const cur_user=JSON.parse(localStorage.getItem('cur_user'))
      if(!this.state.cur_user){
        this.setState({cur_user:cur_user})      
      }
      const {productInfo}=this.props.paperValue
      
      let index
      for(index=0;index<productInfo.productList.length;index++){
        if(pid===productInfo.productList[index].pid){break;}
      }
      let oi=this.state.orderInfo
      oi.pid=productInfo.productList[index].pid
      oi.price=productInfo.productList[index].newPrice
      oi.payment=payment
      this.setState({orderInfo:oi,selectedPay:payment, selectedIndex:index })

      for(let i=0;i<3;i++){
        const { data, errcode, errmsg } = await Http.getOrderInfo({managerid:cur_user.UserID,pid:oi.pid, price:oi.price, payment:oi.payment});
        if (errcode === 0) {
          // console.log(data)
          const {orderidinfo, qrcodekey,payresult}=this.state

          this.setState({step:1, loading:false, qrcodekey:qrcodekey+1})

          if(!orderidinfo.includes(data.OrderId)){
            this.setState({orderidinfo:orderidinfo+data.OrderId+','})
          }
          if(payment==zfbPay){
            // setTimeout(() => {
              let iframe=document.getElementById('zfb_iframe')
              let iframeDocument = iframe.contentWindow.document
              iframeDocument.write(data.Str)
            // }, 0);
          }else{
            // setTimeout(() => {
              this.setState({qrcode:data.Str})
            // }, 0);
          }
          if(payresult==0){
            this.getPayResultTimeout();
          }
          break;
        } else {
          if(i<2){
            continue
          }else{
            this.showMsg(errmsg)
            const {step, orderInfo}=this.state
            this.setState({selectedPay:orderInfo.payment, selectedIndex:index, step:step, loading:false, orderInfo:orderInfo})
          }
        }
      }
    }catch (error) {
      console.log(error)
      this.setState({
          loading: true
      });
    }
  }

  getPayResult=async(value)=>{
    const {payresult, cur_user}=this.state
    // console.log(orderidinfo)
    if(payresult==1){
      try{
        const { data, errcode, errmsg } = await Http.getPayResult({managerid:cur_user.UserID,orderidinfo:value});
        if (errcode === 0) {
          const {orderidinfo, cur_user}=this.state
          if(data==1){
            if(orderidinfo.length>0){
              this.setState({orderidinfo:''})
            }
            this.setState({payresult:2,step:2})
            // setTimeout(() => {
              this.refreshAccountInfo(cur_user.UserID);
            // }, 0);
          }else{
            setTimeout(() => {
              this.getPayResult(this.state.orderidinfo)
            }, 2000);
          }
        }else{
          setTimeout(() => {
            this.getPayResult(this.state.orderidinfo)
          }, 2000);
        }
      }catch (error) {
        setTimeout(() => {
          this.getPayResult(this.state.orderidinfo)
        }, 2000);
      }
    }
  }

  getPayResultTimeout=()=>{
    this.setState({payresult:1})
    setTimeout(()=>{this.getPayResult(this.state.orderidinfo)}, 2000);
  }
  
  handleClose=(value)=>{
    this.setState({step:0, loading:false, selectedPay:-1, selectedIndex:-1,qrcode:'',qrcodekey:0,payresult:0,orderidinfo:''})
    if(value){
      this.props.onClose({accountInfo:this.state.accountInfo,...value});
    }else{
        this.props.onClose({accountInfo:this.state.accountInfo});
    }
  }

  handleJump=()=>{
    const route={tabs:0, conditon:0, id:0}
    localStorage.setItem( 'uc_route', JSON.stringify(route) );
    this.handleClose({history:'/center'});
  }

  refreshAccountInfo= async (info)=>{
      const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
      if (errcode == 0) {
        this.setState({accountInfo:data});
      }else{
        this.showMsg(errmsg)
      }
  }
    
  showMsg = msg => {
      this.setState({
          snackbarsMsg: msg,
          snackbarsVisible: true
      });
      setTimeout(() => {
          this.setState({
              snackbarsMsg: '',
              snackbarsVisible: false
          });
      }, 2000);
  }

    
  render(){
    const {productInfo}=this.props.paperValue
    const { snackbarsMsg, snackbarsVisible, selectedIndex, selectedPay, 
      qrcodekey, qrcode, loading, step, accountInfo 
    }=this.state

    const handleStep = () => {
      this.setState({loading:true})
      this.getOrderPayInfo((selectedIndex==-1?productInfo.dftSelect:productInfo.productList[selectedIndex].pid),(selectedPay==-1?productInfo.dftPay:selectedPay))
    };
  
    const handleCardSelect = (event, index) => {
      if(event.currentTarget.id!==selectedPay){
        let iframe=document.getElementById('zfb_iframe')
        iframe.src='about:blank'
        this.setState({selectedIndex:event.currentTarget.id,loading:true,qrcodekey:qrcodekey+1})
        this.getOrderPayInfo((selectedIndex==-1?productInfo.dftSelect:productInfo.productList[event.currentTarget.id].pid),(selectedPay==-1?productInfo.dftPay:selectedPay))
      }
    };
  
    const handlePaymentSelect = (event, index) => {
      if(event.currentTarget.id!==selectedPay){
        let iframe=document.getElementById('zfb_iframe')
        iframe.src='about:blank'
        this.setState({selectedPay:event.currentTarget.id,loading:true,qrcodekey:qrcodekey+1})
        this.getOrderPayInfo((selectedIndex==-1?productInfo.dftSelect:productInfo.productList[selectedIndex].pid),(selectedPay==-1?productInfo.dftPay:event.currentTarget.id))        
      }
    };

    const FreeCardHeader = styled(CardHeader)({
      '& .MuiTypography-h5': { color: '#222222', fontWeight: 700}
    });
    const VipCardHeader = styled(CardHeader)({
      '& .MuiTypography-h5': { color: 'white', fontWeight: 700}
    });

    return (
      <Paper style={{background:'transparent', height:'100%'}}>
        <SnackbarsMessage
            msg={snackbarsMsg}
            visible={snackbarsVisible}
        />
        <DialogActions style={{borderRadius: 18, padding:'0px 20px', height:'15%'}}>
          <Box style={{width:'100%'}}>
            <Grid container spacing={1} >
              <Grid item xs={1} style={{textAlign:'-webkit-right'}}>
                <Avatar src={(accountInfo.isVip && accountInfo.logo.length>1)?accountInfo.logo:dftLogo} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" component="div" style={{ color: 'white' }}>
                  {accountInfo.name}
                </Typography>
                <img src={(accountInfo.isVip?vipImg:nonVipImg)} style={{maxHeight:12}}/> 
                <Typography variant="body2" component="span" style={{ paddingLeft:10, color: 'white' }}>
                  {(accountInfo.isVip?'会员截止期：'+accountInfo.validDate.substring(0,10):'会员未开通' )}
                </Typography>                      
              </Grid>          
            </Grid>
          </Box>      
          <IconButton size='small' onClick={this.handleClose}><Clear fontSize='small' style={{ color: 'white' }}/></IconButton>
        </DialogActions>
        <DialogContent style={{padding:0, height:'85%'}}>
          <Paper style={{borderRadius:"0px 106px 18px 18px", background:'#F7F7F7', width:'100%', height:'100%'}}>
            <Box spacing={2} style={{padding:20}} >
              <Typography variant="h6" component="div" style={{fontWeight:700}}>
                叮咚签到·企业会员
              </Typography>
            </Box>
            <Box style={{padding:'0px 25px 0px 25px'}}>
              {(step===0 && <Grid container>
                <Grid item xs={6}>
                  <Card style={{borderRadius:'12px 0px 0px 12px'}}>
                    <CardActionArea disabled>                                    
                    <FreeCardHeader title='免费体验版' style={{textAlign:'center', background:'#F4F4F4'}}/>   
                    <Divider/>                     
                    <CardContent>
                      <Grid container >
                        <Grid item xs={12} style={{padding:20}}>
                          <Box style={{width:'100%', height:35}}>
                            <Typography gutterBottom variant="h4" align='center' style={{color:'#999999',fontWeight:700}} >
                              免费
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12} style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}} >
                            签到单位总数：最多2个
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12} style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}} >
                          签到总人数：最多5个
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}} >
                          签到设备总数：最多2个
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}}  >
                            ----
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                      </Grid>
                    </CardContent>
                    </CardActionArea>               
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card style={{borderRadius:'0px 12px 12px 0px'}}>
                    <CardActionArea onClick={handleStep}>
                    <VipCardHeader title='企业VIP' style={{textAlign:'center', background:'#F57852', color:"#FFFFFF"}}/>   
                    <Divider/>                
                    <CardContent style={{background:'#FDF2EF'}}>
                      <Grid container >
                        <Grid item xs={12} style={{padding:'10px 20px 30px 20px'}}>
                          <Box style={{width:'100%', height:35, textAlign:'center'}}>
                            {/* <Typography variant="h4" align='center' style={{color:'#ED5426',fontWeight:900}} >
                              ￥600
                                <Typography component='span' variant="body1" align='center' style={{color:'#ED5426'}} >
                                /年
                              </Typography>
                            </Typography> */}
                            <Chip size="medium" label='立刻开通' style={{color:'white',padding:'3px 30px 2px 30px',background:'#ED5426', borderRadius:15}}/>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12} style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#222222',fontWeight:900}} >
                            签到单位总数：最多5个
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12} style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#222222',fontWeight:900}} >
                          签到总人数：最多100个
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#222222',fontWeight:900}} >
                          签到设备总数：最多50个
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}style={{padding:20}}>
                          <Typography gutterBottom variant="body2" align='center' style={{color:'#F57852',fontWeight:900}}  >
                            -更多特权，敬请期待-
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                      </Grid>
                    </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>)}
              {(step===1 && 
              <Grid container spacing={2}>
                {productInfo.productList.map((product, index)=>(
                  <Grid item xs={4} style={{padding:'10px 10px'}} key={index} > 
                    <Box style={{padding:3, borderRadius:13, background:(selectedIndex==-1)?'transparent':(index==selectedIndex?'#F57853':'transparent') }}>
                          {/* (this.state.selectedIndex==-1)?(this.props.paperValue.dftSelect==product.pid?'#F57853':'transparent'):(index==this.state.selectedIndex?'#F57853':'transparent') }}> */}
                      <Card style={{borderRadius:10, background:(selectedIndex==-1)?'transparent':(index==selectedIndex?'#FDF9F8':'transparent') }}>
                          {/* (this.state.selectedIndex==-1)?(this.props.paperValue.dftSelect==product.pid?'#FDF9F8':'#FFFFFF'):(index==this.state.selectedIndex?'#FDF9F8':'#FFFFFF')}}> */}
                        <CardActionArea id={index} onClick={handleCardSelect}>
                          <CardContent style={{padding:0}}>
                            <Typography variant="body2" component="div" style={{height:25, width:95, color:'white', 
                              backgroundImage:(product.promotionString.length>1?`url(${top4Img})`:''), 
                              backgroundSize: (product.promotionString.length>1?"contain":""), 
                              backgroundPosition:(product.promotionString.length>1? "left top":""), 
                              backgroundRepeat: (product.promotionString.length>1?"no-repeat":''), textAlign:'center', paddingTop:5}}>
                              {product.promotionString}
                            </Typography>
                            <Typography variant="h6" component="div" style={{padding:'15px 0px 10px 20px',color:'#222222',fontWeight:700}}>
                              {product.name}
                            </Typography>
                            <Typography variant="h6" component="div" style={{paddingLeft:20,color:'#D63445',fontWeight:700}}>
                              ￥
                              <Typography variant="h4" component="span" style={{color:'#D63445',fontWeight:700}}>
                                {product.newPrice}
                              </Typography>                                
                              <Typography variant="body2" component="span" style={{paddingLeft:10,color:'#999999', textDecoration:'line-through'}}>
                                ￥{product.oldPrice}
                              </Typography>
                            </Typography>
                            {<Chip label={product.voucherString} variant='outlined' style={{margin:20, color:'#F7B4A0', borderRadius:2, borderColor:(product.voucherString.length>1?'#F7B4A0':(selectedIndex==-1?'#FFFFFF':(index==selectedIndex?'#FDF9F8':'#FFFFFF')))}}/>}
                            {/* (product.promotionString.length>1?'#F7B4A0':(this.state.selectedIndex==-1?(product.pid==this.props.paperValue.dftSelect?'#FDF9F8':'#FFFFFF'):index==this.state.selectedIndex?'#FDF9F8':'#FFFFFF'))}}/>} */}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={12} style={{padding:'10px 30px 10px 30px'}}>
                  <Box style={{background:'white'}}>
                    <Card>
                      <CardContent  style={{display: 'flex'}}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                      
                          <Typography component="div">
                            <img src={dftLogo} hidden={!loading} width={130} height={130}/>
                            <iframe id='zfb_iframe' frameBorder={0} src='about:blank' scrolling='no' width={130} height={130} hidden={loading || selectedPay!=zfbPay}/>
                            <QRCode imageSettings={{src:'/images/weixin.png',height:30,width:30,excavate:true}} size={130} value={qrcode} key={qrcodekey} fgColor='#000000' hidden={loading || selectedPay!=8} width={130} height={130}/>
                          </Typography>
                          <Paper elevation={(0)} style={{textAlign:'center',lineHeight:'20px'}}>
                            <img src={((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?zfbImg:wxImg} style={{width: 20, height:20, verticalAlign:'middle'}}/>
                            <Typography component="span" variant="body2" style={{verticalAlign:'middle', color:'#999999'}}>&nbsp;&nbsp;{(selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay)?'支付宝扫码支付':'微信扫码支付'}</Typography>
                          </Paper>
                        </Box> 
                        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                          <Typography component="div" variant="h6" style={{margin:'0px 0px 10px 30px', color:'#222222', fontWeight:700}}>
                            支付方式
                          </Typography>
                          <Box style={{display: 'flex', flexWrap: 'wrap', margin:'0px 0px 10px 30px',}}>
                            <Chip id={zfbPay} avatar={<Avatar src={(zfbImg)} style={{width: 20, height:20}}/>} label='支付宝扫码支付' variant='outlined' onClick={handlePaymentSelect} style={{paddingLeft:10,marginRight:10, borderRadius:2, fontSize:'12px', 
                              color:((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?'#222222':'#999999',
                              borderColor:((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?'#F57853':'#999999', 
                              background:((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?'#FDF9F8':'#FFFFFF'}}/>
                            <Chip id={wxPay} avatar={<Avatar src={(wxImg)} style={{width: 20, height:20}}/>} label='微信扫码支付' variant='outlined' onClick={handlePaymentSelect} style={{paddingLeft:10,marginRight:10, borderRadius:2, fontSize:'12px', 
                              color:(selectedPay==-1 && productInfo.dftPay==wxPay)||(selectedPay==wxPay)?'#222222':'#999999',
                              borderColor:(selectedPay==-1 && productInfo.dftPay==wxPay)||(selectedPay==wxPay)?'#F57853':'#999999', 
                              background:(selectedPay==-1 && productInfo.dftPay==wxPay)||(selectedPay==wxPay)?'#FDF9F8':'#FFFFFF'}}/>
                          </Box>
                          <Box style={{margin:'30px 0px 10px 30px'}}>
                            <Typography component="span" variant="h6" style={{color:'#222222',fontWeight:700}}>
                              应付金额
                            </Typography>
                            <Typography variant="h6" component="span" style={{paddingLeft:20,color:'#D63445',fontWeight:700}}>
                              ￥
                              <Typography variant="h4" component="span" style={{color:'#D63445',fontWeight:700}}>
                                {productInfo.productList[selectedIndex==-1?0:selectedIndex].newPrice}
                              </Typography>
                            </Typography>
                          </Box>
                          <Typography component="div" variant="body2" style={{margin:'20px 0 10px 30px', color:'#999999'}}>
                            支付即代表您同意&nbsp;&nbsp;<a href='https://h5.gdong.net/dingdong/useragree.html' target='_blank'>《叮咚签到用户服务协议》</a>
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>)}
              {(step==2 && 
              <Grid container spacing={2}>
                <Grid item xs={12} style={{padding:'30px 30px 10px 30px'}}>
                  <Box spacing={4}>
                    <Paper elevation={(0)} style={{textAlign:'center',lineHeight:'20px', background:'transparent'}}>
                      <img src='/images/payok.png' style={{height:200, verticalAlign:'middle'}}/>
                      <Typography component="div" variant="h6" style={{padding:20, verticalAlign:'middle', fontWeight:700}}>支付成功！</Typography>
                      <Chip onClick={this.handleClose} size="medium" label='我知道了' style={{margin:10,color:'#222222',padding:'3px 30px 2px 30px',background:'#DEDEDE', borderRadius:15}}/>
                      <Chip onClick={this.handleJump} size="medium" label='查看订单' style={{margin:10,color:'white',padding:'3px 30px 2px 30px',background:'#ED5426', borderRadius:15}}/>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>)}
              <LinearProgress hidden={!loading} style={{marginTop:10}}/>
              <Backdrop style={{zIndex:10000}} open={loading} /*onClick={handleCloseLoading}*/>   
              </Backdrop>
            </Box>
          </Paper>
        </DialogContent >
      </Paper>



      // <Paper style={{borderRadius:"0px 106px 18px 18px", background:'#F7F7F7', width:'100%', height:'100%'}}>
      //   <Box spacing={2} style={{padding:20}} >
      //     <Typography variant="h6" component="div" style={{fontWeight:700}}>
      //       叮咚签到·企业会员
      //     </Typography>
      //   </Box>
      //   <Box style={{padding:'0px 25px 0px 25px'}}>
      //     {(step===0 && <Grid container>
      //       <Grid item xs={6}>
      //         <Card style={{borderRadius:'12px 0px 0px 12px'}}>
      //           <CardActionArea disabled>                                    
      //           <FreeCardHeader title='免费体验版' style={{textAlign:'center', background:'#F4F4F4'}}/>   
      //           <Divider/>                     
      //           <CardContent>
      //             <Grid container >
      //               <Grid item xs={12} style={{padding:20}}>
      //                 <Box style={{width:'100%', height:35}}>
      //                   <Typography gutterBottom variant="h4" align='center' style={{color:'#999999',fontWeight:700}} >
      //                     免费
      //                   </Typography>
      //                 </Box>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12} style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}} >
      //                   签到单位总数：最多2个
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12} style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}} >
      //                 签到总人数：最多5个
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12}style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}} >
      //                 签到设备总数：最多2个
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12}style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#666666'}}  >
      //                   ----
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //             </Grid>
      //           </CardContent>
      //           </CardActionArea>               
      //         </Card>
      //       </Grid>
      //       <Grid item xs={6}>
      //         <Card style={{borderRadius:'0px 12px 12px 0px'}}>
      //           <CardActionArea onClick={handleStep}>
      //           <VipCardHeader title='企业VIP' style={{textAlign:'center', background:'#F57852', color:"#FFFFFF"}}/>   
      //           <Divider/>                
      //           <CardContent style={{background:'#FDF2EF'}}>
      //             <Grid container >
      //               <Grid item xs={12} style={{padding:'10px 20px 30px 20px'}}>
      //                 <Box style={{width:'100%', height:35, textAlign:'center'}}>
      //                   <Typography variant="h4" align='center' style={{color:'#ED5426',fontWeight:900}} >
      //                     ￥600
      //                       <Typography component='span' variant="body1" align='center' style={{color:'#ED5426'}} >
      //                       /年
      //                     </Typography>
      //                   </Typography>
      //                   <Chip size="medium" label='立刻开通' style={{color:'white',padding:'3px 30px 2px 30px',background:'#ED5426', borderRadius:15}}/>
      //                 </Box>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12} style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#222222',fontWeight:900}} >
      //                   签到单位总数：最多5个
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12} style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#222222',fontWeight:900}} >
      //                 签到总人数：最多100个
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12}style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#222222',fontWeight:900}} >
      //                 签到设备总数：最多50个
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //               <Grid item xs={12}style={{padding:20}}>
      //                 <Typography gutterBottom variant="body2" align='center' style={{color:'#F57852',fontWeight:900}}  >
      //                   -更多特权，敬请期待-
      //                 </Typography>
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <Divider/>
      //               </Grid>
      //             </Grid>
      //           </CardContent>
      //           </CardActionArea>
      //         </Card>
      //       </Grid>
      //     </Grid>)}
      //     {(step===1 && 
      //     <Grid container spacing={2}>
      //       {productInfo.productList.map((product, index)=>(
      //         <Grid item xs={4} style={{padding:'10px 30px 10px 30px'}} key={index} > 
      //           <Box style={{padding:3, borderRadius:13, background:(selectedIndex==-1)?'transparent':(index==selectedIndex?'#F57853':'transparent') }}>
      //                 {/* (this.state.selectedIndex==-1)?(this.props.paperValue.dftSelect==product.pid?'#F57853':'transparent'):(index==this.state.selectedIndex?'#F57853':'transparent') }}> */}
      //             <Card style={{borderRadius:10, background:(selectedIndex==-1)?'transparent':(index==selectedIndex?'#FDF9F8':'transparent') }}>
      //                 {/* (this.state.selectedIndex==-1)?(this.props.paperValue.dftSelect==product.pid?'#FDF9F8':'#FFFFFF'):(index==this.state.selectedIndex?'#FDF9F8':'#FFFFFF')}}> */}
      //               <CardActionArea id={index} onClick={handleCardSelect}>
      //                 <CardContent style={{padding:0}}>
      //                   <Typography variant="body2" component="div" style={{height:25, width:95, color:'white', 
      //                     backgroundImage:(product.promotionString.length>1?`url(${top4Img})`:''), 
      //                     backgroundSize: (product.promotionString.length>1?"contain":""), 
      //                     backgroundPosition:(product.promotionString.length>1? "left top":""), 
      //                     backgroundRepeat: (product.promotionString.length>1?"no-repeat":''), textAlign:'center', paddingTop:5}}>
      //                     {product.promotionString}
      //                   </Typography>
      //                   <Typography variant="h6" component="div" style={{padding:'15px 0px 10px 20px',color:'#222222',fontWeight:700}}>
      //                     {product.name}
      //                   </Typography>
      //                   <Typography variant="h6" component="div" style={{paddingLeft:20,color:'#D63445',fontWeight:700}}>
      //                     ￥
      //                     <Typography variant="h4" component="span" style={{color:'#D63445',fontWeight:700}}>
      //                       {product.newPrice}
      //                     </Typography>                                
      //                     <Typography variant="body2" component="span" style={{paddingLeft:10,color:'#999999', textDecoration:'line-through'}}>
      //                       ￥{product.oldPrice}
      //                     </Typography>
      //                   </Typography>
      //                   {<Chip label={product.voucherString} variant='outlined' style={{margin:20, color:'#F7B4A0', borderRadius:2, borderColor:(product.voucherString.length>1?'#F7B4A0':(selectedIndex==-1?'#FFFFFF':(index==selectedIndex?'#FDF9F8':'#FFFFFF')))}}/>}
      //                   {/* (product.promotionString.length>1?'#F7B4A0':(this.state.selectedIndex==-1?(product.pid==this.props.paperValue.dftSelect?'#FDF9F8':'#FFFFFF'):index==this.state.selectedIndex?'#FDF9F8':'#FFFFFF'))}}/>} */}
      //                 </CardContent>
      //               </CardActionArea>
      //             </Card>
      //           </Box>
      //         </Grid>
      //       ))}
      //       <Grid item xs={12} style={{padding:'30px 30px 10px 30px'}}>
      //         <Box style={{background:'white'}}>
      //           <Card>
      //             <CardContent  style={{display: 'flex'}}>
      //               <Box sx={{ display: 'flex', flexDirection: 'column' }}>                      
      //                 <Typography component="div">
      //                   <img src={dftLogo} hidden={!loading} width={130} height={130}/>
      //                   <iframe id='zfb_iframe' frameBorder={0} src='about:blank' scrolling='no' width={130} height={130} hidden={loading || selectedPay!=zfbPay}/>
      //                   <QRCode imageSettings={{src:'/images/weixin.png',height:30,width:30,excavate:true}} size={130} value={qrcode} key={qrcodekey} fgColor='#000000' hidden={loading || selectedPay!=8} width={130} height={130}/>
      //                 </Typography>
      //                 <Paper elevation={(0)} style={{textAlign:'center',lineHeight:'20px'}}>
      //                   <img src={((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?zfbImg:wxImg} style={{width: 20, height:20, verticalAlign:'middle'}}/>
      //                   <Typography component="span" variant="body2" style={{verticalAlign:'middle', color:'#999999'}}>&nbsp;&nbsp;{(selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay)?'支付宝扫码支付':'微信扫码支付'}</Typography>
      //                 </Paper>
      //               </Box> 
      //               <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      //                   <Typography component="div" variant="h6" style={{margin:'0 0 10px 30px', color:'#222222', fontWeight:700}}>
      //                     支付方式
      //                   </Typography>
      //                   <Box style={{display: 'flex', flexWrap: 'wrap', margin:'0 0 10px 30px',}}>
      //                     <Chip id={zfbPay} avatar={<Avatar src={(zfbImg)} style={{width: 20, height:20}}/>} label='支付宝扫码支付' variant='outlined' onClick={handlePaymentSelect} style={{paddingLeft:10,marginRight:10, borderRadius:2, fontSize:'12px', 
      //                       color:((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?'#222222':'#999999',
      //                       borderColor:((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?'#F57853':'#999999', 
      //                       background:((selectedPay==-1 && productInfo.dftPay==zfbPay)||(selectedPay==zfbPay))?'#FDF9F8':'#FFFFFF'}}/>
      //                     <Chip id={wxPay} avatar={<Avatar src={(wxImg)} style={{width: 20, height:20}}/>} label='微信扫码支付' variant='outlined' onClick={handlePaymentSelect} style={{paddingLeft:10,marginRight:10, borderRadius:2, fontSize:'12px', 
      //                       color:(selectedPay==-1 && productInfo.dftPay==wxPay)||(selectedPay==wxPay)?'#222222':'#999999',
      //                       borderColor:(selectedPay==-1 && productInfo.dftPay==wxPay)||(selectedPay==wxPay)?'#F57853':'#999999', 
      //                       background:(selectedPay==-1 && productInfo.dftPay==wxPay)||(selectedPay==wxPay)?'#FDF9F8':'#FFFFFF'}}/>
      //                   </Box>
      //                   <Box style={{margin:'30px 0 10px 30px'}}>
      //                     <Typography component="span" variant="h6" style={{color:'#222222',fontWeight:700}}>
      //                       应付金额
      //                     </Typography>
      //                     <Typography variant="h6" component="span" style={{paddingLeft:20,color:'#D63445',fontWeight:700}}>
      //                       ￥
      //                       <Typography variant="h4" component="span" style={{color:'#D63445',fontWeight:700}}>
      //                         {productInfo.productList[selectedIndex==-1?0:selectedIndex].newPrice}
      //                       </Typography>
      //                     </Typography>
      //                   </Box>
      //                   <Typography component="div" variant="body2" style={{margin:'20px 0 10px 30px', color:'#999999'}}>
      //                     支付即代表您同意&nbsp;&nbsp;<a href='https://h5.gdong.net/dingdong/useragree.html' target='_blank'>《叮咚签到会员购买规则》</a>
      //                   </Typography>
      //               </Box>
      //             </CardContent>
      //           </Card>
      //         </Box>
      //       </Grid>
      //     </Grid>)}
      //     {(step==2 && 
      //     <Grid container spacing={2}>
      //       <Grid item xs={12} style={{padding:'30px 30px 10px 30px'}}>
      //         <Box spacing={4}>
      //           <Paper elevation={(0)} style={{textAlign:'center',lineHeight:'20px', background:'transparent'}}>
      //             <img src='/images/payok.png' style={{height:200, verticalAlign:'middle'}}/>
      //             <Typography component="div" variant="h6" style={{padding:20, verticalAlign:'middle', fontWeight:700}}>支付成功！</Typography>
      //             <Chip onClick={this.handleClose} size="medium" label='我知道了' style={{margin:10,color:'#222222',padding:'3px 30px 2px 30px',background:'#DEDEDE', borderRadius:15}}/>
      //             <Chip onClick={this.handleJump} size="medium" label='查看订单' style={{margin:10,color:'white',padding:'3px 30px 2px 30px',background:'#ED5426', borderRadius:15}}/>
      //           </Paper>
      //         </Box>
      //       </Grid>
      //     </Grid>)}
      //     <LinearProgress hidden={!loading} style={{marginTop:10}}/>
      //     <Backdrop style={{zIndex:10000}} open={loading} /*onClick={handleCloseLoading}*/>   
      //     </Backdrop>
      //   </Box>
      // </Paper>
    );
  }
}

DialogPaper.propTypes = {
  paperValue: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  // onRefresh: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(DialogPaper);
