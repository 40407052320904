import React, { Component } from 'react';
import Http from 'services/http';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletToolbar,
    PortletContent,
    PortletFooter
} from 'components';

// Chart configuration
import { data1, options } from './chart';

// Component styles
import styles from './styles';

class SalesChart extends Component {
    state = { data: {}, loading: false };
    async componentDidMount() {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        this.setState({
            loading: true
        });
        try {
            const res = await Http.cometime({
                companysn: cur_cpy.CompanySN,
                time: '2019-08-28'
            });
            const { errcode, data } = res;
            let come_list = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let off_list = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            if (errcode === 0) {
                data.come.forEach(doc => {
                    come_list[doc.tm - 7] = doc.num;
                });
                data.off.forEach(doc => {
                    off_list[doc.tm - 7] = doc.num;
                });
                data1.datasets[0].data = come_list;
                data1.datasets[1].data = off_list;
                this.setState({
                    data: data1,
                    loading: false
                });
                // console.log(newData);
            } else {
                this.setState({
                    loading: false
                });
                console.log(res.errmsg);
            }
        } catch (err) {
            this.setState({
                loading: false
            });
            console.log(err);
        }
    }
    render() {
        const { classes, className, ...rest } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <Portlet  className={rootClassName}>
                <PortletHeader noDivider>
                    <PortletLabel title="签到时间分布" />
                    <PortletToolbar>
                        <Button
                            className={classes.dropdownButton}
                            size="small"
                            variant="text"
                        >
                            本周 <ArrowDropDownIcon />
                        </Button>
                    </PortletToolbar>
                </PortletHeader>
                <PortletContent>
                    <div className={classes.chartWrapper}>
                        {this.state.loading ? (
                            <CircularProgress />
                        ) : (
                            <Bar data={this.state.data} options={options} />
                        )}
                    </div>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    <Button color="primary" size="small" variant="text">
                        导出(会员) <ArrowRightIcon />
                    </Button>
                </PortletFooter>
            </Portlet>
        );
    }
}

SalesChart.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SalesChart);
