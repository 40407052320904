export default {
    firmname: {
        presence: { allowEmpty: false, message: '请填写单位名称' }
    },
    address: {
        presence: { allowEmpty: false, message: '请填写单位地址' }
    },
    tel: {
        presence: { allowEmpty: false, message: '请填写电话号码' },
        format: {
            pattern: '[0-9]+',
            flags: 'i',
            message: '只能包含数字'
        }
    }
    
    // pname1: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname2: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname3: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // email: {
    //     presence: { allowEmpty: false, message: '请填写邮箱地址' },
    //     email: { message: '邮箱格式不正确' },
    //     length: {
    //         maximum: 64
    //     }
    // },
    // memo: {
    //     presence: { allowEmpty: false, message: '请选择所属行业' },
    //     exclusion: {
    //         within: ['0'],
    //         message: '请选择所属行业'
    //     }
    // }

    // title: {
    //     presence: { allowEmpty: false, message: '请填写路由器SSID' }
    // },
    // remark: {
    //     presence: { allowEmpty: false, message: '请填写路由器备注' }
    // }
};
