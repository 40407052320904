import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
// import Button from '@material-ui/core/Button';

import { Link as RouterLink } from 'react-router-dom';
import AppBar from '../components/AppBar';
//import history from 'history.js';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import Http from 'services/http';

const styles = theme => ({
    title: {
        fontSize: 24
    },
    placeholder: toolbarStyles(theme).root,
    toolbar: {
        justifyContent: 'space-between'
    },
    left: {
        flex: 1
    },
    leftLinkActive: {
        color: theme.palette.common.white
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    rightLink: {
        fontSize: 16,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3)
    },
    linkSecondary: {
        color: theme.palette.secondary.main
    }
});

function AppAppBar(props) {
    const { classes } = props;
    const [isLogin, setIsLogin] = useState(false);
    async function checkSession() {
        try {
            let resData = await Http.session_check();
            if (resData.errcode === 0) {
                setIsLogin(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function handleSignOut() {
        localStorage.setItem('is_auth', false);
        localStorage.clear();
        await Http.logout();
        const{history}=props; 
        history.push('/');//props.history.push('/');
    }

    useEffect(() => {
        checkSession();
    }, []);

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.left} />
                    <Link
                        className={classes.title}
                        color="inherit"
                        component={RouterLink}
                        to="/homepage"
                        underline="none"
                        variant="h6"
                    >
                        {'叮咚签到'}
                    </Link>
                    {isLogin ? (
                        <div className={classes.right}>
                            {/**<Button
                                style={{ color: 'white', display: 'none'}}
                                href="http://web.ddqiandao.com"
                            >
                                返回老版本
                            </Button>**/}

                            <Link
                                className={classes.rightLink}
                                color="inherit"
                                component={RouterLink}
                                to={JSON.parse(localStorage.getItem('cur_cpy'))? '/signdata': '/newuser'}
                                underline="none"
                                variant="h6"
                            >
                                {'进入管理'}
                            </Link>
                            <Link
                                className={clsx( classes.rightLink, classes.linkSecondary )}
                                component={RouterLink}
                                onClick={handleSignOut}
                                underline="none"
                                variant="h6"
                            >
                                退出登录
                            </Link>
                        </div>
                    ) : (
                        <div className={classes.right}>
                            <a href="http://web.ddqiandao.com" style={{ color: 'white', display: 'none'}} > 返回老版本 </a>
                            <Link
                                className={classes.rightLink}
                                color="inherit"
                                component={RouterLink}
                                to="/sign-in"
                                underline="none"
                                variant="h6"
                            >
                                {'登录'}
                            </Link>
                            <Link
                                className={clsx(
                                    classes.rightLink,
                                    classes.linkSecondary
                                )}
                                component={RouterLink}
                                to="/sign-up"
                                underline="none"
                                variant="h6"
                            >
                                {'注册'}
                            </Link>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <div className={classes.placeholder} />
        </div>
    );
}

AppAppBar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(AppAppBar);
