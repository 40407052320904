import React, { Component } from 'react';
// date time components
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { zhCN } from 'date-fns/locale';
// Material components
import { Typography, Divider, Grid, Box, IconButton, TextField, withStyles, Tooltip } from '@material-ui/core';

// Material icons
import {AddCircleOutline, RemoveCircleOutline} from '@material-ui/icons';
// Externals
import PropTypes from 'prop-types';
// Component styles
import styles from './styles';
// Form validation schema
const kqmode=[{id:0, name:'当天固定班次考勤', desp:'当天范围内，最多设置3个考勤时段，每个时段设置开始签到时间、上班考勤时间、下班考勤时间。多个时段之间的时间设置不能重叠。', desp2:'开始签到时间未到不能签到，到了开始签到时间可以签到，上班考勤时间之后签到为迟到，下班考勤时间之前签退为早退。'},
    {id:1, name:'跨天固定班次考勤', desp:'24小时范围内，最多设置3个考勤时段，每个时段设置开始签到时间、上班考勤时间、下班考勤时间、结束签到时间，时段之间不能重叠，可以跨天设置时间，考勤时段的时间跨度在24小时内。', desp2:'开始签到时间未到不能签到，到了开始签到时间可以签到，上班考勤时间之后签到为迟到，下班考勤时间之前签退为早退，结束签到时间之前可以签退，结束签到时间之后不能签退'}
]

class Index extends Component {
    state={
        
    }
    
    handleNameClick=(e,i)=>{
        if(e.target.value.trim().length==0){
            this.props.isvalid(false)
        }
        this.props.handlefieldchange(e.target.value.trim(),i)
    }

    handleFieldChange=(v,i)=>{
        this.props.handlefieldchange(v,i)
    }

    render() {
        const { classes, form_value, changenum } = this.props;
        const {mode0num: num}=this.props.form_value.mode0info
        return (
            <div>
                <Typography component='div' variant="h5">
                    考勤信息设置
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography component='span' variant="body1">
                            <TextField margin="dense" label="考勤方式" name="mode"  variant="outlined" required select
                                style={{width:'100%', fontSize:12}}  value={form_value.mode}  SelectProps={{ native: true }}
                                onChange={event => this.handleFieldChange(parseInt(event.target.value), 'mode') }
                            >
                                {kqmode.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            <Tooltip title={kqmode[form_value.mode].desp2}>
                            <Typography component='span' variant='body2'>
                                {kqmode[form_value.mode].desp}
                            </Typography>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{marginTop:5, marginBottom:10}} />
                <Grid container spacing={4}>
                    <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                        {/* <Tooltip title={form_value.pname1}>
                            <TextField required label="考勤时段名称" margin="dense" value={form_value.pname1} variant="outlined"
                                onChange={event => this.handleNameClick(event, 'pname1')} 
                                onFocus={event => this.handleNameClick(event, 'pname1')}
                                placeholder='请输入名称'                                
                                error={form_value.pname1.trim().length==0} 
                                helperText={form_value.pname1.trim().length==0?'不能为空':''}
                            />
                        </Tooltip> */}                 
                        <Typography component='div' variant="subtitle1" >
                            第一时段:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>                    
                        <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                            <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。'>
                                <span>
                                    <TimePicker autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" 
                                        inputVariant="outlined" margin="dense" ampm={false}
                                        onChange={value => this.handleFieldChange(value, 'stime1') }
                                        style={{ marginRight: 15, width: '25%' }}
                                        InputLabelProps={{ classes: { root: classes.label } }}
                                        value={form_value.mode0info.stime1}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title='过了上班考勤时间才开始签到为迟到'>
                                <span>
                                    <TimePicker autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定"
                                        inputVariant="outlined" margin="dense" ampm={false}
                                        onChange={value => this.handleFieldChange(value, 'ontime1') }
                                        style={{ marginLeft: 10, width: '33%' }}
                                        InputLabelProps={{ classes: { root: classes.label } }}
                                        value={form_value.mode0info.ontime1}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title='未到下班考勤时间就签退为早退'>
                                <span>
                                    <TimePicker autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定"
                                        inputVariant="outlined" margin="dense" ampm={false}
                                        onChange={value => this.handleFieldChange(value, 'offtime1') }
                                        style={{ marginLeft: 10, width: '33%' }}
                                        InputLabelProps={{ classes: { root: classes.label }}}
                                        value={form_value.mode0info.offtime1}
                                    />
                                </span>
                            </Tooltip>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                        {/* <Tooltip title='建议：时间间隔15~30分钟在方便员工签退，。'> */}
                            <Typography component='span' variant='body2'>
                                开始签到时间和上班考勤时间之间要有时间间隔，方便员工签到操作。
                            </Typography>
                        {/* </Tooltip> */}
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{marginTop:5, marginBottom:10}} />
                <div hidden={num > 0 ? false : true}>
                    <Grid container spacing={4} >
                        <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                            {/* <Tooltip title={form_value.pname2}>
                                <TextField required label="考勤时段名称" margin="dense" value={form_value.pname2} variant="outlined"
                                    onChange={event => this.handleNameClick(event, 'pname2')} 
                                    onFocus={event => this.handleNameClick(event, 'pname2')}
                                    placeholder='请输入名称'                                
                                    error={form_value.pname2.trim().length==0} 
                                    helperText={form_value.pname2.trim().length==0?'不能为空':''}
                                />
                            </Tooltip> */}                 
                            <Typography component='div' variant="subtitle1" >
                                第二时段:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>                    
                            <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                                <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。'>
                                    <span>
                                        <TimePicker autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" 
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value =>this.handleFieldChange(value, 'stime2') }
                                            style={{ marginRight: 15, width: '25%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.mode0info.stime2}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='过了上班考勤时间才开始签到为迟到'>
                                    <span>
                                        <TimePicker autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'ontime2') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.mode0info.ontime2}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='未到下班考勤时间就签退为早退'>
                                    <span>
                                        <TimePicker autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'offtime2') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.mode0info.offtime2}
                                        />
                                    </span>
                                </Tooltip>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            {/* <Tooltip title='建议：时间间隔15~30分钟在方便员工签退，。'> */}
                                <Typography component='span' variant='body2'>
                                    上一时段的下班考勤时间和开始签到时间之间要有时间间隔，方便员工签退操作。
                                </Typography>
                            {/* </Tooltip> */}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <Divider style={{marginTop:5, marginBottom:10}} />
                <div hidden={num > 1 ? false : true}>
                <Grid container spacing={4} >
                        <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                            {/* <Tooltip title={form_value.pname3}>
                                <TextField required label="考勤时段名称" margin="dense" value={form_value.pname3} variant="outlined"
                                    onChange={event => this.handleNameClick(event, 'pname3')} 
                                    onFocus={event => this.handleNameClick(event, 'pname3')}
                                    placeholder='请输入名称'                                
                                    error={form_value.pname3.trim().length==0} 
                                    helperText={form_value.pname3.trim().length==0?'不能为空':''}
                                />
                            </Tooltip> */}                 
                            <Typography component='div' variant="subtitle1" >
                                第三时段:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>                    
                            <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                                <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。'>
                                    <span>
                                        <TimePicker autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" 
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value =>this.handleFieldChange(value, 'stime3') }
                                            style={{ marginRight: 15, width: '25%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.mode0info.stime3}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='过了上班考勤时间才开始签到为迟到'>
                                    <span>
                                        <TimePicker autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'ontime3') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.mode0info.ontime3}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='未到下班考勤时间就签退为早退'>
                                    <span>
                                        <TimePicker autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'offtime3') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.mode0info.offtime3}
                                        />
                                    </span>
                                </Tooltip>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            {/* <Tooltip title='建议：时间间隔15~30分钟在方便员工签退，'> */}
                                <Typography component='span' variant='body2'>
                                    为了方便员工签退操作，(最后一个时段的)下班考勤时间不要设置为23:59。
                                </Typography>
                            {/* </Tooltip> */}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ float: 'right' }}>
                    {num === 0 ? null : (
                        <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => changenum('minus')}
                        >
                            <RemoveCircleOutline />
                        </IconButton>
                    )}

                    {num === 2 ? null : (
                        <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => changenum('add')}
                        >
                            <AddCircleOutline />
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}
Index.propTypes = {
    changenum: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    form_value: PropTypes.object.isRequired,
    handlefieldchange: PropTypes.func.isRequired,
    // num: PropTypes.number.isRequired,
    // isvalid: PropTypes.func.isRequired
};
export default withStyles(styles)(Index);
