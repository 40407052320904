import React, { Component } from 'react';

// MaterialTable

import classNames from 'classnames';

import { Button, Typography, Divider,Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import Http from '../../../services/http';

import { Portlet, PortletContent } from 'components';
import { TextField } from '@material-ui/core';

import styles from './styles';

class Index extends Component {
    state = {
        message: ''
    };
    handleFieldChange = value => {
        this.setState({
            message: value
        });
    };
    sendMessage = async () => {
        try {
            const res = await Http.re_message(
                {
                    smgid: this.props.messageid,
                    ReplyContent: this.state.message
                },
                true
            );
            const { errcode } = res;
            if (errcode === 0) {
                this.setState({
                    message: ''
                });
                this.props.handleClose();
                this.props.refresh();
            }
            console.log('发送成功');
        } catch (err) {
            console.log(err);
        }
    };
    render() {
        const { classes, className, open, handleClose, message, messageid, ...rest } = this.props;

        const rootClassName = classNames(classes.root, className);
        const messageError = false;

        return (
            <Dialog
                aria-labelledby="max-width-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth={'sm'}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle id="max-width-dialog-title">
                    {message.mgname === ''?
                    <Typography component='div' variant="h4" >回复小纸条</Typography>:
                    <Typography component='div' variant="h4" >查看小纸条</Typography>}
                </DialogTitle>
                <DialogContent>
                    <Portlet className={rootClassName}>
                        <PortletContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}} >来自：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}}>{message.Name}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >标题：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{message.Title}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >发送时间：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{message.CreateTime}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >内容：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{message.Content}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >回复人：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    { message.mgname === ''?
                                        <Typography component='div' variant="body1" style={{margin:10,color:'blue'}}>（还未回复）</Typography>:
                                        <Typography component='div' variant="body1" style={{margin:10}}>{message.mgname}</Typography>
                                    }
                                </Grid>
                            </Grid>                           
                            <Divider />
                            <Grid container>
                                { message.mgname === ''?null:
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >回复内容：</Typography>
                                </Grid>}
                                <Grid item xs={message.mgname===''?12:9}>
                                { message.mgname === ''?
                                    <TextField className={classes.textField} 
                                        label="回复内容" margin="dense" multiline
                                        onChange={event =>
                                            this.handleFieldChange(
                                                event.target.value
                                            )
                                        }
                                        required
                                        rows="4"
                                        value={this.state.message}
                                        variant="outlined"
                                    />:
                                    <Typography component='div' variant="body1" style={{margin:10}}>{message.mgcontent}</Typography>
                                }
                                </Grid>
                            </Grid>
                        </PortletContent>
                    </Portlet>
                </DialogContent>
                <DialogActions>
                {message.mgname === ''?
                    <Button color="primary" disabled={this.state.message === ''}
                        onClick={this.sendMessage}
                        variant="outlined"
                    >
                        立即发送
                    </Button>:null}

                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            this.setState({
                                message: ''
                            });
                            this.props.handleClose();
                        }}
                    >
                        {message.mgname === ''?'取消':'确定'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Index);
