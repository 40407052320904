import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, CircularProgress, Typography} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

import Http from 'services/http';

import styles from './styles';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from 'components';

class Index extends Component {
    state = {
        selectDep: '0',
        loading: false,
        forbidden: true
    };

    handleChange = event => {
        this.setState({
            selectDep: event.target.value.toString(),
            forbidden:false
        });
    };

    handleClose = event => {
        this.setState({
            selectDep: '0',
            loading: false,
            forbidden: true
        });        
        this.props.handleclose();
    };

    handleSubmit = async () => {
        this.setState({
            loading: true,
            forbidden: true
        });
        try {
            const res = await Http.changeGroupMt(
                {
                    uids: this.props.users.join(','),
                    newgid: this.state.selectDep
                },
                true
            );

            if (res.errcode === 0) {
                this.setState({ loading: false, forbidden: true, selectDep:'0' });
                this.props.refresh();
                this.props.showmsg('修改成功')
                this.props.handleclose();
            } else {
                this.setState({ loading: false, forbidden: false });
                this.props.showmsg('修改失败');
            }
        } catch (err) {
            this.setState({ loading: false, forbidden: false });
            console.log(err);
        }
    };
    render() {
        const { classes, className, open, handleclose, users, deps, ...rest } = this.props;
        const { selectDep, loading, forbidden } = this.state;
        const rootClassName = classNames(classes.root, className);

        return (
            <Dialog
                aria-labelledby="max-width-dialog-title"
                fullWidth
                maxWidth={'sm'}
                onClose={handleclose}
                open={open}
            >
                <DialogTitle id="max-width-dialog-title">
                    <Typography component='div' variant="h4" style={{marginTop:10}} >
                        <Typography component='span' variant="h4" >批量修改员工部门</Typography>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Portlet className={rootClassName} >
                        <PortletHeader>
                            <PortletLabel
                                title={`已选择${users.length}位员工`}
                                // subtitle={`已选择${users.length}位员工`}
                                // title="批量修改员工部门"
                            />
                        </PortletHeader>
                        <PortletContent>
                            <div className={classes.field}>
                                <FormControl
                                    className={classes.formControl}
                                    component="fieldset"
                                >
                                    <FormLabel component="legend">
                                        请选择部门
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        className={classes.group}
                                        name="gender1"
                                        onChange={this.handleChange}
                                        value={selectDep}
                                    >
                                        {deps.map(dep => (
                                            <FormControlLabel
                                                control={<Radio color="primary" />}
                                                key={dep.GroupID}
                                                label={dep.GroupName}
                                                value={dep.GroupID.toString()}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </PortletContent>
                    </Portlet>
                </DialogContent>
                <DialogActions>
                    <div style={{ position: 'relative' }}>
                        {loading && (
                            <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                        )}
                        <Button variant='outlined' color="primary" disabled={forbidden || loading} onClick={this.handleSubmit} >
                            保存并退出
                        </Button>
                    </div>
                    <Button variant='outlined' color="primary" onClick={this.handleClose} >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(styles)(Index);
