import React, { Component } from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Typography, Button} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { format } from 'date-fns';
import validate from 'validate.js';
import schema from './schema';

import Http from 'services/http';
import { compare_all_timeV2, compare_each_oneV2, compare_result } from 'utils/time_compare';

import styles from './styles';
import { Portlet, PortletHeader, PortletLabel, PortletContent, //TimeSelect,
    TimeSelect0, TimeSelect1
} from 'components';


class Index extends Component {
    state = {
        form_value: {
            mode:0,
            mode0info:{
                mode0num:0,         
                pname1: '第一时段',
                stime1: new Date('December 17, 1995 8:00:00'),
                ontime1: new Date('December 17, 1995 8:30:00'),
                offtime1: new Date('December 17, 1995 17:30:00'),
                pname2: '第二时段',
                stime2: new Date('December 17, 1995 12:30:00'),
                ontime2: new Date('December 17, 1995 13:00:00'),
                offtime2: new Date('December 17, 1995 17:30:00'),
                pname3: '第三时段',
                stime3: new Date('December 17, 1995 16:30:00'),
                ontime3: new Date('December 17, 1995 17:00:00'),
                offtime3: new Date('December 17, 1995 23:00:00'),
            },
            mode1info:{
                mode1num:0,          
                pname1: '第一时段',
                flag11:1,
                stime1: new Date('December 17, 1995 8:00:00'),
                flag12:1,
                ontime1: new Date('December 17, 1995 8:30:00'),
                flag13:1,
                offtime1: new Date('December 17, 1995 17:30:00'),
                flag14:1,
                etime1: new Date('December 17, 1995 23:59:00'),
                pname2: '第二时段',
                flag21:1,
                stime2: new Date('December 17, 1995 12:30:00'),
                flag22:1,
                ontime2: new Date('December 17, 1995 13:00:00'),
                flag23:1,
                offtime2: new Date('December 17, 1995 17:30:00'),
                flag24:1,
                etime2: new Date('December 17, 1995 23:59:00'),
                pname3: '第三时段',
                flag31:1,
                stime3: new Date('December 17, 1995 16:30:00'),
                flag32:1,
                ontime3: new Date('December 17, 1995 17:00:00'),
                flag33:1,
                offtime3: new Date('December 17, 1995 23:00:00'),
                flag34:1,
                etime3: new Date('December 17, 1995 23:59:00'),
            },
        },
        submit_loading: false,
        disabled: false,
        default_form_value: {
            mode:0,
            mode0info:{
                mode0num:0,         
                pname1: '第一时段',
                stime1: new Date('December 17, 1995 8:00:00'),
                ontime1: new Date('December 17, 1995 8:30:00'),
                offtime1: new Date('December 17, 1995 17:30:00'),
                pname2: '第二时段',
                stime2: new Date('December 17, 1995 12:30:00'),
                ontime2: new Date('December 17, 1995 13:00:00'),
                offtime2: new Date('December 17, 1995 17:30:00'),
                pname3: '第三时段',
                stime3: new Date('December 17, 1995 16:30:00'),
                ontime3: new Date('December 17, 1995 17:00:00'),
                offtime3: new Date('December 17, 1995 23:00:00'),
            },
            mode1info:{
                mode1num:0,          
                pname1: '第一时段',
                flag11:1,
                stime1: new Date('December 17, 1995 8:00:00'),
                flag12:1,
                ontime1: new Date('December 17, 1995 8:30:00'),
                flag13:1,
                offtime1: new Date('December 17, 1995 17:30:00'),
                flag14:1,
                etime1: new Date('December 17, 1995 23:59:00'),
                pname2: '第二时段',
                flag21:1,
                stime2: new Date('December 17, 1995 12:30:00'),
                flag22:1,
                ontime2: new Date('December 17, 1995 13:00:00'),
                flag23:1,
                offtime2: new Date('December 17, 1995 17:30:00'),
                flag24:1,
                etime2: new Date('December 17, 1995 23:59:00'),
                pname3: '第三时段',
                flag31:1,
                stime3: new Date('December 17, 1995 16:30:00'),
                flag32:1,
                ontime3: new Date('December 17, 1995 17:00:00'),
                flag33:1,
                offtime3: new Date('December 17, 1995 23:00:00'),
                flag34:1,
                etime3: new Date('December 17, 1995 23:59:00'),
            },
        },
    };

    getDefalutTime = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        const { form_value } = this.state;
        try {
            const res = await Http.defaultTimeV2({
                companysn: cur_cpy.CompanySN
            });
            const { data, errcode, errmsg } = res;
            if (errcode === 0) {
                if (data.mode === 0) {
                    form_value.mode=0
                    if(data.Mode0Info.pname1.length>0){
                        form_value.mode0info.mode0num=0
                        form_value.mode0info.pname1 = data.Mode0Info.pname1;
                        form_value.mode0info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime1 + ':00' );
                        form_value.mode0info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime1 + ':00' );
                        form_value.mode0info.stime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime1 + ':00' );
                    }
                    if(data.Mode0Info.pname2.length>0){
                        form_value.mode0info.mode0num+=1;
                        form_value.mode0info.pname2 = data.Mode0Info.pname2;
                        form_value.mode0info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime2 + ':00' );
                        form_value.mode0info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime2 + ':00' );
                        form_value.mode0info.stime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime2 + ':00' );
                    }
                    if(data.Mode0Info.pname3.length>0){
                        form_value.mode0info.mode0num+=1;
                        form_value.mode0info.pname3 = data.Mode0Info.pname3;
                        form_value.mode0info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime3 + ':00' );
                        form_value.mode0info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime3 + ':00' );
                        form_value.mode0info.stime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime3 + ':00' );
                    }

                    this.setState({
                        form_value, default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                    });
                } else if(data.mode==1){
                    form_value.mode=1
                    if(data.Mode1Info.pname1.length>0){
                        form_value.mode1info.mode1num=0
                        form_value.mode1info.pname1 = data.Mode1Info.pname1;
                        form_value.mode1info.flag11=data.Mode1Info.flag11
                        form_value.mode1info.stime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime1 + ':00' );
                        form_value.mode1info.flag12=data.Mode1Info.flag12
                        form_value.mode1info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime1 + ':00' );
                        form_value.mode1info.flag13=data.Mode1Info.flag13
                        form_value.mode1info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime1 + ':00' );
                        form_value.mode1info.flag14=data.Mode1Info.flag14
                        form_value.mode1info.etime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime1 + ':00' );
                    }
                    if(data.Mode1Info.pname2.length>0){
                        form_value.mode1info.mode1num+=1;
                        form_value.mode1info.pname2 = data.Mode1Info.pname2;
                        form_value.mode1info.flag21=data.Mode1Info.flag21
                        form_value.mode1info.stime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime2 + ':00' );
                        form_value.mode1info.flag22=data.Mode1Info.flag22
                        form_value.mode1info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime2 + ':00' );
                        form_value.mode1info.flag23=data.Mode1Info.flag23
                        form_value.mode1info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime2 + ':00' );
                        form_value.mode1info.flag24=data.Mode1Info.flag24
                        form_value.mode1info.etime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime2 + ':00' );
                    }
                    if(data.Mode1Info.pname3.length>0){
                        form_value.mode1info.mode1num+=1;
                        form_value.mode1info.pname3 = data.Mode1Info.pname3;
                        form_value.mode1info.flag31=data.Mode1Info.flag31
                        form_value.mode1info.stime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime3 + ':00' );
                        form_value.mode1info.flag32=data.Mode1Info.flag32
                        form_value.mode1info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime3 + ':00' );
                        form_value.mode1info.flag33=data.Mode1Info.flag33
                        form_value.mode1info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime3 + ':00' );
                        form_value.mode1info.flag34=data.Mode1Info.flag34
                        form_value.mode1info.etime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime3 + ':00' );
                    }

                    this.setState({
                        form_value, default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                    });
                }
            }
            
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        this.getDefalutTime();
    }

    validateForm = () => {
        let { form_value } = this.state;
        let errors = validate(form_value, schema);
        let disabled = errors ? true : false;
        this.setState({disabled});
    };

    handleFieldChange = (value, field) => {
        const { form_value } = this.state;
        let {mode0info, mode1info}=this.state.form_value
        let fields='stime1stime2stime3etime1etime2etime3ontime1ontime2ontime3offtime1offtime2offtime3flag11flag12flag13flag14flag21flag22flag23flag24flag31flag32flag33flag34'
        if(fields.includes(field)){
            if(form_value.mode==0){
                mode0info[field]=value
                form_value.mode0info={...mode0info}
            }
            if(form_value.mode==1){
                mode1info[field]=value
                form_value.mode1info={...mode1info}
            }
        }else{
            form_value[field] = value;
        }
        this.setState({ form_value}, this.validateForm);
    };

    handleSubmit = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        const { form_value } = this.state;
        const { mode0info, mode1info, mode } = this.state.form_value;

        let code=compare_each_oneV2(form_value, mode)
        if (code<1) {
            this.props.showmsg(compare_result(code));
            return;
        }
        code=compare_all_timeV2(form_value, mode)
        if (code<1) {
            this.props.showmsg(compare_result(code));
            return;
        }

        this.setState({ disabled: true, submit_loading: true });

        
        let num=-1
        let m0ts={
            name1:"",
            name2:"",
            name3:"",
            begin1:"",
            begin2:"",
            begin3:"",
            end1:"",
            end2:"",
            end3:"",
            eff1 :"",
            eff2:"",
            eff3:""
        }
        let m1ts={
            name1:"",
            name2:"",
            name3:"",
            begin1:"",
            begin2:"",
            begin3:"",
            end1:"",
            end2:"",
            end3:"",
            eff1 :"",
            eff2:"",
            eff3:"",
            noeff1 :"",
            noeff2:"",
            noeff3:"",
            flag11:1,
            flag12:1,
            flag13:1,
            flag14:1,
            flag21:1,
            flag22:1,
            flag23:1,
            flag24:1,
            flag31:1,
            flag32:1,
            flag33:1,
            flag34:1
        }
        switch(mode){
            case 0:
                num=mode0info.mode0num;
                
                if(num==0){
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                }else if(num==1){
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                    
                    m0ts.name2=mode0info.pname2
                    m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
                    m0ts.end2=format(mode0info.offtime2, 'HH:mm')
                    m0ts.eff2=format(mode0info.stime2, 'HH:mm')
                }else{
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                    
                    m0ts.name2=mode0info.pname2
                    m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
                    m0ts.end2=format(mode0info.offtime2, 'HH:mm')
                    m0ts.eff2=format(mode0info.stime2, 'HH:mm')
                    
                    m0ts.name3=mode0info.pname3
                    m0ts.begin3=format(mode0info.ontime3, 'HH:mm')
                    m0ts.end3=format(mode0info.offtime3, 'HH:mm')
                    m0ts.eff3=format(mode0info.stime3, 'HH:mm')
                }
                break;
            case 1:                
                num=mode1info.mode1num;                
                if(num==0){
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                }else if(num==1){
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                    m1ts.name2=mode1info.pname2
                    m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
                    m1ts.end2=format(mode1info.offtime2, 'HH:mm')
                    m1ts.eff2=format(mode1info.stime2, 'HH:mm')
                    m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
                    m1ts.flag21=mode1info.flag21
                    m1ts.flag22=mode1info.flag22
                    m1ts.flag23=mode1info.flag23
                    m1ts.flag24=mode1info.flag24
                }else{
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                    m1ts.name2=mode1info.pname2
                    m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
                    m1ts.end2=format(mode1info.offtime2, 'HH:mm')
                    m1ts.eff2=format(mode1info.stime2, 'HH:mm')
                    m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
                    m1ts.flag21=mode1info.flag21
                    m1ts.flag22=mode1info.flag22
                    m1ts.flag23=mode1info.flag23
                    m1ts.flag24=mode1info.flag24
                    m1ts.name3=mode1info.pname3
                    m1ts.begin3=format(mode1info.ontime3, 'HH:mm')
                    m1ts.end3=format(mode1info.offtime3, 'HH:mm')
                    m1ts.eff3=format(mode1info.stime3, 'HH:mm')
                    m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
                    m1ts.flag31=mode1info.flag31
                    m1ts.flag32=mode1info.flag32
                    m1ts.flag33=mode1info.flag33
                    m1ts.flag34=mode1info.flag34
                }
                break;
        }

        let postData = {};
        if (mode === 0) {
            postData = {
                uids: this.props.users.join(','),
                companysn: cur_cpy.CompanySN,
                userid:cur_user.UserID,
                mode:form_value.mode,
                mode0info:{...m0ts},
                mode1info:{},
                mode2info:{},
                mode3info:{},
            };
        } else if (mode === 1) {
            postData = {
                uids: this.props.users.join(','),
                companysn: cur_cpy.CompanySN,
                userid:cur_user.UserID,
                mode:form_value.mode,
                mode0info:{},
                mode1info:{...m1ts},
                mode2info:{},
                mode3info:{}
            };
        } 
        
        try {
            const res = await Http.changeTimeMtV2(postData);
            const { errcode } = res;
            if (errcode === 0) {
                this.setState({
                    disabled: false,
                    submit_loading: false
                });
                this.props.showmsg('修改成功');
                this.getDefalutTime();
                this.props.handleclose();
            } else {
                this.setState({
                    disabled: false,
                    submit_loading: false
                });
                this.props.showmsg('修改失败');
            }
        } catch (err) {
            this.setState({
                disabled: false,
                submit_loading: false
            });
            console.log(err);
        }
    };

    timeValid=(value)=>{
        this.setState({ disabled:!value })
    }

    changeNum = type => {
        const { form_value, default_form_value } = this.state;
        switch(form_value.mode){
            case 0:
                if (form_value.mode0info.mode0num >= 0 && form_value.mode0info.mode0num < 3) {
                    if (type === 'add' && form_value.mode0info.mode0num !== 2) { form_value.mode0info.mode0num += 1; } else if (type === 'minus' && form_value.mode0info.mode0num !== 0) { form_value.mode0info.mode0num -= 1; }
                }
                this.setState({form_value}, this.validateForm);
                break;
            case 1:
                if (form_value.mode1info.mode1num >= 0 && form_value.mode1info.mode1num < 3) {
                    if (type === 'add' && form_value.mode1info.mode1num !== 2) { form_value.mode1info.mode1num += 1; } else if (type === 'minus' && form_value.mode1info.mode1num !== 0) { form_value.mode1info.mode1num -= 1; }
                }
                this.setState({form_value}, this.validateForm);
                break;
        }        
    };

    handleCancel = () => {
        this.getDefalutTime();
        this.props.handleclose();
    };

    render() {
        const { classes, className, open, handleclose, users, accountinfo, ...rest } = this.props;
        const { num, form_value, submit_loading, disabled } = this.state;
        const rootClassName = classNames(classes.root, className);

        return (
            <Dialog
                aria-labelledby="max-width-dialog-title"
                fullWidth
                maxWidth={'md'}
                onClose={handleclose}
                open={open}
            >
                <DialogTitle id="max-width-dialog-title">
                    <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >
                        <Typography component='span' variant="h4" >批量修改签到时间</Typography>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Portlet className={rootClassName}>
                        <PortletHeader>
                            <PortletLabel
                                title={`已选择${users.length}位员工`}
                                // subtitle={`已选择${users.length}位员工`}
                                // title="批量修改签到时间"
                            />
                        </PortletHeader>
                        <PortletContent>
                            <div className={classes.field}>
                                {/* <TimeSelect
                                    changenum={this.changeNum}
                                    form_value={form_value}
                                    handlefieldchange={this.handleFieldChange}
                                    num={num}
                                    isvalid={this.timeValid}
                                /> */}
                                {(form_value.mode==0?
                                    <TimeSelect0
                                        changenum={ this.changeNum }
                                        form_value={form_value}
                                        handlefieldchange={ this.handleFieldChange }
                                        // isvalid={timevalid}
                                    />:
                                    <TimeSelect1
                                        changenum={ this.changeNum }
                                        form_value={form_value}
                                        handlefieldchange={ this.handleFieldChange }
                                        accountinfo={accountinfo}
                                        // isvalid={timevalid}
                                    />
                                )}
                            </div>
                        </PortletContent>
                    </Portlet>
                </DialogContent>
                <DialogActions>
                    <div style={{ position: 'relative' }}>
                        {submit_loading && (
                            <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                        )}
                        <Button variant='outlined' color="primary" disabled={disabled || (accountinfo.isVip<=0 && form_value.mode>0)} onClick={this.handleSubmit} >
                            保存并退出
                        </Button>
                    </div>
                    <Button color="primary" onClick={this.handleCancel} variant='outlined'>
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(styles)(Index);
