import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Material components
import { Drawer } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar, Footer } from './components';

// import SpeedDial from '@material-ui/lab/SpeedDial';
// import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
// import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';

import Http from '../../services/http';

import { connect } from 'react-redux';
import { actions as cpyactions } from '../../redux/modules/cpy';
// Component styles
import styles from './styles';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        const isMobile = ['xs', 'sm', 'md'].includes(props.width);

        this.state = {
            isOpen: !isMobile,
            open: false,
            hidden: false
        };
    }

    async componentDidMount() {
        this.props.cpyactions();
        try {
            let resData = await Http.session_check();

            if (resData && resData.errcode !== 0) {
                localStorage.clear();
                this.props.history.push('/sign-in');
            }
        } catch (err) {
            this.props.history.push('/sign-in');
        }
        const cur_user = localStorage.getItem('cur_user');
        const cpy_list = localStorage.getItem('cpy_list');
        const cur_cpy = localStorage.getItem('cur_cpy');
        if (cur_cpy) {
            this.setState({
                cur_user: JSON.parse(cur_user),
                cpy_list: JSON.parse(cpy_list),
                cur_cpy: JSON.parse(cur_cpy)
            });
        } else {
            this.props.history.push('/sign-in');
        }
    }

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleToggleOpen = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    handleVisibility = () => {
        this.setState(state => ({
            open: false,
            hidden: !state.hidden
        }));
    };

    handleClick = () => {
        this.setState(state => ({
            open: !state.open
        }));
    };

    handleOpen = () => {
        if (!this.state.hidden) {
            this.setState({
                open: true
            });
        }
    };

    handleClose1 = () => {
        this.setState({
            open: false
        });
    };

    render() {
        const { classes, width, title, children } = this.props;
        const { isOpen, hidden, open } = this.state;

        const isMobile = ['xs', 'sm', 'md'].includes(width);
        const shiftTopbar = isOpen && !isMobile;
        const shiftContent = isOpen && !isMobile;
        const actions = [
            { icon: <FileCopyIcon />, name: '更改单位' },
            { icon: <PrintIcon />, name: 'TODO LIST' },
            { icon: <ShareIcon />, name: '新建通知' }
        ];
        return (
            <Fragment>
                <Topbar
                    className={classNames(classes.topbar, {
                        [classes.topbarShift]: shiftTopbar
                    })}
                    isSidebarOpen={isOpen}
                    onToggleSidebar={this.handleToggleOpen}
                    title={title}
                />
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.drawerPaper }}
                    onClose={this.handleClose}
                    open={isOpen}
                    variant={isMobile ? 'temporary' : 'persistent'}
                >
                    <Sidebar className={classes.sidebar} />
                </Drawer>
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: shiftContent
                    })}
                >
                    {/* <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={hidden}
              icon={<SpeedDialIcon />}
              onBlur={this.handleClose1}
              onClick={this.handleClick}
              onClose={this.handleClose1}
              onFocus={this.handleOpen}
              onMouseEnter={this.handleOpen}
              onMouseLeave={this.handleClose1}
              open={open}
            >
              {actions.map(action => (
                <SpeedDialAction
                  icon={action.icon}
                  key={action.name}
                  onClick={this.handleClick}
                  tooltipOpen
                  tooltipTitle={action.name}
                />
              ))}
            </SpeedDial> */}
                    {children}

                    <Footer />
                </main>
            </Fragment>
        );
    }
}

Dashboard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    width: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    cpy: state.cpy
});

const mapDispatchToProps = dispatch => ({
    cpyactions: data => dispatch(cpyactions.getcpylist(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
        withStyles(styles),
        withWidth(),
        withRouter
    )(Dashboard)
);
