export default theme => ({
    root: {},
    field: {
        margin: theme.spacing(3),
        width: '100%'
    },
    textField: {
        width: '420px',
        maxWidth: '100%',
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    textCaptchaButton: {
        width: '110px',
        height: '55px',
        marginLeft: theme.spacing(1)
    },
    textCaptchaField: {
        width: '300px',
        '& + & ': {
            marginTop: theme.spacing(2)
        }
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    signUpButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    }
});
