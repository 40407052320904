import React, { Component } from 'react';

import classNames from 'classnames';

// Material components
import { Popover, Button, TextField, Typography, CircularProgress, Grid, Divider, Tooltip, RemoveCircleOutline} from '@material-ui/core';

import Http from 'services/http';

// Material helpers
import { withStyles } from '@material-ui/styles';
import { SnackbarsMessage, Portlet, PortletHeader, PortletLabel, PortletContent, //TimeSelect,
    TimeSelect1, TimeSelect00
} from 'components';

import styles from './styles';
// import { Tooltip } from 'chart.js';

class Index extends Component {
    state = {
        Loading:false,
        snackbarsMsg: '',
        snackbarsVisible: false,
        anchorEl: null,
        profession:[],
        open: false
    };

    async componentDidMount() {
        try {
            const res = await Http.profession();
            const { errcode } = res;
            if (errcode === 0) {
                this.setState({
                    profession: res.data
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };

    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false,
            anchorEl: null
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            anchorEl: null
        });
    };
    
    render() {
        const { classes, className, form_value, errors, touched, disableaddress, disablefirmname,
            disabletel, accountinfo, handlefieldchange, changenum, isnew, cur_user, ...rest
        } = this.props;

        const { snackbarsMsg, snackbarsVisible,profession } = this.state;

        const rootClassName = classNames(classes.root, className);

        return (
            <Portlet  className={rootClassName}>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <PortletHeader>
                    <PortletLabel subtitle="新增单位基本信息设置"/>                    
                </PortletHeader>
                <PortletContent>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="单位全称" name="name"  variant="outlined" required
                                    style={{width:'100%'}} value={form_value.firmname} placeholder='请输入单位名称'
                                    onFocus={event => handlefieldchange( event.target.value, 'firmname' )}
                                    onChange={event => handlefieldchange( event.target.value, 'firmname' )}
                                    error={disablefirmname} 
                                    helperText={disablefirmname?errors.firmname[0].replace('Firmname', ''):''}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="单位地址" name="address"  variant="outlined" required
                                    style={{width:'100%'}} value={form_value.address} placeholder='请输入单位地址'
                                    onFocus={event => handlefieldchange( event.target.value, 'address' )}
                                    onChange={event => handlefieldchange( event.target.value, 'address' )}
                                    error={disableaddress} 
                                    helperText={disableaddress?errors.address[0].replace('Address', ''):''}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="单位电话" name="tel"  variant="outlined" required
                                    style={{width:'100%'}} value={form_value.tel} placeholder='请输入单位电话'
                                    onFocus={event => handlefieldchange( event.target.value, 'tel' )}
                                    onChange={event => handlefieldchange( event.target.value, 'tel' )}
                                    error={disabletel} 
                                    helperText={disabletel?errors.tel[0].replace('Tel', ''):''}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="所属行业" name="memo"  variant="outlined" required
                                    style={{width:'100%'}} 
                                    onChange={event => handlefieldchange(event.target.value, 'memo') }
                                    value={form_value.memo} 
                                    select
                                    SelectProps={{ native: true }}
                                >
                                    {profession.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{marginTop:20, marginBottom:10}} />
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                                {/* <TimeSelect
                                    changenum={changenum}
                                    form_value={form_value}
                                    handlefieldchange={handlefieldchange}
                                    num={num}
                                    isvalid={timevalid}
                                /> */}
                                {form_value.mode==0?
                                <TimeSelect00
                                    changenum={changenum}
                                    form_value={form_value}
                                    handlefieldchange={handlefieldchange}
                                    accountinfo={accountinfo}
                                    // num={num}
                                    // isvalid={timevalid}
                                />:
                                <TimeSelect1
                                    changenum={changenum}
                                    form_value={form_value}
                                    handlefieldchange={handlefieldchange}
                                    accountinfo={accountinfo}
                                    // num={num}
                                    // isvalid={timevalid}
                                />}
                        </Grid>
                    </Grid>
                </PortletContent>
            </Portlet>
        );
    }
}

export default withStyles(styles)(Index);
