import axios from 'axios';
import API from './api.js';

// service 循环遍历输出不同的请求方法
let instance = axios.create({
    // baseURL: 'http://localhost:3000',
    timeout: 5000
});

const Http = {}; // 包裹请求方法的容器

// 请求格式/参数的统一
for (let key in API) {
    let api = API[key]; // url method
    Http[key] = async function(
        params, //请求参数 get: url, put, post, patch (data), delete: url
        isFormData = false,
        config = {} //配置参数
    ) {
        let newParams = {};
        // content-type 是否是 form-data 的判断
        if (params && isFormData) {
            newParams = new FormData();
            for (let key in params) {
                newParams.append(key, params[key]);
            }
        } else {
            newParams = params;
        }
        // 不同请求的判断
        let response = {}; // 请求的返回值
        if (
            api.method === 'put' ||
            api.method === 'post' ||
            api.method === 'patch'
        ) {
            try {
                response = await instance[api.method](
                    api.url,
                    newParams,
                    config
                );
            } catch (err) {
                response = err;
            }
        } else if (api.method === ' delete' || api.method === 'get') {
            config.params = newParams;
            try {
                response = await instance[api.method](api.url, config);
            } catch (err) {
                response = err;
            }
        }
        return response; // 返回响应值
    };
}

// 请求拦截器的添加
instance.interceptors.request.use(
    config => {
        // 发起请求前做些什么
        // console.log('request 开始');
        return config;
    },
    () => {
        // 请求错误
        // console.log('request 错误');
    }
);

// 响应拦截器
instance.interceptors.response.use(
    res => {
        // 请求成功了
        // console.log('请求成功了');
        if (res.errcode === 40014) {
            this.props.history.push('/sign-in');
        } else {
            return res.data;
        }
    },
    () => {
        this.props.history.push('/sign-in');
        // console.log('请求失败了');
    }
);

export default Http;
