import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import ProductCurvyLines from 'static/onepirate/productCurvyLines.png';
import mainImg from 'static/导出-1.png';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: '#dce2f5'
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        display: 'flex',
        position: 'relative'
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5)
    },
    image: {
        width: '100%',
        top: '1'
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180
    },
    text: {
        // marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    }
});

function ProductValues(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <img
                    alt="curvy lines"
                    className={classes.curvyLines}
                    src={ProductCurvyLines}
                />
                <div>
                    <Typography
                        className={classes.text}
                        gutterBottom
                        style={{ fontSize: '24px' }}
                        variant="h4"
                    >
                        数据导出好轻松，报表文件一键下载
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                            <div>
                                <img
                                    alt="导出"
                                    className={classes.image}
                                    src={mainImg}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>
    );
}

ProductValues.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductValues);
