import React, { Component } from 'react';

// MaterialTable

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import classNames from 'classnames';

import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';

import { Portlet, PortletContent } from 'components';
import { TextField } from '@material-ui/core';

import styles from './styles';

class NoticeDialog extends Component {
    render() {
        const { classes, className, handleFieldChange, ...rest } = this.props;
        const rootClassName = classNames(classes.root, className);

        return (
            <Dialog
                aria-labelledby="max-width-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth={'sm'}
                onClose={this.props.handleClose}
                open={this.props.open}
            >
                <DialogTitle id="max-width-dialog-title">
                  <Typography component='div' variant="h4" >发公告</Typography>
                </DialogTitle>
                <DialogContent>
                    <Portlet className={rootClassName}>
                        <PortletContent>
                            <div className={classes.field}>
                                <TextField
                                    className={classes.textField}
                                    label="公告标题"
                                    margin="dense"
                                    onChange={event =>
                                        this.props.handleFieldChange(
                                            event.target.value,
                                            'notice_title'
                                        )
                                    }
                                    required
                                    value={this.props.notice.notice_title}
                                    variant="outlined"
                                />
                                {this.props.title_error && (
                                    <Typography
                                        className={classes.fieldError}
                                        variant="body2"
                                    >
                                        {this.props.errors.notice_title[0].replace(
                                            'Notice title',
                                            ''
                                        )}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.field}>
                                <TextField
                                    className={classes.textField}
                                    label="公告内容"
                                    margin="dense"
                                    multiline
                                    onChange={event =>
                                        handleFieldChange(
                                            event.target.value,
                                            'notice_body'
                                        )
                                    }
                                    required
                                    rows="4"
                                    value={this.props.notice.notice_body}
                                    variant="outlined"
                                />
                                {this.props.body_error && (
                                    <Typography
                                        className={classes.fieldError}
                                        variant="body2"
                                    >
                                        {this.props.errors.notice_body[0].replace(
                                            'Notice body',
                                            ''
                                        )}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.field}>
                                <MuiPickersUtilsProvider
                                    locale={zhCN}
                                    utils={DateFnsUtils}
                                >
                                    <DateTimePicker
                                        cancellabel="取消"
                                        clearable
                                        clearLabel="清除"
                                        disablePast
                                        format="yyyy-MM-dd HH:mm"
                                        inputVariant="outlined"
                                        label="定时发送时间(选填)"
                                        margin="normal"
                                        // minDate={today}
                                        oklabel="确认"
                                        onChange={date =>
                                            handleFieldChange(
                                                date
                                                    ? format(
                                                          date,
                                                          'yyyy-MM-dd HH:mm'
                                                      )
                                                    : null,
                                                'date_time'
                                            )
                                        }
                                        showTodayButton
                                        size="small"
                                        todayLabel="今天"
                                        value={this.props.notice.date_time}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.field}>
                                推送到手机通知栏
                                <Checkbox
                                    checked={this.props.notice.checked}
                                    color="primary"
                                    onChange={event =>
                                        handleFieldChange(
                                            event.target.checked,
                                            'checked'
                                        )
                                    }
                                />
                            </div>
                        </PortletContent>
                    </Portlet>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="primary" onClick={this.props.sendNoticeAsync} >
                        定时发送
                    </Button>
                    <Button color="primary" onClick={this.props.sendNotice} variant='outlined'>
                        立即发送
                    </Button>
                    <Button color="primary" onClick={this.props.handleClose} variant='outlined'>
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NoticeDialog);

{
    /* <Dialog
aria-labelledby="max-width-dialog-title"
disableBackdropClick
disableEscapeKeyDown
fullWidth
maxWidth={'sm'}
onClose={this.handleClose}
open={this.state.add_notice}
>
<DialogTitle id="max-width-dialog-title">
            发公告
</DialogTitle>
<DialogContent>
  <Portlet className={rootClassName} >
    <PortletContent>
      <form
        autoComplete="off"
        noValidate
      >
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="公告标题"
            margin="dense"
            onChange={event =>
              this.handleFieldChange(
                event.target.value,
                'notice_title'
              )
            }
            required
            value={
              this.state.notice.notice_title
            }
            variant="outlined"
          />
          {title_error && (
            <Typography
              className={classes.fieldError}
              variant="body2"
            >
              {errors.notice_title[0].replace(
                'Notice title',
                ''
              )}
            </Typography>
          )}
        </div>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="公告内容"
            margin="dense"
            multiline
            onChange={event =>
              this.handleFieldChange(
                event.target.value,
                'notice_body'
              )
            }
            required
            rows="4"
            value={
              this.state.notice.notice_body
            }
            variant="outlined"
          />
          {body_error && (
            <Typography
              className={classes.fieldError}
              variant="body2"
            >
              {errors.notice_body[0].replace(
                'Notice body',
                ''
              )}
            </Typography>
          )}
        </div>
        <div className={classes.field}>
          <MuiPickersUtilsProvider
            locale={zhCN}
            utils={DateFnsUtils}
          >
            <DateTimePicker
              cancelLabel="取消"
              clearable
              clearLabel="清除"
              disablePast
              format="yyyy-MM-dd HH:mm"
              inputVariant="outlined"
              label="定时发送时间(选填)"
              margin="normal"
              // minDate={today}
              oklabel="确认"
              onChange={date =>
                this.handleFieldChange(
                  date
                    ? format(
                      date,
                      'yyyy-MM-dd HH:mm'
                    )
                    : null,
                  'date_time'
                )
              }
              showTodayButton
              size="small"
              todayLabel="今天"
              value={
                this.state.notice.date_time
              }
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.field}>
                            推送到手机通知栏
          <Checkbox
            checked={this.state.notice.checked}
            color="primary"
            onChange={event =>
              this.handleFieldChange(
                event.target.checked,
                'checked'
              )
            }
          />
        </div>
      </form>
    </PortletContent>
  </Portlet>
</DialogContent>
<DialogActions>
  <Button
    color="primary"
    onClick={this.sendNoticeAsync}
  >
                定时发送
  </Button>
  <Button
    color="primary"
    onClick={this.sendNotice}
  >
                立即发送
  </Button>

  <Button
    color="primary"
    onClick={this.handleClose}
  >
                取消
  </Button>
</DialogActions>
</Dialog> */
}
