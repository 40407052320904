import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
// Material components
import { withStyles, TextField, Link, Avatar, Divider, List, ListItem, ListItemIcon, 
    ListItemText, ListSubheader, Typography, Button, Tooltip
} from '@material-ui/core';

import { connect } from 'react-redux';
import { actions as cpyactions } from 'redux/modules/cpy';
import { actions as reportActions } from 'redux/modules/report';
import { format } from 'date-fns';

import logoImg from 'static/img/logo.png'
import dftLogoImg from 'static/img/defaultlogo.png'
// Material icons
import { PeopleOutlined as PeopleIcon, ShoppingBasketOutlined as ShoppingBasketIcon,
    TextFields as TextFieldsIcon, InfoOutlined as InfoIcon, SettingsOutlined as SettingsIcon,
    PermIdentityOutlined, WifiTethering, Dashboard, AccountBox as AccountBoxIcon
} from '@material-ui/icons';
// Shared components

// Component styles
import styles from './styles';
// import { Tooltip } from 'chart.js';

const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
));
class Sidebar extends Component {
    state = {
        cur_company: 0,
        cur_user: null,
        cpy_list: []
    };

    componentDidMount() {
        const cur_user = localStorage.getItem('cur_user');
        const cpy_list = localStorage.getItem('cpy_list');
        const cur_cpy = localStorage.getItem('cur_cpy');
        this.setState({
            cur_user: JSON.parse(cur_user),
            cpy_list: JSON.parse(cpy_list),
            cur_cpy: JSON.parse(cur_cpy)
        });
    }

    tohome=event=>{
        this.props.history.push('/homepage');
    }

    handleChange = event => {
        const cur_cpy = this.props.cpy.cpy_list.find(cpy => {
            return cpy.CompanyID === parseInt(event.target.value);
        });
        this.setState({
            cur_cpy
        });
        localStorage.setItem('cur_cpy', JSON.stringify(cur_cpy));
        this.props.reportActions(format(new Date(), 'yyyy-MM-dd'));
        this.props.monthListActions(format(new Date(), 'yyyy-MM-dd'));
        this.props.monthReportActions(format(new Date(), 'yyyy-MM-dd'));
        this.props.history.push('/signdata');
    };
    render() {
        const { classes, className } = this.props;
        const { cur_user, cpy_list, cur_cpy } = this.state;
        const rootClassName = classNames(classes.root, className);

        return (
            <nav className={rootClassName}>
                <div className={classes.logoWrapper} >
                    <span className={classes.logoLink}>
                        <img
                            onClick={this.tohome}
                            alt="dingdong-logo"
                            className={classes.logoImage}
                            src={logoImg}
                        />
                    </span>
                    <span style={{ marginLeft: '30px' }}><Link href='/#/homepage'>叮咚签到</Link></span>
                </div>
                <Divider className={classes.logoDivider} />
                <div className={classes.profile}>
                    {/* <img
                        alt="dingdong-logo"
                        // className={classes.logoImage}
                        src={cur_cpy ? cur_cpy.LogoUrl : null}
                        style={{ width: '50%' }}
                    /> */}
                    <Avatar
                        alt="logo"
                        className={classes.avatar}
                        src={cur_cpy ? cur_cpy.LogoUrl :  dftLogoImg}
                    />
                    <Button style={{ color: 'blue' }}>
                        {cur_cpy ? '单位号: ' + cur_cpy.CompanySN : ''}
                    </Button>
                    <Typography className={classes.bioText} variant="caption">                        
                        <Tooltip title={cur_cpy?cur_cpy.CompanyName:''}>
                        <TextField
                            className={classes.textField}
                            label="当前单位"
                            margin="dense"
                            onChange={this.handleChange}
                            select
                            SelectProps={{ native: true }}
                            style={{ width: '200px' }}
                            value={cur_cpy ? cur_cpy.CompanyID : 0}
                            variant="outlined"
                        >
                            {this.props.cpy.cpy_list.map(cpy => (
                                <option
                                    key={cpy.CompanyID}
                                    value={cpy.CompanyID}
                                >
                                    {cpy.CompanyName}
                                </option>
                            ))}
                        </TextField>
                        </Tooltip>
                    </Typography>
                </div>
                <Divider className={classes.profileDivider} />
                <List component="div" disablePadding>
                    {/* {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={ForwardNavLink}
                                to="/dashboard"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Dashboard"
                                />
                            </ListItem>
                        )
                    ) : null} */}

                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={ForwardNavLink}
                        to="/signdata"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="考勤明细"
                        />
                    </ListItem>

                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={ForwardNavLink}
                                to="/typography"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <TextFieldsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="信息交互"
                                />
                            </ListItem>
                        )
                    ) : null}
                    <Divider className={classes.listDivider} />
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={ForwardNavLink}
                        to="/users"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PermIdentityOutlined />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="员工管理"
                        />
                    </ListItem>
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={ForwardNavLink}
                                to="/routers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <WifiTethering />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="设备管理"
                                />
                            </ListItem>
                        )
                    ) : null}

                    {/* <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={ForwardNavLink}
                        to="/icons"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <TextFieldsIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="通信录"
                        />
                    </ListItem> */}
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={ForwardNavLink}
                                to="/settings"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="单位管理"
                                />
                            </ListItem>
                        )
                    ) : null}
                </List>
                {/* <Divider className={classes.listDivider} /> */}
                <List component="div" disablePadding>
                    {/* <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={ForwardNavLink}
                        to="/reports"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="统计表(VIP功能)"
                        />
                    </ListItem> */}
                    {/* {cur_user ? (
                        ['AdminB','AdminC'].includes( cur_user.RoleCode ) ? null : (
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={ForwardNavLink}
                                to="/account"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AccountBoxIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="用户中心"
                                />
                            </ListItem>
                        )
                    ) : null} */}
                </List>
                <Divider className={classes.listDivider} />
                <List component="div" disablePadding>
                    {cur_user?(
                        ['AdminB','AdminC'].includes( cur_user.RoleCode ) ? null : (
                            <ListItem
                                className={classes.listItem}
                                component={ForwardNavLink}
                                to="/center"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <InfoIcon />
                                </ListItemIcon>

                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={'用户中心'}
                                />
                            </ListItem>
                        )
                    ) : null}
                </List>
                <Divider className={classes.listDivider} />
                <List
                    component="div"
                    disablePadding
                    subheader={
                        <ListSubheader className={classes.listSubheader}>
                            客户服务
                        </ListSubheader>
                    }
                >
                    <ListItem
                        className={classes.listItem}
                        component="a"
                        // href="http://ddsignweb.wo-ish.com"
                        // target="_blank"
                    >
                        {/* <ListItemIcon className={classes.listItemIcon}>
                            <InfoIcon />
                        </ListItemIcon> */}

                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={
                                <div style={{ fontSize: '14px' }}>
                                    <div>邮箱: dingdong@ddqiandao.com</div>
                                    <div>QQ客服: 860799988</div>
                                    <div>客服电话:0531-86079998</div>
                                </div>
                            }
                        />
                    </ListItem>
                </List>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cpy: state.cpy
});

const mapDispatchToProps = dispatch => ({
    cpyActions: data => dispatch(cpyactions.getcpylist(data)),
    reportActions: data => dispatch(reportActions.getReport(data)),
    monthListActions: data => dispatch(reportActions.getMonthList(data)),
    monthReportActions: data => dispatch(reportActions.getMonthReport(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
        withRouter,
        withStyles(styles)
    )(Sidebar)
);

// withStyles(styles)(withRouter(Sidebar));
