import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default function SnackbarsMessage(props) {
    const { visible, msg, handleClose } = props;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            autoHideDuration={2000}
            ContentProps={{
                'aria-describedby': 'message-id'
            }}
            key={('top', 'center')}
            message={<span id="message-id">{msg}</span>}
            onClose={handleClose}
            open={visible}
        />
    );
}
