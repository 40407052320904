import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { InfoSettings, FuncSettings } from './components';

import Http from 'services/http';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class Settings extends Component {
    state = {
        companyList: [],
        curCpy: 0,
        curcpystatus: {},
        loading: false,
        cpy_name: '',
        cpy_sn: '',
        in_form: {
            spot_check_remark: ''
        },
        out_form: {
            out_remark1: '',
            out_remark2: ''
        },

        accountInfo:{},
        productInfo:{},
        accountInfoStatus:false,
        productInfoStatus:false
    };
    async componentDidMount() {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({
            loading: true
        });
        const {curCpy,cpy_sn,cpy_name}=this.state
        try {
            const res = await Http.cpyList();
            this.handleAccountInfo(cur_user.UserID)
            this.handleProductInfo(cur_user.UserID)
            const res2 = await Http.cpySignConfig(
                {
                    companyid: curCpy==0?res.data[0].CompanySN:cpy_sn
                },
                true
            );
            let cpyList = res.data;
            if(curCpy==0){
                this.setState({
                    cpy_sn: cpyList[0].CompanySN
                });
            }
            const { data, errcode } = res;
            let cname=''
            if (errcode === 0) {
                for (let i = 0; i < data.length; i++) {
                    if(data[i].CompanyID==curCpy){
                        cname=data[i].CompanyName
                    }
                    cpyList[i]['detail'] = {};
                    try {
                        let cpy_detail = await Http.getTotalbyCpysn(
                            { companysn: data[i].CompanySN },
                            true
                        );
                        if (cpy_detail.errcode === 0) {
                            cpyList[i]['detail'] = cpy_detail.data;
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
            // console.log(cpyList)
            this.setState({
                curcpystatus: res2.data,
                curCpy: curCpy==0?res.data[0].CompanyID:curCpy,
                cpy_sn: curCpy==0?res.data[0].CompanySN:cpy_sn,
                cpy_name: curCpy==0?res.data[0].CompanyName:cname,
                companyList: cpyList,
                loading: false
            });
        } catch (err) {
            console.log(err);
        }
    }

    changeCurCpy = async (curCpy, cpySn, cpyName) => {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({
            loading: true,
            curCpy: curCpy
        });
        try {
            // 调用更改session的接口
            await Http.upcpyid(
                {
                    userid: cur_user.UserID,
                    cpysn: cpySn
                },
                true
            );
            const res = await Http.cpySignConfig(
                {
                    companyid: cpySn
                },
                true
            );
            const { errcode, errmsg, data } = res;
            if (errcode === 0) {
                this.setState({
                    curcpystatus: res.data,
                    loading: false,
                    cpy_name: cpyName,
                    cpy_sn: cpySn
                });
            } else {
                this.setState({
                    loading: false
                });
                alert('登录过期请重新登录');
            }
        } catch (err) {
            this.setState({
                loading: false
            });
            console.log(err);
        }
        this.setState({
            loading: false
        });
    };

    handleProductInfo= async (info)=>{
        try{
            const { data, errcode, errmsg } = await Http.getProductInfo({managerid:info});
            if (errcode !== 0) {
                console.log(errmsg)
                this.setState({productInfoStatus: false})
            } else {
                this.setState({productInfoStatus: true, productInfo:data}) 
            }
        }catch(error){
            return;
        }
    }
  
    handleAccountInfo= async (info)=>{
        try {
            const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
            if (errcode !== 0) {
                this.setState({accountInfoStatus: false})
            } else {
                this.setState({accountInfoStatus: true, accountInfo:data})         
            }
        }catch (error) {
            return;
        }
    }

    refresh=()=>{
        this.componentDidMount();
    }

    refresh1 = async () => {
        try {
            const res = await Http.cpySignConfig(
                {
                    companyid: this.state.cpy_sn
                },
                true
            );
            this.setState({
                curcpystatus: res.data
            });
        } catch (err) {
            console.log(err);
        }
    };

    changeFunc = async type => {
        const { curcpystatus, cpy_sn } = this.state;
        if (type === 'autoenter') {
            await Http.change_auto_enter(
                {
                    companyid: cpy_sn,
                    auto: parseInt(curcpystatus.AutoEnter) ? 0 : 1
                },
                true
            );
            this.refresh1();
        } else if (type === 'outcheck') {
            await Http.signConfigUpdtW(
                {
                    OuterCheck: parseInt(curcpystatus.OuterCheck) ? 0 : 1,
                    OnRemarkW: curcpystatus.OnRemarkW,
                    OffRemarkW: curcpystatus.OffRemarkW,
                    companysn: cpy_sn
                },
                true
            );
            this.refresh1();
        } else {
            await Http.signConfigUpdtN(
                {
                    InnerCheck: parseInt(curcpystatus.InnerCheck) ? 0 : 1,
                    OnRemarkN: curcpystatus.OnRemarkN,
                    OffRemarkN: curcpystatus.OffRemarkN,
                    companysn: cpy_sn
                },
                true
            );
            this.refresh1();
        }
    };

    render() {
        const { classes } = this.props;
        const {companyList,curCpy,cpy_sn,loading, cpy_name,curcpystatus, accountInfoStatus, accountInfo}=this.state

        return (
            <DashboardLayout title="单位管理">
                <div className={classes.root}>
                    <Grid container spacing={4} padding={4}>
                        <Grid item md={7} xs={12}>
                            <InfoSettings
                                changeCurCpy={this.changeCurCpy}
                                companyList={companyList}
                                curCpyId={curCpy}
                                curcpysn={cpy_sn}
                                cpy_name={cpy_name}
                                loading={(loading==true || accountInfoStatus==false)?true:false}
                                refresh={this.refresh}
                                accountInfo={accountInfo}
                                // refresh={() => this.componentDidMount()}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <FuncSettings
                                changefunc={this.changeFunc}
                                cpy_name={cpy_name}
                                curcpy={cpy_sn}
                                curcpystatus={curcpystatus}
                                refresh1={this.refresh1}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DashboardLayout>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
