export default {
    name: {
        presence: { allowEmpty: false, message: '请填写发票抬头' }
    },
    taxNo: {
        presence: { allowEmpty: false, message: '请输入税号' }
    },
    email:{
        email:{
            message: "请填写正确的电子邮箱"
          }
    }
};
