import React, { Component } from 'react';
// date time components
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { zhCN } from 'date-fns/locale';
// Material components
import { InputAdornment,Chip, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Grid, Box, IconButton, TextField, withStyles, Tooltip } from '@material-ui/core';

// Material icons
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
// Externals
import PropTypes from 'prop-types';
// Component styles
import styles from './styles';
// Form validation schema
const kqmode=[{id:0, name:'当天固定班次考勤', desp:'当天范围内，最多设置3个考勤时段，每个时段设置开始签到时间、上班考勤时间、下班考勤时间。多个时段之间的时间设置不能重叠。', desp2:'开始签到时间未到不能签到，到了开始签到时间可以签到，上班考勤时间之后签到为迟到，下班考勤时间之前签退为早退。'},
    {id:1, name:'跨天固定班次考勤', desp:'24小时范围内，最多设置3个考勤时段，每个时段设置开始签到时间、上班考勤时间、下班考勤时间、结束签到时间，时段之间不能重叠，可以跨天设置时间，考勤时段的时间跨度在24小时内。', desp2:'开始签到时间未到不能签到，到了开始签到时间可以签到，上班考勤时间之后签到为迟到，下班考勤时间之前签退为早退，结束签到时间之前可以签退，结束签到时间之后不能签退'}
]

const cross=['次日','当天']

class Index extends Component {
    handlePickerTime = e => {
    };
    handleCrossClick=(e)=>{
        e.stopPropagation()
    }
    handleCrossChange=(e,i)=>{
        e.stopPropagation()
        this.props.handlefieldchange(e.target.value,i)
    }

    handleNameClick=(e,i)=>{
        if(e.target.value.trim().length==0){
            this.props.isvalid(false)
        }
        this.props.handlefieldchange(e.target.value.trim(),i)
    }

    handleFieldChange=(v,i)=>{
        this.props.handlefieldchange(v,i)
    }

    render() {
        const { classes, form_value, changenum, accountinfo } = this.props;        
        const {mode1num: num}=this.props.form_value.mode1info

        return (
            <div>
                <Typography component='div' variant="h5">
                    考勤信息设置
                    {(accountinfo.isVip<=0?<Typography component='span' variant="body2" style={{color:'red'}} >
                        （需要会员资格才能设置该考勤方式）
                    </Typography>:null)}
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography component='span' variant="body1">
                            <TextField margin="dense" label="考勤方式" name="mode"  variant="outlined" required select
                                style={{width:'100%'}} value={form_value.mode} SelectProps={{ native: true }}
                                onChange={event => this.handleFieldChange(parseInt(event.target.value), 'mode') }
                            >
                                {kqmode.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            <Tooltip title={kqmode[form_value.mode].desp2}>
                            <Typography component='span' variant='body2'>
                                {kqmode[form_value.mode].desp}
                            </Typography>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{marginTop:5, marginBottom:10}} />
                <Grid container spacing={4}>
                    <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                        {/* <Tooltip title={form_value.pname1}>
                            <TextField required label="考勤时段名称" margin="dense" value={form_value.pname1} variant="outlined"
                                onChange={event => this.handleNameClick(event, 'pname1')} 
                                onFocus={event => this.handleNameClick(event, 'pname1')}
                                placeholder='请输入名称'                                
                                error={form_value.pname1.trim().length==0} 
                                helperText={form_value.pname1.trim().length==0?'不能为空':''}
                            />
                        </Tooltip> */}                 
                        <Typography component='div' variant="subtitle1" style={{color:accountinfo.isVip>0?'#12161b':'#aaaaaa'}} >
                            第一时段:
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>                    
                        <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                            <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。时间设置跨天时选择次日。'>
                                <span>
                                    <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                        margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                        InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.stime1}
                                        onChange={value => this.handleFieldChange(value, 'stime1') }
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                    {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true}  style={{fontSize:14}} value={form_value.mode1info.flag11} autoWidth
                                                                onChange={event=>this.handleCrossChange(event, 'flag11')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag11')}
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    {/* </Tooltip> */}
                                                </InputAdornment>,
                                        }}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title='过了上班考勤时间才开始签到为迟到。时间设置跨天时选择次日。'>
                                <span>
                                    <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="上班考勤时间" cancelLabel="取消"  okLabel="确定" inputVariant="outlined"
                                        margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                        onChange={value => this.handleFieldChange(value, 'ontime1') }
                                        InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.ontime1}
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                    {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                    <FormControl size="small">
                                                        <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}} value={form_value.mode1info.flag12} autoWidth
                                                            onChange={event=>this.handleCrossChange(event, 'flag12')}
                                                            onClick={event=>this.handleCrossClick(event, 'flag12')}
                                                        >
                                                            <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                            <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </Tooltip> */}
                                                </InputAdornment>,
                                        }}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title='未到下班考勤时间就签退为早退。时间设置跨天时选择次日。'>
                                <span>
                                    <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="下班考勤时间" cancelLabel="取消"  okLabel="确定" inputVariant="outlined"
                                        margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                        onChange={value => this.handleFieldChange(value, 'offtime1') }
                                        InputLabelProps={{ classes: { root: classes.label } }}  value={form_value.mode1info.offtime1}
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                    {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                    <FormControl size="small">
                                                        <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}} value={form_value.mode1info.flag13} autoWidth
                                                            onChange={event=>this.handleCrossChange(event, 'flag13')}
                                                            onClick={event=>this.handleCrossClick(event, 'flag13')}
                                                        >
                                                            <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                            <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </Tooltip> */}
                                                </InputAdornment>,
                                        }}                                
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title='结束签到时间之前还可以该时段的签到/签退，结束签到时间之后无法再进行该时段的签到/签退。时间设置跨天时选择次日。'>
                                <span>
                                    <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="结束签到时间" cancelLabel="取消"  okLabel="确定" inputVariant="outlined"
                                        ampm={false} margin="dense" style={{ marginRight: 20, width: '20%' }}
                                        InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.etime1}
                                        onChange={value => this.handleFieldChange(value, 'etime1') }
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                    {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                    <FormControl size="small">
                                                        <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}} value={form_value.mode1info.flag14} autoWidth
                                                            onChange={event=>this.handleCrossChange(event, 'flag14')}
                                                            onClick={event=>this.handleCrossClick(event, 'flag14')}
                                                        >
                                                            <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                            <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </Tooltip> */}
                                                </InputAdornment>,
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Divider style={{marginTop:5, marginBottom:10}} />
                <div hidden={num > 0 ? false : true}>
                    <Grid container spacing={4} >
                        <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                            {/* <Tooltip title={form_value.pname2}>
                                <TextField required label="考勤时段名称" margin="dense" value={form_value.pname2} variant="outlined"
                                    onChange={event => this.handleNameClick(event, 'pname2')} 
                                    onFocus={event => this.handleNameClick(event, 'pname2')}
                                    placeholder='请输入名称'                                
                                    error={form_value.pname2.trim().length==0} 
                                    helperText={form_value.pname2.trim().length==0?'不能为空':''}
                                />
                            </Tooltip> */}                 
                            <Typography component='div' variant="subtitle1" style={{color:accountinfo.isVip>0?'#12161b':'#aaaaaa'}}>
                                第二时段:
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>                    
                            <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                                <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定"  inputVariant="outlined" 
                                            margin="dense" ampm={false}  style={{ marginRight: 20, width: '20%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}} value={form_value.mode1info.stime2}
                                            onChange={value =>this.handleFieldChange(value, 'stime2') }
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                    {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}}
                                                                value={form_value.mode1info.flag21}
                                                                onChange={event=>this.handleCrossChange(event, 'flag21')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag21')}
                                                                autoWidth
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='过了上班考勤时间才开始签到为迟到。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                            margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                            onChange={value => this.handleFieldChange(value, 'ontime2') }
                                            InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.ontime2}
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                        {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}}
                                                                value={form_value.mode1info.flag22}
                                                                onChange={event=>this.handleCrossChange(event, 'flag22')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag22')}
                                                                autoWidth
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='未到下班考勤时间就签退为早退。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                            margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                            onChange={value => this.handleFieldChange(value, 'offtime2') }
                                            InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.offtime2}
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                        {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}} value={form_value.mode1info.flag23} autoWidth
                                                                onChange={event=>this.handleCrossChange(event, 'flag23')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag23')}
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='结束签到时间之前还可以该时段的签到/签退，结束签到时间之后无法再进行该时段的签到/签退。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk  label="结束签到时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                            ampm={false} margin="dense" style={{ marginRight: 20, width: '20%' }}
                                            InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.etime2}
                                            onChange={value => this.handleFieldChange(value, 'etime2') }
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                        {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}}
                                                                value={form_value.mode1info.flag24}
                                                                onChange={event=>this.handleCrossChange(event, 'flag24')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag24')}
                                                                autoWidth
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </div>
                <Divider style={{marginTop:5, marginBottom:10}} />
                <div hidden={num > 1 ? false : true}>
                <Grid container spacing={4} >
                        <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                            {/* <Tooltip title={form_value.pname3}>
                                <TextField required label="考勤时段名称" margin="dense" value={form_value.pname3} variant="outlined"
                                    onChange={event => this.handleNameClick(event, 'pname3')} 
                                    onFocus={event => this.handleNameClick(event, 'pname3')}
                                    placeholder='请输入名称'                                
                                    error={form_value.pname3.trim().length==0} 
                                    helperText={form_value.pname3.trim().length==0?'不能为空':''}
                                />
                            </Tooltip> */}                 
                            <Typography component='div' variant="subtitle1" style={{color:accountinfo.isVip>0?'#12161b':'#aaaaaa'}}>
                                第三时段:
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>                    
                            <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                                <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定"  inputVariant="outlined" 
                                            margin="dense" ampm={false}  style={{ marginRight: 20, width: '20%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}} value={form_value.mode1info.stime3}
                                            onChange={value =>this.handleFieldChange(value, 'stime3') }
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                    {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}}
                                                                value={form_value.mode1info.flag31}
                                                                onChange={event=>this.handleCrossChange(event, 'flag31')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag31')}
                                                                autoWidth
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='过了上班考勤时间才开始签到为迟到。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                            margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                            onChange={value => this.handleFieldChange(value, 'ontime3') }
                                            InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.ontime3}
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                        {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}}
                                                                value={form_value.mode1info.flag32}
                                                                onChange={event=>this.handleCrossChange(event, 'flag32')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag32')}
                                                                autoWidth
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='未到下班考勤时间就签退为早退。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                            margin="dense" ampm={false} style={{ marginRight: 20, width: '20%' }}
                                            onChange={value => this.handleFieldChange(value, 'offtime3') }
                                            InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.offtime3}
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                        {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}} value={form_value.mode1info.flag33} autoWidth
                                                                onChange={event=>this.handleCrossChange(event, 'flag33')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag33')}
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='结束签到时间之前还可以该时段的签到/签退，结束签到时间之后无法再进行该时段的签到/签退。时间设置跨天时选择次日。'>
                                    <span>
                                        <TimePicker disabled={accountinfo.isVip>0?false:true} autoOk  label="结束签到时间" cancelLabel="取消" okLabel="确定" inputVariant="outlined"
                                            ampm={false} margin="dense" style={{ marginRight: 20, width: '20%' }}
                                            InputLabelProps={{ classes: { root: classes.label } }} value={form_value.mode1info.etime3}
                                            onChange={value => this.handleFieldChange(value, 'etime3') }
                                            InputProps={{
                                                startAdornment: 
                                                    <InputAdornment position="start">
                                                        {/* <Tooltip title='时间设置跨天时选择次日'> */}
                                                        <FormControl size="small">
                                                            <Select disabled={accountinfo.isVip>0?false:true} style={{fontSize:14}}
                                                                value={form_value.mode1info.flag34}
                                                                onChange={event=>this.handleCrossChange(event, 'flag34')}
                                                                onClick={event=>this.handleCrossClick(event, 'flag34')}
                                                                autoWidth
                                                            >
                                                                <MenuItem value={1}><span style={{fontSize:14}}>当天</span></MenuItem>
                                                                <MenuItem value={0}><span style={{fontSize:14, color:'blue'}}>次日</span></MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* </Tooltip> */}
                                                    </InputAdornment>,
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ float: 'right' }}>
                    {num === 0 ? null : (
                        <IconButton disabled={accountinfo.isVip>0?false:true} aria-label="delete" className={classes.margin} onClick={() => changenum('minus')} >
                            <RemoveCircleOutline />
                        </IconButton>
                    )}

                    {num === 2 ? null : (
                        <IconButton disabled={accountinfo.isVip>0?false:true} aria-label="delete" className={classes.margin} onClick={() => changenum('add')} >
                            <AddCircleOutline />
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}
Index.propTypes = {
    changenum: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    form_value: PropTypes.object.isRequired,
    handlefieldchange: PropTypes.func.isRequired,
    accountinfo: PropTypes.object.isRequired
    // num: PropTypes.number.isRequired,
    // isvalid: PropTypes.func.isRequired
};
export default withStyles(styles)(Index);
