import React from 'react';

import MaterialTable from 'material-table';
import TableIcons from '../../../components/TableIcons/index';

import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import ReplyMessageDialog from '../ReplyMessageDialog/index';
import Http from '../../../services/http';
import export_to_excel from 'utils/export_to_excel';

import styles from './styles';

class MessageTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            change_dialog_state: false,
            staff_detail_state: false,
            open: false,
            message: '',
            messageid: '',
            curpage:0,
            pagesize:5,
            pagearray:[5,10,20]
        };
    }

    async componentDidMount() {
        this.setState({
            loading: true
        });
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));

        try {
            const res = await Http.get_messages(
                {
                    companyid: cur_cpy.CompanySN
                },
                true
            );
            if (res.data !== undefined) {
                let pa=[5,10,20]
                if(res.data.length<11){
                    pa.pop(20)
                }
                if(res.data.length<6){
                    pa.pop(10)
                }
                this.setState({ messages: res.data, loading: false, pagearray:pa});
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
            console.log(e);
        }
    }

    handleClose = () => {
        this.setState({
            change_dialog_state: false,
            staff_detail_state: false
        });
    };
    handleSave = () => {
        // 1,把 cur_router 提交到后台进行保存
        // 2,成功了关闭 dialog
        // 3,不成功提示用于重试
        this.setState({
            change_dialog_state: false
        });
    };

    handle_re_message = async key => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        try {
            const res = await Http.message_detail(
                {
                    smgid: key.id,
                    companyid: cur_cpy.CompanySN
                },
                true
            );
            const { errmsg, errcode } = res;
            if (errcode === 0) {
                this.setState({
                    open: true,
                    messageid: key.id,
                    message: res.data
                });
            } else {
                console.log(errmsg);
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };  

    refresh = () => {
        this.componentDidMount()
    };   

    handleRowsChange = async (rowsPerPage) => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async (Page) => {
        this.setState({ curpage: Page })
    }

    render() {
        const { classes, className, ...rest } = this.props;
        const columns = [
            { title: '纸条标题', field: 'title', sorting: false },
            { title: '纸条内容', field: 'content', sorting: false },
            { title: '创建时间', filtering: false ,field: 'sendtime', defaultSort: 'desc' },
            { title: '发布人', field: 'username', sorting: false },
            { title: '回复', field: 'isrp' },
            { title: '操作', field: '', filtering: false , sorting:false,
                render: key => (
                    <Button
                        color="primary"
                        onClick={() => this.handle_re_message(key)}
                        variant="contained"
                    >
                        {key.isrp === '有' ? '查看' : '回复'}
                    </Button>
                )
            }
        ];
        const rootClassName = classNames(classes.root, className);
        const { messages,curpage,pagesize,pagearray,message,messageid,open } = this.state;
        return (
            <div>
                <ReplyMessageDialog
                    handleClose={this.handleClose}
                    message={message}
                    messageid={messageid}
                    open={open}
                    refresh={this.refresh}
                />
                <MaterialTable
                    columns={columns}
                    data={messages}
                    icons={TableIcons}
                    localization={{
                        pagination: {
                            labelRowsSelect: '行每页',
                            labelDisplayedRows: messages.length>0?' {from}-{to} 共 {count} 条':'0-0 共 0 条',
                            firstTooltip: '第一页',
                            previousTooltip: '前一页',
                            nextTooltip: '后一页',
                            lastTooltip: '到最后'
                        },
                        toolbar: {
                            searchTooltip: '模糊查找',
                            searchPlaceholder: '模糊查找',
                            exportName: '导出表格',
                            nRowsSelected: '{0} row(s) selected',
                            exportTitle: '导出表格'
                        },
                        header: {
                            actions: '操作'
                        },
                        body: {
                            addTooltip: '新增记录',
                            emptyDataSourceMessage: '暂时没有数据',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: '确定要删除吗？',
                                cancelTooltip: '取消',
                                saveTooltip: '确定'
                            }
                        }
                    }}
                    onChangeRowsPerPage={this.handleRowsChange}
                    onChangePage={this.handlePageChange}
                    options={{
                        exportButton: true,
                        minBodyHeight:600,
                        paging:true,
                        pageSizeOptions: pagearray,
                        pageSize:pagesize,
                        paginationType:'normal',
                        initialPage:curpage,
                        exportCsv: export_to_excel
                    }}
                    title="所有小纸条"
                />
            </div>
        );
    }
}

export default withStyles(styles)(MessageTable);
