import Http from 'services/http';
import { formatDistanceStrict } from 'date-fns';
import { zhCN } from 'date-fns/locale';

const initReportState = {
    report_day_list: [],
    report_month_list: [],
    report_month_report: [],
    report_month_chart: [],
    loading: false
};

export const types = {
    // 获取单位日报列表
    REPORT_LIST_REQUEST: 'REPORT/REPORT_LIST_REQUEST',
    REPORT_LIST_SUCCESS: 'REPORT/REPORT_LIST_SUCCESS',
    REPORT_LIST_FAILURE: 'REPORT/REPORT_LIST_FAILURE',

    // 获取单位月报列表
    REPORT_MONTH_LIST_REQUEST: 'REPORT/REPORT_MONTH_LIST_REQUEST',
    REPORT_MONTH_LIST_SUCESS: 'REPORT/REPORT_MONTH_LIST_SUCESS',
    REPORT_MONTH_LIST_FAILURE: 'REPORT/REPORT_MONTH_LIST_FAILURE',

    // 获取单位月统计列表
    REPORT_MONTH_REQUEST: 'REPORT/REPORT_MONTH_REQUEST',
    REPORT_MONTH_SUCESS: 'REPORT/REPORT_MONTH_SUCESS',
    REPORT_MONTH_FAILURE: 'REPORT/REPORT_MONTH_FAILURE',

    // 获取单位月统计列表
    CHART_MONTH_REQUEST: 'REPORT/CHART_MONTH_REQUEST',
    CHART_MONTH_SUCESS: 'REPORT/CHART_MONTH_SUCESS',
    CHART_MONTH_FAILURE: 'REPORT/CHART_MONTH_FAILURE'
};

// actionn creator
// 日签到明细
const doGetReportListRequest = () => ({
    type: types.REPORT_LIST_REQUEST,
    data: { loading: true }
});
const doGetReportListSuccess = data => ({
    type: types.REPORT_LIST_SUCCESS,
    data
});
const doGetReportListFailure = error => ({
    type: types.REPORT_LIST_FAILURE,
    error
});
// 月签到明细
const doGetMonthListRequest = () => ({
    type: types.REPORT_MONTH_LIST_REQUEST,
    data: { loading: true }
});
const doGetMonthListSucess = data => ({
    type: types.REPORT_MONTH_LIST_SUCESS,
    data
});
const doGetMonthListFailure = error => ({
    type: types.REPORT_MONTH_LIST_FAILURE,
    error
});
// 月报表
const doGetMonthReportRequest = () => ({
    type: types.REPORT_MONTH_REQUEST,
    data: { loading: true }
});
const doGetMonthReportSucess = data => ({
    type: types.REPORT_MONTH_SUCESS,
    data
});
const doGetMonthReportFailure = error => ({
    type: types.REPORT_MONTH_FAILURE,
    error
});
// 月考勤榜报表
const doGetMonthChartRequest = () => ({
    type: types.CHART_MONTH_REQUEST,
    data: { loading: true }
});
const doGetMonthChartSucess = data => ({
    type: types.CHART_MONTH_SUCESS,
    data
});
const doGetMonthChartFailure = error => ({
    type: types.CHART_MONTH_FAILURE,
    error
});

export const actions = {
    // 日签到明细
    getReport: date => {
        return async dispatch => {
            const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
            dispatch(doGetReportListRequest());
            try {
                const res = await Http.sign_in_list(
                    {
                        Sch_dt: date,
                        Sch_bu: 0,
                        CompanyID: cur_cpy.CompanyID,
                        type: 'Web_RiQDmingxiReact'
                    },
                    true
                );
                const { data, errcode, errmsg } = res;
                let cpy_day_list = res.data.map(user => {
                    let out_flag1 = user.comeimefmt.indexOf('外');
                    let out_flag2 = user.gotimefmt.indexOf('外');

                    if (out_flag1 === -1 && out_flag2 === -1) {
                        if ( user.gotimefmt === '' || user.comeimefmt === '' || out_flag1 !== -1 || out_flag2 !== -1 ) {
                            user['remark'] = '非正常考勤';
                            return user;
                        } else {
                            let mins= parseInt(formatDistanceStrict(
                                new Date(user.onDate),
                                new Date(user.offDate),
                                {
                                    unit:'minute',
                                    locale: zhCN
                                }
                            ).replace('分钟','').trim());
                            user['remark'] = mins>60?Math.floor(mins/60)+'小时'+mins%60+'分钟':mins+'分钟';
                            return user;
                        }
                    }else{
                        if ( user.gotimefmt !== '' && user.comeimefmt !== '') {
                            let mins= parseInt(formatDistanceStrict(
                                new Date(user.onDate),
                                new Date(user.offDate),
                                {
                                    unit:'minute',
                                    locale: zhCN
                                }
                            ).replace('分钟','').trim());
                            user['remark'] = (mins>60?Math.floor(mins/60)+'小时'+mins%60+'分钟':mins+'分钟')+'(外勤)';
                            return user;
                        }else{
                            user['remark'] = '-'
                            return user;
                        }
                    }
                });
                if (errcode !== 0) {
                    dispatch(
                        doGetReportListFailure({ ...errmsg, loading: false })
                    );
                } else {
                    dispatch(
                        doGetReportListSuccess({
                            report_day_list: cpy_day_list,
                            loading: false
                        })
                    );
                }
            } catch (err) {
                dispatch(doGetReportListFailure({ ...err, loading: false }));
            }
        };
    },
    // 月签到明细
    getMonthList: date => {
        return async dispatch => {
            const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
            dispatch(doGetMonthListRequest());
            try {
                const res = await Http.monthReport(
                    {
                        date: date,
                        companyid: cur_cpy.CompanySN
                    },
                    true
                );
                const { data, errcode, errmsg } = res;
                const users = data.map(user => {
                    let out_flag1 = user.comeimefmt.indexOf('外');
                    let out_flag2 = user.gotimefmt.indexOf('外');
                    if (out_flag1 === -1 && out_flag2 === -1) {
                        if (
                            user.comeimefmt === '' ||
                            user.gotimefmt === '' ||
                            out_flag1 !== -1 ||
                            out_flag2 !== -1
                        ) {
                            user['remark'] = '非正常考勤';
                        } else {
                            let mins= parseInt(formatDistanceStrict(
                                new Date(user.onDate),
                                new Date(user.offDate),
                                {
                                    unit:'minute',
                                    locale: zhCN
                                }
                            ).replace('分钟','').trim());
                            user['remark'] = mins>60?Math.floor(mins/60)+'小时'+mins%60+'分钟':mins+'分钟';
                        }
                    } else {
                        if(user.comeimefmt!=='' && user.gotimefmt!==''){
                            let mins= parseInt(formatDistanceStrict(
                                new Date(user.onDate),
                                new Date(user.offDate),
                                {
                                    unit:'minute',
                                    locale: zhCN
                                }
                            ).replace('分钟','').trim());
                            user['remark'] = (mins>60?Math.floor(mins/60)+'小时'+mins%60+'分钟':mins+'分钟')+'(外勤)';
                        }
                        else{
                            user['remark'] = '-';
                        }
                    }
                    return user;
                });

                if (errcode !== 0) {
                    dispatch(
                        doGetMonthListFailure({ ...errmsg, loading: false })
                    );
                } else {
                    dispatch(
                        doGetMonthListSucess({
                            report_month_list: users,
                            loading: false
                        })
                    );
                }
            } catch (err) {
                dispatch(doGetMonthListFailure({ ...err, loading: false }));
            }
        };
    },
    //
    getMonthReport: date => {
        return async dispatch => {
            const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
            dispatch(doGetMonthReportRequest());
            try {
                const res = await Http.monthStatistics(
                    {
                        companyid: cur_cpy.CompanySN,
                        date: date
                    },
                    true
                );
                const { data, errcode, errmsg } = res;
                data.map(item => {
                    let wl=item.worklong>60?Math.floor(item.worklong/60)+'小时'+item.worklong%60+'分钟':item.worklong+'分钟'
                    item['worklong']=wl
                });
                if (errcode !== 0) {
                    dispatch(
                        doGetMonthReportFailure({ ...errmsg, loading: false })
                    );
                } else {
                    dispatch(
                        doGetMonthReportSucess({
                            report_month_report: data,
                            loading: false
                        })
                    );
                }
            } catch (err) {
                dispatch(doGetMonthReportFailure({ ...err, loading: false }));
            }
        };
    },
    //
    getMonthChart: date => {
        return async dispatch => {
            const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
            let now=new Date()
            let f_date=new Date(date)
            let fy=f_date.getFullYear()
            let fm=f_date.getMonth()+1
            let fd=f_date.getDate()
            let fromdate=(fy+'-'+(fm<10?('0'+fm):fm)+'-01')
            f_date.setMonth(fm)
            let tm=f_date.getMonth()+1
            let todate=(f_date.getFullYear()+'-'+(tm<10?('0'+tm):tm)+'-01')

            if(fm==now.getMonth()+1 && now.getFullYear()==fy ){
                if(fd==1){
                    todate=fromdate
                }else{
                    todate=(f_date.getFullYear()+'-'+(fm<10?('0'+fm):fm)+'-'+(fd<10?('0'+fd):fd))
                }                
            }

            dispatch(doGetMonthChartRequest());
            try {
                const res = await Http.monthChart(
                    {
                        companysn: cur_cpy.CompanySN,
                        fromdate: fromdate,
                        todate:todate
                    },
                    true
                );
                const { data, errcode, errmsg } = res;

                let s_date=new Date(fromdate)
                let e_date=new Date(todate)
                let days=Math.round(Math.abs((e_date.getTime()-s_date.getTime())/(3600*24*1000)))
                if(days>0 && data.length>0){
                    // console.log(data)
                    for(let i=0;i<data.length;i++){
                        for(let j=1;j<=days;j++){
                            let s=new Array(data[i][j+''].length);
                            for (let ii=0;ii<s.length;ii++){
                                s[ii]=new Array(2)
                                for(let jj=0;jj<2;jj++){
                                    // console.log(data[i][j+''][0])
                                    if(data[i][j+''][0]!='/'){
                                        let ss=data[i][j+''][ii].split("，")
                                        s[ii][jj]=ss[jj]
                                    }else{
                                        s[ii][jj]=''
                                    }                         
                                }
                            }
                            // console.log(s)
                            // let sss=new Array(s.length)
                            // for(let kk=0;kk<s.length;kk++){
                            //     sss[kk]=new Array(2)
                            //     console.log(s[kk].map((v,i)=><span key={i}>{v}</span>))
                            // }
                            data[i][j+'']=s
                        }
                    }
                }
                console.log(data)

                if (errcode !== 0) {
                    dispatch(
                        doGetMonthChartFailure({ ...errmsg, loading: false })
                    );
                } else {                    
                    dispatch(
                        doGetMonthChartSucess({
                            report_month_chart: data,
                            loading: false
                        })
                    );
                }
            } catch (err) {
                dispatch(doGetMonthChartFailure({ ...err, loading: false }));
            }
        };
    }
};

const reducer = (state = initReportState, action) => {
    switch (action.type) {
        case types.REPORT_LIST_REQUEST:
            return { ...state, ...action.data };
        case types.REPORT_LIST_SUCCESS:
            return {
                ...state,
                ...action.data
            };
        case types.REPORT_LIST_FAILURE:
            return {
                ...state,
                ...action.error
            };
        case types.REPORT_MONTH_LIST_REQUEST:
            return { ...state };
        case types.REPORT_MONTH_LIST_SUCESS:
            return {
                ...state,
                ...action.data
            };
        case types.REPORT_MONTH_LIST_FAILURE:
            return {
                ...state,
                ...action.error
            };
        case types.REPORT_MONTH_REQUEST:
            return { ...state };
        case types.REPORT_MONTH_SUCESS:
            return {
                ...state,
                ...action.data
            };
        case types.REPORT_MONTH_FAILURE:
            return {
                ...state,
                ...action.error
            };
        case types.CHART_MONTH_REQUEST:
            return { ...state };
        case types.CHART_MONTH_SUCESS:
            return {
                ...state,
                ...action.data
            };
        case types.CHART_MONTH_FAILURE:
            return {
                ...state,
                ...action.error
            };
        default:
            return state;
    }
};

export default reducer;
