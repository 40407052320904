import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import ProductValues from './QaComponent/ProductValues';
import AppAppBar from './QaComponent/AppAppBar';

function Index() {
    return (
        <React.Fragment>
            <AppAppBar />
            <ProductValues />
            <AppFooter />
        </React.Fragment>
    );
}

export default withRoot(Index);
