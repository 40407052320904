import React, { Component } from 'react';
// Externals
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import { connect } from 'react-redux';
import { actions as reportActions } from 'redux/modules/report';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Shared services

// Custom components
import { DayTable, MonthReport, MonthTable, UsersToolbar, MonthChart
} from './components';
// import MonthReport from './components/MonthReport';
// import MonthTable from './components/MonthTable';
// import UsersToolbar from './components/UsersToolbar';
// import _ from 'underscore';

// Component styles
import styles from './style';

const mapDispatchToProps = dispatch => ({
    dayTableActions: data => dispatch(reportActions.getReport(data)),
    monthTableActions: data => dispatch(reportActions.getMonthList(data)),
    monthReportActions: data => dispatch(reportActions.getMonthReport(data)),
    monthChartActions: data => dispatch(reportActions.getMonthChart(data))
});

@connect(
    state => ({ report: state.report }),
    mapDispatchToProps
)
class UserList extends Component {
    signal = true;

    state = {
        isLoading: false,
        limit: 10,
        users: [],
        selectedUsers: [],
        error: null,

        tab_value: 0,

        date_value: null,

        tableHasSubmit: false,
        reportHasSubmit: false,
        chartHasSubmit:false
    };

    componentDidMount() {
        const select_date = format(new Date(), 'yyyy-MM-dd');
        this.setState({
            date_value: select_date
        });
        this.props.dayTableActions(format(new Date(), 'yyyy-MM-dd'));
    }

    componentWillUnmount() {
        this.signal = false;
    }

    handleSelect = selectedUsers => {
        this.setState({ selectedUsers });
    };
    handleDateChange = async value => {
        const select_date = format(value, 'yyyy-MM-dd');
        this.setState({
            date_value: value
        });
        this.props.dayTableActions(select_date);
    };
    renderUsers() {
        const { report_day_list } = this.props.report;
        const pagearray=report_day_list.length>10?[5,10,20]:report_day_list.length>5?[5,10]:[5]
        return (
            <DayTable
                date_value={this.state.date_value}
                handledatechange={this.handleDateChange}
                isloading={this.props.report.loading}
                onSelect={this.handleSelect}
                users={this.props.report.report_day_list}
                pageprops={{pagearray:pagearray, curpage:0,pagesize:5}}
            />
        );
    }

    handleTabChange = (__, v) => {
        if (v === 1 && !this.state.tableHasSubmit) {
            this.props.monthTableActions(format(new Date(), 'yyyy-MM-dd'));
            this.setState({
                tableHasSubmit: true
            });
        } else if (v === 2 && !this.state.reportHasSubmit) {
            this.props.monthReportActions(format(new Date(), 'yyyy-MM-dd'));
            this.setState({
                reportHasSubmit: true
            });
        }else if (v === 3 && !this.state.chartHasSubmit) {
            this.props.monthChartActions(format(new Date(), 'yyyy-MM-dd'));
            this.setState({
                chartHasSubmit: true
            });
        }
        this.setState({
            tab_value: v
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <DashboardLayout title="考勤报表">
                <div className={classes.root}>
                    <UsersToolbar />
                    <Tabs
                        onChange={this.handleTabChange}
                        value={this.state.tab_value}textColor="primary" indicatorColor="primary"
                    >
                        <Tab id={0} label="日报表" />
                        <Tab id={1} label="月报表" />
                        <Tab id={2} label="月统计报表" />
                        {/* <Tab id={3} label="考勤榜" /> */}
                    </Tabs>
                    <div
                        className={classes.content}
                        hidden={!(0 === this.state.tab_value)}
                    >
                        {this.renderUsers()}
                    </div>
                    <div
                        className={classes.content}
                        hidden={!(1 === this.state.tab_value)}
                    >
                        <MonthTable num={this.state.tab_value} />
                    </div>
                    <div
                        className={classes.content}
                        hidden={!(2 === this.state.tab_value)}
                    >
                        <MonthReport num={this.state.tab_value} />
                    </div>
                    <div
                        className={classes.content}
                        hidden={!(3 === this.state.tab_value)}
                    >
                        {/* <MonthChart num={this.state.tab_value} /> */}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

UserList.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
