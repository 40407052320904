import React, { useState, useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '../components/AppBar';

import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import Http from 'services/http';

const styles = theme => ({
    title: {
        fontSize: 24
    },
    placeholder: toolbarStyles(theme).root,
    toolbar: {
        justifyContent: 'space-between'
    },
    left: {
        flex: 1
    },
    leftLinkActive: {
        color: theme.palette.common.white
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    rightLink: {
        fontSize: 16,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3)
    },
    linkSecondary: {
        color: theme.palette.secondary.main
    }
});

function AppAppBar(props) {
    const { classes } = props;
    const [isLogin, setIsLogin] = useState(false);
    async function checkSession() {
        try {
            let resData = await Http.session_check();
            if (resData.errcode === 0) {
                setIsLogin(true);
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        checkSession();
    }, []);
    return (
        <div>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.left} />
                    <Link
                        variant="h6"
                        underline="none"
                        color="inherit"
                        component={RouterLink}
                        className={classes.title}
                        to="/homepage"
                    >
                        {'功能特点'}
                    </Link>

                    {isLogin ? (
                        <div className={classes.right}>
                            <Link
                                color="inherit"
                                variant="h6"
                                underline="none"
                                component={RouterLink}
                                className={classes.rightLink}
                                to={
                                    JSON.parse(localStorage.getItem('cur_cpy'))
                                        ? '/signdata'
                                        : '/newuser'
                                }
                            >
                                {'进入管理'}
                            </Link>
                        </div>
                    ) : (
                        <div className={classes.right}>
                            <Link
                                color="inherit"
                                variant="h6"
                                underline="none"
                                component={RouterLink}
                                className={classes.rightLink}
                                to="/sign-in"
                            >
                                {'登录'}
                            </Link>
                            <Link
                                variant="h6"
                                underline="none"
                                component={RouterLink}
                                className={clsx(
                                    classes.rightLink,
                                    classes.linkSecondary
                                )}
                                to="/sign-up"
                            >
                                {'注册'}
                            </Link>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <div className={classes.placeholder} />
        </div>
    );
}

AppAppBar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppAppBar);
