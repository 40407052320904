import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Typography, Divider, Paper, Chip, Tooltip } from '@material-ui/core';

// Material icons
import { Group, FiberManualRecord } from '@material-ui/icons';

// Shared components
// import { Paper } from 'components';

// Component styles
import styles from './styles';
// import { Tooltip } from 'chart.js';

class RouterCard extends Component {
    render() {
        const { classes, className, product } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <Paper className={rootClassName}>
                <div className={classes.imageWrapper}>
                    <img alt="Product" className={classes.image}
                        src={ 'https://h5.gdong.net/dingdong/img/wifi.png' }
                    />
                </div>
                <div className={classes.details}>
                    <Typography variant="body1" align='center' >
                        wifi设备
                    </Typography>
                    <Typography className={classes.title} variant="h4" >
                        <Chip color="primary" label={product.EquName} onClick={() => this.props.openEditRouter(product)} />
                    </Typography>
                    <Typography className={classes.description} variant="body2" >
                    {product.remark.length==0?'':('备注：'+product.remark)}
                    </Typography>
                </div>
                <Divider />
                <div className={classes.stats}>
                    <FiberManualRecord
                        className={classes.updateIcon}
                        style={ 
                            product.MAC1 || product.MAC2 ? { color: 'green' } : { color: 'red' }
                        }
                    />
                    <Tooltip title={product.MAC1 || product.MAC2 ?'':'连接签到设备完成签到即可完成激活'}>
                        <Typography
                            className={classes.updateText}
                            variant="body2"
                        >
                            {product.MAC1 || product.MAC2 ? '已激活' : '未激活'}
                        </Typography>
                    </Tooltip>
                    <Group className={classes.downloadsIcon} />
                    <Typography className={classes.downloadsText} variant="body2" >
                        {product.userNum} 当前签到员工
                    </Typography>
                </div>
            </Paper>
        );
    }
}

RouterCard.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired
};

export default withStyles(styles)(RouterCard);
