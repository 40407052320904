import React, { Component } from 'react';
// date time components
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { zhCN } from 'date-fns/locale';
// Material components
import { TextField, withStyles, IconButton, Divider, Grid, Tooltip, Typography, Box } from '@material-ui/core';

// Material icons
import {AddCircleOutline, RemoveCircleOutline} from '@material-ui/icons';
// Externals
import PropTypes from 'prop-types';
// Component styles
import styles from './styles';
// Form validation schema
class Index extends Component {

    handleNameClick=(e,i)=>{
        if(e.target.value.trim().length==0){
            this.props.isvalid(false)
        }
        this.props.handlefieldchange(e.target.value.trim(),i)
    }

    handleFieldChange=(v,i)=>{
        this.props.handlefieldchange(v,i)
    }

    handleInit=()=>{
        this.props.handlefieldchange(0,'mode')
    }


    render() {
        const { classes, form_value, num, changenum } = this.props;
        if(form_value.mode!==0){
            this.handleInit()
        }
        return (
            <div>
                <Typography component='div' variant="h5">
                    考勤信息设置
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography component='span' variant="body1">
                            <TextField margin="dense" label="考勤方式" name="mode"  variant="outlined" readOnly
                                style={{width:'100%', fontSize:12}} value={'当天固定班次考勤'}
                            >
                                
                            </TextField>
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            <Tooltip title='开始签到时间未到不能签到，到了开始签到时间可以签到，上班考勤时间之后签到为迟到，下班考勤时间之前签退为早退。'>
                            <Typography component='span' variant='body2'>
                                当天范围内，最多设置3个考勤时段，每个时段设置开始签到时间、上班考勤时间、下班考勤时间。多个时段之间的时间设置不能重叠。
                            </Typography>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4} style={{marginTop:5}}>
                    <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                        {/* <Tooltip title={form_value.pname1}>
                            <TextField required label="考勤时段名称" margin="dense" value={form_value.pname1} variant="outlined"
                                onChange={event => this.handleNameClick(event, 'pname1')} 
                                onFocus={event => this.handleNameClick(event, 'pname1')}
                                placeholder='请输入名称'                                
                                error={form_value.pname1.trim().length==0} 
                                helperText={form_value.pname1.trim().length==0?'不能为空':''}
                            />
                        </Tooltip> */}                 
                        <Typography component='div' variant="subtitle1" >
                            第一时段:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} >                    
                        <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                            <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。'>
                            <span>
                            <TimePicker autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" 
                                inputVariant="outlined" margin="dense" ampm={false}
                                onChange={value => this.handleFieldChange(value, 'stime1') }
                                style={{ marginRight: 15, width: '25%' }}
                                InputLabelProps={{ classes: { root: classes.label } }}
                                value={form_value.stime1}
                            />
                            </span>
                            </Tooltip>
                            <Tooltip title='过了上班考勤时间才开始签到为迟到'>
                            <span>
                            <TimePicker autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定"
                                inputVariant="outlined" margin="dense" ampm={false}
                                onChange={value => this.handleFieldChange(value, 'ontime') }
                                style={{ marginLeft: 10, width: '33%' }}
                                InputLabelProps={{ classes: { root: classes.label } }}
                                value={form_value.ontime}
                            />
                            </span>
                            </Tooltip>
                            <Tooltip title='未到下班考勤时间就签退为早退'>
                            <span>
                            <TimePicker autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定"
                                inputVariant="outlined" margin="dense" ampm={false}
                                onChange={value => this.handleFieldChange(value, 'offtime') }
                                style={{ marginLeft: 10, width: '33%' }}
                                InputLabelProps={{ classes: { root: classes.label }}}
                                value={form_value.offtime}
                            />
                            </span>
                            </Tooltip>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                        {/* <Tooltip title='建议：时间间隔15~30分钟在方便员工签退，。'> */}
                            <Typography component='span' variant='body2'>
                                开始签到时间和上班考勤时间之间要有时间间隔，方便员工签到操作。
                            </Typography>
                        {/* </Tooltip> */}
                        </Box>
                    </Grid>
                </Grid>
                <div hidden={num > 0 ? false : true}>
                    <Divider style={{marginTop:5, marginBottom:10}} />
                    <Grid container spacing={4} >
                        <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                            {/* <Tooltip title={form_value.pname2}>
                                <TextField required label="考勤时段名称" margin="dense" value={form_value.pname2} variant="outlined"
                                    onChange={event => this.handleNameClick(event, 'pname2')} 
                                    onFocus={event => this.handleNameClick(event, 'pname2')}
                                    placeholder='请输入名称'                                
                                    error={form_value.pname2.trim().length==0} 
                                    helperText={form_value.pname2.trim().length==0?'不能为空':''}
                                />
                            </Tooltip> */}                 
                        <Typography component='div' variant="subtitle1" >
                            第二时段:
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>                    
                            <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                                <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。'>
                                    <span>
                                        <TimePicker autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" 
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value =>this.handleFieldChange(value, 'stime2') }
                                            style={{ marginRight: 15, width: '25%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.stime2}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='过了上班考勤时间才开始签到为迟到'>
                                    <span>
                                        <TimePicker autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'ontime2') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.ontime2}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='未到下班考勤时间就签退为早退'>
                                    <span>
                                        <TimePicker autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'offtime2') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.offtime2}
                                        />
                                    </span>
                                </Tooltip>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            {/* <Tooltip title='建议：时间间隔15~30分钟在方便员工签退，。'> */}
                                <Typography component='span' variant='body2'>
                                    上一时段的下班考勤时间和开始签到时间之间要有时间间隔，方便员工签退操作。
                                </Typography>
                            {/* </Tooltip> */}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <div hidden={num > 1 ? false : true}>
                    <Divider style={{marginTop:5, marginBottom:10}} />
                    <Grid container spacing={4} >
                        <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
                                {/* <Tooltip title={form_value.pname3}>
                                    <TextField required label="考勤时段名称" margin="dense" value={form_value.pname3} variant="outlined"
                                        onChange={event => this.handleNameClick(event, 'pname3')} 
                                        onFocus={event => this.handleNameClick(event, 'pname3')}
                                        placeholder='请输入名称'                                
                                        error={form_value.pname3.trim().length==0} 
                                        helperText={form_value.pname3.trim().length==0?'不能为空':''}
                                    />
                                </Tooltip> */}                 
                            <Typography component='div' variant="subtitle1" >
                                第三时段:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>                    
                            <MuiPickersUtilsProvider locale={zhCN} utils={DateFnsUtils}>
                                <Tooltip title='开始签到时间之前无法进行该时段的签到/签退，开始签到时间之后才可以该时段的签到/签退。'>
                                    <span>
                                        <TimePicker autoOk label="开始签到时间" cancelLabel="取消" okLabel="确定" 
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value =>this.handleFieldChange(value, 'stime3') }
                                            style={{ marginRight: 15, width: '25%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.stime3}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='过了上班考勤时间才开始签到为迟到'>
                                    <span>
                                        <TimePicker autoOk label="上班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'ontime3') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.ontime3}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title='未到下班考勤时间就签退为早退'>
                                    <span>
                                        <TimePicker autoOk label="下班考勤时间" cancelLabel="取消" okLabel="确定"
                                            inputVariant="outlined" margin="dense" ampm={false}
                                            onChange={value => this.handleFieldChange(value, 'offtime3') }
                                            style={{ marginLeft: 10, width: '33%' }}
                                            InputLabelProps={{ classes: { root: classes.label }}}
                                            value={form_value.offtime3}
                                        />
                                    </span>
                                </Tooltip>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                        <Box display='flex' alignItems="center" style={{width:'100%',height:'100%'}}>
                            {/* <Tooltip title='建议：时间间隔15~30分钟在方便员工签退，'> */}
                                <Typography component='span' variant='body2'>
                                    为了方便员工签退操作，(最后一个时段的)下班考勤时间不要设置为23:59。
                                </Typography>
                            {/* </Tooltip> */}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ float: 'right' }}>
                    {num === 0 ? null : (
                        <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => changenum('minus')}
                        >
                            <RemoveCircleOutline />
                        </IconButton>
                    )}

                    {num === 2 ? null : (
                        <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => changenum('add')}
                        >
                            <AddCircleOutline />
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}
Index.propTypes = {
    changenum: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    form_value: PropTypes.object.isRequired,
    handlefieldchange: PropTypes.func.isRequired,
    num: PropTypes.number.isRequired
};
export default withStyles(styles)(Index);
