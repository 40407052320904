export default {
  name: {
    presence: { allowEmpty: false, message: '用户名不能为空' },
    email: false,
    length: {
      maximum: 64
    }
  },
  pass: {
    presence: { allowEmpty: false, message: '密码不能为空' },
    length: {
      maximum: 128
    }
  },
  captcha: {
    presence: { allowEmpty: false, message: '验证码不能为空' },
    length: {
      is: 4,
      message: '验证码为4位'
    }
  }
};
