import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import {Router,AddCircle,Delete as DeleteIcon, Clear }from '@material-ui/icons';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Button,List,ListItem, ListItemAvatar, 
    ListItemSecondaryAction, ListItemText, Avatar, IconButton, Popover, Box, Tooltip
}from '@material-ui/core';

import validate from 'validate.js';
import _ from 'underscore';
// Shared components
import { Portlet, PortletHeader, PortletLabel, PortletContent, SnackbarsMessage } from 'components';
import Http from 'services/http';

// Component styles
import styles from './styles';

// 表单校验
import schema from './schema';

class ProductsToolbar extends Component {
    state = {
        add_router_open: false,
        cur_router: [],
        del_router_open: false,

        router_form: {
            title: '',
            remark: ''
        },
        errors: {
            title: null,
            remark: null
        },
        touched: {
            title: false,
            remark: false
        },
        submitError: null,
        msg: '',
        visible: false,
        anchorEl: null,
        open: false,

        cur_router_id: null
    };

    addRouter = () => {
        this.setState({
            add_router_open: true
        });
    };
    delRouter = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        try {
            const routers = await Http.get_routers(
                {
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            this.setState(
                {
                    cur_router: routers.data
                },
                this.setState({
                    del_router_open: true
                })
            );
        } catch (err) {
            this.showMsg('请重试');
            console.log(err);
        }
    };
    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
            add_router_open: false,
            del_router_open: false,
            errors: {
                title: null,
                remark: null
            },
            touched: {
                title: false,
                remark: false
            },
            submitError: null,
        });
    };

    validateFrom = _.debounce(() => {
        const { router_form } = this.state;
        const errors = validate(router_form, schema);
        const submitError = errors ? false : true;
        this.setState({
            errors: errors || {},
            submitError
        });
    }, 300);

    handleFieldChange = (value, field) => {
        const { router_form, touched } = this.state;
        touched[field] = true;
        router_form[field] = value;
        const submitError = null;
        this.setState(
            {
                router_form,
                touched,
                submitError
            },
            this.validateFrom
        );
    };

    showMsg = msg => {
        this.setState({
            msg: msg,
            visible: true
        });
        setTimeout(() => {
            this.setState({
                msg: '',
                visible: false
            });
        }, 1000);
    };
    addRouterSubmit = async () => {
        const { router_form, submitError } = this.state;
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        if (submitError) {
            try {
                const res = await Http.add_equ(
                    {
                        ssid: router_form.title,
                        remark: router_form.remark,
                        companysn: cur_cpy.CompanySN
                    },
                    true
                );
                const { errcode, errmsg } = res;
                if (errcode === 0) {
                    this.showMsg('新建成功');
                    this.props.refresh();
                    this.handleClose();
                } else {
                    this.showMsg(errmsg);
                }
            } catch (err) {
                this.showMsg('新建失败,请稍后再试');
                this.handleClose();
                console.log(err);
            }
        } else {
            this.showMsg('您还未填写');
        }
    };

    delete_router = async (anchorEl, router) => {
        this.setState({
            cur_router_id: router.EquID,
            anchorEl: anchorEl,
            open: true
        });
    };

    delete_router_confirm = async () => {
        try {
            const res = await Http.del_equ(
                {
                    equid: this.state.cur_router_id
                },
                true
            );
            const { errcode } = res;
            if (errcode === 0) {
                this.showMsg('删除成功');
                this.props.refresh();
                const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
                try {
                    const routers = await Http.get_routers(
                        {
                            CompanyID: cur_cpy.CompanySN
                        },
                        true
                    );
                    this.setState({
                        cur_router: routers.data,
                        open: false
                    });
                } catch (err) {
                    this.showMsg('请重试');
                    console.log(err);
                }
            } else {
                this.showMsg('删除失败请重试');
                this.setState({
                    open: false,
                    anchorEl: null
                });
            }
        } catch (err) {
            this.showMsg('删除失败请重试');
            this.setState({
                open: false,
                anchorEl: null
            });
            console.log(err);
        }
    };

    render() {
        const { classes, className, rest } = this.props;
        const { touched, errors, router_form, submitError} = this.state;
        const rootClassName = classNames(classes.root, className);
        const title_error = touched.title && (errors.title?true:false) && (errors.remark==null?false:true);
        const remark_error = touched.remark && (errors.remark?true:false)&& (errors.remark==null?false:true);

        return (
            <div className={rootClassName}>
                <div className={classes.row}>
                    {/* <span className={classes.spacer} /> */}
                    <Button
                        color="primary"
                        onClick={this.delRouter}
                        size="small"
                        style={{ marginRight: '5px' }}
                        variant="outlined"
                    >
                        <DeleteIcon style={{ color: 'red' }} />
                        删除签到设备
                    </Button>
                    <Button
                        color="primary"
                        onClick={this.addRouter}
                        size="small"
                        variant="outlined"
                    >
                        <AddCircle />
                        新增签到设备
                    </Button>
                </div>
                <SnackbarsMessage
                    msg={this.state.msg}
                    visible={this.state.visible}
                />
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    disableBackdropClick
                    disableEscapeKeyDown
                    // fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleClose}
                    open={this.state.add_router_open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='div' variant="h4" >新增签到设备</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Portlet className={rootClassName}>
                            <PortletHeader>
                                <PortletLabel subtitle="添加签到设备" title={this.props.cpyname} />
                            </PortletHeader>
                            <PortletContent>
                                <div className={classes.field}>
                                    <Tooltip title='填写手机连接的签到wifi名称'>
                                        <TextField label="请填写签到wifi名称" required variant="outlined"
                                            margin="dense" className={classes.textField} value={this.state.router_form.title}
                                            onChange={event => this.handleFieldChange( event.target.value, 'title' ) }
                                            error={title_error}
                                            helperText={title_error?errors.title[0].replace( 'Title', '' ):''}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={classes.field}>
                                    <Tooltip title='填写Wifi设备的备注，例如：安装地点，办公室名称'>
                                        <TextField required label="请填写签到设备备注" margin="dense" multiline rows="2"
                                            variant="outlined" className={classes.textField} value={router_form.remark}
                                            onChange={event => this.handleFieldChange( event.target.value, 'remark' ) }
                                            error={remark_error}
                                            helperText={remark_error?errors.remark[0].replace( 'Remark', '' ):''}
                                        />
                                    </Tooltip>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' color="primary" 
                            disabled={!submitError} 
                            onClick={this.addRouterSubmit} 
                        >
                            保存
                        </Button>
                        <Button variant='outlined' color="primary" onClick={this.handleClose}>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
                <Popover
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    // id={id}
                    onClose={this.handleClose}
                    open={this.state.open}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Typography className={classes.typography}>
                        <Button onClick={this.delete_router_confirm}>
                            确定
                        </Button>
                        <Button onClick={this.handleClose}>取消</Button>
                    </Typography>
                </Popover>
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    disableBackdropClick
                    fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleClose}
                    open={this.state.del_router_open}
                >
                    <DialogActions>
                        <Box style={{width:'100%'}}>
                            <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >删除签到设备</Typography>
                        </Box>     
                        <IconButton size='small' onClick={this.handleClose}><Clear fontSize='small' style={{ color: 'primary' }}/></IconButton>
                    </DialogActions>
                    <DialogContent>
                        <Portlet>
                            <PortletHeader>
                                <PortletLabel
                                    subtitle="单位考勤设备"
                                    title={this.props.cpyname}
                                />
                            </PortletHeader>
                            <PortletContent>
                                <List dense={false}>
                                    {this.state.cur_router.map(router => (
                                        <ListItem key={router.EquID}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={{ color: 'green' }}
                                                >
                                                    <Router />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                color="green"
                                                primary={router.EquName}
                                                secondary={router.remark}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    aria-label="Delete"
                                                    edge="end"
                                                    onClick={event =>
                                                        this.delete_router(
                                                            event.currentTarget,
                                                            router
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </PortletContent>
                        </Portlet>
                        <DialogActions>
                            <Button variant='outlined' color="primary" onClick={this.handleClose}>
                                返回
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

ProductsToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductsToolbar);
