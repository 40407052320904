import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './modules';
import api from './middleware/api';
let store;

// create-react-app 注入的环境变量
// chrome 如果安装了 插件，会在window下增加
if (
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk, api))
    );
} else {
    store = createStore(rootReducer, applyMiddleware(thunk));
}

export default store;
