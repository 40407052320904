import React, { Component } from 'react';

import SnackbarsMessage from 'components/SnackbarsMessage';

export default class Showmsg extends Component {
    state = {
        snackbarsVisible: false,
        snackbarsMsg: ''
    };
    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };
    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };
    render() {
        return (
            <SnackbarsMessage
                handleClose={this.handleSnackBarsClose}
                msg={this.state.snackbarsMsg}
                visible={this.state.snackbarsVisible}
            />
        );
    }
}
