import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(0)
    },
    content: {
        textAlign: 'center'
    },
    image: {
        display: 'inline-block',
        height:'130px'
    }
});

class NotFound extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <img
                        alt="Under development"
                        className={classes.image}
                        src="/images/payok.png"
                    />
                </div>
            </div>
        );
    }
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
