// Palette
import palette from 'theme/palette';

// Chart data
export const data1 = {
    labels: [
        '7点',
        '8点',
        '9点',
        '10点',
        '11点',
        '12点',
        '13点',
        '14点',
        '15点',
        '16点',
        '17点',
        '18点',
        '19点'
    ],
    datasets: [
        {
            label: '到岗',
            backgroundColor: palette.primary.main,
            data: [18, 5, 19, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            label: '离岗',
            backgroundColor: palette.common.neutral,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 29, 30, 25]
        }
    ]
};

// Chart options
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.border,
        backgroundColor: palette.common.white,
        titleFontColor: palette.text.primary,
        bodyFontColor: palette.text.secondary,
        footerFontColor: palette.text.secondary
    },
    layout: { padding: 0 },
    scales: {
        xAxes: [
            {
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                ticks: {
                    fontColor: palette.text.secondary
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: palette.text.secondary,
                    beginAtZero: true,
                    min: 0,
                    max: 20
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: palette.divider
                }
            }
        ]
    }
};
