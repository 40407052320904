import React, { Component } from 'react';
import axios from 'axios';
// Externals
import PropTypes from 'prop-types';
import validate from 'validate.js';
import classNames from 'classnames';
import { format, isValid } from 'date-fns';
// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, CircularProgress, Typography } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import { Portlet, PortletHeader, PortletLabel, PortletContent, SnackbarsMessage } from 'components';

import {AccountCircle, Group} from '@material-ui/icons';

// Shared components
import Http from 'services/http';
import utils from 'utility';
import { compare_all_timeV2, compare_each_oneV2, compare_result } from 'utils/time_compare';
// Form validation schema
import schema from './schema';

// Component styles
import UserForm2 from '../UserForm2/index';

import styles from './styles';
// import { Tooltip } from 'chart.js';

const default_form_value={
    status:'N',
    name:'',
    telephone: '',
    department: 0,
    role: 0,
    mode:0,
    mode0info:{ 
        mode0num:0,           
        pname1: '第一时段',
        stime1: new Date('December 17, 1995 8:00:00'),
        ontime1: new Date('December 17, 1995 8:30:00'),
        offtime1: new Date('December 17, 1995 17:30:00'),
        pname2: '第二时段',
        stime2: new Date('December 17, 1995 12:30:00'),
        ontime2: new Date('December 17, 1995 13:00:00'),
        offtime2: new Date('December 17, 1995 17:30:00'),
        pname3: '第三时段',
        stime3: new Date('December 17, 1995 16:30:00'),
        ontime3: new Date('December 17, 1995 17:00:00'),
        offtime3: new Date('December 17, 1995 23:00:00')
    },
    mode1info:{
        mode1num:0,          
        pname1: '第一时段',
        flag11:1,
        stime1: new Date('December 17, 1995 8:00:00'),
        flag12:1,
        ontime1: new Date('December 17, 1995 8:30:00'),
        flag13:1,
        offtime1: new Date('December 17, 1995 17:30:00'),
        flag14:1,
        etime1: new Date('December 17, 1995 19:00:00'),
        pname2: '第二时段',
        flag21:1,
        stime2: new Date('December 17, 1995 12:30:00'),
        flag22:1,
        ontime2: new Date('December 17, 1995 13:00:00'),
        flag23:1,
        offtime2: new Date('December 17, 1995 17:30:00'),
        flag24:1,
        etime2: new Date('December 17, 1995 19:00:00'),
        pname3: '第三时段',
        flag31:1,
        stime3: new Date('December 17, 1995 19:30:00'),
        flag32:1,
        ontime3: new Date('December 17, 1995 20:00:00'),
        flag33:0,
        offtime3: new Date('December 17, 1995 3:00:00'),
        flag34:0,
        etime3: new Date('December 17, 1995 5:00:00')
    },
    chongzhi: false,
    // 未知
    userid: 1362,
    Stype: 0,
    managepass: '',
    // 工号
    sn:'',
    // 职位
    position:'',
    // 备注
    memo:'',
    isphone:1
}

class ProductsToolbar extends Component {
    state = {
        staff_add: false,
        touched: {
          name: false,
          telephone: false
        },
        errors: {
            name: '',
            telephone: ''
        },
        autoStatus:false,
        accountInfo:{},
        productInfo:{},
        accountInfoStatus:false,
        productInfoStatus:false,
        
        isLoading: false,
        submitError: null,
        checked: false,
        form_value: {
            status:'N',
            name:'',
            telephone: '',
            department: 0,
            role: 0,
            mode:1,
            mode0info:{ 
                mode0num:0,           
                pname1: '第一时段',
                stime1: new Date('December 17, 1995 8:00:00'),
                ontime1: new Date('December 17, 1995 8:30:00'),
                offtime1: new Date('December 17, 1995 17:30:00'),
                pname2: '第二时段',
                stime2: new Date('December 17, 1995 12:30:00'),
                ontime2: new Date('December 17, 1995 13:00:00'),
                offtime2: new Date('December 17, 1995 17:30:00'),
                pname3: '第三时段',
                stime3: new Date('December 17, 1995 16:30:00'),
                ontime3: new Date('December 17, 1995 17:00:00'),
                offtime3: new Date('December 17, 1995 23:00:00')
            },
            mode1info:{
                mode1num:0,          
                pname1: '第一时段',
                flag11:1,
                stime1: new Date('December 17, 1995 8:00:00'),
                flag12:1,
                ontime1: new Date('December 17, 1995 8:30:00'),
                flag13:1,
                offtime1: new Date('December 17, 1995 17:30:00'),
                flag14:1,
                etime1: new Date('December 17, 1995 19:00:00'),
                pname2: '第二时段',
                flag21:1,
                stime2: new Date('December 17, 1995 12:30:00'),
                flag22:1,
                ontime2: new Date('December 17, 1995 13:00:00'),
                flag23:1,
                offtime2: new Date('December 17, 1995 17:30:00'),
                flag24:1,
                etime2: new Date('December 17, 1995 19:00:00'),
                pname3: '第三时段',
                flag31:1,
                stime3: new Date('December 17, 1995 19:30:00'),
                flag32:1,
                ontime3: new Date('December 17, 1995 20:00:00'),
                flag33:0,
                offtime3: new Date('December 17, 1995 3:00:00'),
                flag34:0,
                etime3: new Date('December 17, 1995 5:00:00')
            },
            chongzhi: false,
            // 未知
            userid: 1362,
            Stype: 0,
            managepass: '',
            // 工号
            sn:'',
            // 职位
            position:'',
            // 备注
            memo:'',
            isphone:1
        },
        cpy_group: [],
        isValid:false,

        snackbarsMsg: '',
        snackbarsVisible: false,
        submit_loading: false,

        upload_loading: false,
        upload_disable: false,

        cur_user: null
    };

    async componentDidMount() {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        const { form_value } = this.state;
        if(null!=cur_user && undefined!=cur_user){
            this.setState({
                cur_user: cur_user
            });
        }else {
            this.props.history.push('/sign-in');
        } 
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        try {
            const auto_enter = await Http.get_auto_enter(
                {
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            const cpy_group = await Http.get_group(
                {
                    cpySn: cur_cpy.CompanySN,
                    _: new Date().getTime()
                },
                true
            );
            form_value['department'] =default_form_value['department']= cpy_group.data[0].GroupID;
            this.setState({
                checked: auto_enter.data.AutoEnter ? true : false,
                autoStatus:true,
                cpy_group: cpy_group.data,
                form_value
            });
        } catch (e) {
            console.log(e);
        }
    }

    handleSClose = () => {
        this.setState({
            snackbarsMsg: '',
            snackbarsVisible: false
        });
    };

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };

    handleAutoEnter = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        if (this.state.checked === false) {
            await Http.change_auto_enter(
                {
                    auto: 1,
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            this.showMsg('员工自动录入开关已开启');
            this.setState({
                checked: true
            });
        } else {
            await Http.change_auto_enter(
                {
                    auto: 0,
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            this.showMsg('员工自动录入开关已关闭');
            this.setState({
                checked: false
            });
        }
    };

    // handleClose = () => {
    //     this.setState({
    //         staff_add: false
    //     });
    // };

    handleClose= () => {
        this.setState({
            staff_add: false,
            errors: { name: null, telephone: null },
            isValid:false,
            touched: {
                name: false,
                telephone: false
            },
            form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}},            
        });
    }

    timeValid=(value)=>{
        this.setState({
            isValid:value
        })
    }

    addStaff = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        const { form_value } = this.state;
        
        try {
            const res = await Http.defaultTimeV2({
                companysn: cur_cpy.CompanySN
            });
            const { data, errcode, errmsg } = res;
            if (errcode === 0) {
                if (data.mode === 0) {
                    default_form_value.mode=0
                    if(data.Mode0Info.pname1.length>0){
                        default_form_value.mode0info.mode0num=0
                        default_form_value.mode0info.pname1 = data.Mode0Info.pname1;
                        default_form_value.mode0info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime1 + ':00' );
                        default_form_value.mode0info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime1 + ':00' );
                        default_form_value.mode0info.stime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime1 + ':00' );
                    }
                    if(data.Mode0Info.pname2.length>0){
                        default_form_value.mode0info.mode0num+=1;
                        default_form_value.mode0info.pname2 = data.Mode0Info.pname2;
                        default_form_value.mode0info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime2 + ':00' );
                        default_form_value.mode0info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime2 + ':00' );
                        default_form_value.mode0info.stime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime2 + ':00' );
                    }
                    if(data.Mode0Info.pname3.length>0){
                        default_form_value.mode0info.mode0num+=1;
                        default_form_value.mode0info.pname3 = data.Mode0Info.pname3;
                        default_form_value.mode0info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime3 + ':00' );
                        default_form_value.mode0info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime3 + ':00' );
                        default_form_value.mode0info.stime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime3 + ':00' );
                    }

                    this.setState({                        
                        //form_value, default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                        form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}}, //default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                    });
                } else if(data.mode==1){
                    default_form_value.mode=1
                    if(data.Mode1Info.pname1.length>0){
                        default_form_value.mode1info.mode1num=0
                        default_form_value.mode1info.pname1 = data.Mode1Info.pname1;
                        default_form_value.mode1info.flag11=data.Mode1Info.flag11
                        default_form_value.mode1info.stime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime1 + ':00' );
                        default_form_value.mode1info.flag12=data.Mode1Info.flag12
                        default_form_value.mode1info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime1 + ':00' );
                        default_form_value.mode1info.flag13=data.Mode1Info.flag13
                        default_form_value.mode1info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime1 + ':00' );
                        default_form_value.mode1info.flag14=data.Mode1Info.flag14
                        default_form_value.mode1info.etime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime1 + ':00' );
                    }
                    if(data.Mode1Info.pname2.length>0){
                        default_form_value.mode1info.mode1num+=1;
                        default_form_value.mode1info.pname2 = data.Mode1Info.pname2;
                        default_form_value.mode1info.flag21=data.Mode1Info.flag21
                        default_form_value.mode1info.stime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime2 + ':00' );
                        default_form_value.mode1info.flag22=data.Mode1Info.flag22
                        default_form_value.mode1info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime2 + ':00' );
                        default_form_value.mode1info.flag23=data.Mode1Info.flag23
                        default_form_value.mode1info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime2 + ':00' );
                        default_form_value.mode1info.flag24=data.Mode1Info.flag24
                        default_form_value.mode1info.etime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime2 + ':00' );
                    }
                    if(data.Mode1Info.pname3.length>0){
                        default_form_value.mode1info.mode1num+=1;
                        default_form_value.mode1info.pname3 = data.Mode1Info.pname3;
                        default_form_value.mode1info.flag31=data.Mode1Info.flag31
                        default_form_value.mode1info.stime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime3 + ':00' );
                        default_form_value.mode1info.flag32=data.Mode1Info.flag32
                        default_form_value.mode1info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime3 + ':00' );
                        default_form_value.mode1info.flag33=data.Mode1Info.flag33
                        default_form_value.mode1info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime3 + ':00' );
                        default_form_value.mode1info.flag34=data.Mode1Info.flag34
                        default_form_value.mode1info.etime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime3 + ':00' );
                    }

                    this.setState({
                        //form_value, default_form_value:{...form_value, mode0info:{...form_value.mode0info}, mode1info:{...form_value.mode1info}},
                        form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}}
                    });
                }
            }
            this.setState({
                staff_add: true
            });
        } catch (err) {
            this.setState({
                staff_add: true
            });
            console.log(err);
        }
    };

    validateForm = () => {
        let { form_value } = this.state;
        let errors = validate(form_value, schema);
        let isValid = errors ? false : true;
        errors = errors || {};
        this.setState({
            isValid,
            errors
        });
    };

    handleFieldChange = (value, field) => {
        const { form_value, touched } = this.state;
        let {mode0info, mode1info}=this.state.form_value
        let fields='stime1stime2stime3etime1etime2etime3ontime1ontime2ontime3offtime1offtime2offtime3flag11flag12flag13flag14flag21flag22flag23flag24flag31flag32flag33flag34'
        if(fields.includes(field)){
            if(form_value.mode==0){
                mode0info[field]=value
                form_value.mode0info={...mode0info}
            }
            if(form_value.mode==1){
                mode1info[field]=value
                form_value.mode1info={...mode1info}
            }
        }else{
            form_value[field] = value;
        }
        touched[field] = true;
        this.setState({ form_value, touched }, this.validateForm);
    };

    changeNum = type => {
        const { form_value } = this.state;
        switch(form_value.mode){
            case 0:
                if (form_value.mode0info.mode0num >= 0 && form_value.mode0info.mode0num < 3) {
                    if (type === 'add' && form_value.mode0info.mode0num !== 2) { form_value.mode0info.mode0num += 1; } else if (type === 'minus' && form_value.mode0info.mode0num !== 0) { form_value.mode0info.mode0num -= 1; }
                }
                break;
            case 1:
                if (form_value.mode1info.mode1num >= 0 && form_value.mode1info.mode1num < 3) {
                    if (type === 'add' && form_value.mode1info.mode1num !== 2) { form_value.mode1info.mode1num += 1; } else if (type === 'minus' && form_value.mode1info.mode1num !== 0) { form_value.mode1info.mode1num -= 1; }
                }
                break;
        }
        this.setState({form_value}, this.validateForm);      
    };
    
    addNewUser = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        const { form_value } = this.state;
        const { mode0info, mode1info, mode } = this.state.form_value;

        let code=compare_each_oneV2(form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        code=compare_all_timeV2(form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        
        if (form_value.role !== 0 && form_value.managepass === '') {
            this.showMsg('请输新建管理员工密码');
            return;
        }

        this.setState({
            submit_loading: true,
            isValid: false
        });
        let num=-1
        let m0ts={
            name1:"",
            name2:"",
            name3:"",
            begin1:"",
            begin2:"",
            begin3:"",
            end1:"",
            end2:"",
            end3:"",
            eff1 :"",
            eff2:"",
            eff3:""
        }
        let m1ts={
            name1:"",
            name2:"",
            name3:"",
            begin1:"",
            begin2:"",
            begin3:"",
            end1:"",
            end2:"",
            end3:"",
            eff1 :"",
            eff2:"",
            eff3:"",
            noeff1 :"",
            noeff2:"",
            noeff3:"",
            flag11:1,
            flag12:1,
            flag13:1,
            flag14:1,
            flag21:1,
            flag22:1,
            flag23:1,
            flag24:1,
            flag31:1,
            flag32:1,
            flag33:1,
            flag34:1
        }
        switch(mode){
            case 0:
                num=mode0info.mode0num;
                
                if(num==0){
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                }else if(num==1){
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                    
                    m0ts.name2=mode0info.pname2
                    m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
                    m0ts.end2=format(mode0info.offtime2, 'HH:mm')
                    m0ts.eff2=format(mode0info.stime2, 'HH:mm')
                }else{
                    m0ts.name1=mode0info.pname1
                    m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
                    m0ts.end1=format(mode0info.offtime1, 'HH:mm')
                    m0ts.eff1=format(mode0info.stime1, 'HH:mm')
                    
                    m0ts.name2=mode0info.pname2
                    m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
                    m0ts.end2=format(mode0info.offtime2, 'HH:mm')
                    m0ts.eff2=format(mode0info.stime2, 'HH:mm')
                    
                    m0ts.name3=mode0info.pname3
                    m0ts.begin3=format(mode0info.ontime3, 'HH:mm')
                    m0ts.end3=format(mode0info.offtime3, 'HH:mm')
                    m0ts.eff3=format(mode0info.stime3, 'HH:mm')
                }
                break;
            case 1:                
                num=mode1info.mode1num;                
                if(num==0){
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                }else if(num==1){
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                    m1ts.name2=mode1info.pname2
                    m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
                    m1ts.end2=format(mode1info.offtime2, 'HH:mm')
                    m1ts.eff2=format(mode1info.stime2, 'HH:mm')
                    m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
                    m1ts.flag21=mode1info.flag21
                    m1ts.flag22=mode1info.flag22
                    m1ts.flag23=mode1info.flag23
                    m1ts.flag24=mode1info.flag24
                }else{
                    m1ts.name1=mode1info.pname1
                    m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
                    m1ts.end1=format(mode1info.offtime1, 'HH:mm')
                    m1ts.eff1=format(mode1info.stime1, 'HH:mm')
                    m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
                    m1ts.flag11=mode1info.flag11
                    m1ts.flag12=mode1info.flag12
                    m1ts.flag13=mode1info.flag13
                    m1ts.flag14=mode1info.flag14
                    m1ts.name2=mode1info.pname2
                    m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
                    m1ts.end2=format(mode1info.offtime2, 'HH:mm')
                    m1ts.eff2=format(mode1info.stime2, 'HH:mm')
                    m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
                    m1ts.flag21=mode1info.flag21
                    m1ts.flag22=mode1info.flag22
                    m1ts.flag23=mode1info.flag23
                    m1ts.flag24=mode1info.flag24
                    m1ts.name3=mode1info.pname3
                    m1ts.begin3=format(mode1info.ontime3, 'HH:mm')
                    m1ts.end3=format(mode1info.offtime3, 'HH:mm')
                    m1ts.eff3=format(mode1info.stime3, 'HH:mm')
                    m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
                    m1ts.flag31=mode1info.flag31
                    m1ts.flag32=mode1info.flag32
                    m1ts.flag33=mode1info.flag33
                    m1ts.flag34=mode1info.flag34
                }
                break;
        }

        let postData = {};
        if (mode === 0) {
            postData = {
                userid:this.state.cur_user.UserID,
                companysn: cur_cpy.CompanySN,
                name: form_value.name,
                telephone: form_value.telephone,
                department: form_value.department,
                status: form_value.status,
                role: form_value.role,
                //
                managepass:
                    form_value.managepass.length === 32
                        ? form_value.managepass
                        : utils.md5(form_value.managepass),

                Stype: form_value.Stype,
            
                mode:form_value.mode,
                mode0info:{...m0ts},
                mode1info:{},
                mode2info:{},
                mode3info:{},
                //
                sn: form_value.sn,
                position: '',
                memo: '',
                isPhone:form_value.isphone
            };
        } else if (mode === 1) {
            postData = {
                userid:this.state.cur_user.UserID,
                companysn: cur_cpy.CompanySN,
                name: form_value.name,
                telephone: form_value.telephone,
                department: form_value.department,
                status: form_value.status,
                role: form_value.role,
                //
                managepass: utils.md5(form_value.managepass),

                Stype: form_value.Stype,
                mode:form_value.mode,
                mode0info:{},
                mode1info:{...m1ts},
                mode2info:{},
                mode3info:{},

                //
                sn: form_value.sn,

                position: '',
                memo: '',
                isPhone:form_value.isphone
            };
        } 
        try {
            const  { errcode, errmsg } = await Http.addUserV2(postData);
            if (errcode !== 0) {
                this.showMsg(errmsg);
                setTimeout(() => {
                    this.setState({
                        submit_loading: false,
                        isValid: true
                    });
                }, 500);
            } else {
                // const{default_form_value}=this.state
                // setTimeout(() => {
                    this.setState({
                        errors: {
                            name: null,
                            telephone: null
                        },
                        touched: {
                            name: false,
                            telephone: false
                        },
                        form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}},
                        submit_loading: false,
                        isValid: false,
                        staff_add: false,
                    });   
                    this.props.reload();             
                // }, 500);
            }
        } catch (err) {
            this.setState({
                submit_loading: false,
                isValid: true
            });
            console.log(err);
        }
    };

    handleUpload = e => {
        const file1 = e.target.files[0];
        const formData = new FormData();
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        formData.append('file', file1);
        formData.append('companyid', cur_cpy.CompanySN);
        this.setState({
            upload_disable: true,
            upload_loading: true
        });
        axios({
            method: 'post',
            url: '/Handler/Upload.ashx?Type=uploadexcelReact',
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8'
            },
            data: formData
        })
            .then(res => {
                const { errcode, errmsg } = res.data;
                if (errcode === 0) {
                    this.props.reload();
                    this.showMsg('上传成功');
                    this.setState({
                        upload_disable: false,
                        upload_loading: false
                    });
                } else {
                    this.showMsg(errmsg);
                    this.setState({
                        upload_disable: false,
                        upload_loading: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    upload_disable: false,
                    upload_loading: false
                });
                console.log(err);
            });

        e.target.value = ''; // 上传之后还原
    };

    handleCheck=()=>{}
    delUser=()=>{}

    render() {
        const { classes, className, accountInfo, productInfo} = this.props;
        const { checked, touched, errors, cur_user,upload_disable,upload_loading, staff_add,autoStatus,
            cpy_group,form_value, submit_loading, isValid, snackbarsMsg, snackbarsVisible
        } = this.state;

        const rootClassName = classNames(classes.root, className);
        const name_error = touched.name && (errors.name?true:false) && (errors.name==null?false:true);
        const telephone_error = touched.telephone && (errors.telephone?true:false) && (errors.telephone==null?false:true);

        return (
            <div className={rootClassName}>
                <LoadingOverlay active={ !autoStatus } spinner text="正在加载" >
                <div className={classes.row}>
                    {/* <span className={classes.spacer} /> */}
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            // 
                                <Tooltip title='自动导入员工可以让员工在第一次连接签到wifi并且成功登录员工端后，自动完成单位员工信息的录入工作。【强烈建议开启】。'>
                                    <Typography component='div' variant="h4" >
                                        <Typography component='span' variant="h5" >员工自动录入</Typography>
                                        <Switch
                                            checked={checked}
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'secondary checkbox'
                                            }}
                                            onChange={this.handleAutoEnter}
                                            value="checkedA"
                                        />
                                    </Typography>
                                </Tooltip>
                            // 
                        )
                    ) : null}
                    <Button
                        color="primary"
                        onClick={this.addStaff}
                        size="small"
                        style={{ marginLeft: '30px' }}
                        variant="outlined"
                    >
                        <AccountCircle />
                        新增员工
                    </Button>
                    {!upload_disable ? (
                        <input
                            accept="*/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            onChange={e => this.handleUpload(e)}
                            style={{ display: 'none' }}
                            type="file"
                        />
                    ) : null}
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <>
                            <label htmlFor="contained-button-file">
                                <div style={{ position: 'relative' }}>
                                    {upload_loading && (
                                        <CircularProgress size={24}
                                            style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }}
                                        />
                                    )}
                                    <Button color="primary" component="span"
                                        disabled={upload_disable}
                                        onClick={() => this.handleUpload}
                                        size="small"
                                        style={{ marginLeft: '30px' }}
                                        variant="outlined"
                                    >
                                        批量导入
                                    </Button>
                                </div>
                            </label>
                            <a
                                href="https://h5.gdong.net/dingdong/xls/ddqiandao_employee_model.xls"
                                style={{ marginLeft: '20px'}}
                            >
                                模版下载
                            </a>
                            </>
                        )
                    ) : null}
                </div>
                </LoadingOverlay>
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    disableBackdropClick
                    fullWidth
                    maxWidth="md"
                    onClose={this.handleClose}
                    open={staff_add}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='div' variant="h4" >新增员工</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <UserForm2
                            changenum={this.changeNum}
                            cpy_group={cpy_group}
                            errors={errors}
                            form_value={form_value}
                            handlefieldchange={this.handleFieldChange}
                            nameerror={name_error}
                            teleerror={telephone_error}
                            deluser={this.delUser}
                            handlecheck={this.handleCheck}
                            isnew={true}
                            accountInfo={accountInfo}
                            // timevalid={this.timeValid}
                        />
                    </DialogContent>
                    <DialogActions>
                        <div style={{ position: 'relative' }}>
                            {submit_loading && (
                                <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                            )}
                            <Button variant='outlined' color="primary" disabled={!isValid || (accountInfo.isVip<=0 && form_value.mode>0)} onClick={this.addNewUser} >
                                保存并退出
                            </Button>
                        </div>

                        <Button variant='outlined' color="primary" onClick={this.handleClose}>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
                <SnackbarsMessage handleClose={this.handleSClose} msg={snackbarsMsg} visible={snackbarsVisible} />
            </div>
        );
    }
}

ProductsToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    accountInfo: PropTypes.object.isRequired,
    productInfo: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductsToolbar);
