import React, { Component } from 'react';
import axios from 'axios';
// Externals
import PropTypes from 'prop-types';
import validate from 'validate.js';
import classNames from 'classnames';
import { format, isValid } from 'date-fns';
// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, CircularProgress, Typography } from '@material-ui/core';
import { Portlet, PortletHeader, PortletLabel, PortletContent, SnackbarsMessage } from 'components';

import {AccountCircle, Group} from '@material-ui/icons';

// Shared components
import Http from 'services/http';
import utils from 'utility';
import { compare_all_time, compare_each_one, compare_result } from 'utils/time_compare';
// Form validation schema
import schema from './schema';

// Component styles
import UserForm1 from '../UserForm1/index';

import styles from './styles';
// import { Tooltip } from 'chart.js';

class ProductsToolbar extends Component {
    state = {
        staff_add: false,
        touched: {
          name: false,
          telephone: false
        },
        errors: {
            name: '',
            telephone: ''
        },
        
        isLoading: false,
        submitError: null,
        checked: false,
        form_value: {
            status:'N',
            name:'',
            telephone: '',
            department: 0,
            role: 0,
            mode:0,
            pname1: '第一时段',
            flag11:1,
            stime1: new Date('December 17, 1995 8:00:00'),
            flag12:1,
            ontime: new Date('December 17, 1995 8:30:00'),
            flag13:1,
            offtime: new Date('December 17, 1995 17:30:00'),
            flag14:1,
            etime1: new Date('December 17, 1995 23:59:00'),
            pname2: '第二时段',
            flag21:1,
            stime2: new Date('December 17, 1995 12:30:00'),
            flag22:1,
            ontime2: new Date('December 17, 1995 13:00:00'),
            flag23:1,
            offtime2: new Date('December 17, 1995 17:30:00'),
            flag24:1,
            etime2: new Date('December 17, 1995 23:59:00'),
            pname3: '第三时段',
            flag31:1,
            stime3: new Date('December 17, 1995 16:30:00'),
            flag32:1,
            ontime3: new Date('December 17, 1995 17:00:00'),
            flag33:1,
            offtime3: new Date('December 17, 1995 23:00:00'),
            flag34:1,
            etime3: new Date('December 17, 1995 23:59:00'),
            chongzhi: false,
            // 未知
            userid: 1362,
            Stype: 0,
            managepass: '',
            // 工号
            sn:'',
            // 职位
            position:'',
            // 备注
            memo:'',
            isphone:1
        },
        default_num:0,
        default_form_value :{
            status:'N',
            name:'',
            telephone: '',
            department: 0,
            role: 0,
            mode:0,
            pname1: '第一时段',
            flag11:1,
            stime1: new Date('December 17, 1995 8:00:00'),
            flag12:1,
            ontime: new Date('December 17, 1995 8:30:00'),
            flag13:1,
            offtime: new Date('December 17, 1995 17:30:00'),
            flag14:1,
            etime1: new Date('December 17, 1995 23:59:00'),
            pname2: '第二时段',
            flag21:1,
            stime2: new Date('December 17, 1995 12:30:00'),
            flag22:1,
            ontime2: new Date('December 17, 1995 13:00:00'),
            flag23:1,
            offtime2: new Date('December 17, 1995 17:30:00'),
            flag24:1,
            etime2: new Date('December 17, 1995 23:59:00'),
            pname3: '第三时段',
            flag31:1,
            stime3: new Date('December 17, 1995 16:30:00'),
            flag32:1,
            ontime3: new Date('December 17, 1995 17:00:00'),
            flag33:1,
            offtime3: new Date('December 17, 1995 23:00:00'),
            flag34:1,
            etime3: new Date('December 17, 1995 23:59:00'),
            chongzhi: false,
            // 未知
            userid: 1362,
            Stype: 0,
            managepass: '',
            // 工号
            sn:'',
            // 职位
            position:'',
            // 备注
            memo:'',
            isphone:1
        },
        cpy_group: [],
        num: 0,
        isValid:false,

        snackbarsMsg: '',
        snackbarsVisible: false,
        submit_loading: false,

        upload_loading: false,
        upload_disable: false,

        cur_user: null
    };

    async componentDidMount() {
        const cur_user = localStorage.getItem('cur_user');
        const { form_value } = this.state;
        this.setState({
            cur_user: JSON.parse(cur_user)
        });
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        try {
            const auto_enter = await Http.get_auto_enter(
                {
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            const cpy_group = await Http.get_group(
                {
                    cpySn: cur_cpy.CompanySN,
                    _: new Date().getTime()
                },
                true
            );
            form_value['department'] = cpy_group.data[0].GroupID;
            this.setState({
                checked: auto_enter.data.AutoEnter ? true : false,
                cpy_group: cpy_group.data,
                form_value: { ...form_value },
                default_form_value:{...form_value}
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleSClose = () => {
        this.setState({
            snackbarsMsg: '',
            snackbarsVisible: false
        });
    };

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };

    handleAutoEnter = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        if (this.state.checked === false) {
            await Http.change_auto_enter(
                {
                    auto: 1,
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            this.showMsg('员工自动录入开关已开启');
            this.setState({
                checked: true
            });
        } else {
            await Http.change_auto_enter(
                {
                    auto: 0,
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            this.showMsg('员工自动录入开关已关闭');
            this.setState({
                checked: false
            });
        }
    };

    // handleClose = () => {
    //     this.setState({
    //         staff_add: false
    //     });
    // };

    handleClose= () => {
        const {default_form_value}=this.state
        this.setState({
            staff_add: false,
            errors: { name: null, telephone: null },
            num:0,
            isValid:false,
            touched: {
                name: false,
                telephone: false
            },
            form_value: {...default_form_value},            
        });
    }

    timeValid=(value)=>{
        this.setState({
            isValid:value
        })
    }

    addStaff = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        const { form_value } = this.state;
        try {
            const res = await Http.defaultTime({
                companysn: cur_cpy.CompanySN
            });
            const { data, errcode, errmsg } = res;
            if (errcode === 0) {
                if (data.length === 1) {
                    form_value.mode=data[0].mode

                    form_value.pname1 = data[0].timespanName;
                    form_value.flag11=data[0].Todayflag0
                    form_value.flag12=data[0].Todayflag1
                    form_value.flag13=data[0].Todayflag2
                    form_value.flag14=data[0].Todayflag3
                    form_value.ontime = new Date( 'December 17, 1995 ' + data[0].S_begin + ':00' );
                    form_value.offtime = new Date( 'December 17, 1995 ' + data[0].S_end + ':00' );
                    form_value.stime1 = new Date( 'December 17, 1995 ' + data[0].E_begin + ':00' );
                    form_value.etime1 = new Date( 'December 17, 1995 ' + data[0].E_end + ':00' );
                    if(data[0].mode<2){
                        if(data[0].Todayflag0==data[0].Todayflag1==data[0].Todayflag2==data[0].Todayflag3==1){
                            form_value.mode=0
                            if(data[0].E_end!="23:59"){
                                form_value.mode=1
                            }
                        }
                    }
                    // if(data[0].Todayflag0==data[0].Todayflag1 && data[0].Todayflag1==data[0].Todayflag2 &&
                    //     data[0].Todayflag2==data[0].Todayflag3 && data[0].E_end=="23:59"){
                    //     form_value.mode=0
                    // }

                    this.setState({
                        num: 0, form_value: { ...form_value },
                        default_num:0, default_form_value: { ...form_value },
                    });
                } else if (data.length === 2) {
                    form_value.mode=data[0].mode
                    
                    form_value.pname1 = data[0].timespanName;
                    form_value.flag11=data[0].Todayflag0
                    form_value.flag12=data[0].Todayflag1
                    form_value.flag13=data[0].Todayflag2
                    form_value.flag14=data[0].Todayflag3
                    form_value.ontime = new Date( 'December 17, 1995 ' + data[0].S_begin + ':00' );
                    form_value.offtime = new Date( 'December 17, 1995 ' + data[0].S_end + ':00' );
                    form_value.stime1 = new Date( 'December 17, 1995 ' + data[0].E_begin + ':00' );
                    form_value.etime1 = new Date( 'December 17, 1995 ' + data[0].E_end + ':00' );
                    
                    form_value.pname2 = data[1].timespanName;
                    form_value.flag21=data[1].Todayflag0
                    form_value.flag22=data[1].Todayflag1
                    form_value.flag23=data[1].Todayflag2
                    form_value.flag24=data[1].Todayflag3
                    form_value.ontime2 = new Date( 'December 17, 1995 ' + data[1].S_begin + ':00' );
                    form_value.offtime2 = new Date( 'December 17, 1995 ' + data[1].S_end + ':00' );
                    form_value.stime2 = new Date( 'December 17, 1995 ' + data[1].E_begin + ':00' );
                    form_value.etime2 = new Date( 'December 17, 1995 ' + data[1].E_end + ':00' );

                    if(data[0].mode<2){
                        if(data[0].Todayflag0==data[0].Todayflag1==data[0].Todayflag2==data[0].Todayflag3==data[1].Todayflag0==data[1].Todayflag1==
                            data[1].Todayflag2==data[1].Todayflag3==1){
                            form_value.mode=0
                            if(data[1].E_end!="23:59"){
                                form_value.mode=1
                            }
                            //data[1].E_end=="23:59" && data[0].E_end==data[1].E_begin
                        }
                    }
                    // if(data[0].Todayflag0==data[0].Todayflag1 && data[0].Todayflag1==data[0].Todayflag2 &&
                    //     data[0].Todayflag2==data[0].Todayflag3 && data[1].Todayflag0==data[1].Todayflag1 && 
                    //     data[1].Todayflag1==data[1].Todayflag2 && data[1].Todayflag2==data[1].Todayflag3 &&
                    //     data[0].Todayflag3==data[1].Todayflag1 && 
                    //     data[1].E_end=="23:59" && data[0].E_end==data[1].E_begin){
                    //     form_value.mode=0
                    // }

                    this.setState({
                        num: 1, form_value: { ...form_value },
                        default_num:1, default_form_value: { ...form_value },
                    });
                } else if (data.length === 3) {
                    form_value.mode=data[0].mode
                    
                    form_value.pname1 = data[0].timespanName;
                    form_value.flag11=data[0].Todayflag0
                    form_value.flag12=data[0].Todayflag1
                    form_value.flag13=data[0].Todayflag2
                    form_value.flag14=data[0].Todayflag3
                    form_value.ontime = new Date( 'December 17, 1995 ' + data[0].S_begin + ':00' );
                    form_value.offtime = new Date( 'December 17, 1995 ' + data[0].S_end + ':00' );
                    form_value.stime1 = new Date( 'December 17, 1995 ' + data[0].E_begin + ':00' );
                    form_value.etime1 = new Date( 'December 17, 1995 ' + data[0].E_end + ':00' );
                    
                    form_value.pname2 = data[1].timespanName;
                    form_value.flag21=data[1].Todayflag0
                    form_value.flag22=data[1].Todayflag1
                    form_value.flag23=data[1].Todayflag2
                    form_value.flag24=data[1].Todayflag3
                    form_value.ontime2 = new Date( 'December 17, 1995 ' + data[1].S_begin + ':00' );
                    form_value.offtime2 = new Date( 'December 17, 1995 ' + data[1].S_end + ':00' );
                    form_value.stime2 = new Date( 'December 17, 1995 ' + data[1].E_begin + ':00' );
                    form_value.etime2 = new Date( 'December 17, 1995 ' + data[1].E_end + ':00' );
                    
                    form_value.pname3 = data[2].timespanName;
                    form_value.flag31=data[2].Todayflag0
                    form_value.flag32=data[2].Todayflag1
                    form_value.flag33=data[2].Todayflag2
                    form_value.flag34=data[2].Todayflag3
                    form_value.ontime3 = new Date( 'December 17, 1995 ' + data[2].S_begin + ':00' );
                    form_value.offtime3 = new Date( 'December 17, 1995 ' + data[2].S_end + ':00' );
                    form_value.stime3 = new Date( 'December 17, 1995 ' + data[2].E_begin + ':00' );
                    form_value.etime3 = new Date( 'December 17, 1995 ' + data[2].E_end + ':00' );

                    if(data[0].mode<2){
                        if(data[0].Todayflag0==data[0].Todayflag1==data[0].Todayflag2==data[0].Todayflag3==data[1].Todayflag0==
                            data[1].Todayflag1==data[1].Todayflag2==data[1].Todayflag3==data[2].Todayflag0==data[2].Todayflag1==
                            data[2].Todayflag2==data[2].Todayflag3==1){
                            form_value.mode=0
                            if(data[2].E_end!="23:59"){
                                form_value.mode=1
                            }
                            //data[0].E_end==data[1].E_begin && data[1].E_end==data[2].E_begin&&data[2].E_end=="23:59"
                        }
                    }
                    // if(data[0].Todayflag0==data[0].Todayflag1 && data[0].Todayflag1==data[0].Todayflag2 &&
                    //     data[0].Todayflag2==data[0].Todayflag3 && data[1].Todayflag0==data[1].Todayflag1 && 
                    //     data[1].Todayflag1==data[1].Todayflag2 && data[1].Todayflag2==data[1].Todayflag3 &&
                    //     data[2].Todayflag0==data[2].Todayflag1 &&  data[2].Todayflag1==data[2].Todayflag2 && 
                    //     data[2].Todayflag2==data[2].Todayflag3 &&  
                    //     data[0].Todayflag3==data[1].Todayflag1 && data[1].Todayflag3==data[2].Todayflag1 &&
                    //     data[0].E_end==data[1].E_begin &&
                    //     data[2].E_end=="23:59" && data[1].E_end==data[2].E_begin){
                    //     form_value.mode=0
                    // }


                    this.setState({
                        num: 2, form_value: { ...form_value },
                        default_num:2, default_form_value: { ...form_value },
                    });
                }
            }
            this.setState({
                staff_add: true
            });
        } catch (err) {
            this.setState({
                staff_add: true
            });
            console.log(err);
        }
    };

    validateForm = () => {
        let { form_value } = this.state;
        let errors = validate(form_value, schema);
        let isValid = errors ? false : true;
        errors = errors || {};
        this.setState({
            isValid,
            errors
        });
    };

    handleFieldChange = (value, field) => {
        const { form_value, touched } = this.state;
        form_value[field] = value;
        touched[field] = true;
        this.setState({ form_value, touched }, this.validateForm);
    };

    changeNum = type => {
        let { num, form_value, default_form_value } = this.state;
        if (num >= 0 && num < 3) {
            if (type === 'add' && num !== 2) { num += 1; } else if (type === 'minus' && num !== 0) { num -= 1; }
        }
        if(num==0){
            form_value.pname2=(form_value.pname2.trim().length==0?default_form_value.pname2:form_value.pname2)
            form_value.pname3=(form_value.pname3.trim().length==0?default_form_value.pname3:form_value.pname3)
        }
        if(num==1){
            form_value.pname3=(form_value.pname3.trim().length==0?default_form_value.pname3:form_value.pname3)
        }
        this.setState({num, form_value}, this.validateForm());
    };
    
    addNewUser = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        const { num, form_value } = this.state;
        const{mode}=this.state.form_value

        let code=compare_each_one(form_value, num, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        code=compare_all_time(form_value, num, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        if (form_value.role !== 0 && form_value.managepass === '') {
            this.showMsg('请输新建管理员工密码');
            return;
        }
        this.setState({
            submit_loading: true,
            isValid: false
        });
        let postData = {};
        if (num === 0) {
            postData = {
                companysn: cur_cpy.CompanySN,
                name: form_value.name,
                telephone: form_value.telephone,
                department: form_value.department,
                status: form_value.status,
                role: form_value.role,
                //
                managepass:
                    form_value.managepass.length === 32
                        ? form_value.managepass
                        : utils.md5(form_value.managepass),

                Stype: form_value.Stype,
            
                mode:form_value.mode,
                pname1: form_value.pname1,
                flag11: form_value.mode==0?1:form_value.flag11,
                flag12: form_value.mode==0?1:form_value.flag12,
                flag13: form_value.mode==0?1:form_value.flag13,
                flag14: form_value.mode==0?1:form_value.flag14,
                ontime: format(form_value.ontime, 'HH:mm'),
                offtime:format(form_value.offtime, 'HH:mm'),
                stime1: format(form_value.stime1, 'HH:mm'),
                etime1: form_value.mode==0?'':format(form_value.etime1, 'HH:mm'),
                //
                sn: form_value.sn,
                position: '',
                memo: '',
                isPhone:form_value.isphone
            };
        } else if (num === 1) {
            postData = {
                companysn: cur_cpy.CompanySN,
                name: form_value.name,
                telephone: form_value.telephone,
                department: form_value.department,
                status: form_value.status,
                role: form_value.role,
                //
                managepass: utils.md5(form_value.managepass),

                Stype: form_value.Stype,
                mode:form_value.mode,
                pname1: form_value.pname1,
                flag11: form_value.mode==0?1:form_value.flag11,
                flag12: form_value.mode==0?1:form_value.flag12,
                flag13: form_value.mode==0?1:form_value.flag13,
                flag14: form_value.mode==0?1:form_value.flag14,
                ontime: format(form_value.ontime, 'HH:mm'),
                offtime:format(form_value.offtime, 'HH:mm'),
                stime1: format(form_value.stime1, 'HH:mm'),
                etime1: form_value.mode==0?'':format(form_value.etime1, 'HH:mm'),
                pname2: form_value.pname2,
                flag21: form_value.mode==0?1:form_value.flag21,
                flag22: form_value.mode==0?1:form_value.flag22,
                flag23: form_value.mode==0?1:form_value.flag23,
                flag24: form_value.mode==0?1:form_value.flag24,
                ontime2: format(form_value.ontime2, 'HH:mm'),
                offtime2: format(form_value.offtime2, 'HH:mm'),
                stime2: format(form_value.stime2, 'HH:mm'),
                etime2: form_value.mode==0?'':format(form_value.etime2, 'HH:mm'),

                //
                sn: form_value.sn,

                position: '',
                memo: '',
                isPhone:form_value.isphone
            };
        } else {
            postData = {
                companysn: cur_cpy.CompanySN,
                name: form_value.name,
                telephone: form_value.telephone,
                department: form_value.department,
                status: form_value.status,
                role: form_value.role,
                //
                managepass: utils.md5(form_value.managepass),

                Stype: form_value.Stype,
            
                mode:form_value.mode,
                pname1: form_value.pname1,
                flag11: form_value.mode==0?1:form_value.flag11,
                flag12: form_value.mode==0?1:form_value.flag12,
                flag13: form_value.mode==0?1:form_value.flag13,
                flag14: form_value.mode==0?1:form_value.flag14,
                ontime: format(form_value.ontime, 'HH:mm'),
                offtime:format(form_value.offtime, 'HH:mm'),
                stime1: format(form_value.stime1, 'HH:mm'),
                etime1: form_value.mode==0?'':format(form_value.etime1, 'HH:mm'),
                pname2: form_value.pname2,
                flag21: form_value.mode==0?1:form_value.flag21,
                flag22: form_value.mode==0?1:form_value.flag22,
                flag23: form_value.mode==0?1:form_value.flag23,
                flag24: form_value.mode==0?1:form_value.flag24,
                ontime2: format(form_value.ontime2, 'HH:mm'),
                offtime2: format(form_value.offtime2, 'HH:mm'),
                stime2: format(form_value.stime2, 'HH:mm'),
                etime2: form_value.mode==0?'':format(form_value.etime2, 'HH:mm'),
                pname3: form_value.pname3,
                flag31: form_value.mode==0?1:form_value.flag31,
                flag32: form_value.mode==0?1:form_value.flag32,
                flag33: form_value.mode==0?1:form_value.flag33,
                flag34: form_value.mode==0?1:form_value.flag34,
                ontime3: format(form_value.ontime3, 'HH:mm'),
                offtime3: format(form_value.offtime3, 'HH:mm'),
                stime3: format(form_value.stime3, 'HH:mm'),
                etime3: form_value.mode==0?'':format(form_value.etime3, 'HH:mm'),
                //
                sn: form_value.sn,
                position: '',
                memo: '',
                isPhone:form_value.isphone
            };
        }
        try {
            const  { errcode, errmsg } = await Http.addUser(postData, true);
            if (errcode !== 0) {
                this.showMsg(errmsg);
                setTimeout(() => {
                    this.setState({
                        submit_loading: false,
                        isValid: true
                    });
                }, 500);
            } else {
                const{default_form_value, default_num}=this.state
                // setTimeout(() => {
                    this.setState({
                        errors: {
                            name: null,
                            telephone: null
                        },
                        touched: {
                            name: false,
                            telephone: false
                        },
                        num:default_num,
                        form_value: {...default_form_value},
                        submit_loading: false,
                        isValid: false,
                        staff_add: false,
                    });   
                    this.props.reload();             
                // }, 500);
            }
        } catch (err) {
            this.setState({
                submit_loading: false,
                isValid: true
            });
            console.log(err);
        }
    };

    handleUpload = e => {
        const file1 = e.target.files[0];
        const formData = new FormData();
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        formData.append('file', file1);
        formData.append('companyid', cur_cpy.CompanySN);
        this.setState({
            upload_disable: true,
            upload_loading: true
        });
        axios({
            method: 'post',
            url: '/Handler/Upload.ashx?Type=uploadexcelReact',
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8'
            },
            data: formData
        })
            .then(res => {
                const { errcode, errmsg } = res.data;
                if (errcode === 0) {
                    this.props.reload();
                    this.showMsg('上传成功');
                    this.setState({
                        upload_disable: false,
                        upload_loading: false
                    });
                } else {
                    this.showMsg(errmsg);
                    this.setState({
                        upload_disable: false,
                        upload_loading: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    upload_disable: false,
                    upload_loading: false
                });
                console.log(err);
            });

        e.target.value = ''; // 上传之后还原
    };

    handleCheck=()=>{}
    delUser=()=>{}

    render() {
        const { classes, className, rest } = this.props;
        const { checked, touched, errors, cur_user,upload_disable,upload_loading, staff_add,
            cpy_group,form_value,num,submit_loading, isValid, snackbarsMsg, snackbarsVisible
        } = this.state;

        const rootClassName = classNames(classes.root, className);
        const name_error = touched.name && (errors.name?true:false) && (errors.name==null?false:true);
        const telephone_error = touched.telephone && (errors.telephone?true:false) && (errors.telephone==null?false:true);

        return (
            <div className={rootClassName}>
                <div className={classes.row}>
                    {/* <span className={classes.spacer} /> */}
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <Tooltip title='自动导入员工可以让员工在第一次连接签到wifi并且成功登录员工端后，自动完成单位员工信息的录入工作。【强烈建议开启】。'>
                            <Typography component='div' variant="h4" >
                                <Typography component='span' variant="h5" >员工自动录入</Typography>
                                <Switch
                                    checked={checked}
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox'
                                    }}
                                    onChange={this.handleAutoEnter}
                                    value="checkedA"
                                />
                            </Typography>
                            </Tooltip>
                        )
                    ) : null}
                    <Button
                        color="primary"
                        onClick={this.addStaff}
                        size="small"
                        style={{ marginLeft: '30px' }}
                        variant="outlined"
                    >
                        <AccountCircle />
                        新增员工
                    </Button>
                    {!upload_disable ? (
                        <input
                            accept="*/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            onChange={e => this.handleUpload(e)}
                            style={{ display: 'none' }}
                            type="file"
                        />
                    ) : null}
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <>
                                <label htmlFor="contained-button-file">
                                    <div style={{ position: 'relative' }}>
                                        {upload_loading && (
                                            <CircularProgress size={24}
                                                style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }}
                                            />
                                        )}
                                        <Button color="primary" component="span"
                                            disabled={upload_disable}
                                            onClick={() => this.handleUpload}
                                            size="small"
                                            style={{ marginLeft: '30px' }}
                                            variant="outlined"
                                        >
                                            批量导入
                                        </Button>
                                    </div>
                                </label>
                                <a
                                    href="https://h5.gdong.net/dingdong/xls/ddqiandao_employee_model.xls"
                                    style={{ marginLeft: '20px' }}
                                >
                                    模版下载
                                </a>
                            </>
                        )
                    ) : null}
                </div>
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    disableBackdropClick
                    fullWidth
                    maxWidth="md"
                    onClose={this.handleClose}
                    open={staff_add}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='div' variant="h4" >新增员工</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <UserForm1
                            changenum={this.changeNum}
                            cpy_group={cpy_group}
                            errors={errors}
                            form_value={form_value}
                            handlefieldchange={this.handleFieldChange}
                            nameerror={name_error}
                            num={num}
                            teleerror={telephone_error}
                            deluser={this.delUser}
                            handlecheck={this.handleCheck}
                            isnew={true}
                            timevalid={this.timeValid}
                        />
                    </DialogContent>
                    <DialogActions>
                        <div style={{ position: 'relative' }}>
                            {submit_loading && (
                                <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                            )}
                            <Button variant='outlined' color="primary" disabled={!isValid} onClick={this.addNewUser} >
                                保存并退出
                            </Button>
                        </div>

                        <Button variant='outlined' color="primary" onClick={this.handleClose}>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
                <SnackbarsMessage handleClose={this.handleSClose} msg={snackbarsMsg} visible={snackbarsVisible} />
            </div>
        );
    }
}

ProductsToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductsToolbar);
