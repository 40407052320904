import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Button } from '@material-ui/core';

// Material icons
// import { Delete as DeleteIcon, DateRange, Today } from '@material-ui/icons';

// Shared components
// import { DisplayMode, SearchInput } from 'components';

// Component styles
import styles from './styles';

// let testDate = new Date('2019-08-18');

class UsersToolbar extends Component {
    state = {
        cur_user: null
    };
    componentDidMount() {
        const cur_user = localStorage.getItem('cur_user');
        this.setState({
            cur_user: JSON.parse(cur_user)
        });
    }
    handleDirect = type => {
        this.props.history.push(type);
    };
    render() {
        const { classes, className, selectedUsers } = this.props;
        const { cur_user } = this.state;
        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div className={classes.row}>
                    <span className={classes.spacer} />
                    {cur_user ? (
                        ['AdminC'].includes(cur_user.RoleCode) ? null : (
                            <span>
                                <Button
                                    className={classes.importButton}
                                    color="primary"
                                    onClick={() =>
                                        this.handleDirect('/routers')
                                    }
                                    variant="outlined"
                                >
                                    管理设备
                                </Button>
                                <Button
                                    className={classes.importButton}
                                    color="primary"
                                    onClick={() =>
                                        this.handleDirect('/settings')
                                    }
                                    variant="outlined"
                                >
                                    管理单位
                                </Button>
                                <Button
                                    className={classes.importButton}
                                    color="primary"
                                    onClick={() =>
                                        this.handleDirect('/users')
                                    }
                                    variant="outlined"
                                >
                                    管理员工
                                </Button>
                            </span>
                        )
                    ) : null}
                </div>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(withRouter(UsersToolbar));
