import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import {
    Button,
    TextField,
    Typography,
    CircularProgress
} from '@material-ui/core';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletContent,
    PortletFooter
} from 'components';
// import validators from 'common/validators';
import Http from 'services/http';
// import { Link, withRouter } from 'react-router-dom';
import utils from 'utility';
import schema from './schema';
import SnackbarsMessage from 'components/SnackbarsMessage';

// Component styles
import styles from './styles';
const def_state = {
    values: {
        mobile: '',
        captcha: '',
        password: '',
        comfirmPassword: '',
        policy: true
    },
    touched: {
        mobile: false,
        captcha: false,
        password: false,
        comfirmPassword: false,
        policy: true
    },
    errors: {
        mobile: null,
        captcha: null,
        password: null,
        comfirmPassword: null,
        policy: null
    },
    isValid: false,
    isLoading: false,
    submitError: null,
    isSend: false,
    snackbarsMsg: '',
    snackbarsVisible: false,
    issendcount: 120,
    validPasswordError: true
};
class Account extends Component {
    state = {
        values: {
            mobile: '',
            captcha: '',
            password: '',
            comfirmPassword: '',
            policy: true
        },
        touched: {
            mobile: false,
            captcha: false,
            password: false,
            comfirmPassword: false,
            policy: true
        },
        errors: {
            mobile: null,
            captcha: null,
            password: null,
            comfirmPassword: null,
            policy: null
        },
        isValid: false,
        isLoading: false,
        submitError: null,
        isSend: false,
        snackbarsMsg: '',
        snackbarsVisible: false,
        issendcount: 120,
        validPasswordError: true
    };
    componentDidMount() {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({
            values: { ...this.state.value, mobile: cur_user.Phone },
            touched: { ...this.state.value, mobile: true }
        });
    }
    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };
    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };
    validateForm = () => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);
        newState.isValid = errors ? false : true;
        newState.errors = errors || {};

        this.setState(newState, () => {
            if (
                newState.values['password'] ===
                    newState.values['comfirmPassword'] &&
                newState.touched['password'] &&
                newState.touched['comfirmPassword']
            ) {
                this.setState({
                    validPasswordError: true
                });
            } else if (newState.touched['password']) {
                this.setState({
                    validPasswordError: false
                });
            }
        });
    };
    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
    };

    handleSignUp = async () => {
        try {
            const { history } = this.props;
            const { values } = this.state;

            this.setState({ isLoading: true });

            const res = await Http.useyzm(
                {
                    phone: values.mobile,
                    yzm: values.captcha,
                    pass: utils.md5(values.password)
                },
                true
            );
            const { errcode, errmsg } = res;
            if (errcode === 0) {
                setTimeout(() => {
                    this.showMsg(errmsg);
                }, 1000);
                this.setState({ ...def_state });
                // history.push('/sign-in');
            } else {
                this.setState({
                    isLoading: false
                });
                this.showMsg(errmsg);
            }
        } catch (error) {
            this.setState({
                isLoading: false
            });
        }
    };
    handleGetCaptcha = async () => {
        const { values } = this.state;
        this.setState({
            isSend: true
        });
        setTimeout(() => {
            this.setState({
                isSend: false
            });
        }, 1000 * 120);
        let timer = setInterval(() => {
            const { issendcount } = this.state;
            if (issendcount === 0) {
                clearInterval(timer);
                this.setState({
                    issendcount: 120
                });
            } else {
                this.setState({
                    issendcount: issendcount - 1
                });
            }
        }, 1000);

        // 请求验证码
        try {
            const res2 = await Http.findaccount({ phone: values.mobile }, true);
            const { errcode, errmsg } = res2;
            if (errcode !== 0) {
                this.setState({
                    isSend: false
                });
                this.showMsg(errmsg);
            } else {
                this.showMsg('发送成功');
            }
        } catch (err) {
            this.showMsg('服务器开小差了,请稍后再试');
        }
    };
    render() {
        const { classes, className, ...rest } = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;

        const rootClassName = classNames(classes.root, className);

        const showmobileError =
            touched.mobile && errors.mobile ? errors.mobile[0] : false;
        const disableGetCaptcha = touched.mobile
            ? errors.mobile
                ? true
                : false
            : true;
        const showcaptchaError =
            touched.captcha && errors.captcha ? errors.captcha[0] : false;
        const showpasswordError =
            touched.password && errors.password ? errors.password[0] : false;
        const showcomfirmPasswordError =
            touched.comfirmPassword && errors.comfirmPassword
                ? errors.comfirmPassword[0]
                : false;
        return (
            <Portlet className={rootClassName}>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={this.state.snackbarsMsg}
                    visible={this.state.snackbarsVisible}
                />
                <PortletHeader>
                    <PortletLabel subtitle="修改密码" title="修改密码" />
                </PortletHeader>
                <PortletContent noPadding>
                    <div autoComplete="off" noValidate>
                        <div className={classes.field}>
                            <TextField
                                className={classes.textCaptchaField}
                                disabled
                                label="手机号码"
                                name="mobile"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'mobile',
                                        event.target.value
                                    )
                                }
                                value={values.mobile}
                                variant="outlined"
                            />
                            <Button
                                className={classes.textCaptchaButton}
                                disabled={
                                    disableGetCaptcha || this.state.isSend
                                }
                                onClick={this.handleGetCaptcha}
                                variant="contained"
                            >
                                {this.state.isSend
                                    ? this.state.issendcount
                                    : '获取验证码'}
                            </Button>
                            {showmobileError && (
                                <Typography
                                    className={classes.fieldError}
                                    variant="body2"
                                >
                                    {errors.mobile[0].replace('Mobile', '')}
                                </Typography>
                            )}
                        </div>

                        <div className={classes.field}>
                            <TextField
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off'
                                    }
                                }}
                                className={classes.textField}
                                label="验证码"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'captcha',
                                        event.target.value
                                    )
                                }
                                value={values.captcha}
                                variant="outlined"
                            />
                            {showcaptchaError && (
                                <Typography
                                    className={classes.fieldError}
                                    variant="body2"
                                >
                                    {errors.captcha[0].replace('Captcha', '')}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.field}>
                            <TextField
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off'
                                    }
                                }}
                                className={classes.textField}
                                label="密码"
                                name="password"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'password',
                                        event.target.value
                                    )
                                }
                                type="password"
                                value={values.password}
                                variant="outlined"
                            />
                            {showpasswordError && (
                                <Typography
                                    className={classes.fieldError}
                                    variant="body2"
                                >
                                    {errors.password[0].replace('Password', '')}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.field}>
                            <TextField
                                className={classes.textField}
                                label="确认密码"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'comfirmPassword',
                                        event.target.value
                                    )
                                }
                                type="password"
                                value={values.comfirmPassword}
                                variant="outlined"
                            />
                            {showcomfirmPasswordError && (
                                <Typography
                                    className={classes.fieldError}
                                    variant="body2"
                                >
                                    {errors.comfirmPassword[0].replace(
                                        'Comfirm password',
                                        ''
                                    )}
                                </Typography>
                            )}
                            {!this.state.validPasswordError && (
                                <Typography
                                    className={classes.fieldError}
                                    variant="body2"
                                >
                                    两次输入的不一致
                                </Typography>
                            )}
                        </div>
                    </div>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    {submitError && (
                        <Typography
                            className={classes.submitError}
                            variant="body2"
                        >
                            {submitError}
                        </Typography>
                    )}
                    {isLoading ? (
                        <CircularProgress className={classes.progress} />
                    ) : (
                        <Button
                            className={classes.signUpButton}
                            color="primary"
                            disabled={!isValid}
                            onClick={this.handleSignUp}
                            size="large"
                            variant="contained"
                        >
                            确认
                        </Button>
                    )}
                </PortletFooter>
            </Portlet>
        );
    }
}

Account.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
