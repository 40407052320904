import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import Editable from './ContactListTable/index';
import MessageTable from './MessageListTable/index';
// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    }
});

class Typography extends Component {
    state = {
        tab_value: 0
    };
    handleChange = (_, v) => {
        this.setState({
            tab_value: v
        });
    };
    render() {
        const { classes } = this.props;

        return (
            <DashboardLayout title="信息交互">
                {/* <div className={classes.root}>
          <ProductsToolbar />
        </div> */}
                <div className={classes.root}>
                    <Tabs aria-label="信息管理" onChange={this.handleChange} value={this.state.tab_value} textColor="primary" indicatorColor="primary">
                        <Tab id={0} label="公告" />
                        <Tab id={1} label="小纸条" />
                    </Tabs>
                </div>
                <div className={classes.content} hidden={1 === this.state.tab_value} >
                    {/* <span>公告</span> */}
                    <Editable users={[]} />
                </div>
                <div className={classes.content} hidden={0 === this.state.tab_value} >
                    {/* <span>小纸条</span> */}
                    <MessageTable />
                </div>
            </DashboardLayout>
        );
    }
}

Typography.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Typography);
