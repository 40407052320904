import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import TableIcons from '../../../../components/TableIcons/index';

import { zhCN } from 'date-fns/locale';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { actions as reportActions } from '../../../../redux/modules/report';
import export_to_excel from 'utils/export_to_excel';

import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { array } from 'prop-types';

const weekday=['日','一','二','三','四','五','六']

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: '姓名', field: 'UserName' },
                { title: '工号', field: 'GongHao' },
                { title: '职位', field: 'ZhiWei' },
                { title: '部门', field: 'GroupName' },
                { title: '手机号', field: 'UserPhone' },
                { title: '工作时长', filtering: false , field: 'worklong',
                    // render: record => {
                    //     return <span>{record.worklong + ' '}小时</span>;
                    // }
                },
                { title: '迟到', field: 'lateNum' },
                { title: '早退次数', field: 'LvEearlyNum' }
            ],

            users: [],
            isLoading: false,
            date_value: null,

            curpage:0,
            pagesize:20
        };
    }

    componentDidMount() {
        const select_date = format(new Date(), 'yyyy-MM-dd');
        this.setState({
            date_value: select_date
        });
    }

    handleDateChange = async value => {
        const select_date = format(value, 'yyyy-MM-dd');
        this.props.monthChartActions(select_date);
        this.setState({
            date_value: value
        });
    };
       

    handleRowsChange = async (rowsPerPage) => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async (Page) => {
        this.setState({ curpage: Page })
    }

    handleString (record, j) {
        return (record[j+''].map((value,index)=><div key={index}>{value}</div>));
    }

    render() {
        const { classes, chart,num, loading } = this.props;
        const {pagesize,curpage,columns,date_value,isLoading}=this.state

        let s_date=new Date(date_value)
        let now_date=new Date()
        let y=s_date.getFullYear()
        let m=s_date.getMonth()+1
        let days=new Date(y, m, 0).getDate()
        let tcolumns=[{title:'姓名',field:'Name'},{title:'电话', field:'Phone'}]
        // tcolumns.push({title:'姓名',field:'Name'})
        if(y==now_date.getFullYear() && m==(now_date.getMonth()+1)){
            if(s_date.getDate()>1){
                days=now_date.getDate()-1
            }
        }
        // console.log(days)
        for (let i=0;i<days;i++){
            let j=i+1
            let d=new Date(y,m,j).getDay()
            let t=(y+(m<10?('0'+m):m)+(j<10?'0'+j:j)+'('+weekday[d]+')')
            let title={title:t,field:j+''
                // ,render: record => {
                //     let s=new Array(record[j+''].length)
                //     for (let ii=0;ii<s.length;ii++){
                //         s[ii]=new Array(2)
                //         for(let jj=0;jj<2;jj++){
                //             s[ii]=record[j+''][ii].map((v,i)=><span key={i}>{v}</span>)             
                //         }
                //     }
                //     console.log(s.map((v,i)=><div key={i}>{v}</div>))
                //     return (s.map((value,index)=><div key={index}>{value}</div>))
                //     // if(j==1) console.log(record[j+''])
                //     // let s=new Array(record[j+''].length);
                //     // for (let ii=0;ii<s.length;ii++){
                //     //     s[ii]=new Array(2)
                //     //     for(let jj=0;jj<2;jj++){
                //     //         if(record[j+''][0]!='/'){
                //     //             let ss=record[j+''][ii].split("，")
                //     //             s[ii][jj]=ss[jj]
                //     //         }else{
                //     //             s[ii][jj]=''
                //     //         }                         
                //     //     }
                //     // }
                //     // let sss=new Array(record[j+''].length)
                //     // for(let k=0;k<s.length;k++){
                //     //     sss[k]=new Array(2)
                //     //     sss[k]=s[k].map((v,i)=><span key={i}>{v}</span>)
                //     // }
                //     // console.log(sss.map((v,i)=><div key={i}>{v}</div>))
                //     // return sss.map((v,i)=><div key={i}>{v}</div>)
                //     return (record[j+''].map((value,index)=><div key={index}>{value}</div>));
                // }
            }
            tcolumns.push(title)
        }

        const newdata=[]
        const newcolumns=tcolumns

        const dateNow=new Date()
        const now=format(dateNow,"yyyy-MM-dd")
        let year=dateNow.getFullYear()-1
        const minDate=now.replace(now.substring(0,now.indexOf("-")),year+"")
        
        const pagearray=chart.length>10?[5,10,20]:chart.length>5?[5,10]:[5]

        return (
            <MaterialTable
                columns={newcolumns}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div className={classes.row} style={{marginTop:15}}>
                                <span hidden>{num}</span>
                                <MuiPickersUtilsProvider
                                    locale={zhCN}
                                    utils={DateFnsUtils}
                                >
                                    <DatePicker
                                        cancellabel="取消"
                                        // disableToolbar
                                        // minDate={minDate}
                                        disableFuture
                                        format="yyyy-MM"
                                        helperText="No year selection"
                                        inputVariant="outlined"
                                        label="选择月度"
                                        oklabel="确定"
                                        onChange={this.handleDateChange}
                                        value={date_value}
                                        variant="inline"
                                        openTo='month'
                                        views={['year', 'month']}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    )
                }}
                data={chart}
                icons={TableIcons}
                isLoading={loading}
                localization={{
                    pagination: {
                        labelRowsSelect: '行每页',
                        labelDisplayedRows: chart.length>0?' {from}-{to} 共 {count} 条':'0-0 共 0 条',
                        firstTooltip: '第一页',
                        previousTooltip: '前一页',
                        nextTooltip: '后一页',
                        lastTooltip: '到最后'
                    },
                    toolbar: {
                        searchTooltip: '模糊查找',
                        searchPlaceholder: '模糊查找',
                        exportName: '导出表格',
                        nRowsSelected: '{0} row(s) selected',
                        exportTitle: '导出表格'
                    },
                    header: {
                        actions: '操作'
                    },
                    body: {
                        addTooltip: '新增记录',
                        emptyDataSourceMessage: '暂时没有数据',
                        filterRow: {
                            filterTooltip: 'Filter'
                        },
                        editRow: {
                            deleteText: '确定要删除吗？',
                            cancelTooltip: '取消',
                            saveTooltip: '确定'
                        }
                    }
                }}
                options={{
                    exportButton: true,
                    draggable:false,
                    minBodyHeight:600,
                    pageSize: pagesize,
                    paging: true,
                    pageSizeOptions:pagearray,
                    paginationType:'normal',
                    initialPage:curpage,

                    // exportCsv: (columns, data) => {}
                    exportAllData: true,
                    exportCsv: (columnList, initialData) =>
                        export_to_excel(columnList, initialData, '月度考勤榜'),

                    toolbarButtonAlignment: 'left'
                    // paginationType: 'stepped'
                }}
                title={<div style={{fontSize:16, marginRight:10, marginTop:5}}>月度考勤榜</div>}
            />
        );
    }
}

const mapStateToProps = state => ({
    chart: state.report.report_month_chart,
    loading:state.report.loading
});

const mapDispatchToProps = dispatch => ({
    monthChartActions: data => dispatch(reportActions.getMonthChart(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Index));
