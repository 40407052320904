import axios from 'axios';

// axios.defaults.baseURL = ''
axios.defaults.withCredentials = true;
axios.defaults.timeout = 2000;

const headers = new Headers({
  Accept: 'application/json',
  'content-Type': 'application/json'
});

function get(url) {
  return axios
    .get(url)
    .then(response => response)
    .catch(err => {
      console.error(`Request failed. Url = ${url}.
      Message= ${err}`);
      return Promise.reject({
        error: { message: 'Request failed.' }
      });
    });
}

function post(url, data) {
  return axios
    .post(url, data)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.error(`Request failed. Url = ${url}.
          Message= ${err}`);
      return Promise.reject({
        error: { message: 'Request failed.' }
      });
    });
}

function handleResponse(url, response) {
  if (response.status === 200) {
    // 返回了一个 promise
    return response.json();
  } else {
    console.error(`Request failed. Url = ${url}`);
    return Promise.reject({
      error: { message: 'Request failed due to server error' }
    });
  }
}

export { get, post };
