import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import utils from 'utility';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
// Material helpers
import { withStyles } from '@material-ui/styles';
import SnackbarsMessage from '../../components/SnackbarsMessage';

// Material components
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';

// Material icons
// import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Shared utilities
import validators from 'common/validators';
import Http from 'services/http';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

// Shared components

validate.validators.checked = validators.checked;

// Service methods

class FindPass extends Component {
    state = {
        values: {
            mobile: '',
            captcha: '',
            password: '',
            comfirmPassword: '',
            policy: true
        },
        touched: {
            mobile: false,
            captcha: false,
            password: false,
            comfirmPassword: false,
            policy: true
        },
        errors: {
            mobile: null,
            captcha: null,
            password: null,
            comfirmPassword: null,
            policy: null
        },
        isValid: false,
        isLoading: false,
        submitError: null,
        isSend: false,
        snackbarsMsg: '',
        snackbarsVisible: false,
        issendcount: 120,
        validPasswordError: true
    };

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };
    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };
    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    validateForm = () => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);
        newState.isValid = errors ? false : true;
        newState.errors = errors || {};

        this.setState(newState, () => {
            if (
                newState.values['password'] ===
                    newState.values['comfirmPassword'] &&
                newState.touched['password'] &&
                newState.touched['comfirmPassword']
            ) {
                this.setState({
                    validPasswordError: true
                });
            } else if (newState.touched['password']) {
                this.setState({
                    validPasswordError: false
                });
            }
        });
    };

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
    };

    handleSignUp = async () => {
        try {
            const { history } = this.props;
            const { values } = this.state;

            this.setState({ isLoading: true });

            const res = await Http.useyzm(
                {
                    phone: values.mobile,
                    yzm: values.captcha,
                    pass: utils.md5(values.password)
                },
                true
            );
            const { errcode, errmsg } = res;
            if (errcode === 0) {
                setTimeout(() => {
                    this.showMsg(errmsg);
                }, 1000);
                this.setState({
                    isLoading: false
                });
                history.push('/sign-in');
            } else {
                this.setState({
                    isLoading: false
                });
                this.showMsg(errmsg);
            }
        } catch (error) {
            this.setState({
                isLoading: false
            });
        }
    };

    handleGetCaptcha = async () => {
        const { values } = this.state;
        this.setState({
            isSend: true
        });
        setTimeout(() => {
            this.setState({
                isSend: false
            });
        }, 1000 * 120);
        let timer = setInterval(() => {
            const { issendcount } = this.state;
            if (issendcount === 0) {
                clearInterval(timer);
                this.setState({
                    issendcount: 120
                });
            } else {
                this.setState({
                    issendcount: issendcount - 1
                });
            }
        }, 1000);

        // 请求验证码
        try {
            const res2 = await Http.findaccount({ phone: values.mobile }, true);
            const { errcode, errmsg } = res2;
            if (errcode !== 0) {
                this.setState({
                    isSend: false
                });
                this.showMsg(errmsg);
            } else {
                this.showMsg('发送成功');
            }
        } catch (err) {
            this.showMsg('服务器开小差了,请稍后再试');
        }
        // axios
        //   .get(`/Handler/Captcha.ashx?type=pageyzm&phone=${values.mobile}`)
        //   .then(res => {
        //     const {errcode, errmsg} = res.data
        //     if(errcode!==0){
        //       this.setState({
        //         isSend: false
        //       })
        //       this.showMsg(errmsg)
        //     }else{
        //       this.showMsg('发送成功')
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
    };

    render() {
        const { classes } = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;

        const showmobileError =
            touched.mobile && errors.mobile ? errors.mobile[0] : false;
        const disableGetCaptcha = touched.mobile
            ? errors.mobile
                ? true
                : false
            : true;
        const showcaptchaError =
            touched.captcha && errors.captcha ? errors.captcha[0] : false;
        const showpasswordError =
            touched.password && errors.password ? errors.password[0] : false;
        const showcomfirmPasswordError =
            touched.comfirmPassword && errors.comfirmPassword
                ? errors.comfirmPassword[0]
                : false;

        return (
            <div className={classes.root}>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={this.state.snackbarsMsg}
                    visible={this.state.snackbarsVisible}
                />
                <Grid className={classes.grid} container>
                    <Grid className={classes.quoteWrapper} item lg={5}>
                        <div className={classes.quote}>
                            {/* <div className={classes.quoteInner}>
                <Typography
                    className={classes.quoteText}
                    variant="h1"
                >
                  叮咚科技，掌握美好未来
                </Typography>
                <div className={classes.person}>
                  <Typography
                      className={classes.name}
                      variant="body1"
                  >
                  </Typography>
                  <Typography
                      className={classes.bio}
                      variant="body2"
                  >
                  </Typography>
                </div>
              </div> */}
                        </div>
                    </Grid>
                    <Grid className={classes.content} item lg={7} xs={12}>
                        <div className={classes.content}>
                            <div className={classes.contentHeader}></div>
                            <div className={classes.contentBody}>
                                <div className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        叮咚科技，掌握美好未来
                                    </Typography>
                                    <Typography
                                        className={classes.subtitle}
                                        variant="body1"
                                    >
                                        密码找回
                                    </Typography>
                                    <div className={classes.fields}>
                                        <div className={classes.textField}>
                                            <TextField
                                                className={
                                                    classes.textCaptchaField
                                                }
                                                inputProps={{
                                                    autocomplete:
                                                        'new-password',
                                                    form: {
                                                        autocomplete: 'off'
                                                    }
                                                }}
                                                label="手机号码"
                                                name="mobile"
                                                onChange={event =>
                                                    this.handleFieldChange(
                                                        'mobile',
                                                        event.target.value
                                                    )
                                                }
                                                value={values.mobile}
                                                variant="outlined"
                                            />
                                            <Button
                                                className={
                                                    classes.textCaptchaButton
                                                }
                                                disabled={
                                                    disableGetCaptcha ||
                                                    this.state.isSend
                                                }
                                                onClick={this.handleGetCaptcha}
                                                variant="contained"
                                            >
                                                {this.state.isSend
                                                    ? this.state.issendcount
                                                    : '获取验证码'}
                                            </Button>
                                        </div>
                                        {showmobileError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.mobile[0].replace(
                                                    'Mobile',
                                                    ''
                                                )}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            inputProps={{
                                                autocomplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            label="验证码"
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    'captcha',
                                                    event.target.value
                                                )
                                            }
                                            value={values.captcha}
                                            variant="outlined"
                                        />
                                        {showcaptchaError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.captcha[0].replace(
                                                    'Captcha',
                                                    ''
                                                )}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            inputProps={{
                                                autocomplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            label="密码"
                                            name="password"
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    'password',
                                                    event.target.value
                                                )
                                            }
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                        />
                                        {showpasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.password[0].replace(
                                                    'Password',
                                                    ''
                                                )}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            inputProps={{
                                                autocomplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            label="确认密码"
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    'comfirmPassword',
                                                    event.target.value
                                                )
                                            }
                                            type="password"
                                            value={values.comfirmPassword}
                                            variant="outlined"
                                        />
                                        {showcomfirmPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.comfirmPassword[0].replace(
                                                    'Comfirm password',
                                                    ''
                                                )}
                                            </Typography>
                                        )}
                                        {!this.state.validPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                两次输入的不一致
                                            </Typography>
                                        )}
                                    </div>
                                    {submitError && (
                                        <Typography
                                            className={classes.submitError}
                                            variant="body2"
                                        >
                                            {submitError}
                                        </Typography>
                                    )}
                                    {isLoading ? (
                                        <CircularProgress
                                            className={classes.progress}
                                        />
                                    ) : (
                                        <Button
                                            className={classes.signUpButton}
                                            color="primary"
                                            disabled={!isValid}
                                            onClick={this.handleSignUp}
                                            size="large"
                                            variant="contained"
                                        >
                                            确认
                                        </Button>
                                    )}
                                    <Typography
                                        className={classes.signIn}
                                        variant="body1"
                                    >
                                        <Link
                                            className={classes.signInUrl}
                                            to="/sign-in"
                                        >
                                            返回登录
                                        </Link>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

FindPass.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(FindPass);
