import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Divider,
    Switch,
    TextField,
    Typography,
    colors
} from '@material-ui/core';

import SuccessSnackbar from '../SuccessSnackbar';

const useStyles = makeStyles(theme => ({
    root: {},
    saveButton: {
        color: theme.palette.white
        // backgroundColor: colors.green[600],
        // '&:hover': {
        //     backgroundColor: colors.green[900]
        // }
    }
}));

const GeneralSettings = props => {
    const { profile, className, ...rest } = props;

    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [values, setValues] = useState({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phone: profile.phone,
        state: profile.state,
        country: profile.country,
        isPublic: profile.isPublic,
        canHire: profile.canHire
    });

    const handleChange = event => {
        event.persist();

        setValues({
            ...values,
            [event.target.name]:
                event.target.type === 'checkbox'
                    ? event.target.checked
                    : event.target.value
        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const states = ['Alabama', 'New York', 'San Francisco'];

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <form onSubmit={handleSubmit}>
                <CardHeader title="用户基本信息" />
                <Divider />
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                helperText="登录用户名"
                                label="用户名"
                                name="firstName"
                                onChange={handleChange}
                                required
                                value={values.firstName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="真实姓名"
                                name="lastName"
                                onChange={handleChange}
                                required
                                value={values.lastName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="邮箱地址"
                                name="email"
                                onChange={handleChange}
                                required
                                value={values.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="电话号码"
                                name="phone"
                                onChange={handleChange}
                                type="text"
                                value={values.phone}
                                variant="outlined"
                            />
                        </Grid>
                        {/* <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Select State"
                                name="state"
                                onChange={handleChange}
                                select
                                // eslint-disable-next-line react/jsx-sort-props
                                SelectProps={{ native: true }}
                                value={values.state}
                                variant="outlined"
                            >
                                {states.map(state => (
                                    <option key={state} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Country"
                                name="country"
                                onChange={handleChange}
                                required
                                value={values.country}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h6">
                                Make Contact Info Public
                            </Typography>
                            <Typography variant="body2">
                                Means that anyone viewing your profile will be
                                able to see your contacts details
                            </Typography>
                            <Switch
                                checked={values.isPublic}
                                color="secondary"
                                edge="start"
                                name="isPublic"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h6">
                                Available to hire
                            </Typography>
                            <Typography variant="body2">
                                Toggling this will let your teamates know that
                                you are available for acquireing new projects
                            </Typography>
                            <Switch
                                checked={values.canHire}
                                color="secondary"
                                edge="start"
                                name="canHire"
                                onChange={handleChange}
                            />
                        </Grid> */}
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button
                        className={classes.saveButton}
                        type="submit"
                        variant="contained"
                    >
                        保存修改
                    </Button>
                </CardActions>
            </form>
            <SuccessSnackbar
                onClose={handleSnackbarClose}
                open={openSnackbar}
            />
        </Card>
    );
};

GeneralSettings.propTypes = {
    className: PropTypes.string,
    profile: PropTypes.object.isRequired
};

export default GeneralSettings;
