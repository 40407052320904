import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import ProductCurvyLines from 'static/onepirate/productCurvyLines.png';
import dingwei_image from 'static/onepirate/dingwei.png';
import suiszou_image from 'static/onepirate/suiszou.png';

import neiqin_image from 'static/onepirate/neiqin.png';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: '#dce2f5'
    },
    container: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(30),
        display: 'flex',
        position: 'relative'
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5)
    },
    image: {
        height: 55
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180
    }
});

function ProductValues(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <img
                    alt="curvy lines"
                    className={classes.curvyLines}
                    src={ProductCurvyLines}
                />
                <Grid container spacing={5}>
                    <Grid item md={4} xs={12}>
                        <div className={classes.item}>
                            <img
                                alt="clock"
                                className={classes.image}
                                src={neiqin_image}
                            />
                            <Typography className={classes.title} variant="h6">
                                精准内勤签到
                            </Typography>
                            <Typography variant="h5">
                                <span>
                                    有wifi就能签到
                                    <br />
                                    内勤签到快速精准
                                </span>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div className={classes.item}>
                            <img
                                alt="suitcase"
                                className={classes.image}
                                src={dingwei_image}
                            />
                            <Typography className={classes.title} variant="h6">
                                外勤定位签到
                            </Typography>
                            <Typography variant="h5">
                                <span>
                                    有信号就能定位
                                    <br />
                                    外勤签到准确清晰
                                </span>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div className={classes.item}>
                            <img
                                alt="graph"
                                className={classes.image}
                                src={suiszou_image}
                            />
                            <Typography className={classes.title} variant="h6">
                                反作弊辅助签到
                            </Typography>
                            <Typography variant="h5">
                                <span>
                                    可设置拍照反作弊
                                    <br />
                                    签到数据真实可信
                                </span>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

ProductValues.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductValues);
