import Http from 'services/http';

const initCpyState = {
    cpy_list: []
};

export const types = {
    // 获取单位列表
    CPY_LIST_REQUEST: 'CPY/CPY_LIST_REQUEST',
    CPY_LIST_SUCCESS: 'CPY/CPY_LIST_SUCCESS',
    CPY_LIST_FAILURE: 'CPY/CPY_LIST_FAILURE'
};

// actionn creator
const doGetCpyListRequest = () => ({
    type: types.CPY_LIST_REQUEST
});

const doGetCpyListSuccess = data => ({
    type: types.CPY_LIST_SUCCESS,
    data
});

const doGetCpyListFailure = error => ({
    type: types.CPY_LIST_FAILURE,
    error
});

export const actions = {
    getcpylist: () => {
        return async dispatch => {
            dispatch(doGetCpyListRequest());
            try {
                let cpyData = await Http.cpy({ type: 'cpyList' }, true);
                const { data, errcode, errmsg } = cpyData;
                if (errcode !== 0) {
                    dispatch(doGetCpyListFailure(errmsg));
                } else {
                    localStorage.setItem('cpy_list', JSON.stringify(data));
                    dispatch(
                        doGetCpyListSuccess({
                            cpy_list: data
                        })
                    );
                }
            } catch (err) {
                dispatch(doGetCpyListFailure(err));
            }
        };
    }
};

const reducer = (state = initCpyState, action) => {
    switch (action.type) {
        case types.CPY_LIST_REQUEST:
            return { ...state };
        case types.CPY_LIST_SUCCESS:
            return {
                ...state,
                ...action.data
            };
        case types.CPY_LIST_FAILURE:
            return {
                ...state,
                ...action.error
            };
        default:
            return state;
    }
};

export default reducer;
