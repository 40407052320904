import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import utils from 'utility';
import { actions as signinActions } from '../../redux/modules/sign_in';
import SnackbarsMessage from '../../components/SnackbarsMessage';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import Vcode from 'react-vcode';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Grid, Button, CircularProgress, TextField, Typography } from '@material-ui/core';

// Material icons
// import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Shared components
import Http from '../../services/http';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class SignIn extends Component {
    state = {
        values: {
            name: '',
            pass: '',
            captcha: ''
        },
        touched: {
            name: false,
            pass: false,
            captcha: false
        },
        errors: {
            name: null,
            pass: null,
            captcha: null
        },
        isValid: false,
        isLoading: false,
        submitError: null,
        visible: false,
        msg: '',
        captcha: ''
    };    

    async componentDidMount() {
        try {
            let resData = await Http.session_check();
            if (resData && resData.errcode !== 0) {
                localStorage.clear();
            } else if(JSON.parse(localStorage.getItem('cur_user'))){
                JSON.parse(localStorage.getItem('cur_cpy'))?this.props.history.push('/signdata'):this.props.history.push('/newuser')
            }
        }catch (err) {
            localStorage.clear();
        }
    }

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    // debounce 防抖
    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        // 进行 schema 和 values 的匹配 返回 errors 空的化就是没有
        const errors = validate(values, schema);
        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    handleSignIn = async () => {
        try {
            const { history } = this.props;
            const { values, captcha } = this.state;

            this.setState({ isLoading: true });
            const { name, pass } = values;
            if (captcha !== values.captcha) {
                this.setState({ visible: true, msg: '验证码错误' });
                setTimeout(() => {
                    this.setState({ visible: false, isLoading: false });
                }, 2000);
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 500);
            } else {
                const md5password = utils.md5(pass);
                const postData = { username: name, pwd: md5password };
                
                const res = await Http.user_manager(postData);
                const { data, errcode, errmsg } = res;
                if (errcode === 0) {
                    localStorage.setItem('is_auth', true);
                    const LS_KEY = 'cur_user';

                    localStorage.setItem(LS_KEY, JSON.stringify(data));
                    // console.log(data)
                    let cpyData = await Http.cpyList();
                    if (cpyData.data.length == 0) {
                        history.push('/newuser');
                    } else {
                        localStorage.setItem(
                            'cur_cpy',
                            JSON.stringify(cpyData.data[0])
                        );
                        localStorage.setItem(
                            'cpy_list',
                            JSON.stringify(cpyData.data)
                        );
                        const route={tabs:0, conditon:0, id:0}
                        localStorage.setItem(
                            'uc_route', JSON.stringify(route)
                        );
                        this.setState({ isLoading: false });
                        history.push('/signdata');
                    }
                } else {
                    this.setState({ visible: true, msg: errmsg });
                    setTimeout(() => {
                        this.setState({ visible: false, isLoading: false });
                    }, 2000);
                }
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                serviceError: error
            });
        }
    };
    handleClose = () => {
        this.setState({ visible: false });
    };

    isAdmin = e => {
        console.log(e.target);
    };

    render() {
        const { classes } = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading,
            visible,
            msg
        } = this.state;

        const showEmailError = touched.name && errors.name;
        const showPasswordError = touched.pass && errors.pass;
        const showcaptchaError = touched.captcha && errors.captcha;
        return (
            <div className={classes.root}>
                <SnackbarsMessage msg={msg} visible={visible} />
                <Grid className={classes.grid} container>
                    <Grid className={classes.quoteWrapper} item lg={5}>
                        <div className={classes.quote}>
                            <div className={classes.quoteInner}>
                                {/* <Typography
                                    className={classes.quoteText}
                                    variant="h1"
                                >
                                    专注利用手机 实现快捷可靠的签到管理！
                                    跟传统打卡机Say Goooooooodbye！
                                </Typography> */}
                                {/* <div className={classes.person}>
                                    <Typography
                                        className={classes.name}
                                        variant="body1"
                                    >
                                        叮咚签到
                                    </Typography>
                                    <Typography
                                        className={classes.bio}
                                        variant="body2"
                                    >
                                        济南比威
                                    </Typography>
                                </div> */}
                            </div>
                        </div>
                    </Grid>
                    <Grid className={classes.content} item lg={7} xs={12}>
                        <div className={classes.content}>
                            <div className={classes.contentHeader}>
                                {/* <IconButton
                    className={classes.backButton}
                    onClick={this.handleBack}
                  >
                    <ArrowBackIcon />
                  </IconButton> */}
                            </div>
                            <div className={classes.contentBody}>
                                <div className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        登录
                                    </Typography>
                                    <Typography
                                        className={classes.subtitle}
                                        variant="body1"
                                    >
                                        叮咚签到
                                    </Typography>
                                    {/* <Button
                    className={classes.facebookButton}
                    color="primary"
                    onClick={this.handleSignIn}
                    size="large"
                    variant="contained"
                  >
                    <FacebookIcon className={classes.facebookIcon} />
                    微博账号登录
                  </Button>
                  <Button
                    className={classes.googleButton}
                    onClick={this.handleSignIn}
                    size="large"
                    variant="contained"
                  >
                    <GoogleIcon className={classes.googleIcon} />
                    微信账号登录
                  </Button> */}
                                    <Typography
                                        className={classes.sugestion}
                                        variant="body1"
                                    >
                                        手机号码登录
                                    </Typography>
                                    <div className={classes.fields}>
                                        <TextField
                                            className={classes.textField}
                                            label="手机号码 或 用户名"
                                            name="name"
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    'name',
                                                    event.target.value
                                                )
                                            }
                                            type="text"
                                            value={values.name}
                                            variant="outlined"
                                        />
                                        {showEmailError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.name[0].replace(
                                                    'Name',
                                                    ''
                                                )}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                                form: {
                                                    autoComplete: 'off'
                                                }
                                            }}
                                            label="密码"
                                            name="pass"
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    'pass',
                                                    event.target.value
                                                )
                                            }
                                            type="password"
                                            value={values.pass}
                                            variant="outlined"
                                        />
                                        {showPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.pass[0].replace(
                                                    'Pass',
                                                    ''
                                                )}
                                            </Typography>
                                        )}
                                    </div>
                                    {submitError && (
                                        <Typography
                                            className={classes.submitError}
                                            variant="body2"
                                        >
                                            {submitError}
                                        </Typography>
                                    )}
                                    <div className={classes.fields}>
                                        <TextField
                                            className={classes.textField}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                                form: {
                                                    autoComplete: 'off'
                                                }
                                            }}
                                            label="验证码"
                                            name="captcha"
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    'captcha',
                                                    event.target.value
                                                )
                                            }
                                            style={{ width: '65%' }}
                                            // type="password"
                                            value={values.captcha}
                                            variant="outlined"
                                        />
                                        <Vcode
                                            height={55}
                                            onChange={v => {
                                                this.setState({
                                                    captcha: v
                                                });
                                            }}
                                            style={{ float: 'right' }}
                                        />
                                    </div>
                                    {showcaptchaError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.captcha[0].replace(
                                                'Captcha',
                                                ''
                                            )}
                                        </Typography>
                                    )}
                                    {/* <div className={classes.fields}>
                                        <Checkbox
                                            checked={values.policy}
                                            className={classes.policyCheckbox}
                                            color="primary"
                                            name="policy"
                                            onChange={this.isAdmin}
                                        />
                                        <Typography
                                            className={classes.policyText}
                                            variant="body1"
                                        >
                                            账号管理员&nbsp;
                                        </Typography>
                                    </div> */}

                                    {isLoading ? (
                                        <CircularProgress
                                            className={classes.progress}
                                        />
                                    ) : (
                                        <Button
                                            className={classes.signInButton}
                                            color="primary"
                                            disabled={!isValid}
                                            onClick={this.handleSignIn}
                                            size="large"
                                            variant="contained"
                                        >
                                            登录
                                        </Button>
                                    )}
                                    <Typography
                                        className={classes.signUp}
                                        variant="body1"
                                    >
                                        还没有账号?{' '}
                                        <Link
                                            className={classes.signUpUrl}
                                            to="/sign-up"
                                        >
                                            注册
                                        </Link>
                                        <Link
                                            className={classes.findPass}
                                            to="/pass"
                                        >
                                            找回密码
                                        </Link>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    signinUser: state.sign_in
});

const mapDispatchToProps = dispatch => ({
    signinActions: data => dispatch(signinActions.signin(data))
});

SignIn.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
        withRouter,
        withStyles(styles)
    )(SignIn)
);
