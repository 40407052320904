import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import dianji_img from './dianji.png';
import A_img from './A.png';
import Q_img from './Q.png';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        flexBasis: '80%',
        flexShrink: 0,
        margin: theme.spacing(2)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    qtype: {
        margin: theme.spacing(2)
    },
    qcontent: {
        color: 'white',
        fontSize: theme.typography.pxToRem(18),
        margin: theme.spacing(1)
    },
    qcontainer: {
        backgroundColor: '#A1B4DD'
    },
    expansionPanel: {
        borderRadius: '8px'
    }
    // 161 180 221
}));

export default function ControlledExpansionPanels() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.qtype}>
                员工端问题
            </Typography>
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        src={A_img}
                        style={{ width: '50px', height: '50px' }}
                    />
                    <Typography className={classes.heading}>
                        员工端登录后提示更换手机，请通知管理员，怎么办？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        src={Q_img}
                        style={{ width: '65px', height: '65px' }}
                    />

                    <Typography className={classes.qcontent}>
                        由于叮咚签到发现员工更换了手机，造成无法签到的话，需要通知管理员，让管理员在后台进行重置员工手机信息操作。具体操作时，员工管理页面，编辑员工信息时，将重置用户手机的开关打开，保存后员工的手机信息会重置，这时员工在员工端就可以进行签到了。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        src={A_img}
                        style={{ width: '50px', height: '50px' }}
                    />
                    <Typography className={classes.heading}>
                        为什么手机明明连接了签到路由器设备，员工端签到时提示无法找到签到设备，将执行外勤操作？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        src={Q_img}
                        style={{ width: '65px', height: '65px' }}
                    />

                    <Typography className={classes.qcontent}>
                        管理员需要确认后台录入的签到路由设备的名字是否和办公室签到路由设备的名字严格一致，包括大小写是否一致，是否有空格等。名字不一致的话，员工端肯定会提示外勤的。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        为什么手机明明连接了签到路由器设备，后台录入的签到路由设备的名字和办公室签到路由设备的名字也是严格一致，有的员工端签到时可以内勤签到，有的却还是提示无法找到签到设备，将执行外勤操作？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        管理员需要确认一下办公室是否多个同名的签到路由设备（不管是后来新增了路由设备，还是本来就有多个路由设备），如果办公室有多个名字一致的签到路由设备，管理员需要在后台录入多个签到路由设备来保证员工端连接的路由设备和后台录入的签到路由设备的数据一致。假设，办公室有两个名字一致都叫dingdong的路由签到设备A和B，而管理员在后台只录入了一个签到路由设备A，员工端签到时却连接了B进行签到，员工端肯定会提示无法找到签到设备，将执行外勤操作，这时管理员需要在后台再录入一个名字也叫dingdong的签到路由设备，重新打开员工端就可以执行内勤操作了。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        后台没有任何改动，后台录入的路由设备数量和实际的数量也一致，员工端签到时前一天签到还可以进行内勤操作的，今天签到却提示无法找到签到设备，将执行外勤操作，怎么回事？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        管理员需要确认办公室的签到路由设备是否已经更换，如果已经更换了设备，原来后台录入的签到设备的数据就失效了，无法作为内勤签到的依据，因此需要在后台删除该设备的数据，并且在后台重新添加签到设备。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        员工端登录后执行签到操作时提示用户令牌超期，无法执行签到，怎么办？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        员工端执行注销重新登录就可以了。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        员工端使用正确的员工信息登录后却提示手机已经绑定了其他帐号，无法进行考勤操作，怎么回事？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        员工必须使用自己的手机和自己的员工信息登录才能进行正常的考勤操作，如果员工使用别人的手机和自己的员工信息登录时，则会提示手机已经绑定别人的信息，无法进行考勤操作。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        员工端登录时提示员工信息不正确，怎么办？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        员工信息包括员工姓名，员工手机号和单位号，和管理员核对这三个信息是否和后台的员工信息完全一致。只有这三个信息完全一致时员工端才能登录成功，否则无法完成登录操作。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Typography variant="h6" className={classes.qtype}>
                后台和管理端问题
            </Typography>
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        后台设置考勤时段时，如何设置考勤时段？什么是签到有效时间？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        考勤时段的格式是时间A至时间B，签到有效时间C。
                        其中时间A是上班时间，在这个时间前执行第一个签到表示没有迟到，上班是正常的，在这个时间后执行第一个签到表示迟到，上班已经迟到了。时间B是下班时间，在这个时间后执行签出表示没有早退，正常时间下班了，在这个时间前执行签出表示早退了，提前下班早退了。时间C是签到有效时间，表示只有在时间C之后才可以执行考勤签到，在这个时间前，是不能执行考勤的。
                        从时间顺序上来说，时间C早于时间A，时间A早于时间B。在时间C之前是不能签到的，在时间C和时间A之间签到是表明正常签到上班，时间A和时间B之间签到表明上班迟到了，在时间A和时间B之间签出表明下班早退了，时间B之后签出表明正常下班了
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        后台设置考勤时段时提示时段交叉，怎么回事？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        如果只有一个考勤时段的话，设置时考勤时段的格式是时间A至时间B，签到有效时间C。从时间顺序上来说，时间C早于时间A，时间A早于时间B。不符合这个要求的话，提示时段交叉错误。
                        如果多个考勤时段的话，一个考勤时段不能和其他考勤时段存在时间上的重叠，否则也会提示时段交叉。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        后台设置单位信息时的考勤时段，和员工的考勤时段有什么区别？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        设置单位信息时的考勤时段，是新增员工时的默认考勤时段。每个员工信息里的考勤时段才是这个员工真正的考勤时段，修改单位的考勤时段不会修改员工的考勤时段，修改员工的考勤时段需要对员工信息进行编辑时才能够修改。
                        例如，设置单位信息时设置了考勤时段A，设置单位信息后新增员工a时的默认考勤时段会使用单位的考勤时段，也就是时段A。新增员工a后，将单位的考勤时段修改为B，然后再新增员工b，员工b的默认考勤时段为B。而不是A。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        如果我想批量修改员工的考勤时段，该怎么办？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        使用批量操作，批量修改操作可以修改考勤时段，修改部门，修改签到设备。批量导入操作可以批量倒入员工信息。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel12'}
                onChange={handleChange('panel12')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        修改单位考勤时段之后，为何有的员工按照按照新的考勤时段正常完成考勤签到，有的员工却没有按照新的考勤时段考勤签到？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        如果修改了单位的考勤时段之后，修改时段后新添加员工的默认考勤时段会采用新修改的单位考勤时段。但是修改时段前就已经存在的员工，他们的考勤时段是不会改变的。因此如果你想修改单位全体员工的考勤时段，修改单位的考勤时段是无效的，而是应该修改每个已存在员工的考勤时段（可以通过后台的批量操作功能进行修改）才是有效的。修改成功后员工签到即可恢复正常。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel13'}
                onChange={handleChange('panel13')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        在进行注册的时候，收不到验证短信怎么办？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        验证码是由验证短信发送平台发送的，发送到达率不是百分之百，可以联系客服0531-86116897完成注册。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={expanded === 'panel14'}
                onChange={handleChange('panel14')}
            >
                <ExpansionPanelSummary expandIcon={<img src={dianji_img} />}>
                    <img
                        style={{ width: '50px', height: '50px' }}
                        src={A_img}
                    />
                    <Typography className={classes.heading}>
                        后台怎样自动添加员工？
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                        I am an expansion panel
                    </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.qcontainer}>
                    <img
                        style={{ width: '65px', height: '65px' }}
                        src={Q_img}
                    />

                    <Typography className={classes.qcontent}>
                        自动添加员工有一个前提是必须要有签到设备，有了签到设备后，管理员可以在员工管理页面或单位设置页面打开自动录入功能。员工下载叮咚签到员工端APP，手机连接已添加的签到设备，输入“姓名”、“手机号”、“单位号”登录员工端APP即可自动向管理平台添加员工信息。
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}
