export const schema = {
  // 领域实体名称
  username: 'products',
  id: 'id'
};

const reducer = (state = {}, action) => {
  if (action.response && action.response.data) {
    return { ...state, ...action.response.data };
  }
  return state;
};

export default reducer;
