import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import dingdong from 'static/onepirate/dingdong.png';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(9)
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5)
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    buoy: {
        width: 60
    }
});

function ProductSmokingHero(props) {
    const { classes } = props;
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    return (
        <Container className={classes.root} component="section">
            <Button className={classes.button} onClick={handleClickOpen}>
                <Typography component="span" variant="h4">
                    您有定制开发需求？
                </Typography>
            </Button>
            <Typography className={classes.link} variant="subtitle1">
                立刻联系我们
            </Typography>
            <img alt="buoy" className={classes.buoy} src={dingdong} />
            <Dialog
                aria-labelledby="form-dialog-title"
                onClose={handleClose}
                open={open}
            >
                <DialogTitle id="form-dialog-title">联系方式</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        我公司承接,公司考勤建设,考勤设备，公司考勤系统开发。
                    </DialogContentText> */}
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="邮箱或者电话"
                        type="email"
                        fullWidth
                    /> */}
                    <Typography variant="h5">QQ客服: 860799988 </Typography>
                    <Typography variant="h5">客服电话:0531-86079998</Typography>
                    <br />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        取消
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        提交
                    </Button>
                </DialogActions> */}
            </Dialog>
        </Container>
    );
}

ProductSmokingHero.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductSmokingHero);
