export default {
    name: {
        presence: { allowEmpty: false, message: '请输入员工姓名' }
    },
    telephone: {
        presence: { allowEmpty: false, message: '手机号码不允许为空' },
        length: {
            is: 11,
            message: '请填入正确的手机号码'
        },
        format: {
            pattern: '[0-9]+',
            flags: 'i',
            message: '只能包含数字'
        }
    }
};
