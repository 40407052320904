import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import TableIcons from '../../../../components/TableIcons/index';

import { zhCN } from 'date-fns/locale';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { actions as reportActions } from '../../../../redux/modules/report';
import export_to_excel from 'utils/export_to_excel';

import { withStyles } from '@material-ui/styles';
import styles from './styles';
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: '姓名', field: 'UserName', sorting: false },
                { title: '工号', field: 'GongHao' , sorting: false},
                { title: '职位', field: 'ZhiWei' , sorting: false},
                { title: '部门', field: 'GroupName' , sorting: false},
                { title: '手机号', field: 'UserPhone' , sorting: false},
                { title: '工作时长', filtering: false , field: 'worklong',
                    // render: record => {
                    //     return <span>{record.worklong + ' '}小时</span>;
                    // }
                },
                { title: '迟到', field: 'lateNum' },
                { title: '早退次数', field: 'LvEearlyNum' }
            ],

            users: [],
            isLoading: false,
            date_value: null,

            curpage:0,
            pagesize:5
        };
    }

    componentDidMount() {
        const select_date = format(new Date(), 'yyyy-MM-dd');
        this.setState({
            date_value: select_date
        });
    }

    handleDateChange = async value => {
        const select_date = format(value, 'yyyy-MM-dd');
        this.setState({
            date_value: value
        });
        this.props.monthReportActions(select_date);
    };
       

    handleRowsChange = async (rowsPerPage) => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async (Page) => {
        this.setState({ curpage: Page })
    }

    render() {
        const { classes, report,num } = this.props;
        const {pagesize,curpage,columns,date_value,isLoading}=this.state

        const dateNow=new Date()
        const now=format(dateNow,"yyyy-MM-dd")
        let year=dateNow.getFullYear()-1
        const minDate=now.replace(now.substring(0,now.indexOf("-")),year+"")
        
        const pagearray=report.length>10?[5,10,20]:report.length>5?[5,10]:[5]

        return (
            <MaterialTable
                columns={columns}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div className={classes.row} style={{marginTop:15}}>
                                <span hidden>{num}</span>
                                <MuiPickersUtilsProvider
                                    locale={zhCN}
                                    utils={DateFnsUtils}
                                >
                                    <DatePicker
                                        cancellabel="取消"
                                        // disableToolbar
                                        // minDate={minDate}
                                        disableFuture
                                        format="yyyy-MM"
                                        helperText="No year selection"
                                        inputVariant="outlined"
                                        label="选择统计月度"
                                        oklabel="确定"
                                        onChange={this.handleDateChange}
                                        value={date_value}
                                        variant="inline"
                                        openTo='month'
                                        views={['year', 'month']}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    )
                }}
                data={report}
                icons={TableIcons}
                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelRowsSelect: '行每页',
                        labelDisplayedRows: report.length>0?' {from}-{to} 共 {count} 条':'0-0 共 0 条',
                        firstTooltip: '第一页',
                        previousTooltip: '前一页',
                        nextTooltip: '后一页',
                        lastTooltip: '到最后'
                    },
                    toolbar: {
                        searchTooltip: '模糊查找',
                        searchPlaceholder: '模糊查找',
                        exportName: '导出表格',
                        nRowsSelected: '{0} row(s) selected',
                        exportTitle: '导出表格'
                    },
                    header: {
                        actions: '操作'
                    },
                    body: {
                        addTooltip: '新增记录',
                        emptyDataSourceMessage: '暂时没有数据',
                        filterRow: {
                            filterTooltip: 'Filter'
                        },
                        editRow: {
                            deleteText: '确定要删除吗？',
                            cancelTooltip: '取消',
                            saveTooltip: '确定'
                        }
                    }
                }}
                options={{
                    exportButton: true,
                    draggable:false,
                    minBodyHeight:600,
                    pageSize: pagesize,
                    paging: true,
                    pageSizeOptions:pagearray,
                    paginationType:'normal',
                    initialPage:curpage,

                    // exportCsv: (columns, data) => {}
                    exportAllData: true,
                    exportCsv: (columnList, initialData) =>
                        export_to_excel(columnList, initialData, '月统计报表'),

                    toolbarButtonAlignment: 'left'
                    // paginationType: 'stepped'
                }}
                title={<div style={{fontSize:16, marginRight:10, marginTop:5}}>月度考勤统计</div>}
            />
        );
    }
}

const mapStateToProps = state => ({
    report: state.report.report_month_report
});

const mapDispatchToProps = dispatch => ({
    monthReportActions: data => dispatch(reportActions.getMonthReport(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Index));
