export default theme => ({
    root: {
        padding: theme.spacing(4)
    },
    field:{
        margin :10
    },
    textField:{
        width:'100%'
    },
    content: {
        // marginTop: theme.spacing(2)
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    progressWrapper: {
        paddingTop: '48px',
        paddingBottom: '24px',
        display: 'flex',
        justifyContent: 'center'
    },
    pagination: {
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    }
});
