import { combineReducers } from 'redux';

import entities from './entities';

import app from './app';
import sign_in from './sign_in';
import cpy from './cpy';
import report from './report';
// import topbar from './topbar';
// 合并

const rootReducer = combineReducers({
    app,
    entities,
    sign_in,
    cpy,
    // topbar,
    report
});

export default rootReducer;
