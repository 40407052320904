import React, { Component } from 'react';

import { Grid, Box, Button, IconButton, TextField, Typography, CircularProgress, Dialog, DialogActions,DialogContent, DialogTitle} from '@material-ui/core';
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletFooter, SnackbarsMessage } from 'components';
import { Clear } from '@material-ui/icons';
// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/styles';

import Http from 'services/http';
import utils from 'utility';
import schema from './schema';
import styles from './styles';

const def_state = {
    values: {
        mobile: '',
        captcha: '',
        password: '',
        comfirmPassword: '',
        policy: true
    },
    touched: {
        mobile: false,
        captcha: false,
        password: false,
        comfirmPassword: false,
        policy: true
    },
    errors: {
        mobile: null,
        captcha: null,
        password: null,
        comfirmPassword: null,
        policy: null
    },
    isValid: false,
    isLoading: false,
    submitError: null,
    isSend: false,
    snackbarsMsg: '',
    snackbarsVisible: false,
    issendcount: 120,
    // forbidden:false,
    validPasswordError: true
};

class ChangePwdDlg extends Component {
    state = {
        values: {
            mobile: '',
            captcha: '',
            password: '',
            comfirmPassword: '',
            policy: true
        },
        touched: {
            mobile: false,
            captcha: false,
            password: false,
            comfirmPassword: false,
            policy: true
        },
        errors: {
            mobile: null,
            captcha: null,
            password: null,
            comfirmPassword: null,
            policy: null
        },
        isValid: false,
        isLoading: false,
        submitError: null,
        isSend: false,
        snackbarsMsg: '',
        snackbarsVisible: false,
        issendcount: 120,
        // forbidden:false,
        validPasswordError: true
    };
    
    componentDidMount() {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({
            values: { ...this.state.values, mobile: cur_user.Phone }, 
            // forbidden:true,
            touched: { ...this.state.touched, mobile: true }
        });
    }
    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };
    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };
    validateForm = () => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);
        newState.isValid = errors ? false : true;
        newState.errors = errors || {};
        // this.setState({forbidden:(!newState.isValid&&showcaptchaError)})

        this.setState(newState, () => {
            if(newState.values.comfirmPassword.length>0){
                if (
                    newState.values['password'] ===
                        newState.values['comfirmPassword'] &&
                    newState.touched['password'] &&
                    newState.touched['comfirmPassword']
                ) {
                    this.setState({
                        // forbidden:false,
                        validPasswordError: true
                    });
                } else if (newState.touched['password']) {
                    this.setState({
                        // forbidden:true,
                        validPasswordError: false
                    });
                }
            }
        });
        this.setState(newState);
    };

    validateCPForm = () => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);
        newState.isValid = errors ? false : true;
        newState.errors = errors || {};
        // this.setState({forbidden:(!newState.isValid&&showcaptchaError)})

        this.setState(newState, () => {
            if (
                newState.values['password'] ===
                    newState.values['comfirmPassword'] &&
                newState.touched['password'] &&
                newState.touched['comfirmPassword']
            ) {
                this.setState({
                    // forbidden:false,
                    validPasswordError: true
                });
            } else if (newState.touched['password']) {
                this.setState({
                    // forbidden:true,
                    validPasswordError: false
                });
            }
        });
    };

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
    };

    handleCPFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateCPForm);
    };

    handleQuit=()=>{
        const phone=this.state.values.mobile
        this.setState({isLoading:false, validPasswordError:true,
            values: {...def_state.values, mobile:phone},
            touched:{...def_state.touched, mobile:true}})
        this.props.handleclose();
    }

    handleConfirm = async () => {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        try {
            const { values } = this.state;
            if(values.captcha==null || values.password==null || values.comfirmPassword==null || values.mobile.length==0 || values.password.length==0 || values.comfirmPassword.length==0){
                this.setState({submitError:'请完成数据填写'})
                return
            }
            this.setState({ 
                //  forbidden:true,
                isLoading: true });

            const res = await Http.useyzm(
                {
                    phone: values.mobile,
                    yzm: values.captcha,
                    pass: utils.md5(values.password)
                },
                true
            );
            const { errcode, errmsg } = res;
            if (errcode === 0) {
                setTimeout(() => {                                    
                    this.props.handleclose()
                }, 1000); 
                this.showMsg(errmsg); 
                this.setState({ ...def_state, 
                    values: {...def_state.values, mobile:cur_user.Phone},
                    touched: { ...def_state.touched, mobile:true} });
                // history.push('/sign-in');
            } else {
                this.setState({
                    // forbidden:false,
                    isLoading: false
                });
                this.showMsg(errmsg);
            }
        } catch (error) {
            this.setState({
                // forbidden:false,
                isLoading: false
            });
        }
    };
    handleGetCaptcha = async () => {
        const { values } = this.state;
        this.setState({
            isSend: true
        });
        setTimeout(() => {
            this.setState({
                isSend: false
            });
        }, 1000 * 120);
        let timer = setInterval(() => {
            const { issendcount } = this.state;
            if (issendcount === 0) {
                clearInterval(timer);
                this.setState({
                    issendcount: 120
                });
            } else {
                this.setState({
                    issendcount: issendcount - 1
                });
            }
        }, 1000);

        // 请求验证码
        try {
            const res2 = await Http.findaccount({ phone: values.mobile }, true);
            const { errcode, errmsg } = res2;
            if (errcode !== 0) {
                this.setState({
                    isSend: false
                });
                this.showMsg(errmsg);
            } else {
                this.showMsg('发送成功');
            }
        } catch (err) {
            this.showMsg('服务器开小差了,请稍后再试');
        }
    };

    render() {
        const { open, handleclose, classes, className, ...rest } = this.props;
        const { values, touched, errors, isValid, validPasswordError,  submitError, 
            isLoading, snackbarsMsg, snackbarsVisible, isSend, issendcount 
        } = this.state;

        const rootClassName = classNames(classes.root, className);

        const showmobileError =
            touched.mobile && errors.mobile ? errors.mobile[0] : false;

        const disableGetCaptcha = touched.mobile
            ? errors.mobile
                ? true
                : false
            : true;
        const showcaptchaError =
            touched.captcha && 
            (errors.captcha ? true : false);
        const showpasswordError =
            touched.password && errors.password ? true : false;
        const showcomfirmPasswordError =
            touched.comfirmPassword && errors.comfirmPassword
                ? true
                : false;
        
        const forbidden= values.comfirmPassword.length==0 ||isLoading || showcaptchaError || showpasswordError || showcomfirmPasswordError || !validPasswordError || values.mobile.trim()=='' || values.captcha.trim()==''|| values.password.trim()==''
        
        return (
            <Dialog aria-labelledby="max-width-dialog-title" fullWidth maxWidth={'sm'} onClose={this.handleQuit} open={open} >
                <DialogActions>
                    <Box style={{width:'100%'}}>
                        <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >
                            <Typography component='span' variant="h4" >修改密码</Typography>
                        </Typography>                            
                    </Box>     
                    <IconButton size='small' onClick={this.handleQuit}><Clear fontSize='small' style={{ color: 'primary' }}/></IconButton>
                </DialogActions>
                {/* <DialogTitle id="max-width-dialog-title">
                    <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >修改密码</Typography>
                </DialogTitle> */}
                <DialogContent>
                    <Portlet className={rootClassName}  >
                        <SnackbarsMessage handleClose={this.handleSnackBarsClose} msg={snackbarsMsg} visible={snackbarsVisible} />
                        {/* <PortletHeader>
                            <PortletLabel title="修改密码" />
                        </PortletHeader> */}
                        <PortletContent>
                            <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography component='div' variant="body1" align="left" >
                                            <TextField disabled required value={values.mobile} style={{width:'100%'}} label="手机号码" name="mobile"  variant="outlined"
                                                onChange={event => this.handleFieldChange( 'mobile', event.target.value ) }
                                                error={showmobileError} 
                                                helperText={showmobileError?errors.mobile[0].replace('Mobile', ''):''}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography component='div' variant="body1" align="right" >
                                            <Button className={classes.textCaptchaButton} style={{width:'90%'}} disabled={ disableGetCaptcha || isSend } onClick={this.handleGetCaptcha} variant="contained" >
                                                {isSend ? issendcount: '获取验证码'}
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Typography>
                            <Typography component='div' variant="body1" align="center" style={{marginTop:20}}>
                                <TextField required label="验证码" name="captcha"  variant="outlined"
                                    value={values.captcha} style={{width:'100%'}} 
                                    onChange={event => this.handleFieldChange( 'captcha', event.target.value ) }
                                    error={showcaptchaError} 
                                    helperText={showcaptchaError?errors.captcha[0].replace('Captcha', ''):''}
                                />
                            </Typography>
                            <Typography component='div' variant="body1" align="center" style={{marginTop:20}}>
                                <TextField required type="password" label="密码" name="password"  variant="outlined"
                                    value={values.password} style={{width:'100%'}} 
                                    onChange={event => this.handleFieldChange( 'password', event.target.value )}
                                    error={showpasswordError} 
                                    helperText={showpasswordError?errors.password[0].replace('Password', ''):''}
                                />
                            </Typography>
                            <Typography component='div' variant="body1" align="center" style={{marginTop:20}}>
                                <TextField required type="password" label="确认密码" name="comfirmPassword"  variant="outlined"
                                    value={values.comfirmPassword} style={{width:'100%'}} 
                                    onFocus={event => this.handleCPFieldChange( 'comfirmPassword', event.target.value )}
                                    onChange={event => this.handleCPFieldChange( 'comfirmPassword', event.target.value )}
                                    error={showcomfirmPasswordError || !validPasswordError} 
                                    helperText={showcomfirmPasswordError?errors.comfirmPassword[0].replace('Comfirm password', ''):(!validPasswordError?'两次密码输入不一致':'')}
                                />
                            </Typography>
                            <Typography style={{visibility:(submitError?'visible':'hidden')}} className={classes.submitError} variant="body2" >
                                {submitError}!!
                            </Typography>


                            {/* <div autoComplete="off" noValidate>
                                <div className={classes.field}>
                                    <TextField className={classes.textCaptchaField} disabled label="手机号码" name="mobile" value={values.mobile} variant="outlined"
                                        onChange={event => this.handleFieldChange( 'mobile', event.target.value ) }
                                    />
                                    <Button className={classes.textCaptchaButton} disabled={ disableGetCaptcha || isSend } onClick={this.handleGetCaptcha} variant="contained" >
                                        {isSend ? issendcount : '获取验证码'}
                                    </Button>
                                    {showmobileError && (<Typography className={classes.fieldError} variant="body2" > {errors.mobile[0].replace('Mobile', '')} </Typography> )}
                                </div>

                                <div className={classes.field}>
                                    <TextField className={classes.textField} label="验证码" value={values.captcha} variant="outlined"
                                        // inputProps={{ autocomplete: 'new-password', form: { autocomplete: 'off' } }}
                                        onChange={event => this.handleFieldChange( 'captcha', event.target.value ) }
                                    />
                                    {showcaptchaError && (
                                        <Typography className={classes.fieldError} variant="body2" >
                                            {errors.captcha[0].replace('Captcha', '')}
                                        </Typography>
                                    )}
                                </div>
                                <div className={classes.field}>
                                    <TextField
                                        // inputProps={{
                                        //     autocomplete: 'new-password',
                                        //     form: {
                                        //         autocomplete: 'off'
                                        //     }
                                        // }}
                                        className={classes.textField}
                                        label="密码"
                                        name="password"
                                        onChange={event => this.handleFieldChange( 'password', event.target.value )}
                                        type="password"
                                        value={values.password}
                                        variant="outlined"
                                    />
                                    {showpasswordError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.password[0].replace('Password', '')}
                                        </Typography>
                                    )}
                                </div>
                                <div className={classes.field}>
                                    <TextField
                                        className={classes.textField}
                                        label="确认密码"
                                        onChange={event => this.handleFieldChange( 'comfirmPassword', event.target.value )}
                                        type="password"
                                        value={values.comfirmPassword}
                                        variant="outlined"
                                    />
                                    {showcomfirmPasswordError && (
                                        <Typography className={classes.fieldError} variant="body2" >
                                            {errors.comfirmPassword[0].replace( 'Comfirm password', '' )}
                                        </Typography>
                                    )}
                                    {!validPasswordError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            两次输入的不一致
                                        </Typography>
                                    )}
                                </div>
                                
                                <div className={classes.field}>
                                    <Typography
                                        style={{visibility:(submitError?'visible':'hidden')}}
                                        className={classes.submitError}
                                        variant="body2"
                                    >
                                        {submitError}!!
                                    </Typography>
                                    // {submitError && (
                                    //     <Typography
                                    //         hidden={!submitError}
                                    //         className={classes.submitError}
                                    //         variant="body2"
                                    //     >
                                    //         {submitError}!!
                                    //     </Typography>
                                    // )}
                                </div>
                            </div> */}
                        </PortletContent>
                        {/* <PortletFooter className={classes.portletFooter}>
                        </PortletFooter> */}
                    </Portlet>
                    <DialogActions>
                        {/* <div style={{ position: 'relative' }}> */}
                            {isLoading && (
                                <CircularProgress style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                            )}
                            <Button variant='outlined' color="primary" disabled={forbidden} onClick={this.handleConfirm} >  确定 </Button>
                        {/* </div> */}
                        <Button variant='outlined' color="primary" onClick={this.handleQuit} > 取消 </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}



ChangePwdDlg.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleclose: PropTypes.func.isRequired
};
export default withStyles(styles)(ChangePwdDlg);
