export default {
    // pname1: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname2: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
    // pname3: {
    //     presence: { allowEmpty: false, message: '不能为空' }
    // },
};
