import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ProductDiffLayout from './ProductDiffLayout';
import BannerImage from 'static/onepirate/diff.png';
const backgroundImage = BannerImage;

const styles = theme => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        // backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center'
    },
    button: {
        minWidth: 200
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10)
        }
    },
    more: {
        marginTop: theme.spacing(2)
    }
});

function ProductDifference(props) {
    const { classes } = props;

    return (
        <ProductDiffLayout backgroundClassName={classes.background}>
            {/* Increase the network loading priority of the background image. */}
            <img style={{ display: 'none' }} src={backgroundImage} alt="" />
        </ProductDiffLayout>
    );
}

ProductDifference.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductDifference);
