export default {
    session_check: {
        method: 'get',
        url: '/Handler/SessionCheckReack.ashx'
    },
    user_manager: {
        method: 'post',
        // url: '/Handler/Manager.ashx?type=login&xtype=zg'
        url: '/Handler/new/user.ashx?type=login'
    },
    cpy: {
        method: 'post',
        url: '/Handler/Cpy.ashx'
    },
    sign_in_list: {
        method: 'post',
        url: '/Handler/User.ashx'
    },
    user_list: {
        method: 'post',
        url: '/Handler/User.ashx?type=web_UserListReact'
    },
    user_router_list: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_UserWifi'
    },
    logout: {
        method: 'post',
        url: '/Handler/Manager.ashx?type=logoutReact'
    },
    user_rel_wifi: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_userRelwifi'
    },
    user_del_wifi: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_userDelwifi'
    },
    get_routers: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=webequlist'
    },
    get_userid_by_equid: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_equDetailUser'
    },
    get_auto_enter: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_getAutoEnter'
    },
    change_auto_enter: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_changeAutoEnter'
    },
    get_notice_list: {
        method: 'post',
        url: '/Handler/Notice.ashx?type=noticeListReact'
    },
    post_notice: {
        method: 'post',
        url: '/Handler/Notice.ashx?type=webAddNotice'
    },
    get_messages: {
        method: 'post',
        url: '/Handler/Smallmsg.ashx?type=web_SmallMsgListReact'
    },
    message_detail: {
        method: 'post',
        url: '/Handler/Smallmsg.ashx?type=web_getSmallmsgReact'
    },
    re_message: {
        method: 'post',
        url: '/Handler/Smallmsg.ashx?type=web_reply'
    },
    get_group: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=cpyGroup'
    },
    get_user_info: {
        method: 'post',
        url: '/Handler/User.ashx?type=web_GetUserInfoReact'
    },
    get_user_infoV2: {
        method: 'post',
        url: '/Handler/new/User.ashx?type=GetUserInfoReact'
    },
    add_equ: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=addEquReact'
    },
    del_equ: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_delEquReact'
    },
    cpyList: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=cpyList'
    },
    cpySignConfig: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=web_CpySignConfigReact'
    },
    upcpyid: {
        method: 'post',
        url: '/Handler/SessionCheck.ashx?type=upcpyid'
    },
    // TODO:
    signConfigUpdtN: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=updateconfigInner'
    },
    // TODO:
    signConfigUpdtW: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=updateconfigOut'
    },
    addUser: {
        method: 'post',
        url: '/Handler/User.ashx?type=adduser'
    },
    addUserV2: {
        method: 'post',
        url: '/Handler/new/User.ashx?type=adduser'
    },
    changeTimeMt: {
        method: 'post',
        url: '/Handler/User.ashx?type=web_changeTimeMt'
    },
    changeTimeMtV2: {
        method: 'post',
        url: '/Handler/new/user.ashx?type=changeTimeMt'
    },
    changeGroupMt: {
        method: 'post',
        url: '/Handler/User.ashx?type=web_changeGroupMt'
    },
    reg: {
        method: 'post',
        // url: '/Handler/Manager.ashx?type=reg'
        url: '/Handler/new/register.ashx?type=reg'
    },
    profession: {
        method: 'post',
        url: '/Handler/App.ashx?type=profession'
    },
    addFirm: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=add'
    },
    addFirmV2: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=addcpy'
    },
    delBu: {
        method: 'post',
        url: '/Handler/new/department.ashx?type=del'
    },
    addBu: {
        method: 'post',
        url: '/Handler/Bu.ashx?type=web_addBu'
    },
    editBu: {
        method: 'post',
        url: '/Handler/Bu.ashx?type=web_editBu'
    },
    edituser: {
        method: 'post',
        url: '/Handler/User.ashx?type=edituser'
    },
    edituserV2: {
        method: 'post',
        url: '/Handler/new/User.ashx?type=edituser'
    },
    GetEditCpyInfo: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=web_GetEditCpyInfoReact'
    },
    GetEditCpyInfoV2: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=GetEditCpyInfoReact'
    },
    editEqu: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_editEqu'
    },
    getEqu: {
        method: 'post',
        url: '/Handler/Equ.ashx?type=web_getEqu'
    },
    monthReport: {
        method: 'post',
        url: 'Handler/new/signList.ashx?type=yuemingxi'
    },
    monthStatistics: {
        method: 'post',
        url: '/Handler/new/signList.ashx?type=yuetongji'
    },
    monthChart: {
        method: 'post',
        url: '/Handler/new/signList.ashx?type=yuekaoqin'
    },
    getTotalbyCpysn: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=web_getTotalbyCpysn'
    },
    delCpy: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=web_delCpy'
    },
    editCpy: {
        method: 'post',
        url: '/Handler/Cpy.ashx?type=web_EditCpy'
    },
    editCpyV2: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=editcpy'
    },
    userDelMulty: {
        method: 'post',
        url: '/Handler/User.ashx?type=web_UserDelMulty'
    },
    defaultTime: {
        method: 'post',
        url: '/Handler/new/user.ashx?type=defaultTime'
    },
    defaultTimeV2: {
        method: 'post',
        url: '/Handler/new/user.ashx?type=defaultTimeV2'
    },
    findaccount: {
        method: 'post',
        url: '/Handler/Captcha.ashx?type=findaccount'
    },
    useyzm: {
        method: 'post',
        url: '/Handler/Captcha.ashx?type=useyzm'
    },
    comerate: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=comerate'
    },

    cometime: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=cometime'
    },
    comerateweek: {
        method: 'post',
        url: '/Handler/new/company.ashx?type=comerateweek'
    },
    log: {
        method: 'post',
        url: '/Handler/new/log.ashx'
    },
    getProductInfo:{
        method:'post',
        url:'Handler/new/order.ashx?type=web_getproductinfo'
    },
    getAccountInfo:{
        method:'post',
        url:'Handler/new/userinfo.ashx?type=web_getaccountinfo'
    },
    getOrderInfo:{
        method:'post',
        url:'Handler/new/order.ashx?type=web_getorderinfo'
    },
    getNotifications:{
        method:'post',
        url:'Handler/Notice.ashx?type=getNotifications'
    },
    readNotification:{
        method:'post',
        url:'Handler/Notice.ashx?type=readNotification'
    },
    setNotificationsRead:{
        method:'post',
        url:'Handler/Notice.ashx?type=readNotifications'
    },
    setNotificationsDelete:{
        method:'post',
        url:'Handler/Notice.ashx?type=deleteNotifications'
    },
    getPayResult:{
        method:'post',
        url:'Handler/new/order.ashx?type=web_getpayresult'
    },
    addOrder:{
        method:'post',
        url:'Handler/new/order.ashx?type=web_addorder'
    },
    getPayParam:{
        method:'post',
        url:'Handler/new/order.ashx?type=web_getpayparam'
    },
    getReceiptOrdersInfo:{
        method:'post',
        url:'Handler/new/receipt.ashx?type=web_getreceiptorderinfo'
    },
    submitReceiptInfo:{
        method:'post',
        url:'Handler/new/receipt.ashx?type=web_receiptinfoadd'
    },
    checkReceipt:{
        method:'post',
        url:'Handler/new/receipt.ashx?type=web_receiptcheck'
    }
};
// {companysn:66552840}
