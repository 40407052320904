import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';

import { TextField, FormControl, Typography, Checkbox,Radio,RadioGroup, FormControlLabel, FormLabel, Tooltip, Grid, Popover, Button, Divider, CircularProgress } from '@material-ui/core';
import { SnackbarsMessage, Portlet, PortletHeader, PortletLabel, PortletContent, TimeSelect,
    TimeSelect0,TimeSelect1 
} from 'components';

import styles from './styles';

const roles=[{id:0, name:'普通员工', desp:'普通员工只能登录员工端APP'},
    {id:1, name:'部门管理员', desp:'部门管理员可以登录网页或者管理端APP查看部门数据'},
    {id:2, name:'单位管理员', desp:'单位管理员可以登录网页或者管理端APP查看单位数据'}
  ]
class Index extends React.Component {
    state = {
        Loading:false,
        snackbarsMsg: '',
        snackbarsVisible: false,
        anchorEl: null,
        anchorOpen: false
    };
  
    delete_user_open = anchorEl => {
        this.setState({
            anchorEl: anchorEl,
            anchorOpen: true
        });
    };
  
    handleAnchorClose = () => {
        this.setState({
            anchorEl: null,
            anchorOpen: false
        })
    }
    
    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };

    delUser = () => {
        this.handleAnchorClose();
        this.setState({Loading:true})
        this.props.delCpy();
    };

    render() {
        const { classes, form_value, handlefieldchange, cpy_group,nameerror, errors, teleerror,changenum, 
            isnew, handlecheck, deluser, accountInfo, timevalid
        } = this.props;
        const { snackbarsMsg, snackbarsVisible, anchorEl, anchorOpen, Loading } = this.state;

        return (
            <Portlet>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    // id={id}
                    onClose={this.handleAnchorClose}
                    open={anchorOpen}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Typography className={classes.typography}>
                        <Button onClick={deluser}>确定</Button>
                        <Button onClick={this.handleAnchorClose}>取消</Button>
                    </Typography>
                </Popover>
                <PortletHeader>
                    {isnew?<PortletLabel subtitle="新员工基本信息设置"/>:<PortletLabel subtitle="员工基本信息设置" title={form_value.name} />}
                    {isnew ? null:(
                        <div style={{ float: 'right'}}>
                            <div style={{ position: 'relative' }}>
                                {Loading && (
                                    <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                                )}
                                <Tooltip title='删除后将无法恢复员工信息数据和员工考勤数据'><div>
                                    <Button color="primary" disabled={ Loading } variant="outlined"
                                        onClick={event => this.delete_user_open( event.currentTarget )}                                    
                                    > 
                                        删除
                                    </Button></div>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </PortletHeader>
                <PortletContent>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Tooltip title='员工端登录时需要使用员工姓名和手机号码'>
                                <Typography component='div' variant="body1">
                                    <TextField margin="dense" label="姓名" name="name"  variant="outlined" required
                                        style={{width:'100%'}} value={form_value.name} placeholder='请输入员工姓名'
                                        onFocus={event => handlefieldchange( event.target.value, 'name' ) }
                                        onChange={event => handlefieldchange( event.target.value, 'name' ) }
                                        error={nameerror} 
                                        helperText={nameerror?errors.name[0].replace('Name', ''):''}
                                    />
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={isnew==1?4:(form_value.role==0?4:2)}>
                            <Tooltip title={roles[form_value.role].desp}>
                                <Typography component='div' variant="body1">
                                    <TextField margin="dense" label="角色" name="position"  variant="outlined" required
                                        style={{width:'100%'}} disabled={form_value.status=='M'?true:false}
                                        onChange={event => handlefieldchange( parseInt(event.target.value), 'role' ) }
                                        value={form_value.role} 
                                        select SelectProps={{ native: true }}
                                    >
                                        {roles.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                        ))}
                                    </TextField>
                                </Typography>
                            </Tooltip>
                        </Grid>
                        
                        {form_value.role === 0 ? null:(
                            <Grid item xs={isnew==1?4:2}>
                                <Tooltip title='部门管理员或单位管理员登录时需要使用的登录密码'>
                                    <Typography component='div' variant="body1">
                                        <TextField margin="dense" label="管理员密码" name="managepass"  variant="outlined" required type='password'
                                        disabled={form_value.status=='M'?true:false} placeholder='请输入密码'
                                        onChange={event => handlefieldchange( event.target.value, 'managepass' ) }
                                        value={form_value.managepass}
                                        />
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        )}
                        {isnew==1?null:
                            <Grid item xs={4}>
                                <Tooltip title='只有正常在职状态的员工才能在员工端APP或管理端APP登录'>                    
                                    <Typography component='div' variant="body1">
                                        <FormControl component="fieldset">
                                            <FormLabel required component="legend" style={{fontSize:10}}>在职状态</FormLabel>
                                            <RadioGroup aria-label="position" name="position" row
                                                value={form_value.status} 
                                                onChange={event => handlefieldchange( event.target.value, 'status' ) }
                                            >
                                                <FormControlLabel control={<Radio color="primary" />} label="正常" labelPlacement="end" value="N" />
                                                <FormControlLabel control={<Radio color="primary" />} label="离职" labelPlacement="end" value="M" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>

                    <Divider style={{marginTop:10, marginBottom:10}} />

                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Tooltip title='员工端登录时需要使用员工姓名和手机号码'>
                                <Typography component='div' variant="body1">
                                    <TextField margin="dense" label="手机号" name="telephone"  variant="outlined" required
                                        style={{width:'100%'}} value={form_value.telephone} placeholder='请输入员工手机号码'
                                        onFocus={event => handlefieldchange( event.target.value, 'telephone' ) }
                                        onChange={event => handlefieldchange( event.target.value, 'telephone' ) }
                                        error={teleerror} 
                                        helperText={teleerror?errors.telephone[0].replace('Telephone', ''):''}
                                    />
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                            <Tooltip title='选择员工所在的部门'>
                                <Typography component='div' variant="body1">
                                    <TextField required label="部门" margin="dense" variant="outlined" select 
                                        SelectProps={{ native: true }} style={{width:'100%'}} value={form_value.department}
                                        onChange={ event => handlefieldchange(event.target.value, 'department')}
                                    >
                                        {cpy_group.map(option => (
                                        <option
                                            key={option.GroupID}
                                            value={parseInt(option.GroupID)}
                                        >
                                            {option.GroupName}
                                        </option>
                                        ))}
                                    </TextField>
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component='div' variant="body1">
                                <TextField label="工号" margin="dense" variant="outlined"
                                    value={form_value.sn} style={{width:'100%'}} placeholder='请输入工号'
                                    onChange={event => handlefieldchange( event.target.value, 'sn' ) }
                                />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Tooltip title='选中则可以在员工端通讯录中显示手机号码，未选中则不显示。'>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{fontSize:10}}>是否显示号码</FormLabel>
                                    <FormControlLabel label="在员工端通讯录显示手机号码" 
                                        control={
                                            <Checkbox checked={form_value.isphone==1} color="primary" 
                                                inputProps={{ 'aria-label': 'primary checkbox', }}
                                                onChange={event => handlefieldchange(event.target.checked?1:0, 'isphone') }
                                            />
                                        }                                        
                                    />
                                </FormControl>
                            </Tooltip>
                        </Grid>
                        {isnew==1?null:
                            <Grid item xs={6}>
                                <Tooltip title='员工更换手机后，需要重置手机信息，否则无法签到。选中重置，未选择不重置。'>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{fontSize:10}}>是否重置手机信息</FormLabel>
                                        <FormControlLabel label="重置员工手机信息" 
                                            control={
                                            <Checkbox checked={form_value.chongzhi} color="primary"
                                                inputProps={{ 'aria-label': 'primary checkbox', }}
                                                onChange={handlecheck} 
                                            />
                                            } 
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                    <Divider style={{marginTop:10,marginBottom:20}} />
                    <div className={classes.field}>
                        {(form_value.mode==0?
                        <TimeSelect0
                            changenum={ changenum }
                            form_value={form_value}
                            handlefieldchange={ handlefieldchange }
                            // isvalid={timevalid}
                        />:
                        <TimeSelect1
                            changenum={ changenum }
                            form_value={form_value}
                            handlefieldchange={ handlefieldchange }
                            accountinfo={accountInfo}
                            // isvalid={timevalid}
                        />)}
                        </div>
                </PortletContent>
            </Portlet>
        );
    }
}
Index.propTypes = {
    classes: PropTypes.object.isRequired,
    cpy_group: PropTypes.array.isRequired,
    form_value: PropTypes.object.isRequired,
    handlefieldchange: PropTypes.func.isRequired,
    // timevalid: PropTypes.func.isRequired,
    handlecheck:PropTypes.func.isRequired,
    deluser:PropTypes.func.isRequired,
    // num: PropTypes.number.isRequired,
    isnew: PropTypes.bool.isRequired,
    nameerror: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    accountInfo: PropTypes.object.isRequired,
    teleerror: PropTypes.bool.isRequired
};
export default withStyles(styles)(Index);
