export default {
    mobile: {
        presence: { allowEmpty: false, message: '手机号码不允许为空' },
        length: {
            is: 11,
            message: '请填入正确的手机号码'
        },
        format: {
            pattern: '[0-9]+',
            flags: 'i',
            message: '只能包含数字'
        }
    },
    captcha: {
        presence: { allowEmpty: false, message: '请输入验证码' },
        length: {
            is: 6,
            message: '请填入正确的验证码'
        }
    },
    password: {
        presence: { allowEmpty: false, message: '请输入密码' },
        length: {
            maximum: 16,
            message: '密码长度不能超过16个字符'
        }
    },
    comfirmPassword: {
        presence: { allowEmpty: false, message: '请输入确认密码' },
        length: {
            maximum: 16,
            message: '密码长度不能超过16个字符'
        }
    }
};
