import palette from '../palette';

export default {
  colorSecondary: {
    '&.Mui-checked': { color: palette.primary.main },
    '&.Mui-checked:hover': { backgroundColor: palette.common.neutral }
  },
  root:{
    color:palette.primary.main
  }
};