import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import MaterialTable ,{ MTableToolbar } from 'material-table';
// import { format } from 'date-fns'
import {SnackbarsMessage, TableIcons,Portlet, PortletHeader, PortletLabel, PortletContent,} from 'components';
import {Box, Button,Dialog,DialogActions,DialogContent,DialogTitle, Grid, Divider, Typography,IconButton, TextField} from '@material-ui/core';
import utils from 'utility';

// import { compare_all_time, compare_each_one } from 'utils/time_compare';

import { withStyles, styled } from '@material-ui/styles';
import validate from 'validate.js';
import _ from 'underscore';
// import Edit from '@material-ui/icons/Edit';

// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';

import { Clear } from '@material-ui/icons';
// import Avatar from '@material-ui/core/Avatar';
// Form validation schema
import schema from './schema';
import Http from 'services/http';

import styles from './styles';

// import export_to_excel from 'utils/export_to_excel'
// import { email } from 'views/onepirate/modules/form/validation';
// import { includes } from 'lodash';
// import { WindowScroller } from 'react-virtualized';

// const orderlist=[{OrderId:'DS171255585388645532', Payment:0,PayTime:'2024-04-08 15:19:49.000',IsReceipt:1, ProductTitle:'月卡',TotalPrice:0.01, ReceiptId:'RS171262562157006943', IsDone:0},
//     {OrderId:'DS171255585388645533', Payment:0,PayTime:'2024-04-08 15:19:49.000',IsReceipt:1, ProductTitle:'月卡',TotalPrice:0.01, ReceiptId:'RS171262562157006943', IsDone:1},
//     {OrderId:'DS171255585388645534', Payment:0,PayTime:'2024-04-08 15:19:49.000',IsReceipt:1, ProductTitle:'月卡',TotalPrice:0.01, ReceiptId:'RS171262562157006943', IsDone:2},
//     {OrderId:'DS171150375738569997', Payment:2,PayTime:'2024-03-27 09:43:30.000',IsReceipt:0, ProductTitle:'月卡',TotalPrice:0.01, ReceiptId:'', IsDone:-1},
//     {OrderId:'DS171150375738569996', Payment:5,PayTime:'2024-03-27 09:43:30.000',IsReceipt:0, ProductTitle:'季卡',TotalPrice:0.31, ReceiptId:'', IsDone:-1},
//     {OrderId:'DS171150375738569995', Payment:0,PayTime:'2024-03-27 09:43:30.000',IsReceipt:0, ProductTitle:'年卡',TotalPrice:3.01, ReceiptId:'', IsDone:-1},
//     {OrderId:'DS171145591076716453', Payment:3,PayTime:'2024-03-26 20:25:22.000',IsReceipt:-1, ProductTitle:'月卡',TotalPrice:0.01, ReceiptId:'', IsDone:-1}]

// const receiptInfo={name:'', taxNo:'', bank:'b', account:'c',address:'', phone:'', email:'', mobile:''}


class Subscription extends Component {
    state={
        orders:[],
        loading:false,
        cur_user:null,
        snackbarsMsg:'',
        snackbarsVisible: false,
        selectedOrders:[],
        showDetailDlg: false,
        showRptDlg: false,
        orderDetail:null,
        rptOrders:[],

        values: {
            name: '',
            taxNo: '',
            email: '',
            bank:'',
            account:'',
            address:'',
            phone:'',
            mobile:''
        },
        touched: {
            name: false,
            taxNo: false,
            email: false
        },
        errors: {
            name: null,
            taxNo: null,
            email: null
        },
        isValid: false,
        submitError: null,

        curpage:0,
        pagesize:5,
        pagearray:[5,10,20]
    }

    componentDidMount() {
        const {history}=this.props
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({ loading: true, cur_user: cur_user })
        if(null!=cur_user || undefined!=cur_user){
            this.getReceiptOrdersInfo(cur_user.UserID)
        }else{
            history.push('/singin')
        }        
    }

    getReceiptOrdersInfo=async (info)=>{
        try{
            const result= await Http.getReceiptOrdersInfo({managerid:info});//await Http.get_receipt_order_list({managerid:info, condition:0, receiptid:''},true)
            const {data, errcode,errmsg}=result
            if(errcode==0){
                let tv={name: data.Name, taxNo: data.TaxNo, email: data.Email, bank:data.Bank, account:data.Account, address:data.Address, phone:data.Phone, mobile:data.Mobile}
                let te=validate(tv, schema)
                let orderlist=data.orderInfos?data.orderInfos:[]
                let pagearray=[5,10,20]
                if(orderlist.length<11){
                    pagearray.pop(20);
                }
                if(orderlist.length<6){
                    pagearray.pop(10)
                }
                this.setState({pagearray:pagearray, orders:orderlist, loading:false, errors:(te||{}), values:tv})
                // this.setState({orders: result.data, loading:false})
            }else{
                this.showMsg(errmsg)
            }
        } catch (err) {
            this.showMsg(err)
        }
    }
    
    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    }

    // 批量选择订单
    handleSelection = async rows => {
        const orders = await rows.map(order => {
            return order;
        });
        this.setState({
            selectedOrders: orders
        })
    }

    handleRowsChange = async rowsPerPage => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async Page => {
        this.setState({ curpage: Page })
    }
    
    calSelectedPrice = orders => {
        let i=0
        orders.map(order=>{i+= order.TotalPrice*100})
        return i/100
    }
  
    handleRptDlgOpen=()=>{
        if(this.state.selectedOrders.length<=0 || this.calSelectedPrice(this.state.selectedOrders)<=0){
            this.showMsg('请选择可开票订单并确认开票金额大于0！')
        }else{
            this.setState({rptOrders:this.state.selectedOrders, showDetailDlg: false, showRptDlg:true, orderDetail:null})
        }
    }

    handleRptSubmit= async ()=>{   
        let odrs='';        
        this.state.rptOrders.map(order=>{odrs+=order.OrderId+','})
        odrs=odrs.substring(0, odrs.lastIndexOf(','))
        let total=this.calSelectedPrice(this.state.rptOrders) 
        this.setState({ loading: true, rptOrders:null, showRptDlg:false})
        try{
            const result= await Http.submitReceiptInfo({managerid:this.state.cur_user.UserID, receiptinfo:{...this.state.values, orders:odrs, totalPrice: total, taxType:0}});//await Http.get_receipt_order_list({managerid:info, condition:0, receiptid:''},true)
            const {data, errcode,errmsg}=result
            if(errcode==0){                
                this.componentDidMount();
                // this.setState({orders: result.data, loading:false})
            }else{
                this.showMsg(errmsg)
            }
        } catch (err) {
            this.showMsg(err)
        }              
        // this.componentDidMount();
    }

    handleRptDlgClose=()=>{
        this.setState({rptOrders:null, showRptDlg:false, touched: {
            name: false,
            taxNo: false,
            email: false
        }})
    }

    handleDetailDlgOpen =(order)=>{
        this.setState({orderDetail:order, showRptDlg:false, showDetailDlg: true, rptOrders:null})
    }

    handleDetailDlgClose =(event,order)=>{
        this.setState({orderDetail:null, showDetailDlg: false})
    }

    handleJumpDlg =()=>{
        let dorders=[];
        dorders.push(this.state.orderDetail);
        this.setState({rptOrders:dorders, showRptDlg:true, orderDetail:null, showDetailDlg: false})
    }

    handleFirstDown =()=>{
        this.checkReceipt(this.state.orderDetail.ReceiptId)
        let addr=this.state.orderDetail.Url
        this.setState({orderDetail:null, showDetailDlg: false})
        window.open(addr)
    }

    handleHasDowned =()=>{
        let addr=this.state.orderDetail.Url
        this.setState({orderDetail:null, showDetailDlg: false})
        window.open(addr)
    }
    
    checkReceipt=async (info)=>{
        try{
            const result= await Http.checkReceipt({managerid: this.state.cur_user.UserID, receiptid:info});
            const {data, errcode,errmsg}=result
            if(errcode==0){ 
                this.setState 
            }else{
                this.showMsg(errmsg)
            }
        } catch (err) {
            this.showMsg(err)
        } 
    }

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        
        newState.values[field] = value;
        if ('name,taxNo,email'.indexOf(field)>=0){
            newState.touched[field] = true;
            this.setState(newState, this.validateForm);
        }
    };

    // debounce 防抖
    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        // 进行 schema 和 values 的匹配 返回 errors 空的化就是没有
        const errors = validate(values, schema);
        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);
    

    render() {
        const {curpage, pagesize, pagearray, orders, loading, values, errors, touched, 
            submitError, isValid, rptOrders, snackbarsMsg, selectedOrders, showDetailDlg,
            showRptDlg, snackbarsVisible, orderDetail
        }=this.state        

        const showErrorName=touched.name && errors!='undefined' && typeof(errors.name)!='undefined';
        const showErrorTaxNo=touched.taxNo && errors!='undefined' && typeof(errors.taxNo)!='undefined';
        const showErrorEmail=touched.email && errors!='undefined' && typeof(errors.email)!='undefined';

        const columns = [
            { title: '订单号', field: 'OrderId', sorting: false },
            { title: '订阅商品', field: 'ProductTitle', sorting: false },
            { title: '订单金额', field: 'TotalPrice', type:'numeric' },
            { title: '支付时间', field: 'PayTime', type:'datetime' },
            { 
                title: '发票状态', field: 'IsReceipt',
                lookup:{'0':'未申请发票', '1':'已申请发票', '-1':'不可开发票'},
                cellStyle: (records,record) =>({color:(record.IsReceipt==0?'orange':(record.IsReceipt==1?'green':'red'))})
            },
            { 
                title: '操作', field: '',
                editable: 'never',
                filtering: false ,
                sorting:false,
                render: record =>(
                <Button
                    color="primary"
                    onClick={() => this.handleDetailDlgOpen(record)}
                    size="small"
                    style={{ textTransform: 'none' }}
                    variant="contained"
                >
                    查看详情
                </Button>)
            }
        ]
    
        return (
            <div>
                <SnackbarsMessage
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <MaterialTable 
                    columns={columns}
                    components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{marginTop:10}} >
                            </div>
                            <div>
                                <Typography component='span' variant="body1" style={{margin:25}} >
                                    已经选择
                                    <Typography component='span' variant="body1" style={{margin:5, color:'red'}}>
                                        {selectedOrders.length}
                                    </Typography>
                                    <Typography component='span' variant="body1" >
                                        个订单，合计金额为
                                    </Typography>
                                    <Typography component='span' variant="body1" align='center'style={{margin:5, color:'red'}}>
                                        {this.calSelectedPrice(selectedOrders)}
                                    </Typography>
                                    <Typography component='span' variant="body1" align='center'>
                                        元。
                                    </Typography>
                                </Typography>
                                <Button
                                    color="primary"
                                    onClick={()=>this.handleRptDlgOpen('vip')}
                                    size="small"
                                    variant="outlined"
                                >申请开票</Button>
                            </div>
                        </div>
                    )
                    }}
                    data={orders}
                    icons={TableIcons}
                    isLoading = {loading}
                    localization={{
                        pagination: {
                            labelRowsSelect: ' 行每页',
                            labelDisplayedRows: ' {from}-{to} 行，共 {count} 行',
                            firstTooltip: '第一页',
                            previousTooltip: '前一页',
                            nextTooltip: '后一页',
                            lastTooltip: '到最后'
                        },
                        toolbar: {
                            searchTooltip: '模糊查找',
                            searchPlaceholder: '模糊查找',
                            exportName: '导出csv表格',
                            nRowsSelected: '已选择{0}个订单',
                            exportTitle: '导出csv表格'
                        },
                        body: {
                            addTooltip: '新增记录',
                            emptyDataSourceMessage: '暂时没有数据',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: '确定要删除吗？',
                                cancelTooltip: '取消',
                                saveTooltip: '确定'
                            }
                        }
                    }}
                    onSelectionChange={this.handleSelection}
                    onChangeRowsPerPage={this.handleRowsChange}
                    onChangePage={this.handlePageChange}
                    options={{
                        // exportButton: true,
                        minBodyHeight:600,
                        selection: true,
                        selectionProps: rowData => ({
                            disabled: rowData.IsReceipt === -1 || rowData.IsReceipt>0
                        }),
                        paging:true,
                        pageSizeOptions:pagearray,
                        pageSize:pagesize,
                        paginationType:'normal',
                        initialPage:curpage,
                        // exportFileName: 'orders.csv',
                        // filtering: true
                        showSelectAllCheckbox: false
                    }}
                    title=""
                />
                
                {(showDetailDlg && <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleDetailDlgClose}
                    open={showDetailDlg}
                >
                    <DialogActions>
                        <Box style={{width:'100%'}}>
                            <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >订单详情</Typography>
                        </Box>     
                        <IconButton size='small' onClick={this.handleDetailDlgClose}><Clear fontSize='small' style={{ color: 'primary' }}/></IconButton>
                    </DialogActions>
                    <DialogContent>
                        <Portlet>
                        <PortletHeader>
                            <PortletLabel
                                subtitle="详细信息"
                                title={orderDetail.OrderId}
                            />
                        </PortletHeader>
                        <PortletContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}} >订阅服务：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}}>{orderDetail.ProductTitle}VIP会员</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >订单金额：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{orderDetail.TotalPrice}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >支付方式：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{(orderDetail.Payment==0?'APP内支付宝支付':(orderDetail.Payment==2?'APP内微信支付':(orderDetail.Payment==3?'APP内苹果内购':'其他支付')))}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >支付时间：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{orderDetail.PayTime}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:'10px 10px 0px 10px'}} >发票信息：</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    {<Typography component='div' variant="body1" style={{margin:'10px 10px 0px 10px'}}>                                        
                                        {(orderDetail.IsReceipt==-1?
                                            <Typography component='span' variant="body1" style={{color:'red'}}>不可开发票</Typography>:
                                                (orderDetail.IsReceipt==0?<Typography component='span' variant="body1" style={{color:'orange'}}>可开发票（未申请）</Typography>:
                                                    (orderDetail.IsReceipt==1?<Typography component='span' variant="body1" style={{color:'green'}}>可开发票（已经申请）</Typography>:'')))}
                                    </Typography>}
                                </Grid>
                                <Grid item xs={4}>
                                    {<Typography component='div' variant="body1" style={{margin:'10px 10px 0px 10px'}}>                                        
                                        {(orderDetail.IsReceipt==-1 && orderDetail.IsDone==-1?'':
                                            (orderDetail.IsReceipt==0 && orderDetail.IsDone==-1?//去开
                                                <Button size="small" color="primary" onClick={this.handleJumpDlg}>去开票</Button>:
                                                    (orderDetail.IsReceipt==1 && orderDetail.IsDone==0?<Typography component='span' variant="body2">开票处理中...</Typography>:
                                                        (orderDetail.IsDone==1?<Button size="small" color="primary" onClick={this.handleFirstDown}>已开票，请查收</Button>:
                                                            (orderDetail.IsDone==2?<Button size="small" color="primary" onClick={this.handleHasDowned}>已开票，去下载</Button>:'')))))}
                                    </Typography>}
                                </Grid>
                            </Grid>
                        </PortletContent>
                        </Portlet>
                        <DialogActions>
                        {/* <Typography component='div' align='center' style={{margin:20}}> */}
                            <Button color="primary" onClick={this.handleDetailDlgClose} variant='outlined' > 确定 </Button>
                        {/* </Typography> */}
                        </DialogActions>
                    </DialogContent>
                </Dialog>)}

                {(showRptDlg && <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'md'}
                    onClose={this.handleRptDlgClose}
                    open={showRptDlg}
                >
                    <DialogActions>
                        <Box style={{width:'100%'}}>
                            <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >
                                <Typography component='span' variant="h4" >申请开票</Typography>
                                <Typography component='span' variant="body2" style={{marginLeft:5, color:'red'}} >（红色带*的项目为必填项）</Typography>
                            </Typography>                            
                        </Box>     
                        <IconButton size='small' onClick={this.handleRptDlgClose}><Clear fontSize='small' style={{ color: 'primary' }}/></IconButton>
                    </DialogActions>
                    <DialogContent>
                        <Portlet>
                        <PortletContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography component='div' variant="h5" style={{marginTop:10}} >发票信息</Typography>
                                </Grid>
                            </Grid> 
                            <Grid container>
                                <Grid item xs={2}>
                                    <Typography component='div' variant="body1" align="right" style={{marginTop:10}}>开票订单：</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component='div' variant="body1" align='left' style={{marginTop:10}}>
                                        <Typography component='span' variant="body1" >{rptOrders[0].OrderId+(rptOrders.length>1?'...':'')}</Typography>
                                        {(rptOrders.length>1?<Typography component='span' variant="body2" style={{marginLeft:20}} >共{rptOrders.length}个订单</Typography>:'')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography component='div' variant="body1" align="right" style={{marginTop:10}}>开票金额：</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component='div' variant="body1" align='left' style={{marginTop:10, fontWeight:'bold'}}>￥{this.calSelectedPrice(rptOrders)}元</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Typography component='div' variant="body1" align="right" style={{marginTop:10}}>发票类型：</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component='div' variant="body1" align='left' style={{marginTop:10, fontWeight:'bold'}}>增值税普通发票</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography component='div' variant="body1" align="right" style={{marginTop:10}}>发票内容：</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component='div' variant="body1" align='left' style={{marginTop:10, fontWeight:'bold'}}>软件服务</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography component='div' variant="h5" style={{marginTop:20, marginBottom:10}} >
                                        <Typography component='span' variant="h5" >开票信息</Typography>
                                        <Typography component='span' variant="body1" style={{fontWeight:'bold'}} >（企业）</Typography>
                                    </Typography>
                                </Grid>
                            </Grid> 
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField InputLabelProps={{error:true}}  style={{width:'90%'}} defaultValue={values.name} label="发票抬头" name="name"  variant="outlined" required
                                            onFocus={event => this.handleFieldChange( 'name', event.target.value )} 
                                            onChange={event => this.handleFieldChange( 'name', event.target.value )} 
                                            error={showErrorName} 
                                            helperText={showErrorName?errors.name[0].replace('Name ',''):''}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField InputLabelProps={{error:true}} style={{width:'90%'}} defaultValue={values.taxNo} label="纳税人识别号" name="taxNo" variant="outlined" required
                                            onFocus={event => this.handleFieldChange( 'taxNo', event.target.value )}
                                            onChange={event => this.handleFieldChange( 'taxNo', event.target.value )}
                                            error={showErrorTaxNo} 
                                            helperText={showErrorTaxNo?errors.taxNo[0].replace('Tax No ',''):''}/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField style={{width:'90%'}} defaultValue={values.bank} label="开户银行" name="bank"  variant="outlined"  
                                            onFocus={event => this.handleFieldChange( 'bank', event.target.value )}  
                                            onChange={event => this.handleFieldChange( 'bank', event.target.value )}   
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField style={{width:'90%'}} defaultValue={values.account} label="银行账号" name="account"  variant="outlined"  
                                            onFocus={event => this.handleFieldChange( 'account', event.target.value )}  
                                            onChange={event => this.handleFieldChange( 'account', event.target.value )}   
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField style={{width:'90%'}} defaultValue={values.address} label="注册地址" name="address"  variant="outlined"  
                                            onFocus={event => this.handleFieldChange( 'address', event.target.value )}  
                                            onChange={event => this.handleFieldChange( 'address', event.target.value )}   
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField style={{width:'90%'}} defaultValue={values.phone} label="联系电话" name="phone"  variant="outlined"  
                                            onFocus={event => this.handleFieldChange( 'phone', event.target.value )}  
                                            onChange={event => this.handleFieldChange( 'phone', event.target.value )}   
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography component='div' variant="h5" style={{marginTop:20, marginBottom:10}} >联系信息</Typography>
                                </Grid>
                            </Grid> 
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField InputLabelProps={{error:true}}  style={{width:'90%'}} defaultValue={values.email} label="电子邮箱" name="email"  variant="outlined" required 
                                            onFocus={event => this.handleFieldChange( 'email', event.target.value )}  
                                            onChange={event => this.handleFieldChange( 'email', event.target.value )}                                           
                                            error={showErrorEmail} 
                                            helperText={showErrorEmail?errors.email[0].replace('Email ',''):''} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component='div' variant="body1" align="center" style={{marginTop:10}}>
                                        <TextField style={{width:'90%'}} defaultValue={values.mobile} label="手机号码" name="mobile"  variant="outlined"  
                                            onFocus={event => this.handleFieldChange( 'mobile', event.target.value )}  
                                            onChange={event => this.handleFieldChange( 'mobile', event.target.value )}   
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PortletContent>
                        </Portlet>
                        {submitError && ( <Typography variant="body2" > {submitError} </Typography> )}
                        <DialogActions>
                        {/* <Typography component='div' align='center' style={{margin:20}}> */}
                            <Button disabled={!isValid} color="primary" onClick={this.handleRptSubmit} variant='outlined' > 提交申请 </Button>
                            <Button color="primary" onClick={this.handleRptDlgClose} variant='outlined' > 取消 </Button>
                        {/* </Typography> */}
                        </DialogActions>
                    </DialogContent>
                </Dialog>)}
            </div>
        );
    }
}



Subscription.propTypes = {
    history: PropTypes.object.isRequired
};


export default withStyles(styles)(withRouter(Subscription));
