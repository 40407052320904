import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import {ChangePwdDlg} from './components';
import Subscription from './components/Subscription';
import Notifications from './components/Notifications';
import Header from './components/Header';
import { VipDialog } from 'layouts/Dashboard/components/Topbar/components';
import LoadingOverlay from 'react-loading-overlay';
import dftLogo from 'static/img/defaultlogo.png'
import Http from 'services/http';
import Showmsg from 'utils/showmsg';

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {        
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    head: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    }
});

class UserCenter extends Component {
    state = {
        tab_value: 0, accountInfo:null, cur_user:null, productInfo:null, accountInfoStatus:false, productInfoStatus:false, showDlg:false, showPwdDlg:false
    };

    componentDidMount() {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        const uc_route=JSON.parse(localStorage.getItem('uc_route'));        
        // console.log(['AdminB','AdminC'].includes(cur_user.UserID))
        if(null!=cur_user || undefined!=cur_user){
            this.setState({
                cur_user: cur_user
            });
            
            this.handleAccountInfo(cur_user.UserID)
            this.handleProductInfo(cur_user.UserID)
        } 
        if(null!=uc_route || undefined!=uc_route){
            if(uc_route.tabs==0 || uc_route.tabs==1){                
                this.setState({tab_value:uc_route.tabs})
            }else{               
                this.setState({tab_value:0})
            }
        }else{               
            this.setState({tab_value:0})
        }      
        const route={tabs:0, conditon:0, id:0}
        localStorage.setItem(
            'uc_route', JSON.stringify(route)
        );
    }

    handleProductInfo= async (info)=>{
        try{
            const { data, errcode, errmsg } = await Http.getProductInfo({managerid:info});
            if (errcode !== 0) {
                console.log(errmsg)
                this.setState({productInfoStatus: false})
            } else {
                this.setState({productInfoStatus: true, productInfo:data}) 
            }
        }catch(error){
            return;
        }
    }

    handleAccountInfo= async (info)=>{
        try {
            const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
            if (errcode !== 0) {
                console.log(errmsg)
                this.setState({accountInfoStatus: false})
            } else {
                // console.log(data)
                this.setState({accountInfoStatus: true, accountInfo:data})         
            }
        }catch (error) {
            return;
        }
    }

    changePwd=()=>{
        this.setState({
            showPwdDlg: true
        });
    }

    showVip=()=>{
        this.setState({
            showDlg: true
        });
    }

    handleClosePwdDlg = () => {
        this.setState({showPwdDlg: false});
    };

    handleCloseDlg = (value) => {
        this.setState({
            showDlg: false,accountInfo:value.accountInfo
        });
        if(value.history){
            const { history } = this.props;
            history.push(value.history);
        }
    };


    handleChange = (_, v) => {        
        const uc_route=JSON.parse(localStorage.getItem('uc_route'));
        if(v==0 && 0==uc_route.tabs){
            this.setState({
                tab_value: v, tab0condition:uc_route.condition, tab0id:uc_route.id
            });
            return
        }        
        if(v==1 && 0==uc_route.tabs){
            this.setState({
                tab_value: v, tab1condition:uc_route.condition, tab1id:uc_route.id
            });
            return
        }
    };

    render() {
        const { classes } = this.props;
        const {productInfoStatus, accountInfoStatus, showDlg, showPwdDlg, accountInfo,productInfo,tab_value}= this.state

        return (
            <DashboardLayout title="用户中心">
                <div className={classes.root}> 
                    <LoadingOverlay active={productInfoStatus && accountInfoStatus ? false : true} spinner text="正在加载" >
                        <div className={classes.head}>       
                            {(productInfoStatus && accountInfoStatus?
                                <Header userInfo={accountInfo} click2NewPwd={this.changePwd} click2Vip={this.showVip} />:null
                            )}                        
                        </div>
                    </LoadingOverlay>
                    <Tabs aria-label="用户中心" onChange={this.handleChange} value={tab_value} textColor="primary" indicatorColor="primary" >
                        <Tab id={0} label="订单管理" />
                        <Tab id={1} label="系统通知" />
                    </Tabs>
                </div>
                <div className={classes.content} hidden={1 === tab_value} >
                    <Subscription/>
                </div>
                <div className={classes.content} hidden={0 === tab_value} >
                    <Notifications/>
                </div>                
                {productInfoStatus && accountInfoStatus?(<VipDialog open={showDlg} onClose={this.handleCloseDlg} dlgProps={{accountInfo:accountInfo, productInfo:productInfo}} />):null}
                <ChangePwdDlg open={showPwdDlg} handleclose={this.handleClosePwdDlg} />
            </DashboardLayout>
        );
    }
}

UserCenter.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserCenter);
