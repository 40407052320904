import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Checkbox, Typography, Button, TextField, Switch, Slider, Divider, Dialog, DialogActions, DialogContent,DialogTitle, CircularProgress, Grid, Tooltip } from '@material-ui/core';
// Shared components
import { Portlet, PortletHeader, PortletLabel, PortletContent } from 'components';

import LoadingOverlay from 'react-loading-overlay';

// Component styles
import styles from './styles';
import Http from 'services/http';

const PrettoSlider = withStyles({
    root: {
        color: 'primary',
        height: 8
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit'
        }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)'
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    }
})(Slider);
class FuncSettings extends Component {
    state = {
        open: false,
        silder_val: 20,
        spot_check_remark: '',
        buttonLoading: false,
        out_open: false,
        out_form: {
            out_remark1: '',
            out_remark2: ''
        }
    };

    handleOpen = type => {
        const { curcpystatus } = this.props;
        const { out_form } = this.state;
        if (type === 'in') {
            this.setState({
                open: true,
                spot_check_remark: curcpystatus.OffRemarkN
            });
        } else {
            out_form.out_remark1 = curcpystatus.OnRemarkW;
            out_form.out_remark2 = curcpystatus.OffRemarkW;
            this.setState({
                out_open: true,
                out_form
            });
        }
    };
    handleClose = () => {
        this.setState({
            open: false,
            out_open: false
        });
    };
    handleSlider = (_, val) => {
        this.setState({
            silder_val: val
        });
    };

    handle_spot_check = async () => {
        this.setState({
            buttonLoading: true
        });
        try {
            const res = await Http.signConfigUpdtN(
                {
                    InnerCheck: 1,
                    OnRemarkN: this.state.silder_val,
                    OffRemarkN: this.state.spot_check_remark,
                    companysn: this.props.curcpy
                },
                true
            );
            this.props.refresh1();
            setTimeout(() => {
                this.setState({
                    buttonLoading: false
                });
                this.handleClose();
            }, 500);
        } catch (err) {
            console.log(err);
        }
    };
    handleSpotCheck = val => {
        this.setState({
            spot_check_remark: val ? val : ''
        });
    };
    handleOutRemark = (field, val) => {
        const { out_form } = this.state;
        out_form[field] = val;
        this.setState({
            out_form
        });
    };
    handle_out_check = async () => {
        this.setState({
            buttonLoading: true
        });
        try {
            const res = await Http.signConfigUpdtW(
                {
                    OuterCheck: 1,
                    OnRemarkW: this.state.out_form.out_remark1,
                    OffRemarkW: this.state.out_form.out_remark2,
                    companysn: this.props.curcpy
                },
                true
            );
            this.props.refresh1();
            setTimeout(() => {
                this.setState({
                    buttonLoading: false
                });
                this.handleClose();
            }, 500);
        } catch (err) {
            this.setState({
                buttonLoading: false
            });
            console.log(err);
        }
    };
    render() {
        const { classes, className, curcpystatus, cpy_name, refresh1, changefunc, curcpy, ...rest } = this.props;
        const rootClassName = classNames(classes.root, className);
        const {open,spot_check_remark,buttonLoading,out_open, out_form}=this.state

        return (
            <Portlet className={rootClassName}>
                <LoadingOverlay active={cpy_name ? false : true} spinner text="正在加载" >
                    <PortletHeader>
                        <PortletLabel  title="考勤功能设置"
                            subtitle={
                                <span> 
                                    <span>{cpy_name}</span> <br />
                                    <span style={{ color: 'blue' }}> 单位号:{curcpy} </span>
                                </span>
                            }
                        />
                    </PortletHeader>
                    <PortletContent noPadding>
                        <div className={classes.group}>
                            <Typography className={classes.groupLabel} variant="h6" >
                                考勤功能开关
                            </Typography>
                            <div className={classes.field}>
                                <Grid container >
                                    <Grid item xs={8}>
                                        <Switch
                                            checked={ parseInt(curcpystatus.AutoEnter) ? true : false }
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'secondary checkbox'
                                            }}
                                            onChange={() => changefunc('autoenter')}
                                        />
                                        <Typography color="primary" variant="body1" component={'span'}>
                                            员工自动导入（已{parseInt(curcpystatus.AutoEnter)?'开启）':'关闭）'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{paddingLeft:10}}>
                                            <Typography variant="caption" component={'span'}> 
                                                自动导入员工可以让员工在第一次连接签到设备并且成功登录员工端后，自动完成单位员工信息的录入工作。【强烈建议开启】。
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                            <div className={classes.field}>
                                <Grid container >
                                    <Grid item xs={8}>
                                        <Switch
                                            checked={ parseInt(curcpystatus.InnerCheck) ? true : false }
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'secondary checkbox'
                                            }}
                                            onChange={() => changefunc('incheck')}
                                        />
                                        <Typography color="primary" variant="body1" component={'span'}>
                                            内勤抽查（已{parseInt(curcpystatus.InnerCheck)?'开启）':'关闭）'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Typography component={'span'}> 
                                            <Button color="primary" onClick={() => this.handleOpen('in') } variant="outlined" >
                                                参数设置
                                            </Button>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{paddingLeft:10}}>
                                            <Typography variant="caption" component={'span'}> 当前抽查比例：{curcpystatus.OnRemarkN} %</Typography>
                                            <Typography variant="caption" component={'span'} style={{paddingLeft:10}}> 当前抽查拍照说明： {curcpystatus.OffRemarkN}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                            <div className={classes.field}>
                                <Grid container >
                                    <Grid item xs={8}>
                                        <Switch
                                            checked={ parseInt(curcpystatus.OuterCheck) ? true : false }
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'secondary checkbox'
                                            }}
                                            onChange={() => changefunc('outcheck')}
                                        />
                                        <Typography color="primary" variant="body1" component={'span'}>
                                            外勤拍照（已{parseInt(curcpystatus.OuterCheck)?'开启）':'关闭）'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Typography component={'span'}> 
                                            <Button color="primary" onClick={() => this.handleOpen('out') } variant="outlined" >
                                                参数设置
                                            </Button>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{paddingLeft:10}}>
                                            <Typography variant="caption" component={'span'}> 当前外勤签到拍照说明： {curcpystatus.OnRemarkW}</Typography>
                                            <Typography variant="caption" component={'span'} style={{paddingLeft:10}}>  当前外勤签退拍照说明： {curcpystatus.OffRemarkW}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        {/* <div className={classes.group}>
              <Typography
                className={classes.groupLabel}
                variant="h6"
              >
                会员功能
              </Typography>
              <div className={classes.field}>
                <Checkbox color="primary" />
                <div>
                  <Typography variant="body1">月度报表Email发送</Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox color="primary" />
                <div>
                  <Typography variant="body1">自动随机抽检</Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  defaultChecked
                />
                <div>
                  <Typography variant="body1">地理位置采集</Typography>
                </div>
              </div>
            </div> */}
                    </PortletContent>
                </LoadingOverlay>

                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleClose}
                    open={open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='span' variant="h4" >内勤抽查</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Portlet className={rootClassName}>
                            <PortletHeader>
                                <PortletLabel subtitle="内勤抽查设置" title={cpy_name} />
                            </PortletHeader>
                            <PortletContent>
                                <Tooltip title='设置内勤抽查比例，按照比例被抽中的员工在内勤签到时需要拍照。'>
                                    <Typography component='span' variant="body1"  >
                                        内勤抽查比例
                                    </Typography>
                                </Tooltip>
                                <PrettoSlider
                                    aria-label="pretto slider"
                                    defaultValue={parseInt( curcpystatus.OnRemarkN )}
                                    onChangeCommitted={this.handleSlider}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={x => x + '%'}
                                />
                                <div className={classes.field}>
                                    <Tooltip title='拍照说明设置用于告知员工内勤拍照的注意事项，例如：告知员工自拍'>
                                        <TextField
                                            className={classes.textField}
                                            label="内勤抽查拍照说明"
                                            margin="dense"
                                            onChange={e => this.handleSpotCheck(e.target.value) }
                                            required
                                            value={spot_check_remark}
                                            variant="outlined"
                                        />
                                    </Tooltip>
                                </div>
                                <Typography component='span' variant="caption"  >
                                    *设置参数后，需要开启内勤抽查功能后才能生效
                                </Typography>
                            </PortletContent>
                        </Portlet>
                        <DialogActions>
                            <div style={{ position: 'relative' }}>
                                {buttonLoading && (
                                    <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', eft: '50%' }} />
                                )}
                                <Button variant='outlined' color="primary" disabled={buttonLoading} onClick={this.handle_spot_check} >
                                    保存
                                </Button>
                            </div>
                            <Button variant='outlined' color="primary" onClick={this.handleClose}>
                                取消
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleClose}
                    open={out_open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='span' variant="h4" >外勤拍照</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Portlet className={rootClassName}>
                            <PortletHeader>
                                <PortletLabel
                                    subtitle="外勤拍照设置"
                                    title={cpy_name}
                                />
                            </PortletHeader>
                            <PortletContent>
                                <div className={classes.field}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <Tooltip title='告知员工外勤上班照的注意事项，例如：告知员工拍外勤地标'>
                                            <TextField
                                                className={classes.textField}
                                                label="外勤签到拍照说明"
                                                margin="dense"
                                                onChange={e => this.handleOutRemark( 'out_remark1', e.target.value )}
                                                required
                                                value={out_form.out_remark1}
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title='告知员工外勤下班照的注意事项，例如：告知员工拍外勤地标'>
                                            <TextField
                                                className={classes.textField}
                                                label="外勤签退拍照说明"
                                                margin="dense"
                                                onChange={e => this.handleOutRemark( 'out_remark2', e.target.value )}
                                                required
                                                value={out_form.out_remark2}
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                </div>
                                <Typography component='span' variant="caption"  >
                                    *设置参数后，需要开启外勤拍照功能后才能生效
                                </Typography>
                            </PortletContent>
                        </Portlet>
                        <DialogActions>
                            <div style={{ position: 'relative' }}>
                                {buttonLoading && (
                                    <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                                )}
                                <Button variant='outlined' color="primary" disabled={buttonLoading} onClick={this.handle_out_check} >
                                    保存
                                </Button>
                            </div>
                            <Button variant='outlined' color="primary" onClick={this.handleClose}>
                                取消
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Portlet>
        );
    }
}

FuncSettings.propTypes = {
    changefunc: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.object,
    cpy_name: PropTypes.string,
    // curcpy: PropTypes.number,
    curcpystatus: PropTypes.object,
    refresh1: PropTypes.func
};

export default withStyles(styles)(FuncSettings);
