import React, { Component, Fragment, createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Http from 'services/http';

import { Button, DialogTitle, DialogContent, DialogActions, Dialog, RadioGroup, Radio, FormControlLabel, Chip,Backdrop,CircularProgress, Skeleton,
    Box, Toolbar, IconButton, AppBar, Typography, Grid, Paper, Card, CardMedia, CardActionArea, CardContent, CardHeader, Divider, Avatar, LinearProgress } from '@material-ui/core';

import { PermIdentity as UserIcon, Clear } from '@material-ui/icons';
import vipImg from 'static/img/vip.png'
import nonVipImg from 'static/img/nonvip.png'
import dftLogo from 'static/img/defaultlogo.png'
  
class Header extends Component {
  state={
    accountInfo:this.props.userInfo
  };

  refreshAccountInfo= async (info)=>{
      const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
      if (errcode == 0) {
        this.setState({accountInfo:data});
      } 
  }

    
  render(){
    const accountInfo=this.props.userInfo
    
    return (
        <Box style={{width:'100%'}}>
          <Typography variant="h6" component="span">
            用户名：
          </Typography>
          <Typography variant="body2" component="span">
            {accountInfo.name}
          </Typography>
          <Typography style={{marginLeft:30}} variant="h6" component="span">
            会员状态：
          </Typography>
          <img src={(accountInfo.isVip?vipImg:nonVipImg)} style={{maxHeight:12}}/> 
          <Typography variant="body2" component="span" style={{ paddingLeft:10}}>
            {(accountInfo.isVip?'会员截止期：'+accountInfo.validDate.substring(0,10):'会员未开通' )}
          </Typography>  
          <Button color="primary" onClick={this.props.click2NewPwd} size="small" style={{ marginLeft: 50 }} variant="outlined" >修改密码</Button>
          <Button color="primary" onClick={this.props.click2Vip} size="small" style={{ marginLeft: 20 }} variant="outlined"> {(accountInfo.isVip?'续费会员':'成为会员')}</Button> 
        </Box>
    );
  }
}

Header.propTypes = {
  userInfo: PropTypes.object.isRequired,
  click2NewPwd: PropTypes.func.isRequired,
  click2Vip: PropTypes.func.isRequired
};

export default Header;
