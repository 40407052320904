import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import MaterialTable ,{ MTableToolbar } from 'material-table';
import {SnackbarsMessage, TableIcons,Portlet, PortletHeader, PortletLabel, PortletContent,} from 'components';
import {Box, Button,Dialog,DialogActions,DialogContent,DialogTitle, Grid, Divider, Typography,IconButton, TextField} from '@material-ui/core';

import { withStyles, styled } from '@material-ui/styles';

import { Clear } from '@material-ui/icons';
import Http from 'services/http';

import styles from './styles';


class Notifications extends Component {
    state={
        notifications:[],
        loading:false,
        cur_user:null,
        snackbarsMsg:'',
        snackbarsVisible: false,
        notificationDetail:null,
        showDetailDlg: false,
        selectNotifications:[],
        curpage:0,
        pagesize:5,
        pagearray:[5,10,20],
        disableread:false
    }

    componentDidMount() {
        const {history}=this.props
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({ loading: true,cur_user: cur_user })
        if(null!=cur_user || undefined!=cur_user){            
            this.getNotifications(cur_user.UserID)
        }else{
            history.push('/singin')
        }        
    }

    getNotifications=async(info)=>{
        try {
            const {data, errcode, errmsg } = await Http.getNotifications({managerid:info, isread:2, maxnum:0});
            if (errcode !== 0) {
              this.showMsg(errmsg)
            } else {
                // console.log(data)
                let pagearray=[5,10,20]
                if(data.notifications.length<11){
                    pagearray.pop(20);
                }
                if(data.notifications.length<6){
                    pagearray.pop(10)
                }
                this.setState({pagearray:pagearray,notifications:data.notifications, loading:false});
            }
        } catch (error) {
          this.showMsg(error)
          return;
        }
    }

    readNotification=async(notification)=>{
        try {
            const {data, errcode, errmsg } = await Http.readNotification({managerid:this.state.cur_user.UserID, nid:notification.id });
            if (errcode !== 0) {
              this.showMsg(errmsg)
            } else {
              // console.log(data)
              this.setState({notificationDetail:notification, showDetailDlg: true})
              return
            }
        } catch (error) {
          this.showMsg(error)
        }
        this.setState({notificationDetail:null, showDetailDlg: false})
    }
    
    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    }

    handleDetailDlgOpen =(notification)=>{
      if(notification.isread==0){
        this.readNotification(notification)
      }else{
        this.setState({notificationDetail:notification, showDetailDlg: true})
      }
    }

    handleDetailDlgClose =(event,order)=>{
        this.setState({notificationDetail:null, showDetailDlg: false})
        this.componentDidMount();
    }    

    handleRowsChange = async rowsPerPage => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async Page => {
        this.setState({ curpage: Page })
    }
    
    handleSelection = async rows => {
        const {disableread}=this.state
        let allread=true
        let notices = await rows.map(notification => {
            allread=allread&&(notification.isread==1)
            return notification;
        });
        this.setState({
            selectNotifications: notices, disableread:allread
        })
    }
    
    handleNotificationsRead=async() => {
        let notifications='';        
        this.state.selectNotifications.map(notification=>{notifications+=notification.id+','})
        notifications=notifications.substring(0, notifications.lastIndexOf(','))
        this.setState({ loading: true})
        try{
            const result= await Http.setNotificationsRead({managerid:this.state.cur_user.UserID, nids:notifications});//await Http.get_receipt_order_list({managerid:info, condition:0, receiptid:''},true)
            const {data, errcode,errmsg}=result
            if(errcode==0){                
                this.componentDidMount();
                this.setState({selectNotifications:[], loading:false, disableread:false})
            }else{
                this.showMsg(errmsg)
                this.setState({ loading: false})
            }
        } catch (err) {
            this.showMsg(err)
            this.setState({ loading: false})
        }  
    }
    
    handleNotificationDelete=async(e, v) => {
        e.stopPropagation()
        this.setState({ loading: true})
        try{
            const result= await Http.setNotificationsDelete({managerid:this.state.cur_user.UserID, nids:v.id});//await Http.get_receipt_order_list({managerid:info, condition:0, receiptid:''},true)
            const {data, errcode,errmsg}=result
            if(errcode==0){                
                this.componentDidMount();
                this.setState({selectNotifications:[], loading:false})
            }else{
                this.showMsg(errmsg)
                this.setState({ loading: false})
            }
        } catch (err) {
            this.showMsg(err)
            this.setState({ loading: false})
        }  
    }
    
    handleNotificationsDelete=async() => {
        let notifications='';        
        this.state.selectNotifications.map(notification=>{notifications+=notification.id+','})
        notifications=notifications.substring(0, notifications.lastIndexOf(','))
        this.setState({ loading: true})
        try{
            const result= await Http.setNotificationsDelete({managerid:this.state.cur_user.UserID, nids:notifications});//await Http.get_receipt_order_list({managerid:info, condition:0, receiptid:''},true)
            const {data, errcode,errmsg}=result
            if(errcode==0){                
                this.componentDidMount();
                this.setState({selectNotifications:[], loading:false})
            }else{
                this.showMsg(errmsg)
                this.setState({ loading: false})
            }
        } catch (err) {
            this.showMsg(err)
            this.setState({ loading: false})
        }  
    }

    render() {
        const {pagearray,curpage, pagesize, notifications, selectNotifications, notificationDetail, loading, 
            disableread, snackbarsMsg, showDetailDlg, snackbarsVisible
        }=this.state 

        const columns = [
            // { title: '通知状态', field: 'isread',
            //   lookup:{'0':'未读', '1':'已读'},
            //   cellStyle: (records,record) =>({color:(record.isread==0?'#333':'#666'),fontWeight:(record.isread==0?'bold':'normal')})
            // },
            { title: '标题', field: 'title' , sorting: false,
              cellStyle: (records,record) =>({color:(record.isread==0?'#333':'#666'),fontWeight:(record.isread==0?'bold':'normal')}) ,
              //render: record=>(<div>{(record.isread==0?'[未读]':'[已读]')+record.title}</div>)
            },
            { title: '内容', field: 'content', sorting: false, 
              render: record=> (<div style={{color:(record.isread==0?'#333':'#666'),fontWeight:(record.isread==0?'bold':'normal')}}>{(record.content.length>25?record.content.substring(0,24)+"...":record.content)}</div>) 
            },
            { title: '发布时间', field: 'createtime', type:'datetime',
              cellStyle: (records,record) =>({color:(record.isread==0?'#333':'#666'),fontWeight:(record.isread==0?'bold':'normal')}) 
            },
            { 
              title: '操作', field: '',
              editable: 'never',
              filtering: false ,
              sorting:false,
              render: record =>(
                <Button
                    color="primary"
                    onClick={(event) => this.handleNotificationDelete(event,record)}
                    size="small"
                    style={{ textTransform: 'none' }}
                    variant="contained"
                >
                    删除
                </Button>)
            }
        ]

        // const MyMaterialTable = styled(MaterialTable)({
        //     ' .MuiTableRow-root.MuiTableRow-hover:hover ': { backgroundColor:'#a9adb2' }
        //   });
    
        return (
            <div>
                <SnackbarsMessage
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <MaterialTable 
                    columns={columns}
                    components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{marginTop:10}} >
                            </div>
                            <div>
                                <Typography component='span' variant="body1" style={{margin:25}} >
                                    已经选择
                                    <Typography component='span' variant="body1" style={{margin:5, color:'red'}}>
                                        {selectNotifications.length}
                                    </Typography>
                                    <Typography component='span' variant="body1" >
                                        个系统通知。
                                    </Typography>
                                </Typography>
                                <Button color="primary" size="small" variant="outlined" 
                                    disabled={selectNotifications.length==0||loading||disableread}
                                    onClick={this.handleNotificationsRead} style={{margin:5}}
                                >
                                    标为已读
                                </Button>
                                <Button color="primary" size="small" variant="outlined" 
                                    disabled={selectNotifications.length==0||loading}
                                    onClick={this.handleNotificationsDelete} style={{margin:5}} 
                                >
                                    批量删除
                                </Button>
                            </div>
                        </div>
                    )
                    }}
                    data={notifications}
                    icons={TableIcons}
                    isLoading = {loading}
                    localization={{
                        pagination: {
                            labelRowsSelect: ' 行每页',
                            labelDisplayedRows: ' {from}-{to} 行，共 {count} 行',
                            firstTooltip: '第一页',
                            previousTooltip: '前一页',
                            nextTooltip: '后一页',
                            lastTooltip: '到最后'
                        },
                        toolbar: {
                            searchTooltip: '模糊查找',
                            searchPlaceholder: '模糊查找',
                            exportName: '导出csv表格',
                            nRowsSelected: '已选择{0}个订单',
                            exportTitle: '导出csv表格'
                        },
                        body: {
                            addTooltip: '新增记录',
                            emptyDataSourceMessage: '暂时没有数据',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: '确定要删除吗？',
                                cancelTooltip: '取消',
                                saveTooltip: '确定'
                            }
                        }
                    }}
                    onRowClick={(e,v) => this.handleDetailDlgOpen(v)}
                    onChangeRowsPerPage={this.handleRowsChange}
                    onChangePage={this.handlePageChange}
                    onSelectionChange={this.handleSelection}
                    options={{
                        // exportButton: true,
                        selection: true,
                        // selectionProps: rowData => ({
                        //     disabled: rowData.IsReceipt === -1 || rowData.IsReceipt>0
                        // }),
                        // pageSize:10,
                        maxBodyHeight:600,
                        paging:true,
                        pageSize:pagesize,
                        paginationType:'normal',
                        initialPage:curpage,
                        pageSizeOptions:pagearray,
                        // exportFileName: 'orders.csv',
                        // filtering: true
                        showSelectAllCheckbox: true,
                        disableClick:false,
                        // actionsColumnIndex:-1,
                        rowStyle: record => (
                            (record.isread === 1 ? {backgroundColor:'#efefef'}:null )
                          )
                    }}
                    title=""
                />
                
                {(showDetailDlg && <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'md'}
                    onClose={this.handleDetailDlgClose}
                    open={showDetailDlg}
                >
                    <DialogActions>
                        <Box style={{width:'100%'}}>
                            <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >通知详情</Typography>
                        </Box>     
                        <IconButton size='small' onClick={this.handleDetailDlgClose}><Clear fontSize='small' style={{ color: 'primary' }}/></IconButton>
                    </DialogActions>
                    <DialogContent>
                        <Portlet>
                        <PortletContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}} >标题：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}}>{notificationDetail.title}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >发布时时间：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{notificationDetail.createtime}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:'10px 10px 0px 10px'}} >内容：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:'10px 10px 0px 10px'}}>{notificationDetail.content}</Typography>
                                </Grid>
                            </Grid> 
                        </PortletContent>
                        </Portlet>
                        <DialogActions>
                        {/* <Typography component='div' align='center' style={{margin:20}}> */}
                            <Button color="primary" onClick={this.handleDetailDlgClose} variant='outlined' > 我知道了 </Button>
                        {/* </Typography> */}
                        </DialogActions>
                    </DialogContent>
                </Dialog>)}
            </div>
        );
    }
}



Notifications.propTypes = {
    history: PropTypes.object.isRequired
};


export default withStyles(styles)(withRouter(Notifications));
