import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

import bushu_image from 'static/onepirate/bushu-01.png';
import bushu2_image from 'static/onepirate/bushu-02.png';
import bushu3_image from 'static/onepirate/bushu-03.png';
import bushu4_image from 'static/onepirate/bushu-04.png';
import bushu6_image from 'static/onepirate/bushu-06.png';
import bushu7_image from 'static/onepirate/bushu-07.png';
import bushu10_image from 'static/onepirate/bushu-10.png';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4)
    },
    images: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexWrap: 'wrap'
    },
    imageWrapper: {
        position: 'relative',
        display: 'block',
        padding: 0,
        borderRadius: 0,
        height: '40vh',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            height: 100
        },
        '&:hover': {
            zIndex: 1
        },
        '&:hover $imageBackdrop': {
            opacity: 0.15
        },
        '&:hover $imageMarked': {
            opacity: 0
        },
        '&:hover $imageTitle': {
            border: '4px solid currentColor'
        }
    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        width: '100%'
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: theme.palette.common.black,
        opacity: 0.5,
        transition: theme.transitions.create('opacity')
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`
    },
    imageMarked: {
        height: 3,
        width: 18,
        background: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity')
    }
});

function ProductCategories(props) {
    const { classes } = props;
    const router = useContext(__RouterContext);
    const images = [
        {
            url: bushu_image,
            title: (
                <span>
                    数据导出好轻松
                    <br />
                    报表文件一键下载
                </span>
            ),
            width: '50%'
        },
        {
            url: bushu3_image,
            title: (
                <span>
                    批量操作很简单
                    <br />
                    人员维护轻松处理
                </span>
            ),
            width: '50%'
        },
        {
            url: bushu6_image,
            title: (
                <span>
                    员工端APP操作简单
                    <br />
                    使用方便快捷
                </span>
            ),
            width: '40%'
        },
        {
            url: bushu4_image,
            title: '常见问题Q&A',
            width: '20%'
        },
        {
            url: bushu10_image,
            title: (
                <span>
                    配合管理端APP使用
                    <br />
                    签到考勤管理更方便
                </span>
            ),
            width: '40%'
        },
        {
            url: bushu2_image,
            title: (
                <span>
                    员工录入自动完成
                    <br />
                    人事管理省心省力
                </span>
            ),
            width: '50%'
        },
        {
            url: bushu7_image,
            title: (
                <span>
                    签到设备管理智能化
                    <br />
                    设备维护一劳永逸
                </span>
            ),
            width: '50%'
        }
    ];
    const handleClick = () => {
        router.history.push('/help');
    };
    return (
        <Container className={classes.root} component="section">
            <Typography
                align="center"
                component="h2"
                // marked="center"
                variant="h4"
            >
                注册部署如此轻松
            </Typography>
            <div className={classes.images}>
                {images.map(image => (
                    <ButtonBase
                        className={classes.imageWrapper}
                        key={image.url}
                        onClick={
                            image.title === '常见问题Q&A'
                                ? () => {
                                      router.history.push('/qapage');
                                  }
                                : handleClick
                        }
                        style={{
                            width: image.width
                        }}
                    >
                        <div
                            className={classes.imageSrc}
                            style={{
                                backgroundImage: `url(${image.url})`,
                                backgroundSize: 'auto',
                                backgroundRepeat: 'no-repeat'
                            }}
                        />
                        <div className={classes.imageBackdrop} />
                        <div className={classes.imageButton}>
                            <Typography
                                className={classes.imageTitle}
                                color="inherit"
                                component="h3"
                                variant="h4"
                            >
                                {image.title}
                                <div className={classes.imageMarked} />
                            </Typography>
                        </div>
                    </ButtonBase>
                ))}
            </div>
        </Container>
    );
}

ProductCategories.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategories);
