import React from 'react';
import MaterialTable, { MTableHeader, MTableToolbar } from 'material-table';
import { format } from 'date-fns';
import TableIcons from '../../../../components/TableIcons/index';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { zhCN } from 'date-fns/locale';
import { withStyles } from '@material-ui/styles';
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletContent
} from 'components';
import SnackbarsMessage from 'components/SnackbarsMessage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import export_to_excel from 'utils/export_to_excel';

// import { Map, InfoWindow, Marker } from 'react-bmap';

import TiandituMap from '../TiandituMap';
import classNames from 'classnames';

import styles from './styles';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: '考勤日期', filtering: false ,field: 'SignDay' },
                { title: '姓名', field: 'Name', sorting: false },
                { title: '工号', field: 'GongHao' },
                { title: '部门名称', field: 'GroupName' , sorting: false},
                {
                    title: '签到时间',
                    field: 'comeimefmt',
                    filtering: false ,
                    render: record => {
                        if (record.onWN === 'inner') {
                            return <span>{record.comeimefmt.indexOf('次')>0?record.comeimefmt: record.ontime}</span>;
                        } else {
                            return (
                                <Button
                                    color="primary"
                                    style={{fontSize:12}}
                                    onClick={() =>
                                        this.handleOpen(record, 'come')
                                    }
                                    size="small"
                                    variant="outlined"
                                >
                                    {record.comeimefmt.indexOf('次')>0?record.comeimefmt: record.ontime+'(外勤)'}
                                </Button>
                            );
                            // return <span>外勤:{record.ontime}</span>;
                        }
                    }
                },
                {
                    title: '签退时间',
                    field: 'gotimefmt',
                    filtering: false ,
                    render: record => {
                        if (record.offWN === 'inner') {
                            if (record.offtime === '') {
                                return '--';
                            } else {
                                return <span>{record.gotimefmt.indexOf('次')>0?record.gotimefmt: record.offtime}</span>;
                            }
                        } else {
                            if (record.offtime === '') {
                                return '--';
                            } else {
                                return (
                                    <Button
                                        style={{fontSize:12}}
                                        color="primary"
                                        onClick={() =>
                                            this.handleOpen(record, 'go')
                                        }
                                        size="small"
                                        variant="outlined"
                                    >
                                        {record.gotimefmt.indexOf('次')>0?record.gotimefmt: record.offtime+'(外勤)'}
                                    </Button>
                                );
                                // return <span>外勤:{record.offtime}</span>;
                            }
                        }
                    }
                },
                {   title: '迟到情况',  field: 'comelatefmt', sorting: false,
                    render: record => {
                        return <span style={{color:(record.comelatefmt === '迟到')?'red':''}}>{record.comelatefmt}</span>;
                    }
                },
                { title: '早退情况', field: 'goearlyfmt', sorting: false,
                    render: record => {
                        return <span style={{color:((record.goearlyfmt === '早退')||(record.goearlyfmt === '下班未打卡'))?'red':''}}>{record.goearlyfmt}</span>;
                    }
                },
                { title: '工作时长', field: 'remark' }
            ],
            date_value: null,
            isLoading: false,

            open: false,

            addr: '',
            lng: 0,
            lat: 0,
            imgUrl: '',
            type: '',
            mapType:0,
            bdshow:'none',
            tdtshow:'block',
            snackbarsVisible: false,
            snackbarsMsg: '',
            curUser: {},
            curpage:0,
            pagesize:5
        };
    }
    
    handleClose = () => {
        this.setState({
            open: false,
            curUser: {},
            addr: '',
            lng: 0,
            lat: 0,
            mapType:0,
            bdshow:'none',
            tdtshow:'block',
            imgUrl: '',
            type: ''
        });
    };

    handleOpen = (record, type) => {
        let point={lng:'',lat:''}
        if (type === 'come') {
            if (
                record.comeLng === '' &&
                record.comeLat === '' &&
                record.comeUrl === ''
            ) {
                this.showMsg('未采集到地图位置,和照片');
            } else {
                point.lng=record.comeLng
                point.lat=record.comeLat
                if(record.comeOut==0){
                    this.setState({
                        tdtshow: 'block',
                        bdshow:'none'
                    });
                }else{
                    this.setState({
                        tdtshow: 'none',
                        bdshow:'block'
                    });
                }
                this.setState({
                    type: type,
                    addr: record.comeAddr,
                    lng: point.lng,
                    lat: point.lat,
                    mapType:record.comeMapType,
                    imgUrl: record.comeUrl,
                    curUser: record
                });
                this.setState({
                    open: true
                });
            }
        } else {
            if (
                record.goLng === '' &&
                record.goLat === '' &&
                record.goUrl === ''
            ) {
                this.showMsg('未采集到地图位置,和照片');
            } else {
                point.lng=record.goLng
                point.lat=record.goLat
                if(record.goOut==0){
                    this.setState({
                        tdtshow: 'block',
                        bdshow:'none'
                    });
                }else{
                    this.setState({
                        tdtshow: 'none',
                        bdshow:'block'
                    });
                }
                this.setState({
                    type: type,
                    addr: record.goAddr,
                    lng: point.lng,
                    lat: point.lat,
                    imgUrl: record.goUrl,
                    mapType:record.goMapType,
                    curUser: record
                });
                this.setState({
                    open: true
                });
            }
        }
    };

    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false
        });
    };

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    }; 

    handleRowsChange = async (rowsPerPage) => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async (Page) => {
        this.setState({ curpage: Page })
    }

    changedate=(value)=>{
        this.setState({curpage:0, pagesize:5})
        this.props.handledatechange(value);        
    }
    
    render() {
        const { classes, users, date_value,pageprops, isloading, className, ...rest } = this.props;
        const rootClassName = classNames(classes.root, className);
        const dateNow=new Date()
        const now=format(dateNow,"yyyy-MM-dd")
        let year=dateNow.getFullYear()-1
        const minDate=now.replace(now.substring(0,now.indexOf("-")),year+"")

        // let month=(dateNow.getMonth()+1-4)
        // year=year+1-(month>0?0:1)
        // month=(month>0?month:month+12)
        // let datestr=""+year+"-"+(month>9?month+"":"0"+month)
        // const minDate=now.replace(now.substring(0,now.lastIndexOf("-")),datestr)
        
        
        const {pagesize,curpage, snackbarsMsg, snackbarsVisible,open,
            lng,lat,bdshow,tdtshow,addr, curUser,mapType,imgUrl,columns
        }=this.state

        return (
            <div>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    disableBackdropClick
                    disableEscapeKeyDown
                    // fullWidth
                    maxWidth="lg"
                    onClose={this.handleClose}
                    open={open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        查看外勤
                    </DialogTitle>
                    <DialogContent>
                        <Portlet>
                            <PortletContent>
                                <div style={{ width: '800px' }} >
                                    <div style={{ width: '50%', float: 'left' }} >
                                        {lng === '' || lat === '' ? (
                                            <h4>未采集位置信息</h4>
                                        ) : (
                                            <div>
                                                <h4>外勤位置</h4>
                                                {bdshow === 'block' && tdtshow === 'none'?(
                                                <Map
                                                    style={{height: '400px', display: bdshow}}
                                                    center={{ lng: lng, lat: lat }}
                                                    zoom="14"
                                                >
                                                    <Marker
                                                        position={{ lng: lng, lat: lat }}
                                                    />
                                                    <InfoWindow
                                                        enableCloseOnClick={ false }
                                                        position={{ lng: lng, lat: lat }}
                                                        text={ addr || '' }
                                                        title={ curUser.Name || '' }
                                                    />
                                                </Map>
                                                ):(                                              
                                                <TiandituMap
                                                    style={{height: '400px', display: tdtshow}}
                                                    center={{ lng: lng, lat: lat }}
                                                    mapType={mapType}
                                                    zoom="16"
                                                    text={addr ||''}
                                                    title={curUser.Name || ''}
                                                >                                                    
                                                </TiandituMap>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {imgUrl === '' ? (
                                        <h4>未采集外勤照片</h4>
                                    ) : (
                                        <div style={{ width: '50%', float: 'right' }} >
                                            <h4>外勤照片</h4>
                                            <img
                                                style={{ width: '100%' }}
                                                alt=""
                                                src={imgUrl}
                                            />
                                        </div>
                                    )}
                                </div>
                            </PortletContent>
                        </Portlet>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleClose}>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
                <MaterialTable
                    columns={columns}
                    components={{
                        Toolbar: props => (
                            <div >
                                <MTableToolbar {...props} />
                                <div className={classes.row} style={{marginTop:15}}>
                                    <MuiPickersUtilsProvider
                                        locale={zhCN}
                                        utils={DateFnsUtils}
                                    >
                                        <DatePicker
                                            disableFuture
                                            // disableToolbar
                                            // minDate={minDate}
                                            format="yyyy-MM-dd"
                                            helperText="No year selection"
                                            inputVariant="outlined"
                                            label="签到日期选择"
                                            onChange={
                                                this.changedate
                                            }
                                            value={date_value}
                                            variant="inline"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        )
                    }}
                    data={users}
                    icons={TableIcons}
                    isLoading={isloading}
                    localization={{
                        pagination: {
                            labelRowsSelect: '行每页',
                            labelDisplayedRows: users.length>0?' {from}-{to} 共 {count} 条':'0-0 共 0 条',
                            firstTooltip: '第一页',
                            previousTooltip: '前一页',
                            nextTooltip: '后一页',
                            lastTooltip: '到最后'
                        },
                        toolbar: {
                            searchTooltip: '模糊查找',
                            searchPlaceholder: '模糊查找',
                            exportName: '导出表格',
                            nRowsSelected: '{0} row(s) selected',
                            exportTitle: '导出表格'
                        },
                        header: {
                            actions: '操作'
                        },
                        body: {
                            addTooltip: '新增记录',
                            emptyDataSourceMessage: '暂时没有数据',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: '确定要删除吗？',
                                cancelTooltip: '取消',
                                saveTooltip: '确定'
                            }
                        }
                    }}
                    onChangeRowsPerPage={this.handleRowsChange}
                    onChangePage={this.handlePageChange}
                    options={{
                        exportButton: true,
                        draggable:false,
                        minBodyHeight:600,
                        paging:true,
                        pageSizeOptions: pageprops.pagearray,
                        pageSize:pagesize,
                        paginationType:'normal',
                        initialPage:curpage,
                        exportAllData: true,
                        toolbarButtonAlignment: 'left',
                        exportCsv: (columnList, initialData) =>
                            export_to_excel(
                                columnList,
                                initialData,
                                '日考勤报表'
                            )
                    }}
                    title={<div style={{fontSize:16, marginRight:10, marginTop:5}}>日考勤明细</div>}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Index);
