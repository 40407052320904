import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Typography from '../components/Typography';
import ProductCurvyLines from 'static/onepirate/productCurvyLines.png';
import zhuce from 'static/onepirate/zhuce.png';
import xinjian from 'static/onepirate/xinjian.png';
import tongzhi from 'static/onepirate/tongzhi.png';
import peizhi from 'static/onepirate/peizhi.png';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: '#dce2f5',
        overflow: 'hidden'
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5)
    },
    title: {
        marginBottom: theme.spacing(14)
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
    },
    image: {
        height: 55,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
        opacity: 0.7
    },
    button: {
        marginTop: theme.spacing(8)
    }
});

function ProductHowItWorks(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <img
                    src={ProductCurvyLines}
                    className={classes.curvyLines}
                    alt="curvy lines"
                />
                <Typography
                    variant="h4"
                    // marked="center"
                    className={classes.title}
                    component="h2"
                >
                    使用帮助
                </Typography>
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={3}>
                            <div className={classes.item}>
                                <img
                                    src={zhuce}
                                    alt="suitcase"
                                    className={classes.image}
                                />
                                <Typography variant="h5" align="center">
                                    通过官网注册登录
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={classes.item}>
                                <img
                                    src={peizhi}
                                    alt="graph"
                                    className={classes.image}
                                />
                                <Typography variant="h5" align="center">
                                    配置中完善信息
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={classes.item}>
                                <img
                                    src={tongzhi}
                                    alt="clock"
                                    className={classes.image}
                                />
                                <Typography variant="h5" align="center">
                                    <span>通知员工相关信息</span>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={classes.item}>
                                <img
                                    src={xinjian}
                                    alt="clock"
                                    className={classes.image}
                                />
                                <Typography variant="h5" align="center">
                                    <span>
                                        下载员工端
                                        <br />
                                        登录执行签到考勤
                                    </span>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className={classes.button}
                    component="a"
                    href="/sign-up"
                >
                    开始
                </Button> */}
            </Container>
        </section>
    );
}

ProductHowItWorks.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductHowItWorks);
