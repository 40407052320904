import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import validate from 'validate.js';
import _ from 'underscore';
import classNames from 'classnames';
import schema from './schema';
// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from 'components';
// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import ProductsToolbar from './ProductsToolbar/index';
import RouterCard from './RouterCard/index';
// Shared services
import Http from 'services/http';
// Component styles
import styles from './styles';

class RouterManagement extends Component {
    signal = true;

    state = {
        isLoading: false,
        limit: 6,
        products: [],
        productsTotal: 0,
        error: null,

        router_form: {
            ssid: '',
            remark: ''
        },
        errors: {
            ssid: null,
            remark: null
        },
        touched: {
            ssid: false,
            remark: false
        },

        open: false,
        disable: false,
        cpyname:''
    };
    async componentDidMount() {
        this.signal = true;
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        this.setState({ cpyname:cur_cpy.CompanyName,isLoading: true });

        try {
            const routers = await Http.get_routers(
                {
                    CompanyID: cur_cpy.CompanySN
                },
                true
            );
            // console.log(routers.data)
            this.setState({
                products: routers.data,
                isLoading: false,
                productsTotal: routers.data.length
            });
        } catch (e) {
            console.log(e);
        }
    }

    renderProducts() {
        const { classes } = this.props;
        const { isLoading, products } = this.state;

        if (isLoading) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress />
                </div>
            );
        }

        if (products.length === 0) {
            return <Typography variant="h6">现在您还没有签到设备</Typography>;
        }

        return (
            <Grid container spacing={3}>
                {products.map(product => (
                    <Grid item key={product.EquID} lg={4} md={6} xs={12}>
                        {/* <Link to="#"> */}
                        <RouterCard
                            openEditRouter={this.openEditRouter}
                            product={product}
                        />
                        {/* </Link> */}
                    </Grid>
                ))}
            </Grid>
        );
    }

    refresh = () => {
        this.componentDidMount();
    };
    openEditRouter = async product => {
        const { router_form } = this.state;
        try {
            const res = await Http.getEqu( { equid: product.EquID }, true );
            const { data, errcode, errmsg } = res;
            router_form.ssid = data.ssid;
            router_form.remark = data.remark;
            router_form.equid = data.EquID;
            this.setState({
                cur_router: product,
                open: true
            });
        } catch (err) {
            console.log(err);
        }
    };

    handleClose = () => {
        this.setState({ open: false,
            errors: {
                ssid: null,
                remark: null
            },
            touched: {
                ssid: false,
                remark: false
            }});
    };
    handleEditRouter = async () => {
        const { router_form, cur_router } = this.state;
        try {
            const res = await Http.editEqu(
                {
                    ssid: router_form.ssid,
                    remark: router_form.remark,
                    equid: cur_router.EquID
                },
                true
            );
            const { errcode } = res;
            if (errcode === 0) {
                this.componentDidMount();
                this.handleClose();
            }
        } catch (err) {
            console.log(err);
        }
    };

    validateFrom = _.debounce(() => {
        const { router_form } = this.state;
        const errors = validate(router_form, schema);
        const disable = errors ? false : true;
        this.setState({ errors: errors || {}, disable });
    }, 300);

    handleFieldChange = (value, field) => {
        const { router_form, touched } = this.state;
        touched[field] = true;
        router_form[field] = value;
        const disable = null;
        this.setState( { router_form, touched, disable }, this.validateFrom() );
    };
    render() {
        const { classes, className } = this.props;
        const { touched, errors, cpyname, router_form } = this.state;
        const rootClassName = classNames(classes.root, className);
        const ssid_error = touched.ssid && (errors.ssid?true:false)&& (errors.ssid==null?false:true);
        const remark_error = touched.remark && (errors.remark?true:false) && (errors.remark==null?false:true);
        return (
            <DashboardLayout title="设备管理">
                <div className={classes.root}>
                    <ProductsToolbar refresh={this.refresh} cpyname={cpyname} />
                </div>
                <div className={classes.content}>{this.renderProducts()}</div>
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    disableBackdropClick
                    disableEscapeKeyDown
                    fullWidth
                    maxWidth={'xs'}
                    onClose={this.handleClose}
                    open={this.state.open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='div' variant="h4" >修改签到设备</Typography>
                        </Typography>                        
                    </DialogTitle>
                    <DialogContent>
                        <Portlet>
                            <PortletHeader>
                                <PortletLabel subtitle="修改设备信息" title={router_form.ssid} />
                            </PortletHeader>
                            <PortletContent>
                                <div className={classes.field}>
                                    <Tooltip title='填写手机连接的签到wifi名称'>
                                        <TextField required label="请填写签到wifi名称" margin="dense" variant="outlined"
                                            value={this.state.router_form.ssid} className={classes.textField} 
                                            onChange={event => this.handleFieldChange( event.target.value, 'ssid' ) }
                                            error={ssid_error}
                                            helperText={ssid_error?errors.ssid[0].replace('Ssid',''):''}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={classes.field}>
                                    <Tooltip title='填写签到wifi设备的备注，例如：安装地点，办公室名称'>
                                        <TextField required label="请填写签到设备备注" margin="dense" multiline rows="2"
                                            variant="outlined" className={classes.textField} value={router_form.remark}
                                            onChange={event => this.handleFieldChange( event.target.value, 'remark' ) }
                                            error={remark_error}
                                            helperText={remark_error?errors.remark[0].replace( 'Remark', '' ):''}
                                        />
                                    </Tooltip>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            disabled={!this.state.disable}
                            onClick={this.handleEditRouter}
                            variant="outlined"
                        >
                            保存
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.handleClose}
                            variant="outlined"
                        >
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
            </DashboardLayout>
        );
    }
}

RouterManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RouterManagement);
