import React, { Component } from 'react';

import classNames from 'classnames';

// Material components
import { Popover, Button, TextField, Typography, CircularProgress, Grid, Divider, Tooltip, RemoveCircleOutline} from '@material-ui/core';

import Http from 'services/http';

// Material helpers
import { withStyles } from '@material-ui/styles';
import { SnackbarsMessage, Portlet, PortletHeader, PortletLabel, PortletContent, //TimeSelect,
    TimeSelect1, TimeSelect0
} from 'components';
import compose from 'recompose/compose';

import { connect } from 'react-redux';
import { actions as cpyactions } from '../../../../redux/modules/cpy';
import styles from './styles';
// import { Tooltip } from 'chart.js';

class Index extends Component {
    state = {
        Loading:false,
        snackbarsMsg: '',
        snackbarsVisible: false,
        anchorEl: null,
        open: false
    };

    delcompany = () => {
        if (this.props.cpylist.length === 1) {
            this.showMsg('不能删除最后一个单位');
        }else{
            this.handleClose();
            this.setState({Loading:true})
            this.props.delCpy();
        }
    };

    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };

    handleSnackBarsClose = () => {
        this.setState({
            snackbarsVisible: false,
            anchorEl: null
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            anchorEl: null
        });
    };

    delete_cpy_open = anchorEl => {
        this.setState({
            anchorEl: anchorEl,
            open: true
        });
    };
    
    render() {
        const { classes, className, form_value, errors, touched, disableaddress, disablefirmname,
            disabletel, accountinfo, handlefieldchange, changenum, isnew, cur_user, profession, ...rest
        } = this.props;

        const { snackbarsMsg, snackbarsVisible, anchorEl, open, Loading } = this.state;

        const rootClassName = classNames(classes.root, className);

        return (
            <Portlet  className={rootClassName}>
                <SnackbarsMessage
                    handleClose={this.handleSnackBarsClose}
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    // id={id}
                    onClose={this.handleClose}
                    open={open}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Typography className={classes.typography}>
                        <Button onClick={this.delcompany}>确定</Button>
                        <Button onClick={this.handleClose}>取消</Button>
                    </Typography>
                </Popover>
                <PortletHeader>
                    {isnew?<PortletLabel subtitle="新增单位基本信息设置"/>:<PortletLabel subtitle="单位基本信息设置" title={form_value.firmname} />}
                    {cur_user ? (
                        ['AdminB', 'AdminC'].includes(
                            cur_user.RoleCode
                        ) ? null : !isnew ? (
                            <div style={{ float: 'right', color: 'red' }}>
                                <div style={{ position: 'relative' }}>
                                    {Loading && (
                                        <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                                    )}
                                    <Tooltip title='删除操作将删除单位信息、部门信息、员工信息和考勤信息且不可恢复，请谨慎操作'>
                                        <div>
                                            <Button
                                                // style={{color:'red', borderColor:'red'}}
                                                color="primary"
                                                disabled={ Loading }
                                                onClick={event => this.delete_cpy_open( event.currentTarget )}
                                                variant="outlined"
                                            >
                                                删除
                                            </Button>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : null
                    ) : null}
                </PortletHeader>
                <PortletContent>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="单位全称" name="name"  variant="outlined" required
                                    style={{width:'100%'}} value={form_value.firmname} placeholder='请输入单位名称'
                                    onFocus={event => handlefieldchange( event.target.value, 'firmname' )}
                                    onChange={event => handlefieldchange( event.target.value, 'firmname' )}
                                    error={disablefirmname} 
                                    helperText={disablefirmname?errors.firmname[0].replace('Firmname', ''):''}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="单位地址" name="address"  variant="outlined" required
                                    style={{width:'100%'}} value={form_value.address} placeholder='请输入单位地址'
                                    onFocus={event => handlefieldchange( event.target.value, 'address' )}
                                    onChange={event => handlefieldchange( event.target.value, 'address' )}
                                    error={disableaddress} 
                                    helperText={disableaddress?errors.address[0].replace('Address', ''):''}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="单位电话" name="tel"  variant="outlined" required
                                    style={{width:'100%'}} value={form_value.tel} placeholder='请输入单位电话'
                                    onFocus={event => handlefieldchange( event.target.value, 'tel' )}
                                    onChange={event => handlefieldchange( event.target.value, 'tel' )}
                                    error={disabletel} 
                                    helperText={disabletel?errors.tel[0].replace('Tel', ''):''}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component='div' variant="body1">
                                <TextField margin="dense" label="所属行业" name="memo"  variant="outlined" required
                                    style={{width:'100%'}} 
                                    onChange={event => handlefieldchange(event.target.value, 'memo') }
                                    value={form_value.memo} 
                                    select
                                    SelectProps={{ native: true }}
                                >
                                    {profession.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{marginTop:20, marginBottom:10}} />
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {/* <TimeSelect
                                changenum={changenum}
                                form_value={form_value}
                                handlefieldchange={handlefieldchange}
                                num={num}
                                isvalid={timevalid}
                            /> */}
                            {form_value.mode==0?
                            <TimeSelect0
                                changenum={changenum}
                                form_value={form_value}
                                handlefieldchange={handlefieldchange}
                                // num={num}
                                // isvalid={timevalid}
                            />:
                            <TimeSelect1
                                changenum={changenum}
                                form_value={form_value}
                                handlefieldchange={handlefieldchange}
                                accountinfo={accountinfo}
                                // num={num}
                                // isvalid={timevalid}
                            />}
                        </Grid>
                    </Grid>
                </PortletContent>
            </Portlet>
        );
    }
}

const mapStateToProps = state => ({
    cpy: state.cpy
});

const mapDispatchToProps = dispatch => ({
    cpyactions: data => dispatch(cpyactions.getcpylist(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(Index));
