import React from 'react';

// MaterialTable
import MaterialTable, { MTableToolbar } from 'material-table';
import TableIcons from '../../../components/TableIcons/index';

import { Button,Dialog, DialogActions,DialogContent,Box,Grid,Typography,Divider } from '@material-ui/core';
import MailOutline from '@material-ui/icons/MailOutline';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import validate from 'validate.js';
import Http from '../../../services/http';
import export_to_excel from 'utils/export_to_excel';

import { SnackbarsMessage,Portlet, PortletContent } from 'components';
import NoticeDialog from './components/NoticeDialog';
import styles from './styles';
import schema from './schema';

class Editable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notices: [],
            // 信息表单
            notice: {
                checked: false,
                date_time: null,
                notice_title: '',
                notice_body: ''
            },
            errors: {
                notice_title: null,
                notice_body: null
            },
            touched: {
                notice_title: false,
                notice_body: false
            },
            submitError: null,

            add_notice: false,
            loading: false,

            visible: false,
            msg: '',
            curpage:0,
            pagesize:5,
            pagearray:[5,10,20],
            noticeDetail:{},
            showDetailDlg:false
        };
    }

    async componentDidMount() {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        this.setState({
            loading: true
        });
        try {
            const res = await Http.get_notice_list(
                {
                    companyid: cur_cpy.CompanySN
                },
                true
            );
            if (res.data !== undefined) {
                let pa=[5,10,20]
                if(res.data.length<11){
                    pa.pop(20)
                }
                if(res.data.length<6){
                    pa.pop(10)
                }
                this.setState({
                    notices: res.data,
                    loading: false,
                    pagearray:pa
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
            console.log(e);
        }
    }

    handleAddNotice = () => {
        this.setState({
            add_notice: true
        });
    };

    handleClose = () => {
        this.setState({
            add_notice: false,
            notice: {
                checked: false,
                date_time: null,
                notice_title: '',
                notice_body: ''
            },
            errors: {
                notice_title: null,
                notice_body: null
            },
            touched: {
                notice_title: false,
                notice_body: false
            },
            submitError: null
        });
    };

    validateForm = () => {
        let { notice } = this.state;
        let errors = validate(notice, schema);
        let submitError = errors ? false : true;
        errors = errors || {};
        this.setState({
            submitError,
            errors
        });
    };

    // 表单
    handleFieldChange = (value, field) => {
        const { notice, touched } = this.state;
        const submitError = null;
        notice[field] = value;
        touched[field] = true;

        this.setState({ notice, touched, submitError }, this.validateForm);
    };

    sendNotice = async () => {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));

        const { notice, submitError } = this.state;
        if (submitError) {
            try {
                const res = await Http.post_notice(
                    {
                        title: notice.notice_title,
                        Content: notice.notice_body,
                        IsSendNow: notice.date_time ? 0 : 1,
                        IsPush: notice.checked ? 1 : 0,
                        SendTime: notice.date_time ? notice.date_time : '',
                        companyid: cur_cpy.CompanySN
                    },
                    true
                );

                const {errmsg, errcode } = res;
                if (errcode === 0) {
                    this.setState({
                        add_notice: true
                    });
                    this.showMsg('发送成功');
                    this.componentDidMount();
                    this.handleClose();
                } else {
                    this.showMsg('发送失败，请重试。');
                    this.handleClose();
                    console.log(errmsg);
                }
            } catch (e) {
                this.showMsg('网络出现问题，请稍后重试。');
                this.handleClose();
                console.log(e);
            }
        } else {
            this.showMsg('请检查是否填写了标题和内容');
            // console.log(submitError);
        }
    };
    showMsg = msg => {
        this.setState({
            msg: msg,
            visible: true
        });
        setTimeout(() => {
            this.setState({
                msg: '',
                visible: false
            });
        }, 1000);
    };

    sendNoticeAsync = () => {
        const { notice } = this.state;
        if (notice.date_time) {
            this.sendNotice();
        } else {
            this.showMsg('定时发送请选择发送时间');
        }
    };    

    handleRowsChange = async (rowsPerPage) => {
        this.setState({ pagesize: rowsPerPage })
    }

    handlePageChange= async (Page) => {
        this.setState({ curpage: Page })
    }

    handleDetailDlgOpen =(order)=>{
        this.setState({noticeDetail:order, showDetailDlg: true})
    }

    handleDetailDlgClose =()=>{
        this.setState({noticeDetail:{}, showDetailDlg: false})
    }

    render() {
        const columns = [
            { title: '公告标题', field: 'title', sorting: false, 
                render: record=> (<div >{(record.title.length>15?record.title.substring(0,14)+"...":record.title)}</div>)  },
            { title: '公告内容', field: 'ncontent', sorting: false, 
                render: record=> (<div >{(record.ncontent.length>15?record.ncontent.substring(0,14)+"...":record.ncontent)}</div>)  },
            { title: '创建时间', filtering: false , field: 'CreateTime', defaultSort: 'desc' },
            { title: '发布时间', filtering: false , field: 'sendtime' },
            { title: '操作', field: '', editable: 'never', filtering: false , sorting:false,
                render: record =>(
                <Button
                    color="primary"
                    onClick={() => this.handleDetailDlgOpen(record)}
                    size="small"
                    variant="contained"
                >
                    查看详情
                </Button>)
            }
        ];
        const { classes, className, ...rest } = this.props;
        const { curpage, pagesize, pagearray, notices, notice, loading, touched, errors,add_notice,msg,visible,noticeDetail, showDetailDlg} = this.state;
        const rootClassName = classNames(classes.root, className);
        const title_error = touched.notice_title && errors.notice_title;
        const body_error = touched.notice_body && errors.notice_body;
        return (
            <div>
                <NoticeDialog
                    body_error={body_error}
                    errors={errors}
                    handleClose={this.handleClose}
                    handleFieldChange={this.handleFieldChange}
                    notice={notice}
                    open={add_notice}
                    sendNotice={this.sendNotice}
                    sendNoticeAsync={this.sendNoticeAsync}
                    title_error={title_error}
                />
                <MaterialTable
                    columns={columns}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div className={classes.sendMessage}>
                                    <Button
                                        color="primary"
                                        onClick={this.handleAddNotice}
                                        variant="outlined"
                                    >
                                        <MailOutline />
                                        发公告
                                    </Button>
                                </div>
                            </div>
                        )
                    }}
                    data={notices}
                    icons={TableIcons}
                    isLoading={loading}
                    localization={{
                        pagination: {
                            labelRowsSelect: '行每页',
                            labelDisplayedRows: notices.length>0?' {from}-{to} 共 {count} 条':'0-0 共 0 条',
                            firstTooltip: '第一页',
                            previousTooltip: '前一页',
                            nextTooltip: '后一页',
                            lastTooltip: '到最后'
                        },
                        toolbar: {
                            searchTooltip: '模糊查找',
                            searchPlaceholder: '模糊查找',
                            exportName: '导出表格',
                            nRowsSelected: '{0} row(s) selected',
                            exportTitle: '导出表格'
                        },
                        header: {
                            actions: '操作'
                        },
                        body: {
                            addTooltip: '新增记录',
                            emptyDataSourceMessage: '暂时没有数据',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: '确定要删除吗？',
                                cancelTooltip: '取消',
                                saveTooltip: '确定'
                            }
                        }
                    }}
                    onChangeRowsPerPage={this.handleRowsChange}
                    onChangePage={this.handlePageChange}
                    options={{
                        exportButton: true,
                        minBodyHeight:600,
                        paging:true,
                        pageSizeOptions: pagearray,
                        pageSize:pagesize,
                        paginationType:'normal',
                        initialPage:curpage,
                        exportCsv: export_to_excel
                    }}
                    title="所有公告"
                />
                {(this.state.showDetailDlg && <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleDetailDlgClose}
                    open={this.state.showDetailDlg}
                >
                    <DialogActions>
                        <Box style={{width:'100%'}}>
                            <Typography component='div' variant="h4" style={{margin:'20px 0px 10px 20px'}} >公告详情</Typography>
                        </Box>
                    </DialogActions>
                    <DialogContent>
                        <Portlet>
                        <PortletContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}} >公告标题：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:'0px 10px 10px 10px'}}>{noticeDetail.title}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >公告内容：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{noticeDetail.ncontent}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >创建时间：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{noticeDetail.CreateTime}</Typography>
                                </Grid>
                            </Grid>                            
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >发布时间：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{noticeDetail.sendtime.substring(0,noticeDetail.sendtime.length-3)}</Typography>
                                </Grid>
                            </Grid>                           
                            <Divider />
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component='div' variant="body1" style={{margin:10}} >发布方式：</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='div' variant="body1" style={{margin:10}}>{noticeDetail.sendtime.indexOf("即")>0?"立即发送":"定时发送"}</Typography>
                                </Grid>
                            </Grid>
                        </PortletContent>
                        </Portlet>
                        <DialogActions>
                        {/* <Typography component='div' style={{margin:20}}> */}
                            <Button color="primary" onClick={this.handleDetailDlgClose} variant='outlined' > 确定 </Button>
                        {/* </Typography> */}
                        </DialogActions>
                    </DialogContent>
                </Dialog>)}
                <SnackbarsMessage
                    msg={msg}
                    visible={visible}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Editable);
