import { post } from '../../utils/request';
import url from '../../utils/url';

const initUserState = {
  Name: 'guest',
  tryLogin: false
};

export const types = {
  // 登录
  USER_SIGNIN_REQUEST: 'SIGNIN/USER_SIGNIN_REQUEST',
  USER_SIGNIN_SUCCESS: 'SIGNIN/USER_SIGNIN_SUCCESS',
  USER_SIGNIN_FAILURE: 'SIGNIN/USER_SIGNIN_FAILURE'
};
// action creator
const doSignInRequest = () => ({
  type: types.USER_SIGNIN_REQUEST
});
const doSignInSuccess = data => ({
  type: types.USER_SIGNIN_SUCCESS,
  data
});
const doSignInFailure = error => ({
  type: types.USER_SIGNIN_FAILURE,
  error
});

const LS_KEY = '_$-loginuser_';
export const actions = {
  signin: data => {
    const endpoint = url.login();
    return dispatch => {
      dispatch(doSignInRequest());
      return post(endpoint, data)
        .then(res => {
          const { data, errcode, errmsg } = res.data;
          if (errcode !== 0) {
            dispatch(doSignInFailure(errmsg));
          } else {
            localStorage.setItem(LS_KEY, JSON.stringify(data));
            dispatch(doSignInSuccess(data));
          }
        })
        .catch(error => {
          dispatch(doSignInFailure(error));
        });
    };
  }
};

const reducer = (state = initUserState, action) => {
  switch (action.type) {
    case types.USER_SIGNIN_REQUEST:
      return { ...state, tryLogin: true };
    case types.USER_SIGNIN_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    case types.USER_SIGNIN_FAILURES:
      return {
        ...state,
        ...action.error
      };
    default:
      return state;
  }
};

export default reducer;
