import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import compose from 'recompose/compose';
// import Share from 'social-share-react';
import erweima1 from 'static/管理版二维码.png';
import erweima2 from 'static/员工版二维码.png';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: '#dce2f5'
    },
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: 'flex'
    },
    iconsWrapper: {
        height: 120
    },
    icons: {
        display: 'flex'
    },
    icon: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.warning.main,
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        }
    },
    list: {
        margin: 0,
        listStyle: 'none',
        paddingLeft: 0
    },
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
    },
    language: {
        marginTop: theme.spacing(1),
        width: 150
    }
});

function AppFooter(props) {
    const { classes } = props;

    return (
        <Typography className={classes.root} component="footer">
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item md={3} sm={4} xs={6}>
                        <Grid
                            className={classes.iconsWrapper}
                            container
                            direction="column"
                            justify="flex-end"
                            spacing={2}
                        >
                            <Grid className={classes.icons} item>
                                <div>
                                    <img
                                        alt="app"
                                        src={erweima1}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            marginRight: '80px'
                                        }}
                                    />
                                    <br />
                                    <span>管理端APP</span>
                                </div>

                                <div>
                                    <img
                                        alt="app"
                                        src={erweima2}
                                        style={{
                                            width: '100px',
                                            height: '100px'
                                        }}
                                    />
                                    <br />
                                    <span>员工端APP</span>
                                </div>
                            </Grid>
                            {/* <Grid item></Grid> */}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={6} sm={4} md={2}></Grid> */}
                    {/* <Grid item xs={6} sm={8} md={4}>
                        <Share
                            url="http://www.ddqiandao.com"
                            title="叮咚,签到啦！一个专注于考勤签到的手机应用"
                            sites={['qq']}
                        ></Share>
                        QQ分享
                    </Grid> */}
                    <Grid item>
                        <Typography variant="caption">
                            <a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备13016255号-5 © 2001 - 2023 山东紫光比威网络技术有限公司 版权所有</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}

AppFooter.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    React.memo,
    withStyles(styles)
)(AppFooter);
