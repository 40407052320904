import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { IconButton, Popover, Toolbar, Typography, Badge, Button, Menu, MenuItem, Divider, Backdrop, CircularProgress } from '@material-ui/core';

// Material icons
import {
    Menu as MenuIcon,
    Close as CloseIcon,
    NotificationsOutlined as NotificationsIcon,
    Input as InputIcon,
    ArrowBack,
    PermIdentity as UserIcon
} from '@material-ui/icons';

// Shared services
// import { getNotifications } from 'services/notification';

// Custom components
import { NotificationList, VipDialog } from './components';
import Http from 'services/http';

// Component styles
import styles from './styles';
import { connect } from 'react-redux';
import { actions as topbarActions } from 'redux/modules/topbar';

import vipImg from 'static/img/vip.png'
import nonVipImg from 'static/img/nonvip.png'
/**
const accountInfo = {name:'name', isVip:false, validDate:'2023-12-23 23:59:59', vipClass:1, logo:''}
const productInfo={
  productList:[{name:'年卡（12个月）',oldPrice:100, newPrice:60, voucherString:'最热选购', promotionString:'每天0.2元'},
      {name:'季卡（3个月）',oldPrice:60, newPrice:40, voucherString:'', promotionString:'每天0.7元'},
      {name:'月卡（1个月）',oldPrice:20, newPrice:18, voucherString:'首购优选', promotionString:'每天0.8元'}],
  dftSelect:0, dftPay:0, }
**/


// const mapDispatchToProps = dispatch => ({
//     getAccountInfoAction: data => dispatch(topbarActions.getAccountInfo(data)),
//     getProductInfoAction: data => dispatch(topbarActions.getProductInfo(data))
// });
// @connect(
//     state => ({ topbar: state.topbar }),
//     mapDispatchToProps
// )
class Topbar extends Component {
    signal = true;

    state = {
        cur_user:null,
        notifications: [],
        notificationsLimit: 100,
        notificationsCount: 0,
        notificationsEl: null,
        showDlg: false,
        accountInfoSatus:false,
        productInfoStatus:false,
        accountInfo:null,
        productInfo:null
    };

    componentDidMount() {
        this.signal = true;
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        if(null!=cur_user || undefined!=cur_user){
            this.setState({
                cur_user: cur_user
            });
            // console.log(cur_user)
            this.handleAccountInfo(cur_user.UserID)
            this.handleProductInfo(cur_user.UserID)
            this.getNotifications(cur_user.UserID);
            //this.props.getAccountInfoAction(cur_user.UserID);
            // this.props.getProductInfoAction(cur_user.UserID);
            // console.log(this.state.topbar)
        }   
    }

    componentWillUnmount() {
        this.signal = false;
    }

    getNotifications=async(info)=>{
        try {
            const { notificationsLimit } = this.state;
            const {data, errcode, errmsg } = await Http.getNotifications({managerid:info, isread:0, maxnum:notificationsLimit});
            if (errcode !== 0) {
                this.setState({notifications:[], notificationsCount:0});
            } else {
                // console.log(data)
                this.setState({notifications:data.notifications, notificationsCount:data.notificationsCount });
            }
        } catch (error) {
            return;
        }
    }

    handleAccountInfo= async (info)=>{
        try {
            const { data, errcode, errmsg } = await Http.getAccountInfo({managerid:info});
            if (errcode !== 0) {
                this.setState({accountInfoSatus: false})
            } else {
                // console.log(data)
                this.setState({accountInfoSatus: true, accountInfo:data})         
            }
        }catch (error) {
            return;
        }
    }

    handleProductInfo= async (info)=>{
        try{
            const { data, errcode, errmsg } = await Http.getProductInfo({managerid:info});
            if (errcode !== 0) {
                this.setState({productInfoStatus: false})
            } else {
                this.setState({productInfoStatus: true, productInfo:data}) 
            }
        }catch(error){
            return;
        }
    }

    handleSignOut = async () => {
        const { history } = this.props;

        localStorage.setItem('is_auth', false);
        localStorage.clear();
        await Http.logout();
        history.push('/sign-in');
    };

    handlePricing = () => {
        const { history } = this.props;

        history.push('/pricing');
    };

    handleShowNotifications = event => {
        this.setState({
            notificationsEl: event.currentTarget
        });
    };

    handleCloseNotifications = () => {
        this.setState({
            notificationsEl: null
        });
    };

    handle2Notification = () => {
        const { history } = this.props;
        history.push('/center');
    };

    handle2Notifications = () => {        
        const { history,title } = this.props;
        if(title!=='用户中心'){
            const route={tabs:1, conditon:0, id:0}
            localStorage.setItem('uc_route',JSON.stringify(route))
            history.push('/center');
        }
    };

    handleShowMenu = event => {
        this.setState({
            menuEl: event.currentTarget
        });
    };    

    handleCloseMenu = () => {
        this.setState({
            menuEl: null
        });
    };    

    handleShowDlg = (value) => {
        this.setState({
            showDlg: true
        });
    }; 

    handleCloseDlg = (value) => {
        this.setState({
            showDlg: false,accountInfo:value.accountInfo
        });
        if(value.history){
            const { history } = this.props;
            history.push(value.history);
        }
    };

    openUserCenter= () => {
        this.setState({
            menuEl: null
        });
        this.props.history.push('/center')
    };

    render() {
        const {
            classes,
            className,
            title,
            isSidebarOpen,
            onToggleSidebar
        } = this.props;
        const {
            notifications,
            notificationsCount,
            notificationsEl,
            menuEl,
            cur_user,
            showDlg,
            productInfoStatus,
            accountInfoSatus,
            accountInfo,
            productInfo
        } = this.state;

        const rootClassName = classNames(classes.root, className);
        const showNotifications = Boolean(notificationsEl);
        const showMenu=Boolean(menuEl)
        const isvip=false;

        return (
            <Fragment>
                <div className={rootClassName}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            className={classes.menuButton}
                            onClick={onToggleSidebar}
                            variant="text"
                        >
                            {isSidebarOpen ? <ArrowBack /> : <MenuIcon />}
                        </IconButton>
                        <Typography className={classes.title} variant="h4">
                            {title}
                        </Typography>
                        {/*<IconButton
                            className={classes.signOutButton}
                            onClick={this.handlePricing}
                            style={{ position: 'absolute', right: '120px' }}
                            >
                            升级到会员
                        </IconButton>*/}
                        {/* 信息提示 */}
                        {/*<IconButton
                            className={classes.notificationsButton}
                            onClick={this.handleShowNotifications}
                        >
                            <Badge
                                badgeContent={notificationsCount}
                                color="primary"
                                variant="dot"
                            >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        {/* className={classes.signOutButton} */}
                        <span className={classes.notificationsButton}>
                            {cur_user && accountInfoSatus && productInfoStatus?(['Admin'].includes(cur_user.RoleCode)?(<IconButton
                                className={classes.notificationsButton}
                                onClick={/**this.handlePricing**/ this.handleShowDlg}
                            >
                                <img  className={classes.isVipImg} src={accountInfo.isVip?vipImg:nonVipImg} />
                                {/**<Typography
                                    className={classes.nameText}
                                    variant="h6"
                                >
                                    升级到会员
                                </Typography>**/}                               
                            </IconButton>):null):null}
                            {cur_user && ['Admin'].includes(cur_user.RoleCode) ?<IconButton
                                className={classes.notificationsButton}
                                // onClick={this.handleShowNotifications}
                                onClick={this.handle2Notifications}
                            >
                                {notificationsCount<this.state.notificationsLimit?(<Badge
                                    badgeContent={notificationsCount}
                                    color="primary"
                                >
                                    <NotificationsIcon />
                                </Badge>):(<Badge
                                    color="primary"
                                    variant="dot"
                                >
                                    <NotificationsIcon />
                                </Badge>)}
                            </IconButton>:null}
                            <Button
                                style={{textTransform:'none'}}
                                className={classes.notificationsButton}
                                onClick={this.handleShowMenu}
                            >
                                <Typography
                                    className={classes.nameText}
                                    variant="h6"
                                >
                                    {cur_user ? cur_user.Name: '还未登录'}
                                </Typography>
                            </Button>
                            {cur_user ?(['AdminB', 'AdminC'].includes(cur_user.RoleCode)? (<IconButton
                                className={classes.notificationsButton}
                                onClick={this.handleSignOut}
                            >
                            <InputIcon />
                            </IconButton>)
                            :(
                            <Menu
                                anchorEl={menuEl}
                                open={showMenu}
                                onClose={this.handleCloseMenu}
                                // anchorOrigin={{
                                //     vertical: 'bottom',
                                //     horizontal: 'right'
                                // }}
                                transformOrigin={{
                                    vertical: -50,
                                    horizontal: 'left'
                                }}
                            >
                                <MenuItem onClick={this.openUserCenter}>
                                    <Typography variant="body1" >用户中心 </Typography>
                                </MenuItem>
                                <Divider/>
                                <MenuItem onClick={this.handleSignOut}>
                                <Typography
                                    variant="body1"
                                >退出登录
                                </Typography></MenuItem>
                            </Menu>)):null}
                            
                            
                        </span>
                    </Toolbar>
                </div>
                <Popover
                    anchorEl={notificationsEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    onClose={this.handleCloseNotifications}
                    open={showNotifications}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <NotificationList
                        notifications={notifications}
                        onSelect={this.handle2Notification}
                    />
                </Popover>
                {/**<Popover
                    anchorReference='anchorPosition'
                    anchorPosition={{top:0, left:0}}
                    onClose={this.handleCloseDlg}
                    open={showDlg}
                >**/}
                {productInfoStatus && accountInfoSatus?
                    <VipDialog
                        open={showDlg}
                        onClose={this.handleCloseDlg}
                        dlgProps={{accountInfo:accountInfo, productInfo:productInfo}}
                    />:null}
                {/**</Popover>**/}
            </Fragment>
        );
    }
}

Topbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool,
    onToggleSidebar: PropTypes.func,
    title: PropTypes.string
};

Topbar.defaultProps = {
    onToggleSidebar: () => {}
};

export default compose(
    withRouter,
    withStyles(styles)
)(Topbar);
