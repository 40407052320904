import React, { Component } from 'react';
import {
    // Router,
    // Router
    HashRouter as Router
} from 'react-router-dom';
import { Provider } from 'react-redux';

// Externals
import { Chart } from 'react-chartjs-2';

// Material helpers
import { ThemeProvider } from '@material-ui/styles';

// ChartJS helpers
import { chartjs } from './helpers';

// Theme
import theme from './theme';

// Styles
// import 'react-perfect-scrollbar/dist/css/styles.css';
// import './assets/scss/index.scss';

// Routes
import Routes from './Routes';

// redux
import store from './redux/store';

// Browser history
import history from 'history.js';
// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </ThemeProvider>
            </Provider>
        );
    }
}
