import React, { Component } from 'react';
import {List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Button, TextField, Divider, Avatar, Typography, CircularProgress,
    ListItemSecondaryAction, IconButton, Checkbox, DialogActions, DialogContent, Dialog, DialogTitle
} from '@material-ui/core';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Material helpers
import { withStyles } from '@material-ui/styles';

import LoadingOverlay from 'react-loading-overlay';
import { compare_all_timeV2, compare_each_oneV2, compare_result } from 'utils/time_compare';

import {BookmarkBorderOutlined, Delete as DeleteIcon} from '@material-ui/icons';

import { format, isValid } from 'date-fns';

import validate from 'validate.js';
// Shared components
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletFooter, SnackbarsMessage } from 'components';

// import CompanyForm from '../CompanyForm';
import CompanyForm2 from '../CompanyForm2';

import Http from 'services/http';
import compose from 'recompose/compose';

import { connect } from 'react-redux';
import { actions as cpyactions } from '../../../../redux/modules/cpy';
// Form validation schema
import schema from './schema';
// Component styles
import styles from './styles';
import { post } from 'utils/request';

const default_form_value = {
    firmname: '',
    tel: '',
    address: '',
    email: '',
    mode:0,
    mode0info:{
        mode0num:0,           
        pname1: '第一时段',
        stime1: new Date('December 17, 1995 8:00:00'),
        ontime1: new Date('December 17, 1995 8:30:00'),
        offtime1: new Date('December 17, 1995 17:30:00'),
        pname2: '第二时段',
        stime2: new Date('December 17, 1995 12:30:00'),
        ontime2: new Date('December 17, 1995 13:00:00'),
        offtime2: new Date('December 17, 1995 17:30:00'),
        pname3: '第三时段',
        stime3: new Date('December 17, 1995 16:30:00'),
        ontime3: new Date('December 17, 1995 17:00:00'),
        offtime3: new Date('December 17, 1995 23:00:00')
    },
    mode1info:{
        mode1num:0,          
        pname1: '第一时段',
        flag11:1,
        stime1: new Date('December 17, 1995 8:00:00'),
        flag12:1,
        ontime1: new Date('December 17, 1995 8:30:00'),
        flag13:1,
        offtime1: new Date('December 17, 1995 17:30:00'),
        flag14:1,
        etime1: new Date('December 17, 1995 19:00:00'),
        pname2: '第二时段',
        flag21:1,
        stime2: new Date('December 17, 1995 12:30:00'),
        flag22:1,
        ontime2: new Date('December 17, 1995 13:00:00'),
        flag23:1,
        offtime2: new Date('December 17, 1995 17:30:00'),
        flag24:1,
        etime2: new Date('December 17, 1995 19:00:00'),
        pname3: '第三时段',
        flag31:1,
        stime3: new Date('December 17, 1995 19:30:00'),
        flag32:1,
        ontime3: new Date('December 17, 1995 20:00:00'),
        flag33:0,
        offtime3: new Date('December 17, 1995 3:00:00'),
        flag34:0,
        etime3: new Date('December 17, 1995 5:00:00')
    },

    equipname: '',
    firmlogo:
        'https://app.ddqiandao.com/Upload/20170824/a60bb445-1665-4bc2-9e84-163a562a75ab.jpg',
    Stype: '',
    memo: ''
};
class InfoSettings extends Component {
    state = {
        selectCompany: 0,
        open: false,
        depart_open: false,

        form_value: {
            firmname: '',
            tel: '',
            address: '',
            email: '',
            mode:0,
            mode0info:{
                mode0num:0,           
                pname1: '第一时段',
                stime1: new Date('December 17, 1995 8:00:00'),
                ontime1: new Date('December 17, 1995 8:30:00'),
                offtime1: new Date('December 17, 1995 17:30:00'),
                pname2: '第二时段',
                stime2: new Date('December 17, 1995 12:30:00'),
                ontime2: new Date('December 17, 1995 13:00:00'),
                offtime2: new Date('December 17, 1995 17:30:00'),
                pname3: '第三时段',
                stime3: new Date('December 17, 1995 16:30:00'),
                ontime3: new Date('December 17, 1995 17:00:00'),
                offtime3: new Date('December 17, 1995 23:00:00')
            },
            mode1info:{
                mode1num:0,          
                pname1: '第一时段',
                flag11:1,
                stime1: new Date('December 17, 1995 8:00:00'),
                flag12:1,
                ontime1: new Date('December 17, 1995 8:30:00'),
                flag13:1,
                offtime1: new Date('December 17, 1995 17:30:00'),
                flag14:1,
                etime1: new Date('December 17, 1995 19:00:00'),
                pname2: '第二时段',
                flag21:1,
                stime2: new Date('December 17, 1995 12:30:00'),
                flag22:1,
                ontime2: new Date('December 17, 1995 13:00:00'),
                flag23:1,
                offtime2: new Date('December 17, 1995 17:30:00'),
                flag24:1,
                etime2: new Date('December 17, 1995 19:00:00'),
                pname3: '第三时段',
                flag31:1,
                stime3: new Date('December 17, 1995 19:30:00'),
                flag32:1,
                ontime3: new Date('December 17, 1995 20:00:00'),
                flag33:0,
                offtime3: new Date('December 17, 1995 3:00:00'),
                flag34:0,
                etime3: new Date('December 17, 1995 5:00:00')
            },

            equipname: '',
            firmlogo:
                'https://app.ddqiandao.com/Upload/20170824/a60bb445-1665-4bc2-9e84-163a562a75ab.jpg',
            Stype: '',
            memo: ''
        },

        errors: {
            firmname: null,
            tel: null,
            address: null
        },

        touched: {
            firmname: false,
            tel: false,
            address: false
        },
        isValid: false,
        isLoading: false,

        num: 0,
        cpyList: [],
        departments: [],
        chagedep:0,
        chagecpy:0,

        department_name: '',

        cpySn: '',

        profession: [],

        isnew: false,

        buttonLoading: false,
        snackbarsMsg: '',
        snackbarsVisible: false,

        cur_user: null,

        select_group: 0,
        select_group_name: ''
    };

    componentDidMount() {
        const cur_user = localStorage.getItem('cur_user');
        this.setState({
            cur_user: JSON.parse(cur_user)
        });
        this.getProfession()
    }

    getProfession=async()=>{        
        try {
            const res = await Http.profession();
            this.setState({
                profession: res.data
            });
        } catch (err) {
            console.log(err);
        }
    }

    validateForm = () => {
        const { form_value } = this.state;

        let errors = validate(form_value, schema);

        const isValid = errors ? false : true;
        errors = errors || {};
        this.setState({
            errors,
            isValid
        });
    };

    timeValid=(value)=>{
        this.setState({
            isValid:value
        })
    }

    handleFieldChange = (value, field) => {
        const { form_value, touched } = this.state;
        let {mode0info, mode1info}=this.state.form_value
        let fields='stime1stime2stime3etime1etime2etime3ontime1ontime2ontime3offtime1offtime2offtime3flag11flag12flag13flag14flag21flag22flag23flag24flag31flag32flag33flag34'
        if(fields.includes(field)){
            if(form_value.mode==0){
                mode0info[field]=value
                form_value.mode0info={...mode0info}
            }
            if(form_value.mode==1){
                mode1info[field]=value
                form_value.mode1info={...mode1info}
            }
        }else{
            form_value[field] = value;
        }
        touched[field] = true;
        this.setState({ form_value, touched }, this.validateForm);
    };

    handleListItemClick = id => {
        this.setState({
            selectCompany: id
        });
    };

    handleClose = () => {
        const{chagedep, depart_open, chagecpy,open}=this.state
        let departcancel=0, firmcancel=0;
        if (chagedep==0 && depart_open==true) {
            departcancel=1
        }
        if (chagecpy==0 && open==true) {
            firmcancel=1
        }
        
        this.setState({
            open: false,
            depart_open: false,
            isnew: false,
            errors: {
                firmname: null,
                tel: null,
                address: null
            },
            num:0,
            isValid:false,
            touched: {
                firmname: false,
                tel: false,
                address: false
            },
            form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}},
            department_name:'',
            select_group:0,
            select_group_name:'',
            chagedep:0,
            chagecpy:0
        });
        if(departcancel==1 || firmcancel==1){
            return
        }else{
            this.props.cpyactions();
            this.componentDidMount();
            this.props.refresh()
        }
    };

    // 修改单位
    handleCompanyEdit = async company => {
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));
        this.setState({
            cpySn: company.CompanySN
        });
        const{form_value}=this.state
        try {
            const res = await Http.GetEditCpyInfoV2( { companysn: company.CompanySN, userid:cur_user.UserID } );
            const { data, errmsg, errcode } = res;
            if (errcode === 0) {
                form_value.firmname=data.Name
                form_value.tel=data.Phone
                form_value.address=data.Address
                form_value.email=data.Email
                form_value.firmlogo=data.LogoUrl
                form_value.Stype=''
                form_value.equipname=data.equipname
                form_value.equipaddress=data.equipaddress
                form_value.memo=data.CompanyType

                if(data.mode==0){
                    form_value.mode=0
                    if(data.Mode0Info.pname1.length>0){
                        form_value.mode0info.mode0num=0
                        form_value.mode0info.pname1 = data.Mode0Info.pname1;
                        form_value.mode0info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime1 + ':00' );
                        form_value.mode0info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime1 + ':00' );
                        form_value.mode0info.stime1 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime1 + ':00' );
                    }
                    if(data.Mode0Info.pname2.length>0){
                        form_value.mode0info.mode0num+=1;
                        form_value.mode0info.pname2 = data.Mode0Info.pname2;
                        form_value.mode0info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime2 + ':00' );
                        form_value.mode0info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime2 + ':00' );
                        form_value.mode0info.stime2 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime2 + ':00' );
                    }
                    if(data.Mode0Info.pname3.length>0){
                        form_value.mode0info.mode0num+=1;
                        form_value.mode0info.pname3 = data.Mode0Info.pname3;
                        form_value.mode0info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.ontime3 + ':00' );
                        form_value.mode0info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.offtime3 + ':00' );
                        form_value.mode0info.stime3 = new Date( 'December 17, 1995 ' + data.Mode0Info.stime3 + ':00' );
                    }
          
                }else if(data.mode==1){
                    form_value.mode=1
                    if(data.Mode1Info.pname1.length>0){
                        form_value.mode1info.mode1num=0
                        form_value.mode1info.pname1 = data.Mode1Info.pname1;
                        form_value.mode1info.flag11=data.Mode1Info.flag11
                        form_value.mode1info.stime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime1 + ':00' );
                        form_value.mode1info.flag12=data.Mode1Info.flag12
                        form_value.mode1info.ontime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime1 + ':00' );
                        form_value.mode1info.flag13=data.Mode1Info.flag13
                        form_value.mode1info.offtime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime1 + ':00' );
                        form_value.mode1info.flag14=data.Mode1Info.flag14
                        form_value.mode1info.etime1 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime1 + ':00' );
                    }
                    if(data.Mode1Info.pname2.length>0){
                        form_value.mode1info.mode1num+=1;
                        form_value.mode1info.pname2 = data.Mode1Info.pname2;
                        form_value.mode1info.flag21=data.Mode1Info.flag21
                        form_value.mode1info.stime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime2 + ':00' );
                        form_value.mode1info.flag22=data.Mode1Info.flag22
                        form_value.mode1info.ontime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime2 + ':00' );
                        form_value.mode1info.flag23=data.Mode1Info.flag23
                        form_value.mode1info.offtime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime2 + ':00' );
                        form_value.mode1info.flag24=data.Mode1Info.flag24
                        form_value.mode1info.etime2 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime2 + ':00' );
                    }
                    if(data.Mode1Info.pname3.length>0){
                        form_value.mode1info.mode1num+=1;
                        form_value.mode1info.pname3 = data.Mode1Info.pname3;
                        form_value.mode1info.flag31=data.Mode1Info.flag31
                        form_value.mode1info.stime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.stime3 + ':00' );
                        form_value.mode1info.flag32=data.Mode1Info.flag32
                        form_value.mode1info.ontime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.ontime3 + ':00' );
                        form_value.mode1info.flag33=data.Mode1Info.flag33
                        form_value.mode1info.offtime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.offtime3 + ':00' );
                        form_value.mode1info.flag34=data.Mode1Info.flag34
                        form_value.mode1info.etime3 = new Date( 'December 17, 1995 ' + data.Mode1Info.etime3 + ':00' );
                    }
                }
                this.setState({
                    open: true,
                    isnew: false,
                    form_value
                });
            }else{
                this.showMsg(errmsg)
            }
        } catch (err) {
            this.showMsg('服务器开小差了，请重试')
            console.log(err);
        }
    };

    handleDepart = async company => {
        try {
            const res = await Http.get_group(
                {
                    cpySn: company.CompanySN,
                    _: new Date().getTime()
                },
                true
            );
            this.setState({
                depart_open: true,
                departments: res.data,
                cpySn: company.CompanySN
            });
        } catch (err) {
            console.log(err);
        }
    };

    changeNum = type => {
        const { form_value } = this.state;
        switch(form_value.mode){
            case 0:
                if (form_value.mode0info.mode0num >= 0 && form_value.mode0info.mode0num < 3) {
                    if (type === 'add' && form_value.mode0info.mode0num !== 2) { form_value.mode0info.mode0num += 1; } else if (type === 'minus' && form_value.mode0info.mode0num !== 0) { form_value.mode0info.mode0num -= 1; }
                }
                break;
            case 1:
                if (form_value.mode1info.mode1num >= 0 && form_value.mode1info.mode1num < 3) {
                    if (type === 'add' && form_value.mode1info.mode1num !== 2) { form_value.mode1info.mode1num += 1; } else if (type === 'minus' && form_value.mode1info.mode1num !== 0) { form_value.mode1info.mode1num -= 1; }
                }
                break;
        }         
        this.setState({form_value}, this.validateForm);   
    };

    changeDepartmentName = name => {
        this.setState({
            department_name: name
        });
    };

    delete_department = async groupid => {
        const{departments, cpySn}=this.state
        const{companyList}=this.props
        if (departments.length === 1) {
            this.showMsg('不能删除最后一个部门');
        }else{
            try {
                const res = await Http.delBu({
                    groupid: groupid,
                    companysn: cpySn
                });
                const { errcode, errmsg } = res;
                if (errcode == 0) {
                    this.showMsg('删除部门成功')
                    this.setState({
                        department_name: '', chagedep:1
                    });
                    try {
                        const res = await Http.get_group(
                            {
                                cpySn: cpySn,
                                _: new Date().getTime()
                            },
                            true
                        );
                        
                        for(let i=0;i<companyList.length;i++){
                            if(companyList[i].CompanySN==cpySn){
                                companyList[i].detail.deptNum=res.data.length
                                break;
                            }
                        }
                        this.setState({
                            departments: res.data
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
                else{
                    this.showMsg('删除部门失败（'+errmsg+'）')
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    add_department = async () => {
        const{department_name, cpySn}=this.state
        try {
            const res = await Http.addBu(
                {
                    groupname: department_name,
                    companyid: cpySn
                },
                true
            );
            const { errcode, msg } = res;
            if (errcode == 0) {
                this.showMsg('新增部门成功')
                this.setState({
                    department_name: '',chagedep:1
                });

                try {
                    const res = await Http.get_group(
                        {
                            cpySn: cpySn,
                            _: new Date().getTime()
                        },
                        true
                    );
                    this.setState({
                        departments: res.data
                    });
                } catch (err) {
                    console.log(err);
                }
            }else{
                this.showMsg('新增部门失败（'+msg+'）')
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleNewCpy = () => {
        const{profession}=this.state
        this.setState({
            form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}, memo:profession[0].name},
            isnew: true,
            isValid:false,
            open: true
        })
    };

    saveCpy = async () => {
        const { form_value, isnew, profession } = this.state;
        const { mode0info, mode1info, mode } = this.state.form_value;
        const cur_user = JSON.parse(localStorage.getItem('cur_user'));

        let code=compare_each_oneV2(form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        code=compare_all_timeV2(form_value, mode)
        if (code<1) {
            this.showMsg(compare_result(code));
            return;
        }
        
        this.setState({
            buttonLoading: true
        });
        let num=-1
        let m0ts={
          name1:"",
          name2:"",
          name3:"",
          begin1:"",
          begin2:"",
          begin3:"",
          end1:"",
          end2:"",
          end3:"",
          eff1 :"",
          eff2:"",
          eff3:""
        }
        let m1ts={
          name1:"",
          name2:"",
          name3:"",
          begin1:"",
          begin2:"",
          begin3:"",
          end1:"",
          end2:"",
          end3:"",
          eff1 :"",
          eff2:"",
          eff3:"",
          noeff1 :"",
          noeff2:"",
          noeff3:"",
          flag11:1,
          flag12:1,
          flag13:1,
          flag14:1,
          flag21:1,
          flag22:1,
          flag23:1,
          flag24:1,
          flag31:1,
          flag32:1,
          flag33:1,
          flag34:1
        }
        switch(mode){
          case 0:
            num=mode0info.mode0num;
            
            if(num==0){
              m0ts.name1=mode0info.pname1
              m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
              m0ts.end1=format(mode0info.offtime1, 'HH:mm')
              m0ts.eff1=format(mode0info.stime1, 'HH:mm')
            }else if(num==1){
              m0ts.name1=mode0info.pname1
              m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
              m0ts.end1=format(mode0info.offtime1, 'HH:mm')
              m0ts.eff1=format(mode0info.stime1, 'HH:mm')
              
              m0ts.name2=mode0info.pname2
              m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
              m0ts.end2=format(mode0info.offtime2, 'HH:mm')
              m0ts.eff2=format(mode0info.stime2, 'HH:mm')
            }else{
              m0ts.name1=mode0info.pname1
              m0ts.begin1=format(mode0info.ontime1, 'HH:mm')
              m0ts.end1=format(mode0info.offtime1, 'HH:mm')
              m0ts.eff1=format(mode0info.stime1, 'HH:mm')
              
              m0ts.name2=mode0info.pname2
              m0ts.begin2=format(mode0info.ontime2, 'HH:mm')
              m0ts.end2=format(mode0info.offtime2, 'HH:mm')
              m0ts.eff2=format(mode0info.stime2, 'HH:mm')
              
              m0ts.name3=mode0info.pname3
              m0ts.begin3=format(mode0info.ontime3, 'HH:mm')
              m0ts.end3=format(mode0info.offtime3, 'HH:mm')
              m0ts.eff3=format(mode0info.stime3, 'HH:mm')
            }
            break;
          case 1:                
            num=mode1info.mode1num;                
            if(num==0){
              m1ts.name1=mode1info.pname1
              m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
              m1ts.end1=format(mode1info.offtime1, 'HH:mm')
              m1ts.eff1=format(mode1info.stime1, 'HH:mm')
              m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
              m1ts.flag11=mode1info.flag11
              m1ts.flag12=mode1info.flag12
              m1ts.flag13=mode1info.flag13
              m1ts.flag14=mode1info.flag14
            }else if(num==1){
              m1ts.name1=mode1info.pname1
              m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
              m1ts.end1=format(mode1info.offtime1, 'HH:mm')
              m1ts.eff1=format(mode1info.stime1, 'HH:mm')
              m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
              m1ts.flag11=mode1info.flag11
              m1ts.flag12=mode1info.flag12
              m1ts.flag13=mode1info.flag13
              m1ts.flag14=mode1info.flag14
              m1ts.name2=mode1info.pname2
              m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
              m1ts.end2=format(mode1info.offtime2, 'HH:mm')
              m1ts.eff2=format(mode1info.stime2, 'HH:mm')
              m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
              m1ts.flag21=mode1info.flag21
              m1ts.flag22=mode1info.flag22
              m1ts.flag23=mode1info.flag23
              m1ts.flag24=mode1info.flag24
            }else{
              m1ts.name1=mode1info.pname1
              m1ts.begin1=format(mode1info.ontime1, 'HH:mm')
              m1ts.end1=format(mode1info.offtime1, 'HH:mm')
              m1ts.eff1=format(mode1info.stime1, 'HH:mm')
              m1ts.noeff1=format(mode1info.etime1, 'HH:mm')
              m1ts.flag11=mode1info.flag11
              m1ts.flag12=mode1info.flag12
              m1ts.flag13=mode1info.flag13
              m1ts.flag14=mode1info.flag14
              m1ts.name2=mode1info.pname2
              m1ts.begin2=format(mode1info.ontime2, 'HH:mm')
              m1ts.end2=format(mode1info.offtime2, 'HH:mm')
              m1ts.eff2=format(mode1info.stime2, 'HH:mm')
              m1ts.noeff2=format(mode1info.etime2, 'HH:mm')
              m1ts.flag21=mode1info.flag21
              m1ts.flag22=mode1info.flag22
              m1ts.flag23=mode1info.flag23
              m1ts.flag24=mode1info.flag24
              m1ts.name3=mode1info.pname3
              m1ts.begin3=format(mode1info.ontime3, 'HH:mm')
              m1ts.end3=format(mode1info.offtime3, 'HH:mm')
              m1ts.eff3=format(mode1info.stime3, 'HH:mm')
              m1ts.noeff3=format(mode1info.etime3, 'HH:mm')
              m1ts.flag31=mode1info.flag31
              m1ts.flag32=mode1info.flag32
              m1ts.flag33=mode1info.flag33
              m1ts.flag34=mode1info.flag34
            }
            break;
        }
        let postData = {};
        if (mode === 0) {
            postData = {
                userid: cur_user.UserID,
                firmname: form_value.firmname,
                address: form_value.address,
                address2: '',
                tel: form_value.tel,
                email: form_value.email,
                typeSP: form_value.Stype,
                equipname: '',
                equipaddress: '',
                firmlogo: '',
                memo: form_value.memo,
                mode:form_value.mode,
                mode0info:{...m0ts},
                mode1info:{},
                mode2info:{},
                mode3info:{}
                
            };
        } else if (mode === 1) {
            postData = {
                userid: cur_user.UserID,
                firmname: form_value.firmname,
                address: form_value.address,
                address2: '',
                tel: form_value.tel,
                email: form_value.email,
                typeSP: form_value.Stype,
                equipname: '',
                equipaddress: '',
                firmlogo: '',
                memo: form_value.memo,
                mode:form_value.mode,
                mode0info:{},
                mode1info:{...m1ts},
                mode2info:{},
                mode3info:{}
                
            };
        } 

        try {
            let res = {};
            if (isnew) {
                res = await Http.addFirmV2(postData);
            } else {
                postData = { companysn: this.props.curcpysn, ...postData };
                res = await Http.editCpyV2(postData);
            }
            const { errmsg, errcode } = res;
            if (errcode === 0) {
                this.props.cpyactions();
                this.componentDidMount();
                this.setState({
                    buttonLoading: false,
                    form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}, memo:profession[0].name},
                    errors: {
                        firmname: null,
                        tel: null,
                        address: null
                    },

                    touched: {
                        firmname: false,
                        tel: false,
                        address: false
                    },
                    isValid: false,
                    isLoading: false,
                    chagecpy:1
                });
                // this.props.refresh();
                this.handleClose();
            } else {
                this.setState({
                    buttonLoading: false,
                    chagecpy:0
                });
                // this.props.refresh();
                this.showMsg(errmsg)
                this.handleClose();
            }
        } catch (err) {
            this.setState({
                buttonLoading: false
            });
            console.log(err);
        }
    };

    delCpy= async()=>{
        const{companyList}=this.props
        const{profession}=this.state
        if(companyList.length==1){
            this.showMsg("最后一个单位不能删除")
            return
        }
        try {
            const res = await Http.delCpy(
                {
                    companysn: this.props.curcpysn
                },
                true
            );
            const { errcode, errmsg, data } = res;
            if (errcode === 0) {
                this.showMsg('删除成功');
                this.setState({
                    form_value:{...default_form_value, mode0info:{...default_form_value.mode0info}, mode1info:{...default_form_value.mode1info}, memo:profession[0].name},
                    errors: {
                        firmname: null,
                        tel: null,
                        address: null
                    },

                    touched: {
                        firmname: false,
                        tel: false,
                        address: false
                    },
                    isValid:false,
                    open: false,
                    depart_open: false,
                    isnew: false,
                    department_name:'',
                    chagedep:0,
                    chagecpy:0
                });
                await this.props.cpyactions();
                this.componentDidMount();
                let cpyList=companyList
                cpyList.filter(company=>company.CompanySN!=this.props.curcpysn)
                await this.props.changeCurCpy(cpyList[0].CompanyID, cpyList[0].CompanySN, cpyList[0].CompanyName)
                this.props.refresh()
            } else {
                this.handleClose();
                this.showMsg('删除失败（'+errmsg+'）');
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleSnackbarsClose = () => {
        this.setState({
            snackbarsMsg: '',
            snackbarsVisible: false
        });
    };
    showMsg = msg => {
        this.setState({
            snackbarsMsg: msg,
            snackbarsVisible: true
        });
        setTimeout(() => {
            this.setState({
                snackbarsMsg: '',
                snackbarsVisible: false
            });
        }, 2000);
    };

    handleEdit = group => {
        this.setState({
            select_group: group.GroupID,
            select_group_name: group.GroupName
        });
    };
    editDepartmentName = value => {
        this.setState({
            select_group_name: value
        });
    };
    saveEditDepartmentName = async () => {
        // groupname: zz
        // groupid: 38034
        const{select_group_name, select_group, cpySn}=this.state
        if (select_group_name.trim() === '') {
            this.showMsg('请输入部门名称');
        } else {
            try {
                const res = await Http.editBu(
                    {
                        groupname: select_group_name,
                        groupid: select_group
                    },
                    true
                );
                const { errcode } = res;
                if (errcode === 0) {
                    this.refreshGroup(cpySn);
                    this.editCancel();
                    this.showMsg('修改成功');
                } else {
                    this.showMsg('服务器开小差了，请稍后尝试');
                }
            } catch (err) {
                this.showMsg('服务器开小差了，请稍后尝试');
                console.log(err);
            }
        }
    };
    editCancel = () => {
        this.setState({ select_group: 0, select_group_name: '' });
    };
    refreshGroup = async sn => {
        try {
            const res = await Http.get_group(
                {
                    cpySn: sn,
                    _: new Date().getTime()
                },
                true
            );
            this.setState({
                departments: res.data
            });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes, className, companyList, changeCurCpy,  loading, curcpysn, cpy_name, refresh, accountInfo } = this.props;
        const { snackbarsVisible, snackbarsMsg, cur_user, open, buttonLoading, isValid,depart_open,
            cpySn,departments, touched, errors,form_value, isnew, num, profession,select_group,
            select_group_name,department_name
         } = this.state;

        const rootClassName = classNames(classes.root, className);
        const disableFirmname = touched.firmname && (errors.firmname?true:false)&&(errors.firmname==null?false:true);
        const disableAddress = touched.address && (errors.address?true:false)&&(errors.address==null?false:true); 
        const disableTel = touched.tel && (errors.tel?true:false)&&(errors.tel==null?false:true); 

        return (
            <Portlet className={rootClassName}>
                <SnackbarsMessage
                    handleClose={this.handleSnackbarsClose}
                    msg={snackbarsMsg}
                    visible={snackbarsVisible}
                />
                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'md'}
                    onClose={this.handleClose}
                    open={open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='span' variant="h4" >单位管理</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <CompanyForm2
                            changenum={this.changeNum}
                            cpylist={companyList}
                            cpysn={cpySn}
                            disableaddress={disableAddress}
                            disablefirmname={disableFirmname}
                            disabletel={disableTel}
                            errors={errors}
                            form_value={form_value}
                            handleclose={() => this.handleClose()}
                            handlefieldchange={this.handleFieldChange}
                            isnew={isnew}
                            // num={num}
                            profession={profession}
                            refresh={refresh}
                            touched={touched}
                            cur_user={cur_user}
                            delCpy={this.delCpy}
                            // timevalid={this.timeValid}
                            accountinfo={accountInfo}
                        />
                        <DialogActions>
                            <div style={{ position: 'relative' }}>
                                {buttonLoading && (
                                    <CircularProgress size={24} style={{ position: 'absolute', marginLeft: -12, marginTop: -12, top: '50%', left: '50%' }} />
                                )}
                                <Button color="primary" disabled={ buttonLoading || !isValid ||(form_value.mode==1 && cur_user.isVip<=0) } onClick={this.saveCpy} variant="outlined" >
                                    保存
                                </Button>
                            </div>    
                            <Button color="primary" onClick={this.handleClose} variant="outlined" >
                                取消
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <PortletHeader>
                    <PortletLabel
                        subtitle="选择列表中需要修改的单位，可以管理和设置单位信息"
                        title="单位列表"
                    />
                </PortletHeader>
                <LoadingOverlay active={loading} spinner text="正在加载">
                <PortletContent>
                    <List
                        aria-label="Contacts"
                        className={classes.root}
                        component="nav"
                    >
                        {companyList.map(company => (
                            <ListItem
                                button
                                key={company.CompanySN}
                                onClick={() =>
                                    changeCurCpy(
                                        company.CompanyID,
                                        company.CompanySN,
                                        company.CompanyName
                                    )
                                }
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            company.CompanySN === curcpysn
                                        }
                                        // disableRipple
                                        // edge="start"
                                        // inputProps={{ 'aria-labelledby': labelId }}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={company.CompanyName}
                                    secondary={
                                        <span>
                                            <span> {'单位号: ' + company.CompanySN} </span> &nbsp;&nbsp; <span> {'部门数量: ' + company.detail.deptNum} </span> <br />
                                            <span> {'员工人数: ' + company.detail.userNum} </span> &nbsp;&nbsp; <span> {'签到设备数量: ' + company.detail.wifiNum} </span>
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Button color="primary" size="small" variant="outlined"
                                        disabled={ !( company.CompanySN === curcpysn ) }
                                        onClick={() => this.handleDepart(company) }
                                    >
                                        部门管理
                                    </Button>
                                    <Button color="primary" size="small" style={{ marginLeft: '5px' }} variant="outlined" 
                                        disabled={ !( company.CompanySN === curcpysn ) }
                                        onClick={() => this.handleCompanyEdit(company) }
                                    >
                                        修改单位详情
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </PortletContent>
                {cur_user ? (
                    ['AdminB', 'AdminC'].includes( cur_user.RoleCode ) ? null : (
                        <PortletFooter className={classes.portletFooter}>
                            <Button color="primary" onClick={this.handleNewCpy} style={{ float: 'right' , marginBottom:20}} variant="outlined" >
                                新增单位
                            </Button>
                        </PortletFooter>
                    )
                ) : null}
                </LoadingOverlay>

                <Dialog
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth={'sm'}
                    onClose={this.handleClose}
                    open={depart_open}
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography component='div' variant="h4" style={{marginTop:10}} >
                            <Typography component='div' variant="h4" >部门管理</Typography>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Portlet  className={rootClassName}>
                            <PortletHeader>
                                <PortletLabel title={cpy_name}
                                    subtitle={ <span> 单位部门管理{' '}  <span style={{ color: 'blue' }}> 点击部门名称可以编辑部门 </span> </span> }
                                />
                            </PortletHeader>
                            <PortletContent>
                                <List dense={false}>
                                    {departments.map(department => (
                                        <ListItem key={department.GroupID}>
                                            <ListItemAvatar>
                                                <Avatar style={{ color: 'green' }} >
                                                    <BookmarkBorderOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            {select_group === department.GroupID ? (
                                                <div>
                                                    <TextField margin="dense" required value={ select_group_name } variant="outlined"
                                                        onChange={e => this.editDepartmentName( e.target.value )}
                                                    />
                                                    <Button color="primary" onClick={ this.saveEditDepartmentName }
                                                        style={{ marginLeft: '10px', marginTop: '10px' }} 
                                                        // variant="outlined"
                                                    >
                                                        保存
                                                    </Button>
                                                    <Button color="primary" onClick={ this.editCancel } style={{ marginLeft: '10px', marginTop: '10px' }} 
                                                        // variant="outlined" 
                                                    >
                                                        取消
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Button style={{textTransform:'none'}} onClick={() => this.handleEdit( department )} >
                                                    {department.GroupName}
                                                </Button>
                                            )}
                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="Delete" edge="end" onClick={() => this.delete_department( department.GroupID )} >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                    <Divider />
                                </List>

                                <div className={classes.field}>
                                    <TextField
                                        className={classes.textField}
                                        label="新部门"
                                        margin="dense"
                                        onChange={e =>this.changeDepartmentName(e.target.value)}
                                        style={{ width: '70%' }}
                                        value={department_name}
                                        variant="outlined"
                                    />
                                    <Button color="primary"
                                        // className={classes.textField}
                                        disabled={ department_name === ''? true:false }
                                        edge="end"
                                        onClick={this.add_department}
                                        style={{ width: '20%', float: 'right', marginTop: '10px' }}
                                        // variant="outlined"
                                    >
                                        新增部门
                                    </Button>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleClose} variant='outlined'>
                            关闭
                        </Button>
                    </DialogActions>
                </Dialog>
            </Portlet>
        );
    }
}

InfoSettings.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cpyList: state.cpy_list
});

const mapDispatchToProps = dispatch => ({
    cpyactions: data => dispatch(cpyactions.getcpylist(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(InfoSettings));
