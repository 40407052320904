import React, { Component } from 'react';

import { connect } from 'react-redux';
import Http from 'services/http';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/styles';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
    Budget,
    Users,
    Progress,
    Profit,
    SalesChart,
    DevicesChart,
    ProductList,
    OrdersTable,
    LineChart
} from './components';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
    // item: {
    //     height: '100%'
    // }
});

class Dashboard extends Component {
    state = {
        dashboard_data: {}
    };
    async componentDidMount() {
        const cur_cpy = JSON.parse(localStorage.getItem('cur_cpy'));
        try {
            const res = await Http.comerate({
                companysn: cur_cpy.CompanySN
            });
            const { errcode, data } = res;

            if (errcode === 0) {
                this.setState({
                    dashboard_data: data
                });
            } else console.log(res.errmsg);
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        const { classes, loginUser } = this.props;

        return (
            <DashboardLayout loginUser={loginUser} title="Dashboard">
                <div className={classes.root}>
                    <Grid container spacing={4}>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <Budget
                                dashboard_data={this.state.dashboard_data}
                                className={classes.item}
                            />
                        </Grid>
                        {/* <Grid item lg={3} sm={6} xl={3} xs={12}> */}

                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <Users
                                dashboard_data={this.state.dashboard_data}
                                className={classes.item}
                            />
                        </Grid>
                        {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <Progress className={classes.item} />
                        </Grid> */}
                        {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <Profit className={classes.item} />
                        </Grid> */}

                        {/* <Grid item lg={8} md={12} xl={9} xs={12}>
                            <SalesChart className={classes.item} />
                        </Grid> */}
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                            <SalesChart className={classes.item} />
                        </Grid>
                        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
                            <DevicesChart className={classes.item} />
                        </Grid> */}
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                            <LineChart className={classes.item} />
                        </Grid>

                        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
                            <ProductList className={classes.item} />
                        </Grid>
                        <Grid item lg={8} md={12} xl={9} xs={12}>
                            <OrdersTable className={classes.item} />
                        </Grid> */}
                    </Grid>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = state => ({
    loginUser: state.sign_in
});

const mapDispatchToProps = dispatch => ({
    fetchDashboard: () => dispatch(() => {})
});

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Dashboard));
