import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductCategories from './HelpPageComponent/modules/views/ProductCategories';
import AppFooter from './modules/views/AppFooter';
import ProductValues from './HelpPageComponent/modules/views/ProductValues';
import AppAppBar from './HelpPageComponent/modules/views/AppAppBar';
import AutoImport from './HelpPageComponent/modules/views/AutoImport';
import IntSystem from './HelpPageComponent/modules/views/IntSystem';
import AppUse from './HelpPageComponent/modules/views/AppUse';
// import LastPage from './HelpPageComponent/modules/views/LastPage';

function Index() {
    return (
        <React.Fragment>
            <AppAppBar />
            <ProductValues />
            <ProductCategories />
            <AutoImport />
            <IntSystem />
            <AppUse />
            {/* <LastPage /> */}
            <AppFooter />
        </React.Fragment>
    );
}

export default withRoot(Index);
