import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid';

import mainImg from 'static/批量1.png';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4)
    },

    image: {
        width: '100%',
        top: '1'
    },
    text: {
        // marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    }
});

function ProductCategories(props) {
    const { classes } = props;

    return (
        <Container className={classes.root} component="section">
            <Typography
                // align="center"
                className={classes.text}
                component="h4"
                // marked="center"
                style={{ fontSize: '24px' }}
                variant="h4"
            >
                批量操作很简单，人员维护轻松处理
            </Typography>
            <Grid container spacing={5}>
                <Grid item md={12} xs={12}>
                    <div>
                        <img
                            alt="导出"
                            className={classes.image}
                            src={mainImg}
                        />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

ProductCategories.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategories);
